import {Link} from "react-router-dom";
import SiteService from "../../../services/SiteService";
import CategoryInImage from "../CategoryInImage";

export default function ContentBlock5({parts, customStyle}) {
	let part = {};
	if (!parts || !parts[0]) {
		console.log('ContentBlock5 has no parts');
		part = {
			imageLink: `${SiteService.apiPath}/image/articles/640f3f77081a28d9d3a1d4db/header.jpg`,
			name: 'Gå til bæsj og ta en drit i bar',
			preface: 'KJAWdh paiudhawiu dhOAWUd hawdh9 129hd921q nd+2" AHD aWd AdlkjAHWd hAK DwAd øhA',
		}
	}else{
		part = parts[0];
	}

	 return <Link to={part.linkTo?part.linkTo:''} className={'contentBlock5 contentBlock'} style={customStyle?customStyle:null}>
		 <div className={'contentBlock5Part1 contentImageContainer'}>
			 <img src={part.imageLink}/>
			 <CategoryInImage customStyle={customStyle} part={part}/>
		 </div>

		 <div className={'contentBlock5Part2'}>
			 <h2>
			 {part.frontpageTitle?
				 <div dangerouslySetInnerHTML={{__html:part.frontpageTitle}}></div>
				 :
				 <>{part.name?part.name:'Tittel'}</>
			 }
			 </h2>
			 <p>{part.preface}</p>
		 </div>
	 </Link>
}