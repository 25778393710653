import {useRef} from "react";
import classes from "../../../pages/admin/ArticleEdit.module.scss";
import Checkbox from "../../ui/Checkbox";

export default function YoutubeEditor({valueRef, part, setPart}){
	const ref = useRef(part.value)
	const variantRef = useRef(part.variant)

	const handleChange = (e, property)=>{
		valueRef.current[property] = e.target.value;
	}

	const handleChangeAutoStart = (checked)=>{
		setPart({...part, autoplay: checked});
		part.autoplay = checked;
	}

	return(<>
		<label>Utseende</label>
		<select style={{width: "auto"}} defaultValue={part.variant} ref={variantRef} className={classes.bodyPart}
				required onChange={(ev) => {
			handleChange(ev, 'variant');
		}}>
			<option value={"1"}>Normal</option>
			<option value={"2"}>Full-skjermbredde</option>
		</select>
		<input type={'text'} placeholder="Youtube-URL" defaultValue={part.value} ref={ref} className={classes.bodyPart}
			   required onChange={(ev) => {
			handleChange(ev, 'value');
		}}/>

		<br/>
		<Checkbox label={"Autostart (fjerner lyd og knapper)"} setState={handleChangeAutoStart} state={part.autoplay}/>
	</>)
}