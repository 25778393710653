import axios from "axios";
import SiteService from "./SiteService";

class FileUploadService {
	upload(path, file, onUploadProgress, newName) {
		let formData = new FormData();
		console.log(newName);
		console.log(file);
		formData.append("file", file, newName+'.'+file.name.split('.')[1]);

		axios.request(
			{
				url: `${SiteService.apiPath}/imageupload/`+path,
				method: "POST",
				data: formData,
				headers: {
				/*	"Content-Type": "multipart/form-data",**/
				},
				onUploadProgress: onUploadProgress,
			}
		).then((response)=> {
			return response;
		})
		.then((data)=>{
			return onUploadProgress('finished');
		})
		.catch(()=>{
			onUploadProgress('error');
		})
		return true;
	}

	getFiles() {
		fetch(`${SiteService.apiPath}/uploadedfiles`,
		).then((response)=> {
			return response.json();
		})
		.then((data)=>{
			return data;
		})
	}
}

export default new FileUploadService();