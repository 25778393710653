import {useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import SiteService from "../services/SiteService";
import AdvertService from "../services/AdvertService";
import Checkbox from "../components/ui/Checkbox";
import Helpers from "../helpers/Helpers";

export default function AdvertViews(){
	const advertId = useParams().advertId;
	if (!advertId) return <div>Fant ikke annonse-id</div>;
	const [scriptLoaded, setScriptLoaded] = useState(false);
	const chart = useRef(null);

	const [advert, setAdvert] = useState(null);
	const [advertStats, setAdvertStats] = useState();
	const [ctr, setCtr] = useState(null);
	const [advertViewsGrouped, setAdvertViewsGrouped] = useState();
	const getCurrentDatePlusOne = () => {
		const today = new Date();
		today.setDate(today.getDate() + 1);
		return today.toISOString().substring(0, 10);
	};

	const [viewOptions, setViewOptions] = useState({
		timeframe: 'week',
		startDate: null, // set a default start date here if needed
		endDate: getCurrentDatePlusOne(),
		showClicks: true,
		showViews: true,
	});
	const [firstLastDates, setFirstLastDates] = useState(null);

	async function start() {
		await import('../redis/chart.min.js');
		let advertStats = await getAdvertViews();
		let firstLastDates = getFirstLastDates(advertStats);
		if (viewOptions.timeframe === "month")
			setAdvertViewsGrouped(groupByMonth(getDataWithinTimeRange(advertStats,firstLastDates[0],firstLastDates[1])));
		else if (viewOptions.timeframe === "week")
			setAdvertViewsGrouped(groupByWeek(getDataWithinTimeRange(advertStats,firstLastDates[0],firstLastDates[1])));
		else if (viewOptions.timeframe === "day")
			setAdvertViewsGrouped(getDataWithinTimeRange(advertStats,firstLastDates[0],firstLastDates[1]));

		const advert = await AdvertService.getOne(advertId);
		setCtr(((advert.clickCount/advert.viewCount)*100).toString().substring(0,4));
		setAdvert(advert);

		setScriptLoaded(true);
	}

	const getDataWithinTimeRange = (data, startDate, endDate) => {
		// Convert the startDate and endDate to Date objects for comparison
		const start = new Date(startDate);
		const end = new Date(endDate);

		console.log(startDate)
		console.log(endDate)

		// Filter the data array to include only items within the specified date range
		const filteredData = data.filter(item => {
			const itemDate = new Date(item.date);
			return itemDate >= start && itemDate <= end;
		});

		// Return the filtered data with dates formatted as "YYYY-MM-DD"
		return filteredData.map(item => ({
			...item,
			date: item.date.slice(0, 10) // Extract the "YYYY-MM-DD" part of the date
		}));
	};

	const getFirstLastDates = (advertStats)=>{
		let first = advertStats[0].date.substring(0,10), last = advertStats[advertStats.length-1].date.substring(0,10);
		setFirstLastDates([first,last]);
		setViewOptions({...viewOptions, startDate: first})
		return [first, last]
	};

	useEffect(() => {
		if (!scriptLoaded) start();
	}, [scriptLoaded]);

	useEffect(() => {
		if (!advertStats) return;
		if (viewOptions.timeframe === "month")
			setAdvertViewsGrouped(groupByMonth(getDataWithinTimeRange(advertStats,viewOptions.startDate,viewOptions.endDate)));
		else if (viewOptions.timeframe === "week")
			setAdvertViewsGrouped(groupByWeek(getDataWithinTimeRange(advertStats,viewOptions.startDate,viewOptions.endDate)));
		else if (viewOptions.timeframe === "day")
			setAdvertViewsGrouped(getDataWithinTimeRange(advertStats,viewOptions.startDate,viewOptions.endDate));
	}, [viewOptions]);

	useEffect(() => {
		if (!advertViewsGrouped) return;
		createChart(advertViewsGrouped, 'Visninger');
	}, [advertViewsGrouped]);

	const getAdvertViews = async () => {
		try {
			let response = await fetch(`${SiteService.apiPath}/advertviews/${advertId}`);
			if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

			let advertViews = await response.json();

			// Sort the advertViews by date
			advertViews.sort((a, b) => new Date(a.date) - new Date(b.date));

			setAdvertStats(advertViews);
			return advertViews;
		} catch (e) {
			console.error(e);
			alert('Feil under henting av annonse-visninger.');
		}
	};

	const groupByMonth = (data) => {
		const result = {};

		data.forEach(({ date, views, clicks }) => {
			const month = date.slice(0, 7); // Extract YYYY-MM from date

			if (!result[month]) {
				result[month] = { month, views: 0 , clicks: 0};
			}

			result[month].views += views;
			result[month].clicks += clicks;
		});

		// Convert the result object to an array and sort by month (YYYY-MM)
		const months = Object.values(result).map(({ month, views, clicks }) => ({
			date: month,
			views,
			clicks
		})).sort((a, b) => a.date.localeCompare(b.date));

		console.log(months);
		return months;
	};

	const groupByWeek = (data) => {
		const result = {};

		data.forEach(({ date, views, clicks }) => {
			const week = Helpers.getWeekNumber(date); // Extract week number and year from date

			if (!result[week]) {
				result[week] = { week, views: 0, clicks: 0 };
			}

			result[week].views += views;
			result[week].clicks += clicks;
		});

		// Convert the result object to an array and sort by week
		const weeks = Object.values(result).map(({ week, views, clicks }) => ({
			date: week,
			views,
			clicks
		})).sort((a, b) => a.date.localeCompare(b.date));

		console.log(weeks);
		return weeks;
	};


	const createChart = async (data) => {
		if (data) {
			try {
				if (chart.current) chart.current.destroy();
				const ctx = document.getElementById("chart").getContext("2d");

				const dates = data.map(v => v.date.substring(0,10));
				const views = data.map(v => v.views);
				const clicks = data.map(v => v.clicks);

				chart.current = new Chart(ctx, {
					type: 'line',
					data: {
						labels: dates,
						datasets: [
							...(viewOptions.showViews?[{
								label: 'Visninger',
								data: views,
								backgroundColor: 'rgba(75, 192, 192, 0.5)',
								borderColor: 'rgba(75, 192, 192, 1)',
								borderWidth: 1
							}]:[]),
							...(viewOptions.showClicks?[{
								label: 'Klikk',
								data: clicks,
								backgroundColor: 'rgba(255, 99, 132, 0.8)',
								borderColor: 'rgba(255, 99, 132, 1)',
								borderWidth: 1
							}]:[])
						]
					},
					options: {
						scales: {
							x: {
								stacked: false
							},
							y: {
								beginAtZero: true,
								stacked: false
							}
						}
					}
				});
			} catch (error) {
				console.error('Failed to load Chart.js:', error);
			}
		}
	};

	return <div style={{background: 'rgb(243, 243, 243)',padding:'1em 0'}}><div className={'centered'} style={{width:'800px',maxWidth:'94vw', background: 'white',
		padding: '1.5rem', borderRadius: '13px',marginBottom: '1em'}}>
		<h1>Annonse{advert?.name && <> - {advert.name}</>}</h1>
		<div>
			<div style={{display:'flex', alignItems:'center', justifyContent: 'space-between'}}>
				{firstLastDates &&<div>Data fra <b>{firstLastDates[0]}</b> til <b>{firstLastDates[1]}</b></div>}
				<div>
					Total: Visninger: <b>{advert?.viewCount}</b>, Klikk: <b>{advert?.clickCount}</b>, CTR: <b>{ctr}%</b>
				</div>
			</div>
			<button style={{marginLeft:'auto',fontFamily:'var(--font1)'}} className={'button1'} onClick={()=>{Helpers.downloadCSV(
				Helpers.convertToCSV(advertStats,['date','clicks','views'])
				,'annonse_'+advert.name)}}><u>↓</u>&nbsp; Last ned CSV-fil</button>
			<hr/>
			<div style={{textAlign:'right',marginBottom:'0.4rem'}}><i>Obs: Tabell har ikke data fra før 21. Juni 2024</i></div>
			<div style={{display:'flex', alignItems:'center', justifyContent: 'space-between', gap:'1rem', flexWrap:'wrap'}}>
				{firstLastDates && <div className={"input1"} style={{display:'inline-flex', alignItems:'center', margin:'0.1em'}}>
					<label>
						Fra:&nbsp;
						<input type={"date"} value={viewOptions.startDate} min={firstLastDates[0]} max={firstLastDates[1]}
							   onChange={
								   (ev) => {
									   setViewOptions({...viewOptions, startDate: ev.currentTarget.value})
								   }
					   }/>
					</label>
					&nbsp;
					<label>
						Til:&nbsp;
						<input type={"date"} value={viewOptions.endDate} min={firstLastDates[0]}
							   max={getCurrentDatePlusOne()} onChange={
							(ev) => {
								setViewOptions({...viewOptions, endDate: ev.currentTarget.value})
							}
						}/>
					</label>
				</div>}

				<label>
					Grupper etter
					<select className={'select1'} value={viewOptions.timeframe} onChange={(ev) => {
						setViewOptions({...viewOptions, timeframe: ev.target.value})
					}} style={{width:'fit-content', display:'inline-flex'}}>
						<option value={'day'}>Dag</option>
						<option value={'week'}>Uke</option>
						<option value={'month'}>Måned</option>
					</select>
				</label>
				<div style={{display:'inline-flex',gap:'0.5rem'}}>
					<Checkbox style={{display:'inline-flex'}} label={'Visninger'} state={viewOptions.showViews} changeFunc={(newVal)=>{
						setViewOptions({...viewOptions, showViews:newVal})
					}}/>
					<Checkbox style={{display:'inline-flex'}} label={'Klikk'} state={viewOptions.showClicks} changeFunc={(newVal)=>{
						setViewOptions({...viewOptions, showClicks:newVal})
					}}/>
				</div>
			</div>
		</div>
		<div>
			<br/>
			<canvas id="chart" width={'500'} height={'500'}
					style={{width: '400px', height: '400px', maxHeight: '70vh'}}/>
		</div>
	</div></div>
}