import advertService from "../../../services/AdvertService";
import SiteService from "../../../services/SiteService";
import {useContext, useRef, useState} from "react";
import adPlacementService from "../../../services/AdPlacementService";
import ContentBlockWrapper from "../ContentBlockWrapper";
import userContext from "../../../store/userContext";
import useInView from "../../../hooks/useInView";

export default function AdvertBlock8({parts, adPlacementId, customStyle}) {// Netboard
	if (!parts)
		parts = [{}];

	const id = adPlacementId?adPlacementId:parts[0].id;

	const [ad, setAd] = useState(null);

	const adRef = useRef();
	const role = useContext(userContext).role;
	const handleInView = () => {
		if(!id || ad) return;
		adPlacementService.getById(id, (ad)=>{
			setAd(ad);
		}, role > 0)
	};
	useInView(adRef, {rootMargin:'100px'}, handleInView, true);

	return <ContentBlockWrapper customStyle={customStyle?customStyle:null}>
		<a ref={adRef} className={'advertBlock8 advBlock contentBlock'} href={(ad&&ad.link) ? ad.link : ''} target={"_blank"}
			  onClick={() => advertService.advertOnclick(ad._id)}>
		<picture>
			<source media="(max-width: 810px)"
					srcSet={(ad&&ad.wideImage) ? `${SiteService.apiPath}/image/adv/${ad.mobileImage}` : null}/>
			<img className="advertBlock8Image"
				 src={(ad&&ad.wideImage) ? `${SiteService.apiPath}/image/adv/${ad.wideImage}` : null}
				 sizes="(max-width: 810px) 100vw, 810px"
				 alt="Annonse"/>
		</picture>
	</a></ContentBlockWrapper>
}