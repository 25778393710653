import StyleEditor from "../../ui/functional/StyleEditor";
import {useEffect, useState} from "react";

export default function ArticleButtonEditor({valueRef, part, setPart}){
	if (!part){
		part = {
			kind: '',
			text: '',
			link: '',
			style: {}
		}
	}

	const [style, setStyle] = useState(part.style? part.style : {})
	useEffect(() => {
		if (valueRef.current){
			valueRef.current.style = style? style : null;
		}
	}, [style]);

	const editProperty = (propName, value) => {
		setPart({...part, [propName]: value})
	};

	return <div>
		<StyleEditor style={style} setStyle={setStyle} />
		<br/>
		<div><label>Tekst</label>
		<input type="text" defaultValue={part.text} onBlur={(e) => editProperty('text', e.target.value)} />
		</div>
		<br/>
		<div>
			<label>Lenke</label>
			<input type={"text"} defaultValue={part.link} onBlur={(e) => editProperty('link', e.target.value)} />
			<div>For lokale lenker, start lenken med skråstrek /.</div>
			<div>Eks: http://b-v.no/artikler, blir /artikler.</div>
			<div>Da laster siden raskere.</div>
		</div>
	</div>
}