import {useEffect, useRef, useState} from "react";
import classes from "../../../components/adminEdit/AdminEdit.module.scss";
import AdminEdit from "../../../components/adminEdit/AdminEdit";

import SiteService from "../../../services/SiteService";
import AdminSubscriptionProductService from "../../../services/AdminSubscriptionProductService";
import SelectManyFromList from "../../../components/ui/functional/SelectManyFromList";
import AdminSubscriptionPlanService from "../../../services/AdminSubscriptionPlanService";

function SubscriptionProductEdit(){

	const [isLoading, setIsLoading] = useState(true);
	const [subscriptionProducts, setSubscriptionProducts] = useState([]);
	const [subscriptionPlans, setSubscriptionPlans] = useState([]);

	useEffect(()=>{
		AdminSubscriptionPlanService.getAll((reqItem)=>{
			setSubscriptionPlans(reqItem);
			setIsLoading(false);
		})
	}, [])


	function EditList({obj, handleChange, index}){
		//const optionsList = [{name:'poopoo', _id:'n73b'},{name:'peepee', _id:'c42t42t2'},{name:'båsj', _id:'b1b90n'},];
		console.log(obj)
		const [selectedList, setSelectedList] = useState(obj.subscriptionPlanIds);

		const setSelectedListFunc = function (newList){
			setSelectedList(newList);
			handleChange('subscriptionPlanIds', newList, index);
		}

		return(
			<SelectManyFromList
				list={subscriptionPlans}
				selectedListState={selectedList}
				setSelectedListState={setSelectedListFunc}>
			</SelectManyFromList>
		)
	}

	function TableHeaders(){return(
		<div className={classes.th}>
			<div className={classes.td}></div>
			<div className={classes.td}>Navn</div>
			<div className={classes.td}>Betalingsplan</div>
		</div>
	)}

	function UnEditableSectionChildren({obj, handleChange, index}){
		return(<div className={classes.td}>
			{obj.isEditable ?
				<EditList obj={obj} index={index} handleChange={handleChange}/>
				:
				<>{obj.subscriptionPlanIds.map((plan)=>{
					return(
						<div>{subscriptionPlans.find((el) => el._id === plan).name}</div>
				)})}</>
			}

		</div>)
	}

	function EditableSectionChildren({obj, index, handleChange}) {
		return(<>

	</>)}




	return(
		<AdminEdit
			pageName={"Abonnement-produkter"}
			isLoading={isLoading}
			mainService={AdminSubscriptionProductService}
			mainArray={subscriptionProducts}
			setMainArray={setSubscriptionProducts}
			NewSectionBool={false}
			TableHeaders={TableHeaders}
			EditableSectionChildren={EditableSectionChildren}
			UnEditableSectionChildren={UnEditableSectionChildren}>
		</AdminEdit>
	)
}

export default SubscriptionProductEdit;