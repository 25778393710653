import SiteService from "./SiteService";
import ArticleImage from "../components/articleEdit2/rendered/ArticleImage";
import ImageEditor from "../components/articleEdit2/bodypartEditors/ImageEditor";
import ImageCarouselEditor from "../components/articleEdit2/bodypartEditors/ImageCarouselEditor";
import ArticleImageCarousel from "../components/articleEdit2/rendered/ArticleImageCarousel";
import QuoteEditor from "../components/articleEdit2/bodypartEditors/QuoteEditor";
import ArticleQuote from "../components/articleEdit2/rendered/ArticleQuote";
import EmbedEditor from "../components/articleEdit2/bodypartEditors/EmbedEditor";
import ArticleYoutube from "../components/articleEdit2/rendered/ArticleYoutube";
import YoutubeEditor from "../components/articleEdit2/bodypartEditors/YoutubeEditor";
import SplitEditor from "../components/articleEdit2/bodypartEditors/SplitEditor";
import ArticleSplit from "../components/articleEdit2/rendered/ArticleSplit";
import ArticleVideo from "../components/articleEdit2/rendered/ArticleVideo";
import ArticleVideoEditor from "../components/articleEdit2/bodypartEditors/ArticleVideoEditor";
import ArticleTextEditor from "../components/articleEdit2/bodypartEditors/ArticleTextEditor";
import ArticleText from "../components/articleEdit2/rendered/ArticleText";
import ArticleSuggestionsEditor from "../components/articleEdit2/bodypartEditors/ArticleSuggestionsEditor";
import ArticleArticleSuggestions from "../components/articleEdit2/rendered/ArticleArticleSuggestions";
import ArticleButtonEditor from "../components/articleEdit2/bodypartEditors/ArticleButtonEditor";
import ArticleButton from "../components/articleEdit2/rendered/ArticleButton";
import ArticleTableEditor from "../components/articleEdit2/bodypartEditors/ArticleTableEditor";
import ArticleTable from "../components/articleEdit2/rendered/ArticleTable";
import ArticleBoatFactsEditor from "../components/articleEdit2/bodypartEditors/ArticleBoatFactsEditor";
import ArticleBoatFacts from "../components/articleEdit2/rendered/ArticleBoatFacts";
import ArticleImageGallery from "../components/articleEdit2/rendered/ArticleImageGallery";
import AdvertBlock5 from "../components/frontpage/content-blocks/AdvertBlock5";
import ArticleBatsokBoatsEditor from "../components/articleEdit2/bodypartEditors/ArticleBatsokBoatsEditor";
import BatsokBlock1 from "../components/frontpage/content-blocks/BatsokBlock1";
import ArticleEmbed from "../components/articleEdit2/rendered/ArticleEmbed";

const ArticleService = {
	getUrlHeaderImage: function (article){
		return `${SiteService.apiPath}/image/articles/${article._id}/`+article.image.url;
	},

	delete: async function(id, cb){
		if (!confirm('Slett artikkel?'))
			return;
		fetch(`${SiteService.apiPath}/article/`+id+'/delete',
			{
				method: "POST",
			}).then((response)=> {
			return response.json();
		}).then((data)=>{
			if(data.success) {
				if(cb)
					cb(data);
			}else{
				confirm('Feil under sletting');
				if(cb)
					cb();
			}
		})
	}
};

ArticleService.moduleTypes = {
	'paragraph': {
		name: 'Tekst',
		component: ArticleText,
		editorComponent: ArticleTextEditor
	},
	'image': {
		name: 'Bilde',
		variations: ['normal', 'full', 'original'],
		component: ArticleImage,
		editorComponent: ImageEditor
	},
	'carousel': {
		name: 'Bilde-karusell',
		variations: ['normal', 'full'],
		editorComponent: ImageCarouselEditor,
		component: ArticleImageCarousel
	},
	'gallery': {
		name: 'Bilde-galleri',
		editorComponent: ImageCarouselEditor,
		component: ArticleImageGallery
	} ,
	'quote': {
		name: 'Sitat',
		editorComponent: QuoteEditor,
		component: ArticleQuote
	},
	/*'subtitle': {
		name: 'Undertittel',
		component: ArticleSubtitle,
		editorComponent: SubtitleEditor
	},*/
	'video': {
		name: 'Video',
		component: ArticleVideo,
		editorComponent: ArticleVideoEditor
	},
	'embed': {
		name: 'Innbygg',
		component: ArticleEmbed,
		editorComponent: EmbedEditor
	},
	'youtube':{
		name: 'Youtube',
		component: ArticleYoutube,
		editorComponent: YoutubeEditor
	},
	'split': {
		name: 'To-delt',
		editorComponent: SplitEditor,
		component: ArticleSplit
	},
	'articleSuggestions': {
		name: 'Artikler-anbefalinger',
		editorComponent: ArticleSuggestionsEditor,
		component: ArticleArticleSuggestions
	},
	'batsokSuggestions': {
		name: 'Båtsøk-anbefalinger',
		editorComponent: ArticleBatsokBoatsEditor,
		component: BatsokBlock1
	},
	'button': {
		name: 'Knapp-lenke',
		editorComponent: ArticleButtonEditor,
		component: ArticleButton
	},
	'table': {
		name: 'Tabell',
		editorComponent: ArticleTableEditor,
		component: ArticleTable
	},
	'boatFact': {
		name: 'Båt-fakta',
		editorComponent: ArticleBoatFactsEditor,
		component: ArticleBoatFacts
	},
	'ad': {
		name: 'Ad',
		component: AdvertBlock5,
		selectable: false
	},
}

export default ArticleService;