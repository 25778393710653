import UserContext from "../../store/userContext";
import {useContext, useState} from "react";
import BellIcon from "../../svg/BellIcon";
import {Link} from "react-router-dom";
import ArticleIcon from "../../svg/ArticleIcon";

export default function NotificationBell() {
	const userContext = useContext(UserContext);
	const notifications = userContext.notifications?userContext.notifications:[];
	const [isModalOpen, setIsModalOpen] = useState(false)

	const getUnreadCountToShow = () => {
		// get all notifications that are newer than userContext.notificationsLastRead and do not have .isRead set to true
		if (userContext.notificationsLastRead === null)
			return notifications.filter(notification =>!notification.isRead).length;
		else return notifications.filter(notification =>!notification.isRead && notification.date > userContext.notificationsLastRead).length;
	}

	const unreadCount = getUnreadCountToShow()

	const calculateHowLongAgoString = (date) => {
		// if < 29 days, return "x days ago", if more return "x months ago"
		// if less than a day, return "x hours ago"
		const now = new Date();
		const then = new Date(date);
		const diff = now - then;
		const days = Math.floor(diff / (1000 * 60 * 60 * 24));
		const months = Math.floor(diff / (1000 * 60 * 60 * 24 * 30.4167));
		const hours = Math.floor(diff / (1000 * 60 * 60));
		const minutes = Math.floor(diff / (1000 * 60));
		if (months > 12)
			return `${Math.floor(months / 12)} år siden`;
		else if (months > 0) {
			let isSingle = months === 1;
			return `${months} ${isSingle ? 'måned' : 'måneder'}`;
		} else if (days > 0){
			let isSingle = days === 1;
			return `${days} ${isSingle ? 'dag' : 'dager'}`;
		}else if (hours > 0){
			let isSingle = hours === 1;
			return `${hours} ${isSingle ? 'time' : 'timer'}`;
		}else if (minutes > 0){
			let isSingle = minutes === 1;
			return `${minutes} ${isSingle ? 'minutt' : 'minutter'}`;
		}else
			return 'nå';
	}

	const makeMessageIntoHTML = (message) => {
		// Replace <b>...</b> with <strong>...</strong> for valid HTML
		const htmlMessage = message.replace(/<b>(.*?)<\/b>/g, '<strong>$1</strong>');

		// Return as a JSX element with dangerouslySetInnerHTML
		return <span dangerouslySetInnerHTML={{ __html: htmlMessage }} />;
	}


	function Notifications() {
		const sortedNotifications = [...notifications]

		return sortedNotifications.map(notification => {
			let howLongAgo = calculateHowLongAgoString(notification.date);
			let messageAsHTML = makeMessageIntoHTML(notification.message);
			let link = null;
			if (notification.type === 'article')
				link = notification.linkFragment? `/artikkel/${notification.linkFragment}` : null;


			return (
				<Link to={link?link:''}
					className={['notification', notification.isRead ? 'isRead' : 'notRead'].join(' ')}
					key={notification._id}
				  	onClick={()=>{userContext.readNotification(notification._id); setIsModalOpen(false)}}
				>
					<ArticleIcon/>
					<div className={'notification-col2'}>
						<div className={'notificationText'}>{messageAsHTML}</div>
						{/*!notification.isRead && (
							<button
								className='notificationClose'
								onClick={() => {
									userContext.readNotification(notification._id);
								}}
							>
								👁
							</button>
						)*/}
						<div className='notificationHowLongAgo'>{howLongAgo}</div>
					</div>
				</Link>
			);
		});
	}

	return (
		<div className={'notificationBellContainer'}>
			<button className={'notificationBell'} onClick={() => {
				if (!isModalOpen && unreadCount > 0)
					userContext.updateNotificationsLastRead();
				setIsModalOpen(!isModalOpen)
			}}>
				{unreadCount>0&&<div className={'notificationCount'}>{unreadCount}</div>}
				<BellIcon/>
			</button>
			<div className={['notificationDialog', isModalOpen?'':'hidden'].join(' ')} open={isModalOpen}>
				<Notifications/>
			</div>
		</div>
	)
}