import SiteService from "./SiteService";

const AdPlacementService = {
	getById: function(id, cb, noCount){
		if(noCount) noCount = '?nocount=true';

		fetch(
			`${SiteService.apiPath}/advertplacement/id/${id}/${noCount?noCount:''}`,
			{
				method: "GET",
			}
		).then((response)=> {
			return response.json();
		})
		.then((data)=> {
			cb(data);
		}).catch((err)=>{
			console.error(err);
			console.log('Feil under henting av annonse-plassering')
		})
	},

	getAll: async function(cb) {
		try {
			const response = await fetch(
				`${SiteService.apiPath}/advertplacement/all`,
				{
					method: "GET",
				}
			);
			const data = await response.json();
			if (cb) {
				return cb(data);
			}else{
				return data;
			}
		} catch (err) {
			console.error(err);
			alert("Feil under henting av annonseplasseringer");
		}
	},

	create: function (adPlacement: {_id: string, name: string, advertIds: Array}, cb){
		fetch(
			`${SiteService.apiPath}/advertplacement/upsert`,
			{
				method: "POST",
				body: JSON.stringify({
					_id: adPlacement._id,
					name: adPlacement.name,
					advertIds: adPlacement.advertIds
				}),
				headers: {
					'Content-Type': 'application/json'
				}
			}
		).then((response)=> {
			return response.json();
		})
			.then((data)=> {
				if(data.success) {
					console.log('saved placement: ' + ' with Id: ' + data._id);
					if(cb) {
						data.name = adPlacement.name;
						cb(data);
					}
				}
			})
	},

	save: function (adPlacement: {_id: string, name: string, adverts: Array}, cb){
		fetch(
			`${SiteService.apiPath}/advertplacement/upsert`,
			{
				method: "POST",
				body: JSON.stringify({
					_id: adPlacement._id,
					name: adPlacement.name,
					adverts: adPlacement.adverts
				}),
				headers: {
					'Content-Type': 'application/json'
				}
			}
		).then((response)=> {
			return response.json();
		})
		.then((data)=> {
			if(data.success) {
				console.log('created adplacement: ' + name + ' with Id: ' + data._id);
				if(cb)
					cb(data._id);
			}
		}).catch((err)=>{
			console.error(err);
			alert('Feil under oppretting av annonse-plassering')
		})
	},

	delete: function (_id, cb){
		if (!confirm('Er du sikker på at du vil slette denne annonseplasseringen?')) {
			return;
		}
		fetch(
		`${SiteService.apiPath}/advertplacement/delete`,
		{
			method: "POST",
			body: JSON.stringify({
				_id: _id
			}),
			headers: {
				'Content-Type': 'application/json'
			}
		}
	).then((response)=> {
		return response.json();
	})
	.then((data)=> {
		if(data.success) {
			console.log('deleted cat with id: ' + _id);
			if(cb)
				cb(true);
		}
	}).catch((err)=>{
		if(err) {
			console.error(err);
			alert('Feil under sletting');
			return false;
		}
	})
}
}

export default AdPlacementService;