import React from 'react';

const PlusIcon = ({ width = 24, height = 24, color = 'currentColor' }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect x="11" y="5" width="2" height="14" fill={color} />
		<rect x="5" y="11" width="14" height="2" fill={color} />
	</svg>
);

export default PlusIcon;
