import {useRef} from "react";
import classes from "../../../pages/admin/ArticleEdit.module.scss";

export default function QuoteEditor({valueRef, part}) {
	const ref = useRef(part.value ? part.value : '')

	const handleChange = (e) => {
		valueRef.current.value = e.target.value;
	}


	return (<div>
		<br/>
		<label>Utseende</label>
		<select defaultValue={part.variant} onChange={(ev) => {
			valueRef.current.variant = ev.target.value
		}} name={"variant"}>
			<option value={null}>Tykk Strek</option>
			<option value={2}>Midtstilt-u.strek</option>
		</select>
		<textarea type="text" placeholder="Sitat" defaultValue={part.value} ref={ref} className={classes.bodyPart}
			   onChange={handleChange} required={true}/>
	</div>)
}