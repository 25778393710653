export function CrossButton({onclick}) {

	const style = {
		border: 		'1px solid black',
		background: 	"gray",
		color: 			"white",
		borderRadius: 	"50%",
		width: 			"21px",
		height:			"21px",
		aspectRatio: 	"1/1",
		cursor: 		"pointer",
		display: 		"inline-flex",
		justifyContent: "center",
		alignItems: 	"center",
		margin: '0 5px'
	}

	return (
			<button type={"button"} style={style} className={'buttonCross'} onClick={(ev)=>{ev.preventDefault(); if (onclick)onclick()}}>
				×
			</button>
		)
}