const SiteService = {};

SiteService.path = `${window.location.protocol+'//'+window.location.hostname}:${process.env.REACT_APP_APIPORT}`;
SiteService.apiPath = `${window.location.protocol+'//'+window.location.hostname}:${process.env.REACT_APP_APIPORT}/api`;

SiteService.errorCodeReasons = {
	 400: 'Feil med inndata',
	 401: 'Ugyldig brukernavn eller passord',
	 403: 'Ugyldig brukernavn eller passord',
	 404: 'Siden finnes ikke',
	 500: 'Feil oppstod hos serveren',

}

SiteService.handleError = async (error) => {
	let message = "";
	if (error.reason){
		message = error.reason;
	} else if (SiteService.errorCodeReasons[error.code]) {
		message = SiteService.errorCodeReasons[error.code];
	}

	return message;
}

export default SiteService;