import React, { useState, useEffect, useRef } from 'react';

const FadeIn = ({ children, propRef, fadeType, threshold}) => {
	const [isVisible, setIsVisible] = useState(false);
	const fadeInRef = propRef ? propRef : useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					if (propRef && propRef.current) {
						propRef.current.classList.remove('invisible');
						propRef.current.classList.add('visible');
					}
					setIsVisible(true);
					observer.disconnect();
				}
			},
			{
				threshold: threshold?threshold : 0.3, // Adjust threshold as needed
				rootMargin: '-60px 0px' // Adjust rootMargin
			}
		);

		if (fadeInRef.current) {
			observer.observe(fadeInRef.current);
		}

		// set classes on fadeInRef.current only if propRef is provided and propRef.current exists
		if (propRef && propRef.current) {
			propRef.current.classList.add('fade-in');
			propRef.current.classList.add('invisible');
			if (fadeType) propRef.current.classList.add('fade-in-' + fadeType);
		}

		return () => {
			if (fadeInRef.current) {
				observer.unobserve(fadeInRef.current);
			}
		};
	}, []);

	if (!propRef) {
		return (
			<div ref={fadeInRef} className={`fade-in ${isVisible ? 'visible' : 'invisible'}`}>
				{children}
			</div>
		);
	}

	return children;
};

export default FadeIn;
