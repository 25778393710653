const tests = {
	password: [
		[new RegExp('(?=.*[a-z])'), 	'Minst èn liten bokstav som ikke er æ,ø,å'],
		[new RegExp('(?=.*[A-Z])'), 	'Minst èn stor bokstav som ikke er æ,ø,å'],
		[new RegExp('(?=.*\\d)'), 		'Minst ett nummer'],
		[new RegExp('(.{8,})'), 		'Minst 8 tegn'],
		[new RegExp('^.{0,128}$'),		'Maks 128 tegn'],
	]
}

// Tests return an object with a passed Boolean, and a message about why the test failed.
const ValidateInput = function (testName, testInput){
	const returnObj = {passed: true, message:''};

	const testObj = tests[testName];

	//test the password until fails
	for (let i = 0; i < testObj.length; i++) {
		if (!testObj[i][0].test(testInput)) {
			returnObj.passed = false;
			returnObj.message = testObj[i][1];
			break;
		}
	}

	return returnObj;
};

module.exports = ValidateInput;