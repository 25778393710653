import {useEffect, useState} from "react";
import classes from "../../../components/adminEdit/AdminEdit.module.scss";
import CategoryService from "../../../services/CategoryService";
import AdminEdit from "../../../components/adminEdit/AdminEdit";
import B64ImageUploader from "../../../components/ui/functional/B64ImageUploader/B64ImageUploader";
import SiteService from "../../../services/SiteService";
import categoryService from "../../../services/CategoryService";
import OnTopBox from "../../../components/ui/onTopBox";
import FrontpageChooser from "../../../components/ui/functional/FrontpageChooser";
import {CrossButton} from "../../../components/ui/CrossButton";

function CategoriesEdit(){

	const [isLoading, setIsLoading] = useState(true);
	const [loadedCategories, setLoadedCategories] = useState([]);

	useEffect(()=>{
		CategoryService.getAll((cats)=>{
			cats.forEach((cat)=>{
				cat.isEditable = false;
			})
			setLoadedCategories(categoryService.sortCategoriesAdjacentToParent(cats));
		})
	}, [])

	const findParentCategoryName = (catId)=>{
		let found = loadedCategories.find((cat)=>{return cat._id === catId});
		if (found){
			return found.name;
		}else{
			return null;
		}
	}

	function TableHeaders(){return(
		<div className={classes.th}>
			<div className={classes.td}></div>
			<div className={classes.td}>Navn</div>
			<div className={classes.td}>Forelder-kategori</div>
			<div className={classes.td}>Ikon</div>
			<div className={classes.td}>Deskripsjon</div>
			<div className={classes.td}>Forside</div>
		</div>
	)}

	function EditableSectionChildren({obj, index, handleChange}){
		return(<>

		</>)
	}

	function UnEditableSectionChildren({obj, handleChange, index, refObj}){
		const [isChooseFrontpageModalOpen, setIsChooseFrontpageModalOpen] = useState(false);
		return(<>
		<div className={[classes.td, classes.alignTop].join(' ')}>
			{!obj.isEditable?<>
				{obj.parentCategory?findParentCategoryName(obj.parentCategory) : ''}
			</>	:	<>
				<select defaultValue={obj.parentCategory} onChange={(ev)=>{handleChange('parentCategory', ev.target.value, index)}}>
					<option value={null}>Ingen kategori</option>
					{loadedCategories.map((cat, index) => {
						if (cat._id === obj._id)	return null;
						return <option key={index} value={cat._id}>{cat.name}</option>
					})}
				</select>
			</>}
		</div>
		<div className={classes.td}>
			{obj.isEditable?<>
				{obj['imageB64'] ? (
					<img style={{maxHeight: "200px"}} src={obj.imageB64} alt="" />
				):(
					<img style={{maxHeight: "200px"}} src={SiteService.apiPath + `/image/categories/${obj._id+obj.imageName}`} alt="" />
				)}
				<B64ImageUploader
					setImageB64={(b64) => {
						handleChange("imageB64", b64, index)
					}}
					setFileNameExtention={(name) => {
						handleChange("imageName", name, index)
					}}
				></B64ImageUploader>
			</>:<>
				{obj.imageName&&<img style={{maxHeight: "2em"}} src={SiteService.apiPath + `/image/categories/${obj._id+obj.imageName}`} alt="" />}
			</>}
		</div>
		<div className={classes.td}>
			{obj.isEditable?<textarea defaultValue={obj.description} onChange={(ev)=> {
				obj.description = ev.target.value;
				}} ref={refObj.description}/>
				: obj.description&&obj.description}
		</div>
		<div className={classes.td}>
			{obj.isEditable?<>
				{obj.frontpage&&obj.frontpage} <button type={"button"} onClick={()=>{console.log(isChooseFrontpageModalOpen);setIsChooseFrontpageModalOpen(true)}}>Velg forside</button>
				{obj.frontpage&&<CrossButton onclick={()=>handleChange('frontpage', null, index)}/>}
			</>
			:<>
				{obj.frontpage&&obj.frontpage}
			</>}
		</div>
		{isChooseFrontpageModalOpen && <OnTopBox onTopEnabled={isChooseFrontpageModalOpen} setOnTopEnabled={setIsChooseFrontpageModalOpen}>
			<FrontpageChooser chooseFunc={(frontpage)=>{handleChange('frontpage', frontpage, index);setIsChooseFrontpageModalOpen(false)}} />
		</OnTopBox>}
	</>)}
	return(<>
		<AdminEdit
			pageName={'Kategorier'}
			mainService={CategoryService}
			mainArray={loadedCategories}
			setMainArray={setLoadedCategories}
			TableHeaders={TableHeaders}
			EditableSectionChildren={EditableSectionChildren}
			UnEditableSectionChildren={UnEditableSectionChildren}
			NewSectionBool={true}
		>
		</AdminEdit>
	</>)
}

export default CategoriesEdit;