import SiteService from "./SiteService";

const AdminSubscriptionProductService = {
	getAll: async function(cb){
		try {
			const response = await fetch(SiteService.apiPath+'/subscription-products', {
				method: "GET",
			});
			const data = await response.json();

			if(cb)
				cb(data);
			else
				return data;
		} catch (error) {
			console.error(error);
		}
	},

	getPage: function(pageNumber, sortBy, searchObj, cb){
		fetch(
			`${SiteService.apiPath}/admin/getsubscriptionproductpage`,
			{
				method: "POST",
				body: JSON.stringify({
					pageOptions: {page: pageNumber, sortBy: sortBy, searchObj: searchObj}
				}),
				headers: {'Content-Type': 'application/json'}
			}
		).then((response)=> {
			return response.json();
		})
			.then((data)=> {
				if(cb) cb(data);
			})
			.catch(reason => {
				console.error(reason);
				alert('Feil under henting av artikler');
			})
	},

	create: function(author, cb){
		return alert('funksjon ikke støttet');
	},

	save: async function(updateObj, cb){
		let res = await fetch(SiteService.apiPath + '/admin/subscription-product/propertychange',
			{
				method: 'POST',
				body: JSON.stringify({updateObj: updateObj}),
				headers: {
					'Content-Type': 'application/json'
				}
			});
		if (!res.ok){
			alert('Feil under lagring');
		}
		return cb(true);
	},

	delete: function (id, cb) {
		return alert('Funksjon ikke støttet');
	}
}

export default AdminSubscriptionProductService;