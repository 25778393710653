import {useContext, useRef} from "react";
import useTooltip from "../../hooks/useTooltip";
import MinusIcon from "../../svg/MinusIcon";
import BellIcon from "../../svg/BellIcon";
import {Link} from "react-router-dom";
import UserContext from "../../store/userContext";
import SiteContext from "../../store/siteContext";

export default function CategoryBox({category}){ //todo fix this rerendering its parent, like in an article where it makes new ads appear
	const userContext = useContext(UserContext);
	const siteContext = useContext(SiteContext);

	const bellTooltipRef = useRef(null);
	const bellTooltip = useTooltip(bellTooltipRef, 'Få varsel om nye artikler fra emnet');

	let potentialName = siteContext.categories.find(c => c._id === category)?.name;
	const catName = category.name?
		category.name
		: (potentialName? potentialName : 'Ingen navn');

	let isFollowed = userContext.isLoggedIn;
	if (userContext.isLoggedIn)
		isFollowed = category.topicId ? true :
			userContext.followedTopics.some(topic => topic.topicType === 'category' && topic.topicId === category);

	const categoryId = (typeof category === "string")? category : category.topicId;

	if(category)
		return <div className={'categoryBox'}>
			{userContext.isLoggedIn && (
				<>{isFollowed?(
					<button className={'tagBellContainer'} onClick={() => {
						userContext.unfollowTopic('category', categoryId);
					}}>
						<MinusIcon/>
					</button>
				) : (
					<button ref={bellTooltipRef} className={'tagBellContainer'} onClick={() => {
						userContext.followTopic('category', categoryId);
					}}>
						<BellIcon stroke={'var(--theme-color1)'}/>
					</button>
				)}
				</>
			)}

			<Link to={'/kategorier/' + catName.toLowerCase()}>{catName}</Link>
		</div>

}