import {useEffect, useRef, useState} from "react";
import classes from "../../../components/adminEdit/AdminEdit.module.scss";
import AdPlacementService from "../../../services/AdPlacementService";
import AdminEdit from "../../../components/adminEdit/AdminEdit";
import advertService from "../../../services/AdvertService";

function AdPlacementEdit(){

	const [isLoading, setIsLoading] = useState(true);
	const [loadedAdPlacements, setLoadedAdPlacements] = useState([]);
	const [loadedAds, setLoadedAds] = useState([]);

	useEffect(()=>{
		AdPlacementService.getAll((data)=>{
			data.forEach((adPlacement)=>{
				adPlacement.isEditable = false;
			})
			//sort data by putting the ones with permanent:true at top
			data.sort((a, b) => {
				if (a.permanent === b.permanent) {
					return a.name.localeCompare(b.name);
				}
				return a.permanent? -1 : 1;
			});
			setLoadedAdPlacements(data);
			setIsLoading(false);
		});

		advertService.getAll((data)=>{
			setLoadedAds(data);
		});
	}, [])


	const handleChange = (old, newValue, index) => {
		setLoadedAdPlacements((oldList) => {
			oldList[index][old] = newValue;
			return [...oldList];
		});
	};

	function adToggleUseAllAds(obj, index){
		/*let newAdPlacement = loadedAdPlacements;
		newAdPlacement.forEach((newAdPlacement)=>{
			if (newAdPlacement._id === obj._id) {
				newAdPlacement.useAllAds = !newAdPlacement.useAllAds;
			}
		});
		setLoadedAdPlacements(newAdPlacement.concat());*/
		obj.adverts = [];
		handleChange('useAllAds', !obj.useAllAds, index)
	}

	function TableHeaders(){return(
		<div className={classes.th}>
			<div className={classes.td}></div>
			<div className={classes.td}>Navn</div>
			<div className={classes.td}>Annonser</div>
		</div>
	)}

	function UnEditableSectionChildren({obj, index}){
		const advertsRef = useRef(obj.adverts);

		return(<>
		{/*<span style={{paddingLeft: '15px'}}>Bruk alle annonser: </span>
			<input style={{height:'18px', width:'18px'}} disabled={!obj.isEditable} type={"checkbox"}
			defaultChecked={obj.useAllAds} id={obj._id + "useAll"} onChange={(ev) => {
			adToggleUseAllAds(obj, index);
		}}/>*/}
		{obj.permanent&& <span style={{paddingLeft: '15px'}}>🔒</span>}

		{obj.isEditable &&
			<select ref={advertsRef} multiple={true}
				id={obj._id + "Annonse"}
				disabled={!obj.isEditable}
				defaultValue={obj.adverts}
				className={classes['isEditable'+obj.isEditable.toString()]} style={{width:'100%', height:'250px'}}
				onInput={()=>{
					let ads = [];
					//if(document.getElementById(adPlacement._id+"Annonse"))
					ads = Array.from(advertsRef.current.selectedOptions, Option => Option.value);
					obj.adverts = ads;
				}}
			>
				{loadedAds.map((ad)=>{return(
					<option key={ad._id} value={ad._id}>{ad.name}</option>
				)})}
			</select>}

		{!obj.isEditable && <ul style={{listStyleType: 'none'}}>
			{obj.adverts.map((ad)=>{
				let newAd = loadedAds.find(lAd => lAd._id === ad);
				if (!newAd)
					return(null);
				return(
				<li key={newAd._id} style={{paddingLeft: '15px'}}>{newAd.name}</li>
			)})}
		</ul>}
	</>)}

	function EditableSectionChildren({obj, index, handleChange}) {return(<>

	</>)}

	function BeforeTableChildren(){return(
		<p>🔒 <i>Fast plassering i nettstedet; kan endres, men ikke slettes.</i></p>
	)}

	return(
		<AdminEdit
			pageName={'Annonse-plasseringer'}
			mainService={AdPlacementService}
			mainArray={loadedAdPlacements}
			setMainArray={setLoadedAdPlacements}
			UnEditableSectionChildren={UnEditableSectionChildren}
			EditableSectionChildren={EditableSectionChildren}
			NewSectionBool={true}
			TableHeaders={TableHeaders}
			BeforeTableChildren={BeforeTableChildren}
		></AdminEdit>
	)
}

export default AdPlacementEdit;