import bannerImage2 from "../../images/abo-tilbud.png";
import advertService from "../../services/AdvertService";
import classes from './KampanjeTilbud.module.scss';

export default function KampanjeTilbud() {return(
	<a className={classes.kampanjeBox} href={"https://abo.b-v.no/august24/"} target="_blank" rel="noreferrer">
		<img className={classes.part1} src={bannerImage2} style={{height: '185px'}}/>
		<div className={classes.part2}>
			<div style={{textAlign: 'center', fontFamily: 'var(--font2)'}}>
				<p className={classes.tittel}>Høst-tilbud</p>
				<p><b>Ett år til kun 343,-</b></p>
				<p className={classes.desc2}>Få med digitalt utgavearkiv + Mathefte</p>
			</div>
			<a style={{fontSize: '1.1rem'}} onClick={() => {
				advertService.incrementTempad('Artikkel_nederst-Se_kampanjepris')
			}} href={"https://abo.b-v.no/august24/"} target="_blank" rel="noreferrer">Se kampanjepris</a>
		</div>
	</a>
)}