import {Link, Route, Routes} from "react-router-dom";
import Layout from "./components/layout/Layout";
import LoginPage from "./pages/Login";
import Register from "./pages/Register";
import AdminPanel from "./pages/admin/AdminPanel";
import Article from "./pages/Article";
import ArticlesSearch from "./pages/ArticlesSearch";
import AdminUploadImage from "./pages/admin/AdminUploadImage";
import AdminFrontpageEdit from "./pages/admin/FrontpageEdit/AdminFrontpageEdit";
import NotFound from "./pages/NotFound";
import TagArchive from "./pages/archive/TagArchive";
import CategoriesEdit from "./pages/admin/edit/CategoriesEdit";
import AdvertEdit from "./pages/admin/edit/AdvertEdit";
import AdPlacementEdit from "./pages/admin/edit/AdPlacementEdit";
import AuthorEdit from "./pages/admin/edit/AuthorEdit";
import ScrollToTop from "./components/non-ui/ScrollToTop";
import VerifyEmail from "./pages/VerifyEmail";
import VerifyEmailToken from "./pages/VerifyEmailToken";
import GlemtPassord from "./pages/GlemtPassord";
import FornyPassord from "./pages/FornyPassord";
import Abonner from "./pages/Abonner";
import AdminUsers from "./pages/admin/edit/AdminUsers";
import AdminArticles from "./pages/admin/edit/AdminArticles";
import AdminDefaultPrices from "./pages/admin/edit/AdminDefaultPrices";
import VippsBuyArticle from "./pages/VippsBuyArticle";
import VippSubscribe from "./pages/VippSubscribe";
import SubscriptionProductEdit from "./pages/admin/edit/SubscriptionProductEdit";
import SubscriptionPlanEdit from "./pages/admin/edit/SubscriptionPlanEdit";
import { FavouritesContextProvider } from "./store/favourites-context";
import Frontpage from "./pages/Frontpage";
import { SiteContextProvider } from "./store/siteContext";
import Category from "./pages/Category";
import ArticleEditor2 from "./components/articleEdit2/ArticleEditor2";
import TagsEdit from "./pages/admin/edit/TagsEdit";
import TestPage from "./pages/TestPage";
import VideosEdit from "./pages/VideosEdit";
import "./scss/styles2.scss";
import SponsorEdit from "./pages/admin/edit/SponsorEdit";
import usePageTracking from "./hooks/usePageTracking";
import Statistics from "./pages/admin/Statistics";
import React, { useContext } from "react";
import UserContext from "./store/userContext";
import AdminOpen from "./pages/admin/AdminOpen";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AdvertViews from "./pages/AdvertViews";
import TempAdClicks from "./pages/admin/TempAdClicks";
import useDismissibleBanner from "./hooks/useDismissibleBanner";
import advertService from "./services/AdvertService";
import userContext from "./store/userContext";
import Loginbox from "./components/ui/functional/Loginbox";
import MinSideTop from "./components/layout/MinSideTop";
import Varsler from "./pages/min_side/Varsler";
import Favoritter from "./pages/min_side/Favoritter";
import Profil from "./pages/min_side/Profil";
import Abonnement from "./pages/min_side/Abonnement";

function AdminRequireLogin({ minRole, children }) {
	const user = useContext(UserContext);
	if (user.isLoadingAuth) return <div>Laster...</div>

	if (!user.isLoggedIn) {
		return (
			<div className={'centered'}>
				<p style={{color:"darkred"}}><b>Du må logge inn for å få tilgang til denne siden.</b></p>
				<LoginPage />
			</div>
		);
	}
	if (user.role < minRole) {
		return <div className={'centered'}>
			<p>Brukeren mangler rettigheter</p>
			</div>;
	}
	return <>{children}</>;
}

function AdminWrapper() {
	return (
		<AdminRequireLogin minRole={1}>
			<Routes>
				<Route path="/" element={<AdminPanel />} />
				<Route path="forside-rediger" element={<AdminFrontpageEdit />} />
				<Route path="kategorier" element={<CategoriesEdit />} />
				<Route path="stikkord" element={<TagsEdit />} />
				<Route path="standardpriser" element={<AdminDefaultPrices />} />
				<Route path="brukere" element={<AdminUsers />} />
				<Route path="artikler" element={<AdminArticles />} />
				<Route path="forfattere" element={<AuthorEdit />} />
				<Route path="annonsorer" element={<SponsorEdit />} />
				<Route path="annonser" element={<AdvertEdit />} />
				<Route path="annonseplasseringer" element={<AdPlacementEdit />} />
				<Route path="abonnement-produkter" element={<SubscriptionProductEdit />} />
				<Route path="abonnement-planer" element={<SubscriptionPlanEdit />} />
				<Route path="videoer" element={<VideosEdit />} />
				<Route path="statistikk" element={<Statistics />} />
				<Route path="ny_artikkel" element={<ArticleEditor2 />} />
				<Route path="artikkel-rediger/:urlString2" element={<ArticleEditor2 />} />
				<Route path="bildeopplasting" element={<AdminUploadImage />} />

				<Route path="lenkeklikk" element={<TempAdClicks />} />
				<Route path="open" element={<AdminOpen />} />
			</Routes>
		</AdminRequireLogin>
	);
}

function MinSideWrapper() {
	const userContext = useContext(UserContext);
	if (!userContext.isLoggedIn) return <Loginbox/>

	return (
		<MinSideTop>
			<Routes>
				<Route path="/" element={<Profil />} />
				<Route path="profil" element={<Profil />} />
				<Route path="varsler" element={<Varsler/>} />
				<Route path="favoritter" element={<Favoritter />} />
				<Route path="abonnement" element={<Abonnement />} />
			</Routes>
		</MinSideTop>
	);
}

function App() {
	const linjebanner = useDismissibleBanner(<a href={'https://abo.b-v.no/bis24/'} onClick={()=>{
		advertService.incrementTempad('linjebanner-ukestilbud-Båter-i-sjøen-aug-2024')}} target="_blank"
		>UKESTILBUD - Båtens Verden i et helt år + Messebilletter - <span style={{
		fontWeight: "bold",
		textDecoration: "underline",
	}}>Se tilbudet her</span></a>, "test", "linjebanner-ukestilbud-Båter-i-sjøen-aug-2024", 4000);
	usePageTracking();

	return (
		<ScrollToTop>
			<SiteContextProvider>
				<FavouritesContextProvider>
					<Layout>
						<Routes>
							<Route path="/" element={<Frontpage />} />
							<Route path="*" element={<NotFound />} />
							<Route path="/forside" element={<Frontpage />} />
							<Route path="/:videoId" element={<Frontpage />} />

							<Route path="/admin/*" element={<AdminWrapper />} />

							<Route path="/min-side/*" element={<MinSideWrapper />} />

							<Route path="/ikke-funnet/:message" element={<NotFound />} />

							<Route path="/annonse-visninger/:advertId" element={<AdvertViews />} />

							<Route path="/personvernerklaering" element={<PrivacyPolicy/>} />

							<Route path="/artikkel/:urlString/" element={<Article />} />
							<Route path="/artikler/:pageNumber" element={<ArticlesSearch />} />
							<Route path="/artikler" element={<ArticlesSearch />} />

							<Route path="/kategorier" element={<Category />} />
							<Route path="/kategorier/:categoryParam" element={<Category />} />
							<Route path="/kategorier/:categoryParam/:pageNumParam" element={<Category />} />

							<Route path="/stikkord/:name" element={<TagArchive />} />

							<Route path="/login" element={<LoginPage />} />
							<Route path="/registrer" element={<Register />} />
							<Route path="/verifiser" element={<VerifyEmail />} />
							<Route path="/verifiser/:token" element={<VerifyEmailToken />} />
							<Route path="/glemtpassord" element={<GlemtPassord />} />
							<Route path="/fornypassord/:token" element={<FornyPassord />} />

							<Route path="/abonner" element={<Abonner />} />

							<Route path="/vipps-artikkel" element={<VippsBuyArticle />} />
							<Route path="/vipps-abonner" element={<VippSubscribe />} />

							<Route path="/test" element={<TestPage />} />
						</Routes>
					</Layout>
				</FavouritesContextProvider>
			</SiteContextProvider>
		</ScrollToTop>
	);
}

export default App;
