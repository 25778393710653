import classes from "./BatPermBoatList.module.scss";
import tableClasses from "./BatPermBoatList.module.scss"
import {Link} from "react-router-dom";

const exampleProjectionArr = [{
	isDisplayedInTable: false,//optional, default: true
	label: 'Navn',
	propertyName: 'name',
	type: 'text',
	maxLength: 99,
	minLength: 1,
}];

export default function Table({tableObjects, projectionArr, actionElements}) {

	return(
	<div className={tableClasses.tableContainer}>
		<table>
			<tbody>
			<tr>
				<th>#</th>
				{projectionArr.map((item, index) => {
					if(item.isDisplayedInTable === false)return;

					return	<th key={index}>{item.label}</th>
				})}
				{actionElements&&<th></th>}
			</tr>
			{tableObjects&&tableObjects.map((item,index) => (
				<tr key={index}>
					<td>{index+1}</td>
					{projectionArr.map((projItem, index2) => {
						if(projItem.isDisplayedInTable === false)return;
						if (projItem.type === "date") {
							return <td key={index2}>{
								item[projItem.propertyName] &&
								item[projItem.propertyName].substring(0, 10)}</td>
						}else if(projItem.type === "textarea"){
							return <td key={index2}>{item[projItem.propertyName].substring(0,20)+'...'}</td>
						}
						return(
						<td key={index2}>{item[projItem.propertyName]}</td>
					)})}
					{actionElements&&<td className={tableClasses.actions}>
						{actionElements.map((actionEl, index3) => {
							return (
								<button key={index3} className={classes.actionButton} onClick={() => {
									actionEl.action(item, index)
								}}>{actionEl.label}
								</button>
							)
						})}
					</td>}
				</tr>
			))}
			</tbody>
		</table>
	</div>)
}