import AbonnemangInfo from "../../components/user/AbonnemangInfo";
import {useState} from "react";
import SiteService from "../../services/SiteService";
import classes from "./MinSide.module.scss";

export default function Abonnement() {
	const [receiptURLs, setReceiptURLs] = useState([]);
	const [receiptsFetched, setReceiptsFetched] = useState(false);
	const [receiptsIsLoading, setReceiptsIsLoading] = useState(false);

	async function getReceipts(){
		setReceiptsIsLoading(true);
		let res = await fetch(SiteService.apiPath + '/user/receipturls');
		if(res.ok) {
			setReceiptURLs(await res.json());
			setReceiptsFetched(true);
		}
		setReceiptsIsLoading(false);
	}

	function Receipts(){
		if(receiptURLs.length < 1)	return(<div>Du har ingen kvitteringer.</div>)
		return(
			<table><tbody>
			{receiptURLs.map((receipt,i)=>{return(
				<tr key={i} className={classes.receiptUrl}>
					<td>{i+1}.   </td>
					<td><a href={receipt.url} target="_blank" className={classes.link1}>
						{receipt.productName && <span>{receipt.productName}</span>}</a></td>
					<td>{new Date(receipt.date).toLocaleString().substring(0,10)}</td>
				</tr>
			)})}
			</tbody></table>
		)}


	return <div className={classes.abonnement}>
		<h1>Abonnement</h1>

		<AbonnemangInfo></AbonnemangInfo>

		<div style={{marginLeft: '20px', marginTop: '10px'}}>
			{receiptsFetched ?
				<Receipts></Receipts>
				:
				<button className={`buttonDull2 ${receiptsIsLoading ? 'toggle-switch-loading buttonDull2' : ''}`}
						onClick={() => {
							getReceipts()
						}}>Hent kvitteringer
				</button>
			}
		</div>
	</div>
}