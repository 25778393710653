import {useEffect, useMemo, useRef, useState} from "react";
import Table from "./Table";
import classes from "../batperm.module.scss";
import Modal from "./Modal";
import AttachmentMultiUploadShower from "./AttachmentMultiUploadShower";
import SiteService from "../../services/SiteService";
import notificationIcon from "../../images/notification.png";
import waveIcon from "../../images/icons/wave.png";

export default function BatpermTodoSubmenu({boat}){
	const [displayMode, setDisplayMode] = useState('card');
	const [isDisplayingCategories, setIsDisplayingCategories] = useState(true)
	const [isShowingDoneTodos, setIsShowingDoneTodos] = useState(false);

	const [todos, setTodos] = useState([]);

	useEffect(()=>{
		getTodos();
	},[]);

	const getTodos = async ()=>{
		let res = await fetch(`${SiteService.apiPath}/batperm/boat/${boat._id}/todos`);
		if (res.ok) setTodos(await res.json());
	}

	const [refsObj] = useState({
		name: useRef(),
		category: useRef(),
		deadlineDate: useRef(),
		newName: useRef(),
		note: useRef(),
	});

	const projectionArr = {
		edit:[{
			label: 'Navn',
			propertyName: 'name',
			type: 'text',
			maxLength: 99,
			minLength: 1,
		},{
			label: 'Kategori',
			propertyName: 'category',
			type:'select',
			options: ['', 'annet','elektronikk', 'sikkerhet', 'navigasjon', 'VVS', 'varme', 'bysse', 'truster/ankervisnj', 'rigg og mast', 'seilgarderobe'],
		},{
			label: 'Deadline',
			propertyName: 'deadlineDate',
			type: 'date',
			min: new Date().toISOString().slice(0,10),
		},
		{
			label: 'Notat',
			propertyName: 'note',
			type: 'textarea',
			maxLength: 1000,
		}
		],

		new:[{
			label: 'Navn',
			propertyName: 'newName',
			type: 'text',
			maxLength: 99,
			minLength: 1,
		}]
	};


	const createNewTodo = async ()=>{
		let res = await fetch(`${SiteService.apiPath}/batperm/boat/${boat._id}/todos`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({todo: {
					name: refsObj.newName.current.value
			}})
		});
		if (res.ok) {
			const data = await res.json();
			if (todos === undefined)
				setTodos([data]);
			else setTodos([...todos, data]);

			setSelectedItem(data);
		}else {
			alert('Feil under oppretting');
		}
	}

	const saveTodo = async ()=>{
		let sendData = {
			todo: {
				name: refsObj.name.current.value,
				category: refsObj.category.current.value,
				deadlineDate: refsObj.deadlineDate.current.valueAsDate,
				note: refsObj.note.current.value
			}
		};

		let res = await fetch(`${SiteService.apiPath}/batperm/boat/${boat._id}/todos/${selectedItem._id}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(sendData)
		});
		if (res.ok) {
			const data = await res.json();

			//find todos with same _id and replace it with the new one. Use map to avoid mutating the array.
			const nextTodos = todos.map(item => {
				if (item._id === selectedItem._id) {
					return data;
				}
				return item;
			});
			setTodos(nextTodos);

			setSelectedItem(undefined);
			editModalRef.current.close();
		}else{
			alert('Feil under lagring');
		}
	}

	const toggleDone = async (done, todoId, checkboxRef)=>{
		let sendData = {
			todo: {
				isDone: done
			}
		};
		let res = await fetch(`${SiteService.apiPath}/batperm/boat/${boat._id}/todos/${todoId}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(sendData)
		});
		if (res.ok) {
			// set todo with todoId to done
			const nextTodos = todos.map(item => {
				if (item._id === todoId) {
					return {
						...item,
						isDone: done
					};
				}
				return item;
			});
			setTodos(nextTodos);
			setSelectedItem(undefined);
			editModalRef.current.close();
		}
		else {
			alert('Feil under lagring');
			checkboxRef.current.checked = false;
		}
	}

	const deleteTodo = async ()=>{
		if (!confirm('Er du sikker på at du vil slette gjæremålet?')) return;
		let res = await fetch(`${SiteService.apiPath}/batperm/boat/${boat._id}/todos/${selectedItem._id}`, {
			method: 'DELETE'
		});
		if (res.ok) {
			//remove toddo of same id as selectedItem._id from equipment array
			const nextTodos = todos.filter(item => item._id!== selectedItem._id);
			setTodos(nextTodos);
			setSelectedItem(undefined);
			editModalRef.current.close();
		}
		else {
			alert('Feil under sletting');
		}
	}


	const onNewClick = ()=>{
		setSelectedItem({});
	}
	const [selectedItem, setSelectedItem] = useState(undefined);
	useEffect(()=>{
		// dont show modal on first component render
		console.log('selectedItem changed')
		if (selectedItem === undefined) return;

		if (Object.keys(selectedItem).length === 0)
			newModalRef.current.showModal();
		else {
			newModalRef.current.close();
			editModalRef.current.showModal();
		}
	}, [selectedItem])
	const editModalRef = useRef();
	const newModalRef = useRef();

	function sortTodosByCreationDate(todos){
		// sort todos by creation date year and divide into arrays of the same year
		return todos.sort((a, b) => {
			return new Date(a.createdAt).getFullYear() - new Date(b.createdAt).getFullYear();
		}).reduce((acc, item) => {
			if (acc[new Date(item.createdAt).getFullYear()] === undefined) {
				acc[new Date(item.createdAt).getFullYear()] = [];
			}

			acc[new Date(item.createdAt).getFullYear()].push(item);

			return acc;
		}, {});
	}

	function sortTodosByCategory(todos){
		// sort todos by category and divide into arrays of the same category
		// some todos don't have a category, keep those in the first array
		return todos.sort((a, b) => {
			return a.category.localeCompare(b.category);
		}).reduce((acc, item) => {
			if (acc[item.category] === undefined) {
				acc[item.category] = [];
			}
			acc[item.category].push(item);
			return acc;
		}, {});
	}

	function TodoCards({todos}){
		if (todos.length===0) return <div>Ingen gjøremål</div>

		//only use todos that are done if displaying done todos. If not then only use todos that are not done
		if (isShowingDoneTodos)
			todos = todos.filter(item => item.isDone);
		else todos = todos.filter(item =>!item.isDone);

		return <div className={classes.todoCards}>
			<TodoCardsSections todos={todos}/>
		</div>
	}

	function TodoCardsSections({todos}){ //todo make collapsable. Need to have collapsable as a global state, otherwise, editing a todo resets it.
		let todoSections = {};
		if (isDisplayingCategories)
			todoSections = sortTodosByCategory(todos);
		else todoSections = sortTodosByCreationDate(todos);

		return Object.keys(todoSections).map((sectionName, catIndex) => {
			const [isSectionOpen, setIsSectionOpen] = useState(true);

			return <div key={catIndex} className={classes.todoSection}>
				<div className={classes.todoSectionHeader} onClick={()=>{
					//setIsSectionOpen(!isSectionOpen);
				}}>
					<div className={classes.todoSectionName}>{/*<span>{isSectionOpen ? "🞃" : "🞂"}</span>*/}{sectionName&& <h3>{sectionName}</h3>}

					</div>
					{todoSections[sectionName].length>5&&<div className={classes.todosCount}>{"( " + todoSections[sectionName].length + " )"}</div>}
				</div>

				<div className={[classes.todoSectionTodos, !isSectionOpen&&classes.todoSectionTodosHidden].join(' ')}>
				{todoSections[sectionName].map((item, index) => {
					return <TodoCard key={index} todo={item} index={index}/>
				})}
				</div>
			</div>
		});
	}


	function TodoCard({todo, index}){
		const checkboxRef = useRef(todo.isDone);
		let isDeadlined = false;
		if (!todo.isDone &&
			todo.deadlineDate && new Date(todo.deadlineDate) < new Date())
			isDeadlined = true;

		return(
			<div className={[classes.todoCardContainer, isDeadlined?classes.deadlined:''].join(' ')}>
				<div className={classes.todoCardContent}>
					<div className={classes.todoContentFirst}  onClick={()=>{
						setSelectedItem(todo);
					}}>
						<div className={[classes.todoCardName, classes.dottedEndText].join(' ')}>{todo.name}</div>
						{todo.deadlineDate&&<div className={classes.todoCardDeadline}>
							<img src={notificationIcon}/>
							{new Date(todo.deadlineDate).toISOString().slice(0, 10)}
						</div>}
						<div className={classes.todoCardCategory}>{todo.category}</div>
					</div>

					<div className={classes.todoContentSecond}>
						{todo.note && <div>📄</div>}
						{(todo.attachments && todo.attachments.length>0) && <div>📎</div>}
					</div>

					<div className={classes.todoContentThird}>
						<input id={todo._id} type={"checkbox"} defaultChecked={todo.isDone}></input>
						<label htmlFor={todo._id} onClick={()=>{
							toggleDone(!todo.isDone, todo._id, checkboxRef);
						}}></label>
					</div>
				</div>
			</div>
		)
	}


	function DeadlinedTodos(){
		// find which todos with deadlineDate same date as today
		let deadlinedTodos = [];
		for (let i = 0; i<todos.length;i++){
			let todo = todos[i];
			if(!todo.isDone &&
				todo.deadlineDate && new Date(todo.deadlineDate) < new Date())
				deadlinedTodos.push(todo);
		}

	return(<div className={classes.todoNotDoneContainer}>
		<div className={classes.todoSectionHeader}>
			<h3>Utløpt</h3>
		</div>
		{deadlinedTodos.map((todo, index)=>{
			return	<TodoCard todo={todo} key={todo._id} index={index}/>
		})}
	</div>)
	}


	const formRenderSwitch = (item, iEquipment)=>{
		switch (item.type) {
			case 'select':
				return <select ref={refsObj[item.propertyName]} defaultValue={iEquipment&&iEquipment[item.propertyName]}>
					{item.options.map((option, index) => <option key={index}>{option}</option>)}
				</select>
			case 'textarea':
				return <textarea
					id={item.propertyName}
					ref={refsObj[item.propertyName]}
					defaultValue={iEquipment&&iEquipment[item.propertyName]}
					maxLength={item.maxLength}
				></textarea>
			case 'date':
				let date = "";
				if (iEquipment[item.propertyName])
					date = new Date(iEquipment&&iEquipment[item.propertyName]).toISOString().slice(0, 10);
				return <input
					id={item.propertyName}
					type={item.type}
					ref={refsObj[item.propertyName]}
					defaultValue={iEquipment&&	date}
					defaultChecked={iEquipment&&iEquipment[item.propertyName]}
					maxLength={item.maxLength}
					minLength={item.minLength}
					max={item.max}
					min={item.min}
					step={'any'}
					required={item.required}
				/>
			default:
				return <input
					id={item.propertyName}
					type={item.type}
					ref={refsObj[item.propertyName]}
					defaultValue={iEquipment&&iEquipment[item.propertyName]}
					defaultChecked={iEquipment&&iEquipment[item.propertyName]}
					maxLength={item.maxLength}
					minLength={item.minLength}
					max={item.max}
					min={item.min}
					step={'any'}
					required={item.required}
				/>}
	}
	function FormElement({iEquipment, formObject}){
		return formObject.map((item) => {return (
			<div className={classes.modalFormItem} key={item.propertyName}>
				<label htmlFor={item.propertyName}>
					{item.label}
				</label>

				{formRenderSwitch(item, iEquipment)}
			</div>
		)})
	}

	function NewItem({formObject}){
		return	<form id={'newEquipmentForm'} onSubmit={(ev)=>{ev.preventDefault();createNewTodo()}}>
			<FormElement formObject={formObject}/>
			<input className={classes.batpermButtonInvert} type={"submit"} form={'newEquipmentForm'} value={'Opprett'}/>
		</form>
	}

	function EditItem({iEquipment,formObject}){
		if (!iEquipment) return <div>Feil: Objektet finnes ikke</div>;
		return (<form onSubmit={(ev)=> {
			ev.preventDefault();saveTodo();
		}}>
			<div className={classes.modalForm}>
				<FormElement formObject={formObject} iEquipment={iEquipment}/>
			</div>
			<AttachmentMultiUploadShower selectedItem={selectedItem} maxAttachments={5} boat={boat} resourceName={'todos'}/>

			<div className={classes.modalBottomButtonsContainer}>
				<input className={classes.batpermButtonInvert} type={"submit"} value={'Lagre'}/>
				<button className={classes.batpermButtonInvert} onClick={(ev)=>{ev.preventDefault();deleteTodo()}}>
					Slett
				</button>
			</div>
		</form>)}

	const tableActionElements = [
		{label: 'Rediger', action: (item, index)=>{
				if (item !== selectedItem) setSelectedItem(item);
				else editModalRef.current.showModal();
			}}
	];

	return(<div className={classes.todoPage}>
		<h2>Gjøremål</h2>
		<img src={waveIcon} className={classes.waveImage}/>

		<DeadlinedTodos/>

		<div className={classes.overBoatListContainer}>
			<button className={[classes.fullWidthButton ,classes.newHarborBoatButton].join(' ')} onClick={onNewClick}>Nytt gjøremål</button>
			<div className={classes.displayModeContainer}>
				<button onClick={()=>{setIsDisplayingCategories(true)}}>Kategori</button>
				<div className={classes.verticalDivider}></div>
				<button onClick={()=>{setIsDisplayingCategories(false)}}>År</button>
			</div>
		</div>

		<div className={classes.homePageNav}>
			<button className={isShowingDoneTodos?classes.homePageNavActiveButton:''}
					onClick={()=>{setIsShowingDoneTodos(false)}}>Å Gjøre</button>
			<button className={!isShowingDoneTodos?classes.homePageNavActiveButton:''}
					onClick={()=>{setIsShowingDoneTodos(true)}}>Gjort</button>
		</div>


		{displayMode === 'card' ?
			<TodoCards todos={todos}/>
			:
			<Table tableObjects={todos} projectionArr={projectionArr.edit} actionElements={tableActionElements}></Table>
		}

		<Modal modalRef={newModalRef} title="Nytt gjøremål"
			   onClose={()=>{setSelectedItem(undefined)}}
			   children={<NewItem formObject={projectionArr.new} iEquipment={{}}/>}></Modal>

		<Modal modalRef={editModalRef} title="Rediger gjøremål"
			   onClose={()=>{setSelectedItem(undefined)}}
			   children={<EditItem formObject={projectionArr.edit} iEquipment={selectedItem}/>}></Modal>
	</div>)
}