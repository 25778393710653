//font-logo: Lexia, font-brød: Sofia Pro

import classes from "../batperm.module.scss"
import {useState} from "react";
import Loginbox from "../../components/ui/functional/Loginbox";
import batpermLogoWithText from "../../images/batperm-logo-withtext.svg"

export default function BatpermLogin(){
	const [isLoginOpen, setIsLoginOpen] = useState(false);

return(
	<div className={classes.batpermLoginPage}>
		{!isLoginOpen ? <><img className={classes.batpermLoginLogo} src={batpermLogoWithText}/>
			<a className={classes.batpermButton} onClick={() => {
		setIsLoginOpen(true)
			}}>Logg inn</a>
		<a className={classes.batpermButton} onClick={() => {
			setIsLoginOpen(true)
		}}>Opprett bruker</a></>
		:
		<Loginbox></Loginbox>
		}
	</div>
)}