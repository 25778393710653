import classes from "./ArticleEdit.module.scss";
import {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import B64ImageUploader from "../../components/ui/functional/B64ImageUploader/B64ImageUploader";
import AuthorChoice from "../../components/articleEdit2/AuthorChoice";
import AuthorService from "../../services/AuthorService";
import TagChooser2 from "../../components/ui/functional/TagChooser2";
import SiteService from "../../services/SiteService";
import CategoryService from "../../services/CategoryService";
import categoryService from "../../services/CategoryService";
import CategoryCheckbox from "../../components/articleEdit2/CategoryCheckbox";
import ArticleBodyEditor from "../../components/articleEdit2/ArticleBodyEditor";
import OnTopBox from "../../components/ui/onTopBox";
import VideosEdit from "../VideosEdit";
import VideoPlayer from "../../components/ui/VideoPlayer";
import Checkbox from "../../components/ui/Checkbox";
import {TextEditor} from "../../components/articleEdit2/bodypartEditors/TextEditor";
import HeaderMutlipleImageEditor from "../../components/articleEdit2/headers/HeaderMutlipleImageEditor";
import HeaderMutlitpleTextBoxesEditor from "../../components/articleEdit2/headers/HeaderMutlitpleTextBoxesEditor";
import ImageCarouselEditor from "../../components/articleEdit2/bodypartEditors/ImageCarouselEditor";
import SponsorChooser from "../../components/ui/functional/SponsorChooser";

function ArticleEdit({article, setArticle, isSplitPanel}){
	const [isBodyPartsEditing, setIsBodyPartsEditing] = useState(false);
	const [isSponsorOverlayOpen, setIsSponsorOverlayOpen] = useState(false);

	const navigate = useNavigate();
	const { urlString2 } = useParams();

	const [isLoading, setIsLoading] = useState(true);
	if (!article)
	 [article, setArticle] = useState({});

	const [loadedAuthors, setLoadedAuthors] = useState([]);

	const [loadedCategories, setLoadedCategories] = useState([]);
	const [categories, setCategories] = useState([]);

	const [active, setActive] = useState(false);
	const [isSponsored, setIsSponsored] = useState(false);
	const [hasVideo, setHasVideo] = useState(false);
	const [scheduledToActivate, setScheduledToActivate] = useState(false);
	const [pluss, setPlus] = useState(false);

	var now = new Date();
	var nowDate = now.toISOString().substring(0,10)
	nowDate+='T';
	nowDate+=now.getHours()+':'+now.getMinutes();
	const [date, setDate] = useState(nowDate);
	const [image, setImage] = useState('');
	const [imageAlt, setImageAlt] = useState('');
	const [bodyParts, setBodyParts] = useState([]);
	const [urlString, setUrlString] = useState('');

	const [video, setVideo] = useState(null);
	const [frontpageTitle, setFrontpageTitle] = useState('');

	const [sponsor, setSponsor] = useState(null);

	const [price, setPrice] = useState(0);
	const [useDefaultPrice, setUseDefaultPrice] = useState(true);

	const [chosenTags, setChosenTags] = useState([]);
	const [allTags, setAllTags] = useState([]);
	const [featuredTags, setFeaturedTags] = useState([]);


	let apiRoute = '';
	if(window.location.toString().split('/')[4] !== 'artikkel-rediger'){
		apiRoute = "create";
	}else{
		apiRoute = "update";
	}

	const frontpageTitleRef = useRef({});
	const titleInput = useRef();
	const prefaceInput = useRef();
	const introInput = useRef();
	const dateInput = useRef();
	const imageAltInput = useRef();
	const urlStringInput = useRef();
	const videoInput = useRef();

	const [isFirstLoad, setIsFirstLoad] = useState(true);
	useEffect(()=>{
		if (isFirstLoad) {
			load();
			setIsFirstLoad(false);
		}else{
			setIsFirstLoad(false);
		}
	}, [urlString2]);

	useEffect(()=>{
		AuthorService.getAll((authors)=>{
			// sort alphabetically by author.name
			authors.sort((a, b) => {
				if (a.name < b.name) {
					return -1;
				}
				if (a.name > b.name) {
					return 1;
				}
				return 0;
			});
			setLoadedAuthors(authors);
		});
	},[])


	const load = async () => {
		try {
			const tagsResponse = await fetch(SiteService.apiPath + '/tags/tagsfreq');
			const tagsData = await tagsResponse.json();
			setAllTags(tagsData);
			const categoryResponse = await CategoryService.getAll();
			setLoadedCategories(categoryService.sortCategoriesAdjacentToParent(categoryResponse));

			if (window.location.toString().split('/')[4] !== 'artikkel-rediger') {
				setIsLoading(false);
				return;
			}

			console.log(urlString2);
			if (!urlString2 || urlString2 === '')
				return;

			const articleResponse = await fetch(SiteService.apiPath + '/article/urlstring/' + urlString2, {
				method: 'GET',
			});
			const articleData = await articleResponse.json();
			setArticle(articleData.articleInfo);
			document.title = 'Rediger:' + articleData.articleInfo.name;

			setActive(articleData.articleInfo.active);
			setPlus(articleData.articleInfo.pluss);
			setHasVideo(articleData.articleInfo.hasVideo);
			setPrice(articleData.articleInfo.price);
			setUseDefaultPrice(articleData.articleInfo.useDefaultPrice);
			setAuthor(articleData.articleInfo.author);
			setSponsor(articleData.articleInfo.sponsor);
			setVideo(articleData.articleInfo.video);
			setFrontpageTitle({value: articleData.articleInfo.frontpageTitle});

			var articleDate = new Date(articleData.articleInfo.date);
			var articleDateFirstPart = articleDate.toISOString().substring(0,10)
			articleDateFirstPart+='T';
			articleDateFirstPart+=articleDate.toLocaleString().split(', ')[1].substring(0,5)
			setDate(articleDateFirstPart);

			setImage(articleData.articleInfo.image.url);
			setImageAlt(articleData.articleInfo.image.alt);
			setUrlString(articleData.articleInfo.urlString);
			setCategories(articleData.articleInfo.categories);
			setIsSponsored(articleData.articleInfo.isSponsored);
			setScheduledToActivate(articleData.articleInfo.scheduledToActivate);

			if (articleData.articleInfo.tags) setChosenTags(articleData.articleInfo.tags);

			if (articleData.articleInfo.featuredTag) {
				setFeaturedTags([{name: articleData.articleInfo.featuredTag, articleIds: []}]);
			}

			articleData.articleInfo.bodyParts.forEach((part, i) => {
				articleData.articleInfo.bodyParts[i].index = i;
			});
			setBodyParts(articleData.articleInfo.bodyParts);

			setArticle(articleData.articleInfo);

			setIsLoading(false);
		} catch (error) {
			console.error(error);
		}
	}

	const editArticleProperty = (property, value) => {
		setArticle({...article, [property]: value});
	}


	async function newArticleHandler(event) {
		event.preventDefault();
		setIsLoading(true);

		let articleObj = {
			_id: article._id,
			date: new Date(dateInput.current.value),
			active: active,
			pluss: pluss,
			isSponsored: isSponsored,
			hasVideo: hasVideo,
			theme: article.theme,

			headerType: article.headerType,
			headerImages: article.headerImages,
			headerParts: article.headerParts,

			titleEmphasis: article.titleEmphasis?article.titleEmphasis:null,
			prefaceEmphasis: article.prefaceEmphasis?article.prefaceEmphasis:null,
			name: article.name,
			frontpageTitle: frontpageTitleRef.current.value,
			preface: article.preface,
			intro: article.intro,
			bodyParts: article.bodyParts,
			video: videoInput.current.value ? videoInput.current.value : null,
			author: article.author,
			sponsor: sponsor,
			headerB64: article.headerB64,
			image: {
				url: image,
				alt: imageAltInput.current.value ? imageAltInput.current.value : null,
			},
			urlString: urlString,
			tags: chosenTags.map((tag) => tag._id),
			categories: categories,
			useDefaultPrice: useDefaultPrice,
			price: price,
			scheduledToActivate: scheduledToActivate,
		};

		// remove all <p> and </p> from article.frontpageTitle
		if (articleObj.frontpageTitle) {
			articleObj.frontpageTitle = articleObj.frontpageTitle.replace('<p>', '')
				.replace('</p>', '')
				.replace('\n', '');
			if (articleObj.frontpageTitle.length < 1)
				articleObj.frontpageTitle = null;
		}

		if (featuredTags.length > 0)
			articleObj.featuredTag = featuredTags[0].name;

		if (dateInput.current.value.length > 0)
			articleObj.date = dateInput.current.value;

		let apiRoute;
		if (window.location.toString().split('/')[4] !== 'artikkel-rediger') {
			apiRoute = "create";
		} else {
			apiRoute = "update";
		}

		try {
			const response = await fetch(SiteService.apiPath + '/article/' + apiRoute, {
				method: "POST",
				body: JSON.stringify(articleObj),
				headers: {
					'Content-Type': 'application/json'
				}
			});

			const data = await response.json();

			if (!response.ok){
				if (data.error) {
					if (data.error.reason)
						alert(data.error.reason);
					else
						alert('Feil under opplasting');
				}else{
					alert('Feil under opplasting. Sjekk om det mangler noen felter.');
				}
				return setIsLoading(false);
			}


			if (data.success) {
				if (data.destination)
					return navigate(data.destination);
			}
		} catch (error) {
			console.log(error);
			alert('Feil under opplasting');
			setIsLoading(false);
		}
	}


	const setAuthor = (author) => {
		editArticleProperty('author', author);
	}

	function ThemeChanger(){
		return <div>
			<h2>Tema</h2>
			<select value={article.theme} onChange={(ev)=>{
				//convert value to number
				editArticleProperty('theme', parseInt(ev.target.value));
			}}>
				<option value={0}>Standard</option>
				<option value={1}>Mørk</option>
			</select>
		</div>
	}
	function HeaderTypeChanger(){
		return <div>
			<h2>Header utseende</h2>
			<select value={article.headerType} onChange={(ev)=>{
				//convert value to number
				editArticleProperty('headerType', parseInt(ev.target.value));
			}}>
				<option value={0}>Standard</option>
				<option value={8}>Standard - FullBredde</option>
				<option value={4}>Tittel øverst</option>
				<option value={9}>Tittel øverst - fullbredde</option>
				<option value={1}>FullBredde</option>
				<option value={5}>FullBredde 2</option>
				<option value={6}>FullBredde 3</option>
				<option value={2}>Rollover - Flere</option>
				<option value={7}>Rollover - Ett bilde (modulær)</option>
				<option value={3}>Karusell - Flere</option>
			</select>
		</div>
	}



	function HeaderYellowTextChooser() {
		useEffect(() => {
			if (!article.titleEmphasis)
				editArticleProperty('titleEmphasis', [0,0]);
			if (!article.prefaceEmphasis)
				editArticleProperty('prefaceEmphasis', [0,0]);
		}, []);


		if (article.titleEmphasis && article.prefaceEmphasis)
		return(<div>
			<h3>Tittel fremheving</h3>
			<div>Fra ord: <input type="number" min={0} max={100} value={article.titleEmphasis[0]} onChange={(ev) => {
				editArticleProperty('titleEmphasis',[ev.target.value, article.titleEmphasis[1]])
			}}/></div>
			<div>Til ord: <input type={"number"} min={0} max={100} value={article.titleEmphasis[1]} onChange={(ev) => {
				editArticleProperty('titleEmphasis',[article.titleEmphasis[0], ev.target.value])
			}}/></div>
			{/*<h3>Ingress fremheving</h3>
			<div>Fra ord: <input type="number" min={0} max={100} value={article.prefaceEmphasis[0]} onChange={(ev) => {
				editArticleProperty('prefaceEmphasis',[ev.target.value, article.prefaceEmphasis[1]])
			}}/></div>
			<div>Til ord: <input type={"number"} min={0} max={100} value={article.prefaceEmphasis[1]} onChange={(ev) => {
				editArticleProperty('prefaceEmphasis',[article.prefaceEmphasis[0], ev.target.value])
			}}/></div>*/}
		</div>)
		return null;
	}


function ActiveChecker() {
	return (<Checkbox state={active} setState={setActive}></Checkbox>)
}

function ScheduledToActivateChecker() {
	return (<>
		<Checkbox state={scheduledToActivate} setState={setScheduledToActivate}></Checkbox>
	</>)
}

function PlussChecker() {
	return (<>
		<Checkbox state={pluss} setState={setPlus}></Checkbox>
	</>)}
	function SponsoredChecker(){return(<>
		<Checkbox state={isSponsored} setState={setIsSponsored}></Checkbox>
	</>)}
	function HasVideoChecker(){return(<>
		<Checkbox state={hasVideo} setState={setHasVideo}></Checkbox>
	</>)}
	function UseDefaultPriceChecker(){return(<>
		<Checkbox state={useDefaultPrice} setState={setUseDefaultPrice}></Checkbox>
		{!useDefaultPrice &&<>
			<input required={true} type={"number"} placeholder={"Pris"} value={price} min={3} onChange={e => {setPrice(e.target.value);}}/>{"-NOK (kroner)"}
		</>}
	</>)}


	function VideoChooser() {
		const [isVideoBoxOpen, setIsVideoBoxOpen] = useState(false);
		const chooseVideo = (videoId) => {
			videoInput.current.value = videoId;
			setVideo(videoId);
			setIsVideoBoxOpen(false);
		}
		return <div className={'videoChooser'}>
			<div><h3><label>Video</label></h3><p>Brukes istedenfor forsidebilde på forsiden.</p></div>
			<input hidden={true} type={"text"} ref={videoInput} defaultValue={video}/>
			{video&& <>
				<VideoPlayer videoId={video}/>
				<button type={"button"} onClick={()=>{videoInput.current.value = ''; setVideo(null)}}>Fjern</button>
			</>}
			<button type={"button"} onClick={()=>setIsVideoBoxOpen(true)}>Velg video</button>
			{isVideoBoxOpen&&<OnTopBox onTopEnabled={isVideoBoxOpen} setOnTopEnabled={setIsVideoBoxOpen}>
				<VideosEdit chooseVideo={chooseVideo}></VideosEdit>
			</OnTopBox>}
		</div>
	}

	function FrontpageTitleEditor() {
		return <div className={'frontpageTitleEdit'}>
			<div><h3><label>Forside tittel</label></h3>
				<p>Brukes istedenfor Tittel på forsiden.</p>
			</div>
			<TextEditor part={frontpageTitle} valueRef={frontpageTitleRef}></TextEditor>
		</div>
	}

	function MainImageEditor() {return <>
		<h2>Forsidebilde</h2>
		<B64ImageUploader setFileNameExtention={(name) => {
			setImage(name)
		}} setImageB64={(b64) => {
			editArticleProperty('headerB64', b64)
		}}></B64ImageUploader>
		<div>
			{(article.headerB64) ? (
				<img style={{maxHeight: "200px", maxWidth: '600px'}} src={article.headerB64} alt=""/>
			) : (
				<>{image&&<img style={{maxHeight: "200px", maxWidth: '600px'}}
						src={`${SiteService.apiPath}/image/articles/${article._id + '/' + image}`} alt=""/>}</>
			)}
		</div>
		<br style={{marginBottom: '10px'}}/>
		<span>Bildetekst:</span>
		<input type="text" placeholder={"bildetekst"} defaultValue={imageAlt} ref={imageAltInput}/>
		</>
	}



	function TitleNameAndURLnameInput() {
		if (apiRoute === 'create') return (<>
			<input type="text" placeholder={"Tittel"} defaultValue={article.name} onBlur={(e) => {
				editArticleProperty('name', e.target.value);
				// if e.target.value has a letter that is not in regular alphabet, replace it with "-"

				setUrlString(
					e.target.value.replaceAll('å','a').replaceAll('ø','o').replaceAll('æ','a')
					.replaceAll(/[^a-zA-Z0-9]/g, '-')
					.replaceAll('--', '-')
					.replaceAll('--', '-')
					.toLowerCase())
			}} ref={titleInput} required={true}
			/>
			<br/>
			<h2>URL-navn</h2>
			<span style={{color: "gray"}}>{window.location.origin.toString() + "/artikkel/"}{urlString}</span>
			<input type="text" placeholder={"URL-navn"} value={urlString} ref={urlStringInput} onBlur={e =>
				setUrlString(e.target.value.toLowerCase().replaceAll(/[^a-zA-Z0-9]/g, '-'))} required={true}
			/>
		</>)
		else if(apiRoute === 'update') return(<>
			<input type="text" placeholder={"Tittel"} defaultValue={article.name} onBlur={e => {
				editArticleProperty('name', e.target.value);
			}} ref={titleInput} required></input>
			<br/>
			<h2>URL-navn</h2>
			<span style={{color:"gray"}}>{window.location.origin.toString()+"/artikkel/"}{urlStringInput.current && urlStringInput.current.value}</span>
			<input disabled={true} type="text" placeholder={"URL-navn"} defaultValue={urlString} ref={urlStringInput} onBlur={e => {
				editArticleProperty('name', e.target.value);
				setUrlString(e.target.value.toLowerCase().replaceAll(' ', '-'))
			}}
			/>
		</>)
	}

	if(isLoading) return(<h2 style={{textAlign:"center"}}>Laster...</h2>)

	if (isBodyPartsEditing) return(
		<ArticleBodyEditor parts={bodyParts} setParts={setBodyParts} article={article} setArticle={setArticle} isSplitPanel={isSplitPanel}
	   		endEditingFunc={()=>{setIsBodyPartsEditing(false)}}>
		</ArticleBodyEditor>
	)

	else return(<>
		{/*<OverviewPane articleId={article._id} title={title} bodyParts={bodyParts}/>*/}

	<div className={classes.editContainer}>
		<h1 style={{display: "contents", width:"fit-content"}}>Rediger artikkel</h1>
		<p>Alle bilder foruten GIF blir komprimert og gjort om til WEBP</p>
		<div style={{float: "right",paddingTop: "20px"}}>Aktiv:
			<ActiveChecker></ActiveChecker>
		</div>
		<div style={{float: "right",height: "33px",paddingTop: "20px"}}>Pluss:
			<PlussChecker></PlussChecker>
		</div>

		<form className={classes.editArticleForm} onSubmit={(ev) => {
			newArticleHandler(ev)
		}}>
			{article._id && <div style={{float: 'right'}}>Id: {article._id}</div>}
			<button style={{margin: '25px'}} className={"buttonDull1"} type="submit">Lagre</button>

			<VideoChooser></VideoChooser>
			<h2 style={{width: "fit-content"}}>Tittel</h2>
			{TitleNameAndURLnameInput()}

			<FrontpageTitleEditor/>

			<MainImageEditor/>
			<br/>
			<ThemeChanger/>
			<HeaderTypeChanger/>
			{(article.headerType === 3) && <ImageCarouselEditor disableVariantChooser={true} article={article} part={article} overridePropertyName={'headerImages'}
												setPart={(newPart)=>{setArticle(newPart)}}/>}
			{(article.headerType === 2) && <HeaderMutlipleImageEditor article={article} editArticleProperty={editArticleProperty} headerType={article.headerType}/>}
			{(article.headerType === 6) && <HeaderYellowTextChooser/>}
			{(article.headerType === 7) && <HeaderMutlitpleTextBoxesEditor article={article} editArticleProperty={editArticleProperty}/>}

			<br/>
			<hr/>
			<h2>Ingress</h2>
			<textarea style={{resize: "vertical"}} placeholder={"Ingress"} defaultValue={article.preface}
					  onBlur={(e) => editArticleProperty('preface', e.target.value)}
					  ref={prefaceInput} required></textarea>
			<br/>

			<h2>Intro</h2>
			<textarea required={true} style={{resize: "vertical"}} placeholder={"Intro"} defaultValue={article.intro}
					  onBlur={e => {
						  editArticleProperty('intro', e.target.value);
					  }} ref={introInput}></textarea>
			<br/>

			{/* BODYPART SELECTION*/}
			<div className={classes.bodyPartsContainer}>
				<h2>Innhold</h2>
				<br/>
				<button onClick={(ev) => {
					ev.preventDefault();
					setIsBodyPartsEditing(true)
				}}>Rediger kropp
				</button>
			</div>

			<div><details>
				<summary><h2>Kategorier [{article.categories&&article.categories.length}]</h2></summary>
				Velg kategorier i rekkefølge av relevans. Kategori nr.<b>[1]</b> fremheves på forsiden.
				<div className={classes.categoriesContainer}>
					{loadedCategories.map((category, index) => {
						return (
							<CategoryCheckbox key={index}
											  categories={categories}
											  category={category}
											  setCategories={(newCats) => {
												  editArticleProperty('categories', newCats)
											  }}
											  index={index} classes={classes}></CategoryCheckbox>
						)
					})}
				</div>
				</details>
				<br/>
			</div>

			<div className={classes.tagsContainer}>
				<h2>Stikkord [{chosenTags&&chosenTags.length}]</h2>
				<TagChooser2 allInputTags={allTags} setAllInputTags={setAllTags} chosenTags={chosenTags}
							 setChosenTags={setChosenTags}/>
			</div>

			<div className={classes.authorContainer}>
				<br/>
				<h2>Forfatter</h2>
				<AuthorChoice authors={loadedAuthors} setAuthors={setLoadedAuthors} author={article.author}
							  setAuthor={setAuthor}></AuthorChoice>
			</div>

			<br/>
			<div>
				<b>Dato: </b>
				<input style={{width: "fit-content"}} type="datetime-local" placeholder={"Dato"} value={date}
					   onInput={e => setDate(e.target.value)} ref={dateInput}></input>
				<br/>
				<strong><ScheduledToActivateChecker/> - Aktiver når Dato er møtt</strong>
			</div>

			<br/><strong style={{marginBottom: '5px', marginTop: '30px'}}>
			<ActiveChecker></ActiveChecker> - Aktiv</strong>
			<br/><strong><PlussChecker></PlussChecker> - Pluss</strong>
			<br/><strong><SponsoredChecker></SponsoredChecker> - Annonseinnhold</strong>
			{isSponsored && <><br/><input disabled={true} value={sponsor}/><button type={"button"} onClick={()=>setIsSponsorOverlayOpen(true)}>Velg Annonsør</button></>}
			<br/><strong><HasVideoChecker></HasVideoChecker> - Har Video</strong>
			<br/>
			<h3 style={{marginBottom: '5px', marginTop: '50px'}}>Pris</h3>
			<div><UseDefaultPriceChecker></UseDefaultPriceChecker> - Bruk standard pris</div>

			<br/>
			<button style={{margin: '25px'}} className={"buttonDull1"} type="submit">Lagre</button>
			<br/>
		</form>
		<OnTopBox onTopEnabled={isSponsorOverlayOpen} setOnTopEnabled={setIsSponsorOverlayOpen}>
			<SponsorChooser chooseFunc={(id)=>{setSponsor(id); setIsSponsorOverlayOpen(false)}}/>
		</OnTopBox>
	</div></>)
}

export default ArticleEdit;