import {useContext, useState} from "react";
import BatpermContext from "../BatpermContext";
import notificationIcon from "../../images/notification.png"

export default function NotificationBell ({classes}){
	const batpermContext = useContext(BatpermContext);
	const notifications = batpermContext.notifications;

	const [isModalOpen, setIsModalOpen] = useState(false)


	function Notifications(){
		return notifications.map(notification => <div key={notification._id}>
			<div>{notification.message}
				<button onClick={()=>{batpermContext.deleteNotification(notification._id)}}>×</button>
			</div>
		</div>)
	}


	return (<div className={classes.notificationBellContainer}>
		<button className={classes.notificationBell} onClick={() => setIsModalOpen(!isModalOpen)}>
			<img src={notificationIcon}/>
		</button>
		<dialog open={isModalOpen}>
			<Notifications/>
		</dialog>
	</div>

	)
}