import SiteService from "../../services/SiteService";

const batpermService = {
	boat: {},
	harbor: {},
	QRCode: {},
};

batpermService.boat.getAll = async () => {try {
	const response = await fetch(SiteService.apiPath + '/batperm/boats');
	const data = await response.json();
	return data;
} catch (error) {
	console.log(error);
	return [];
}}

export default batpermService;