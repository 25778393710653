export default function HamburgerIcon() {
	return(
	<svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
		<g id={"hamburgerIcon"} stroke="inherit" strokeWidth={4} strokeLinecap={"round"}>
			<line
				  x2={5} y2={10} y1="10" x1="35"/>
			<line
				  x2={5} y2={20} y1="20" x1="35"/>
			<line
				  x2={5} y2={30} y1="30" x1="35"/>
		</g>
	</svg>)
}