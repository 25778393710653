import SiteService from "./SiteService";


const TagService = {
	getAll: async function(cb){try {
		const response = await fetch(SiteService.apiPath+'/tags/', {
			method: "GET",
		});
		const data = await response.json();
		//sort by name
		data.mainObj.sort((a, b) => a.name.localeCompare(b.name));
		if(cb)
			cb(data);
		else
			return data;
	} catch (error) {
		console.error(error);
	}},

	getAllFreq: function (cb) {
		fetch(SiteService.apiPath + '/tags/tagsfreq')
		.then((response)=> {
			return response.json();
		}).then((data)=>{
			if(cb)
				cb(data);
		})
	},

	getOneByName: async function(name){try {
		const response = await fetch(SiteService.apiPath+'/tagsname/'+name+'/');
		return await response.json();
	}catch (e) {
		console.error(e);
		return null;
	}},

	create: function(tag: {name:String}, cb){
		let bodyObj = {name: tag.name};

		fetch(
			SiteService.apiPath+'/tags/',
			{
				method: "POST",
				body: JSON.stringify({tag:bodyObj}),
				headers: {
					'Content-Type': 'application/json'
				}
			}
		).then((response)=> {
			return response.json();
		})
		.then((data)=> {
			if(data.success) {
				console.log('created cat: ' + tag.name + ' with Id: ' + tag._id);
				if(cb)
					cb(data.tag);
			}else{
				return alert('Feil under oppretting av kategori')
			}
		})
	},


	create2: async function (name){try {
		let res = await fetch(SiteService.apiPath + '/tags', {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				name: name
			})
		});

		if (res.ok) {
			return res.json();
		}else{
			throw new Error('Feil under oppretting av stikkord');
			return false;
		}
	} catch (e) {
		alert('Feil under oppretting av stikkord')
		console.error(e);
	}},

	save: async function(updateObj, cb){
		console.log(updateObj)
		let res = await fetch(SiteService.apiPath + '/admin/tag/propertychange',
			{
				method: 'POST',
				body: JSON.stringify({updateObj: updateObj}),
				headers: {
					'Content-Type': 'application/json'
				}
			});
		if (!res.ok){
			alert('Feil under lagring');
			return false;
		}
		return cb(true);
	},

	delete: async function (id, cb){try {
		let res = await fetch(SiteService.apiPath + '/tags/' + id, {
			method: "DELETE",
		});
		if (res.ok) {
			if (cb) return cb(true);
			return true;
		}else{
			if (cb) return cb(false);
			return false;
		}
	} catch (e) {
		alert('Feil under oppretting av stikkord');
	}},
}

export default TagService;