import {useEffect, useRef, useState} from "react";
import ArticlesSearch from "../../ArticlesSearch";
import OnTopBox from "../../../components/ui/onTopBox";
import VideosEdit from "../../VideosEdit";
import ColorPresetPicker from "../../../components/ui/functional/ColorPresetPicker";
import {DuplicateButton} from "../../../components/ui/DuplicateButton";
import Checkbox from "../../../components/ui/Checkbox";
import SiteService from "../../../services/SiteService";

export default function FrontpagePartEdit({editingParts, setEditingParts, part, index, adPlacements,
  tags, categories, moduleOptions,	videoDetails
}){
	const [type, setType] = useState(part.type?part.type:'article');
	const [subType, setSubType] = useState(part.subType?part.subType:undefined);

	const [isChoosingArticle, setIsChoosingArticle] = useState(false);
	const [isVideoChooserOpen, setIsVideoChooserOpen] = useState(false);

	const idRef = useRef(part.id);
	const typeRef = useRef(part.type);
	const subTypeRef = useRef(part.subType);

	const [background, setBackground] = useState(part.style?part.style.background:null);
	const [textColor, setTextColor] = useState((part.style)?part.style.color:null);

	const [isBGColorModemOpen, setIsBGColorModemOpen] = useState(false);
	const [isTextColorModemOpen, setIsTextColorModemOpen] = useState(false);

	const [random, setRandom] = useState(part.random?part.random:false);

	const [videoName, setVideoName] = useState(null);
	useEffect(() => {
		if (part.type === 'video') {
			// find videoDetails with .id same as part.id
			const video = videoDetails.find(video => video._id === part.id);
			if (video) {
				setVideoName(video.name);
			}
		}
	}, [videoDetails, part.id]);

	useEffect(()=>{
		saveParts();
	}, [background, textColor])


	const saveParts = ()=>{
		if (!module.parts) {
			module.parts = [];
			console.log('Parts was empty???')
		}
		if (typeRef.current.value) {
			part.type = typeRef.current.value;
		}
		if(idRef.current && idRef.current.value)
			part.id = idRef.current.value;

		if (subTypeRef.current && subTypeRef.current.value) {
			part.subType = subTypeRef.current.value;
			setSubType(subTypeRef.current.value);
		}

		if (moduleOptions.customStylePerPart && part.type!=='ad') {
			part.style = {};
			if (background)
				part.style.background = background;
			if (textColor)
				part.style.color = textColor;
		}

		setEditingParts([...editingParts]);
	}

	const removePart = (index) => {
		setEditingParts(editingParts.filter((part, i) => i!= index));
	}

	const duplicatePart = (index) => {
		const partToDuplicate = editingParts[index];
		const duplicatedPart = JSON.parse(JSON.stringify(partToDuplicate)); // Create a deep copy
		setEditingParts([...editingParts, duplicatedPart]);
	};


	const chooseArticle = (article)=>{
		part.id = article._id;
		idRef.current.value = article._id;
		saveParts();
		setIsChoosingArticle(false);
	}

	return(<div className={['frontpageEdit-chosenModulesList-item', 'frontpageEdit-part'].join(' ')}>
		<div className={'frontpageEdit-part-IndexAndInner'}>
			<div className={'frontpageEdit-chosenModulesList-index'}>{index+1}.</div>

			<div className={'frontpageEdit-chosenModulesList-inner'}>
				{type==='article' && 'Artikkel fra: '}
				{type==='article' &&
					<select value={part.subType} ref={subTypeRef} onChange={() => {
						saveParts();
					}}>
						<option value={'specific'}>Spesifik</option>
						<option value={'category'}>Kategori</option>
						<option value={'tag'}>Stikkord</option>
					</select>}
				{type==='ad'&&<div>Annonseplass: </div>}

				{subType === 'specific' && <>
					<button onClick={()=>{setIsChoosingArticle(true)}}>Velg artikkel</button>
					<input onChange={saveParts} type={"text"} value={part.id} defaultValue={part.id} ref={idRef}/>
					{isChoosingArticle&&<OnTopBox onTopEnabled={isChoosingArticle} setOnTopEnabled={setIsChoosingArticle}>
						<ArticlesSearch articleClickAction={chooseArticle}/>
					</OnTopBox>}
				</>}
				{subType === 'category' && <select onChange={saveParts} value={part.id} defaultValue={part.id} ref={idRef}>
					<option value={null}>Ingen</option>
					{categories.map((category, index) => {
						return <option key={index} value={category._id}>{category.name}</option>
					})}
				</select>}

				{subType === 'tag' && <select onChange={saveParts} value={part.id} defaultValue={part.id} ref={idRef}>
					<option value={null}>Ingen</option>
					{tags.map((tag, index) => {
						return <option key={index} value={tag._id}>{tag.name}</option>
					})}
				</select>}

				{type === 'ad' && <select onChange={saveParts} value={part.id} defaultValue={part.id} ref={idRef}>
					<option value={null}>Ingen</option>
					{adPlacements&&adPlacements.map((adPlacement, index) => {
						return <option key={index} value={adPlacement._id}>{adPlacement.name}</option>
					})}
				</select>}

				{type === 'video' && <>
					{videoName&&<input disabled={true} value={videoName}/>}
					<input hidden={true} type={"text"} value={part.id} defaultValue={part.id} ref={idRef}
							  onBlur={saveParts}/>
					<button onClick={() => {
						setIsVideoChooserOpen(true)
					}}>Velg video
					</button>
					{isVideoChooserOpen &&
						<OnTopBox onTopEnabled={isVideoChooserOpen} setOnTopEnabled={setIsVideoChooserOpen}>
							<VideosEdit chooseVideo={(videoId) => {
								idRef.current.value = videoId;
								saveParts();
								setIsVideoChooserOpen(false);
							}}/>
						</OnTopBox>}
				</>}

				{type === 'article' &&
					<Checkbox state={random} setState={setRandom} changeFunc={(bool) => part.random = bool}
							  label="Tilfeldig"/>}

				{(moduleOptions.minParts&&editingParts.length>moduleOptions.minParts) && <button className="frontpageEdit-chosenModulesList-removeButton"
				   onClick={() => removePart(index)}>×</button>}
				{moduleOptions.minParts && <button type={"button"} onClick={()=>duplicatePart(index)}><DuplicateButton/></button>}
			</div>
		</div>

		{(moduleOptions.customStylePerPart && part.type !=='ad')&&<div className={'frontpageEdit-part-style'}>
			<details>
				<summary>Stil</summary>
				<div>
					<label>Bakgrunn: </label>
					<input type={"color"} value={background} onInput={(ev) => {
						setBackground(ev.target.value)
					}}/>
					<button onClick={() => {setIsBGColorModemOpen(true)}}>Palett</button>
					<input type={"text"} value={background} onInput={(ev) => {
						setBackground(ev.target.value)
					}}/>
					<button onClick={() => {setBackground('')}}>Slett</button>
				</div>
				<div>
					<label>Tekst: </label>
					<input type={"color"} value={textColor} onInput={(ev) => {
						setTextColor(ev.target.value)
					}}/>
					<button onClick={() => {setIsTextColorModemOpen(true)}}>Palett</button>
					<input type={"text"} value={textColor} onInput={(ev) => {
						setTextColor(ev.target.value)
					}}/>
					<button onClick={() => {
						setTextColor('')
					}}>Slett
					</button>
				</div>
			</details>
		</div>}

		{isBGColorModemOpen && <OnTopBox setOnTopEnabled={setIsBGColorModemOpen} onTopEnabled={isBGColorModemOpen}>
			<ColorPresetPicker chooseColorFunc={(c) => {
				setBackground(c.value);
				setIsBGColorModemOpen(false);
			}}/>
		</OnTopBox>}
		{isTextColorModemOpen&&<OnTopBox setOnTopEnabled={setIsTextColorModemOpen} onTopEnabled={isTextColorModemOpen}>
			<ColorPresetPicker chooseColorFunc={(c) => {
				setTextColor(c.value);
				setIsTextColorModemOpen(false);
			}}/>
		</OnTopBox>}
	</div>
)}
