import {useContext, useRef, useState} from "react";
import styles from "../scss/styles.module.scss";
import {useNavigate} from "react-router-dom";
import SiteService from "../services/SiteService";


export default function GlemtPassord({propUsername}){
	const navigate = useNavigate();

	if(!propUsername)
		propUsername = "";

	const [username, setUsername] = useState(propUsername);
	const [password, setPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	const [successFullySent, setSuccessFullySent] = useState(false);

	const usernameInput = useRef();

	function requestForgottenEmail(username, pass){
		fetch(
			`${SiteService.apiPath}/user/requestpassreset`,
			{
				method: "POST",
				body: JSON.stringify(
					{
						email: 	username,
					}),
				headers: {
					'Content-Type': 'application/json'
				}
			}
		).then((response)=> {
			return response.json();
		})
		.then((data)=>{
			if(data.success)
				setSuccessFullySent('true');
				
		})
	}

	return(
		<section className={[styles.centered, styles.loginBox].join(' ')}>
			<h1>Glemt Passord</h1>
			<form onSubmit={(ev)=>{ev.preventDefault(); requestForgottenEmail(username, password)}}>
				{!successFullySent ?<>
					<p>Skriv inn epost til brukeren du har glemt passordet for.</p>
					<p>Vi sender deg en epost med fornyelssesinstrukser.</p>
				<input type="email" placeholder={"E-post adresse"} value={username} onChange={e => setUsername(e.target.value)} ref={usernameInput} required={true}
				style={{width:'100%'}}></input>
				{errorMessage && <div style={{color:"red"}}>{errorMessage}</div>}
				<br/><button className={'buttonDull2'} type="submit">Send </button>
					</>:<>
					<p>Dersom brukeren eksisterer i vårt system sender vi nå Epost til <strong>{username}</strong> med lenke til passord-fornyelse!</p>
					<p>Husk å sjekke søppelposten/spam mappen din dersom du ikke finner E-posten!</p>
				</>}
			</form>
		</section>
	)
}