import {useEffect, useState} from "react";
import FrontpageService from "../../../services/FrontpageService";

export default function FrontpageChooser({chooseFunc}){
	const [frontpages, setFrontpages] = useState([]);

	useEffect(() => {
		loadFrontpages();
	}, []);
	const loadFrontpages = async () => {
		setFrontpages(await FrontpageService.getAll());
	}

	return <div>
		<h1>Velg forside</h1>
		{frontpages.map((fp)=>{
			return <div key={fp.id}><button type={"button"} onClick={()=>{if(chooseFunc)chooseFunc(fp._id)}}>{fp.name}</button></div>
		})}
	</div>
}