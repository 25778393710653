import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import SiteService from "../../services/SiteService";
import classes from "../batperm.module.scss";
import BatpermBoatList from "../components/BatpermBoatList";
import BatpermBoatCardFullHarbor from "../components/BatpermBoatCardFullHarbor";
import waveIcon from "../../images/icons/wave.png";
import visningIcon1 from "../../images/icons/visning1.svg";
import visningIcon2 from "../../images/icons/visning2.svg";

export default function BatpermHarbor(){
	const { harborId } = useParams('harborId');
	const navigate = useNavigate();

	const [harbor, setHarbor] = useState({});
	const [boats, setBoats] = useState([]);

	const [displayMode, setDisplayMode] = useState('true');

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		getHarbor();
		getHarborBoats();
	}, [harborId]);

	const getHarbor = async () => {
		setIsLoading(true);
		const response = await fetch(`${SiteService.apiPath}/batperm/harbor/${harborId}`);
		const data = await response.json();
		setHarbor(data);
		setIsLoading(false);
	};

	const getHarborBoats = async () => {
		setIsLoading(true);
		const response = await fetch(`${SiteService.apiPath}/batperm/harbor/${harborId}/boats`);
		const data = await response.json();
		setBoats(data);
		setIsLoading(false);
	};


if (isLoading) return (<div className="loader">Laster...</div>)
if (boats.length<1) return (<><h1>{harbor.name}</h1><div className={classes.noBoats}>Ingen båter</div></>)
return(<>
	
<div className={classes.harborPage}>
	<h2 className={classes.boatEditableName}>{harbor.name}</h2>
	<h2>Havnens Båter</h2>
	<img className={classes.waveImage} src={waveIcon}/>
	<div className={classes.overBoatListContainer}>
		<button className={[classes.fullWidthButtonInvert, classes.newHarborBoatButton].join(' ')} onClick={()=>{navigate('./ny-bat')}}>Ny havn-båt</button>

		<div className={classes.displayModeContainer}>
			<button className={displayMode?classes.displayModeButtonActive:' '} onClick={()=>{setDisplayMode(true)}}>
				<img src={visningIcon1}/>
			</button>
			<div className={classes.verticalDivider}></div>
			<button className={!displayMode?classes.displayModeButtonActive:' '} onClick={()=>{setDisplayMode(false)}}>
				<img src={visningIcon2}/>
			</button>
		</div>
	</div>
	{!displayMode && <BatpermBoatList boats={boats} harborId={harborId}></BatpermBoatList>
	}
	{displayMode && <div className={classes.harborBoatsContainer}>{boats.map((boat ,i)=>{
		return <BatpermBoatCardFullHarbor boat={boat} key={i}></BatpermBoatCardFullHarbor>
		})}</div>
	}

</div>
	
</>)};