import SiteService from "../../../services/SiteService";
import classes from "../../../pages/Article.scss";
import {useId, useRef} from "react";
import ImagePopout from "../../ui/ImagePopout";

export default function ArticleImage({article, part}) {
	if(!part) return null;
	const imageRef = useRef(null);
	let url = "";
	if (part.value && !part.value.startsWith("data:image/"))
		url = `${SiteService.apiPath}/image/articles/` + article._id + '/' + part.value;

	const returnJSX = [];
	if (part.desc)
		returnJSX.push(<figure key={useId()}>
			{(part.variant == 3) ? <>
					<div className={classes.articleImage3BG} style={{backgroundImage: "url('" + url + "')"}}>
						<img loading="lazy" ref={imageRef} className={['articleImage' + part.variant, 'bodyPartImage'].join(' ')}
							 alt={part.alt} src={url?url:part.value}/>
					</div>
					<figcaption>{part.desc}</figcaption>
				</>
				: <>
					<img loading="lazy" ref={imageRef} className={['articleImage' + part.variant, 'bodyPartImage'].join(' ')}
						 alt={part.alt} src={url?url:part.value}/>
					<figcaption>{part.desc}</figcaption>
				</>
			}
		</figure>);
	else returnJSX.push (<figure key={useId()}>
			{(part.variant === 3) ? <>
					<div style={{backgroundImage: "url('" + url + "')"}}>
						<img loading="lazy" ref={imageRef} className={['articleImage' + part.variant, 'bodyPartImage'].join(' ')}
							 alt={part.alt} src={url?url:part.value}/>
					</div>
					<figcaption>{part.desc}</figcaption>
				</>
				: <img loading="lazy" ref={imageRef} className={['articleImage' + part.variant, 'bodyPartImage'].join(' ')}
					   alt={part.alt} src={url?url:part.value}/>
			}
		</figure>);

	returnJSX.push(<ImagePopout key={useId()} imageRef={imageRef}/>)

	return returnJSX;
}