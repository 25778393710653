import { useEffect } from 'react';

const useEventListener = (ref, eventType, callback) => {
	// eventType can be "key:KEYNAME", for example "key:ArrowRight"

	useEffect(() => {
		const element = ref?.current;

		if (!element) return;

		const isKeyEvent = eventType.startsWith('key:');
		let eventListener;

		if (isKeyEvent) {
			const key = eventType.split(':')[1];
			eventListener = (event) => {
				if (event.key.toLowerCase() === key.toLowerCase()) {
					callback(event);
				}
			};
			document.addEventListener('keydown', eventListener);
		} else {
			eventListener = (event) => callback(event);
			element.addEventListener(eventType, eventListener);
		}

		return () => {
			if (isKeyEvent) {
				document.removeEventListener('keydown', eventListener);
			} else {
				element.removeEventListener(eventType, eventListener);
			}
		};
	}, [ref, eventType, callback]);
};

export default useEventListener;
