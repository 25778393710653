import {useRef} from "react";
import classes from "./SelectManyFromList.module.scss"


export default function SelectManyFromList({list, selectedListState, setSelectedListState, addOnlyId}){
	if (!list) return null;
	const selectElementRef = useRef();

	if (!selectedListState)
		selectedListState = [];

	function addToSelectedList(item) {try {
		if (selectedListState.find(item2 => item2 === item._id))
			return;
		setSelectedListState(selectedListState.concat(item._id));
	}catch (e) {
		console.log(e);
	}}

	function removeFromSelectedList(_id) {
		setSelectedListState(selectedListState.filter(item => item !== _id));
	}

	const findName = (item) => {
		try {
			return list.find((el) => el._id === item).name || item
		}
		catch (e) {
			return item.name
		}
	}

return(<>

	<select ref={selectElementRef}>
		<option style={{display: 'none'}}></option>
		{list.map((item, index) => {return(
			<option key={index} value={item._id}>{item.name || item}</option>
		)})}
	</select>

	<button onClick={(ev) => {
		ev.preventDefault();
		let selectedId = selectElementRef.current.value;
		addToSelectedList(list.find((item) => item._id === selectedId));
	}}>Legg til</button>

	<div className={classes.selectedList}>
		{selectedListState.length>0 && selectedListState.map((item, index) => {return(
			<div key={index} className={classes.selected}
				 onClick={()=>{removeFromSelectedList(selectedListState[index])}}>
				{findName(item)}
				<span className={classes.crossButton}>
					×
				</span>
			</div>
		)})}
	</div>
</>)};