import {Link, useLocation} from "react-router-dom";
import '../../scss/footer.scss';
import waveImage from "../../images/wave2.svg";

function MainNavigation(){
	const location = useLocation();
	if (location.pathname.split('/')[1]==='admin') return(null);
	return(<>
		<div className={'footerWave'}><img src={waveImage}/> </div>
		<div className={'footerContainer'}>
			<footer className={'footer'}>
				<div>
					<p>Båtens Verden er hele Norges båtblad, utgis syv ganger årlig, i 19. årgang.
						<br/>
						Tips oss: <b><a href="mailto:tips@b-v.no">tips@b-v.no</a></b></p>
					<p>Ansvarlig redaktør: Vetle Børresen
						<br/>Alt innhold er opphavsrettslig beskyttet © VB Media AS</p>
				</div>
				<nav>
					<a href={'https://abo.b-v.no'}>Magasin</a>
					<a href={'https://annonse.vbmedia.no'}>Annonsere</a>
					<a href={'https://abo.b-v.no/om'}>Om Båtens Verden</a>
					<a href={'https://abo.b-v.no/kontakt'}>Kontakt oss</a>
					<Link to={'/personvernerklaering'}>Personværnerklæring</Link>
				</nav>
			</footer>
		</div>
	</>)
}

export default MainNavigation;