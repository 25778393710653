import {useContext, useEffect, useState} from "react";
import SiteService from "../services/SiteService";
import {Link} from "react-router-dom";
import userContext from "../store/userContext";

export default function VerifyEmail(){

	const [isSending, setIsSending] = useState(false);
	const [isSent, setIsSent] = useState(false);

	const user = useContext(userContext);

useEffect(()=>{
	console.log(user)
	/*if(user.awaitVerification === false){
		window.location = "/";
	}*/
},[])

	const requestVerificationEmail = ()=>{
		setIsSending(true);
		fetch(SiteService.apiPath+'/user/requestverificationemail')
		.then((response)=> {
			return response.json();
		})
		.then((data)=> {
			if(data.verified){
				window.location = "/";
			}else if(data.success === false){

			}
			if(data.success) {
				setIsSent(true);
			}
			setIsSending(false);
		})
		.catch((err)=>{
			console.log(err);
			if(err)
				alert('Feil under sending av epost')
			setIsSending(false);
		})
	}

return(<>
	<div style={{textAlign:'center'}}>
		<h3>Du må verifisere din e-post adresse før brukeren din kan benyttes.</h3>
		<p>Sjekk søppelpost/spam dersom du ikke finner eposten i din innboks.</p>
		<div><i>Ikke fått eposten?</i></div>
		{isSent ? <div>Sent!<br/>Husk å sjekke søppelpost om du ikke finner eposten</div> :
			!isSending ?
			<Link to={""} onClick={requestVerificationEmail}>Send ny verifikasjonslenke til epost</Link>
			:
			<div>Sender ny e-post...</div>
		}
	</div>
</>)}