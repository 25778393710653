import {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import SiteService from "../../services/SiteService";
import cardClasses from "../components/BatpermBoatCard.module.scss"
import batpermService from "../store/batpermService";
import UserContext from "../../store/userContext";
import Loginbox from "../../components/ui/functional/Loginbox";
import BatpermBoatList from "../components/BatpermBoatList";
import BatpermQRBoat from "../components/BatpermQRBoat";
import shipIcon from "../../images/icons/ship.png";

export default function BatpermQrCode(){
	const params = useParams();
	const { QRCodeId } = useParams('QRCodeId');
	const userContext = useContext(UserContext);

	const [QRCode, setQRCode] = useState({});

	const [isLoading, setIsLoading] = useState(true);
	const [harborBoats, setHarborBoats] = useState([]);

	useEffect(() => {
		getQRCode();

		if (params.QRCodeId) //todo fix, only needed if selecting boat from list
			getHarborBoatsInitialize();
	}, [userContext.adminHarborIds]);

	const getQRCode = async () => {
		setIsLoading(true);
		const response = await fetch(`${SiteService.apiPath}/batperm/qrcode/${QRCodeId}`);
		const data = await response.json();
		setQRCode(data);
		setIsLoading(false);
	};

	const getHarborBoatsInitialize = async () => {
		let boats = [];
		for (const id of userContext.adminHarborIds) {
			boats = boats.concat(await getHarborBoats(id));
		}

		setHarborBoats(boats);
	}

	const getHarborBoats = async (id) => {
		const response = await fetch(`${SiteService.apiPath}/batperm/harbor/${id}/boats`);
		return await response.json();
	};

	const assignBoatToQRcode = async (boat) => {
		let alertBoatName = boat.name? boat.name : boat.modelName;
		if (!confirm(`Er du sikker på at du vil knytte QR-Kode til ${alertBoatName}?`)) return;
		const response = await fetch(`${SiteService.apiPath}/batperm/qrcode/${QRCode._id}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				batpermBoatId: boat._id
			})
		});
		if (response.status === 200) {
			window.location.reload();
		}

	}


	//---------------------------------------------------------------

	function SelectBoatSection(){
		useEffect(() => {
			fetchBoats();
		}, []);

		const [boats, setBoats] = useState([]);
		const fetchBoats = async () => {
			const data = await batpermService.boat.getAll();
			setBoats(data);
		};

		function BoatCard2({boatObj}){return(
			<div className={cardClasses.batpermBoatCard}>
				<div className={cardClasses.boatImageContainer}>
					{boatObj.imageName ? <img src={`${SiteService.apiPath}/image/batperm-boat/${boatObj._id}/${boatObj.imageName}`}/>
						:<img style={{objectFit:'contain'}} src={shipIcon}/>
					}
				</div>
				<div className={cardClasses.boatSecondContainer}>
					<div>
						<div className={cardClasses.boatName}>{boatObj.name ? boatObj.name : boatObj.modelName}</div>
						<button style={{margin:'0 auto',display:'block'}} onClick={()=>{
							assignBoatToQRcode(boatObj)}
						}>Velg</button>
					</div>
				</div>
			</div>
		)}

		if (userContext.isLoggedIn) {
			return (<>
				<h1>Knytt QR-Kode til Båt</h1>


				<h3>Mine båter</h3>
				{boats.length > 0 ? boats.map((boat, index) => {
						return <BoatCard2 key={index} boatObj={boat}></BoatCard2>
					})
					:
					<div>Du har ingen båter.</div>
				}

				{harborBoats.length>0 && <div>
					<h3>Havnens båter</h3>
					<BatpermBoatList boats={harborBoats} actionButton={
						{text: 'Velg', action: assignBoatToQRcode}
					}></BatpermBoatList>
				</div>}
			</>)
		}else {
			return (<div>
				<div>Du må logge inn for å knytte QR-Kode til båt.</div>
				<Loginbox></Loginbox>
			</div>)
		}
	}


if (isLoading) return (<div className="loader">Laster...</div>)

if (!QRCode.batpermBoatId){
	return (<SelectBoatSection />);
}else if (QRCode.batpermBoatId){
return(<BatpermQRBoat boatId={QRCode.batpermBoatId}></BatpermQRBoat>)}};