import {useEffect, useRef, useState} from "react";
import Table from "./Table";
import classes from "../batperm.module.scss"
import SiteService from "../../services/SiteService";
import Modal from "./Modal";
import AttachmentMultiUploadShower from "./AttachmentMultiUploadShower";

export default function BatpermEquipmentSubmenu({boat, setBoat}){
	const [equipment, setEquipment] = useState([]);

	const [tableDisplayAsCards, setTableDisplayAsCards] = useState('false');

	const [selectedItem, setSelectedItem] = useState(undefined);
	useEffect(()=>{
		// dont show modal on first component render
		if (selectedItem === undefined) return;

		if (Object.keys(selectedItem).length === 0)
			newEquipmentModalRef.current.showModal();
		else {
			newEquipmentModalRef.current.close();
			editEquipmentModalRef.current.showModal();
		}
	}, [selectedItem])

	useEffect(()=>{
		getEquipment();
	},[]);

	const getEquipment = async ()=>{
		let res = await fetch(`${SiteService.apiPath}/batperm/boat/${boat._id}/equipment`);
		if (res.ok) setEquipment(await res.json());
	}


	let [refsObj, setBoatRefObj] = useState(
		{
			name: useRef(),
			notes: useRef(),
			category: useRef(),
			manufacturer: useRef(),
			productionYear: useRef(),
			newName: useRef(),
		}
	);

	const formsObject= {
		equipment: [{
			label: 'Navn',
			propertyName: 'name',
			type: 'text',
			maxLength: 99,
			minLength: 1,
			required: true,
		},	{
			label: 'Kategori',
			propertyName: 'category',
			type: 'select',
			options: ['elektronikk', 'sikkerhet', 'navigasjon', 'VVS', 'varme', 'bysse', 'truster/ankervisnj', 'rigg og mast', 'seilgarderobe', 'annet'],
		}, {
			label: 'Produsent',
			propertyName:'manufacturer',
			type: 'text',
			maxLength: 99,
			minLength: 1,
		}, {
			label: 'Produksjonsår',
			propertyName: 'productionYear',
			type: 'number',
			min: 0,
			max: new Date().getFullYear(),
		}, {
			label: 'Notater',
			propertyName: 'notes',
			type: 'textarea',
			maxLength: 1000,
			minLength: 1,
			isDisplayedInTable: false,
		}],
		newEquipment:[{
			label: 'Navn',
			propertyName: 'newName',
			type: 'text',
			maxLength: 99,
			minLength: 1,
			required: true,
		}]
	};

	const saveEquipment = async ()=>{
		let sendData = {
			equipment: {
				name: refsObj.name.current.value,
				notes: refsObj.notes.current.value,
				category: refsObj.category.current.value,
				manufacturer: refsObj.manufacturer.current.value,
				productionYear: Number(refsObj.productionYear.current.value),
			}
		};

		if (sendData.equipment.productionYear == 0)
			sendData.equipment.productionYear = null;

		let res = await fetch(`${SiteService.apiPath}/batperm/boat/${boat._id}/equipment/${selectedItem._id}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(sendData)
		});
		if (res.ok) {
			const data = await res.json();

			//find equipment with same _id and replace it with the new one. Use map to avoid mutating the array.
			const nextEquipment = equipment.map(item => {
				if (item._id === selectedItem._id) {
					return data;
				}
				return item;
			});
			setEquipment(nextEquipment);

			setSelectedItem(undefined);
			editEquipmentModalRef.current.close();
		}else{
			alert('Feil under lagring');
		}
	}




	const formRenderSwitch = (item, iEquipment)=>{
		switch (item.type) {
			case 'select':
				return <select ref={refsObj[item.propertyName]} defaultValue={iEquipment&&iEquipment[item.propertyName]}>
					{item.options.map((option, index) => <option key={index}>{option}</option>)}
				</select>
			case 'textarea':
				return <textarea
					id={item.propertyName}
					ref={refsObj[item.propertyName]}
					defaultValue={iEquipment&&iEquipment[item.propertyName]}
					maxLength={item.maxLength}
					></textarea>
			default:
				return <input
					id={item.propertyName}
					type={item.type}
					ref={refsObj[item.propertyName]}
					defaultValue={iEquipment&&iEquipment[item.propertyName]}
					defaultChecked={iEquipment&&iEquipment[item.propertyName]}
					maxLength={item.maxLength}
					minLength={item.minLength}
					max={item.max}
					min={item.min}
					step={'any'}
					required={item.required}
			/>}
	}
	function FormElement({iEquipment, formObject}){
		return formObject.map((item) => {return (
			<div className={classes.modalFormItem} key={item.propertyName}>
				<label htmlFor={item.propertyName}>
					{item.label}
				</label>

				{formRenderSwitch(item, iEquipment)}
			</div>
		)})
	}

	const deleteEquipment = async ()=>{
		if (!confirm('Er du sikker på at du vil slette utstyret?')) return;
		let res = await fetch(`${SiteService.apiPath}/batperm/boat/${boat._id}/equipment/${selectedItem._id}`, {
			method: 'DELETE'
		});
		if (res.ok) {
			//remove equipment of same id as selectedItem._id from equipment array
			const nextEquipment = equipment.filter(item => item._id!== selectedItem._id);
			setEquipment(nextEquipment);
			setSelectedItem(undefined);
			editEquipmentModalRef.current.close();
		}
		else {
			alert('Feil under sletting');
		}
	}

	const createNewEquipment = async ()=>{
		let res = await fetch(`${SiteService.apiPath}/batperm/boat/${boat._id}/equipment`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({equipment: {
				name: refsObj.newName.current.value
			}})
		});
		if (res.ok) {
			const data = await res.json();
			if (equipment === undefined)
				setEquipment([data]);
			else setEquipment([...equipment, data]);

			setSelectedItem(data);
		}else {
			alert('Feil under oppretting');
		}
	}

	function EquipmentNew({formObject}){
		return	<form id={'newEquipmentForm'} onSubmit={(ev)=>{ev.preventDefault();createNewEquipment()}}>
			<FormElement formObject={formObject}/>
			<input type={"submit"} form={'newEquipmentForm'} value={'Opprett'}/>
		</form>
	}

	function EquipmentEdit({iEquipment,formObject}){
		if (!iEquipment) return <div>Feil: Objektet finnes ikke</div>;
		return (<form onSubmit={(ev)=> {
			ev.preventDefault();saveEquipment();
		}}>
		<div className={classes.modalForm}>
		<FormElement formObject={formObject} iEquipment={iEquipment}/>
		</div>
		<AttachmentMultiUploadShower selectedItem={selectedItem} boat={boat} resourceName={'equipment'}/>

		<div className={classes.modalBottomButtonsContainer}>
			<input type={"submit"} value={'Lagre'}/>
			<button onClick={(ev)=>{ev.preventDefault();deleteEquipment()}}>
				Slett
			</button>
		</div>
	</form>)}


	const tableActionElements = [
		{label: 'Rediger', action: (item, index)=>{
			if (item !== selectedItem) setSelectedItem(item);
			else editEquipmentModalRef.current.showModal();
		}}
	];


	function EquipmentCardTable({equipment, formsObject}){return(
		<div className={classes.equipmentCards}>
			{equipment.map((equipmentItem, equipmentIndex) => {
				return (
					<div key={equipmentIndex} className={classes.equipmentCard}>
						<div className={classes.equipmentCardName}>{equipmentItem.name}</div>
						<div className={classes.equipmentCardManufacturer}>{equipmentItem.manufacturer}</div>
						<div className={classes.equipmentCardCategory}>{equipmentItem.category}</div>
						<button onClick={() => {
							tableActionElements[0].action(equipmentItem, equipmentIndex)
						}}>Vis
						</button>
					</div>
				)
			})}
		</div>
	)}


	const onNewEquipment = ()=>{
		setSelectedItem({});
	}

	const editEquipmentModalRef = useRef();
	const newEquipmentModalRef = useRef();
	return <div>
		<h2>Utstyr</h2>
		<button onClick={onNewEquipment}>Opprett Utstyr</button>
		<button onClick={()=>{setTableDisplayAsCards(!tableDisplayAsCards)}}>Endre visning</button>

		{tableDisplayAsCards ?
			<EquipmentCardTable equipment={equipment} formsObject={formsObject.equipment}/>
			:
			<Table tableObjects={equipment} projectionArr={formsObject.equipment}
				   actionElements={tableActionElements}></Table>
		}


		<Modal modalRef={newEquipmentModalRef} title="Nytt utstyr"
			   onClose={()=>{setSelectedItem(null)}}
			   children={<EquipmentNew formObject={formsObject.newEquipment} iEquipment={{}}/>}></Modal>

		<Modal modalRef={editEquipmentModalRef} title="Rediger utstyr"
			   onClose={()=>{setSelectedItem(null)}}
			   children={<EquipmentEdit formObject={formsObject.equipment} iEquipment={selectedItem}/>}></Modal>
	</div>
}