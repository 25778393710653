import SiteService from "./SiteService";

export default {
	async search(searchIn: String, page:Number, searchObj, orderBy: String) {
		const pageOptions = {
			page,
			searchObj,
			orderBy
		};

		let req = await fetch(`${SiteService.apiPath}/search/${searchIn}/`, {
			method: 'POST',
			body: JSON.stringify({pageOptions}),
			headers: {'Content-Type': 'application/json'}
		});

		if (!req.ok) {
			alert('Feil under søk');
			return null;
		}
		return await req.json();
	}
}