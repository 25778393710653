import VideoPlayer from "../ui/VideoPlayer";
import PlayIcon from "../../svg/PlayIcon";
import plussIcon from "../../images/icons/pluss.svg";

export default function ContentImage({part, children, customStyle}){
	if(part.isSponsored)
		part.category = 'Annonsørinnhold';

 	return <div className={'contentImageContainer'}>
		{part.video?<VideoPlayer videoId={part.video} autoPlay={true} disableControls={true}/> : children}
		{part.pluss && <img className={'plussIcon'} src={plussIcon} alt={'Pluss ikon'}></img>}
		{part.hasVideo && <div className={'hasVideoIcon'}><PlayIcon/></div>}
		{part.category && <div style={customStyle?{color: customStyle.color, background:customStyle.background}:null} className={['categoryInImage categoryBoxThemed', part.isSponsored?'sponsored':''].join(' ')}>{part.category}</div>}
	</div>
}