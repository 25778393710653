export default function ArticleTableEditor({part, setPart}){
	if (!part) part = {kind:'table', table: [
		['',''],
		['',''],
	]};
	if (part && !part.table) part = {...part,table: [['',''],['',''],]};

	const convertExcelTableToArray = (excelTable) => {
		if (excelTable.length < 2) return;
		try {
			let newTable = excelTable.split('\n').map(row => row.split('\t'));
			setPart({...part, table: newTable});
		} catch (e) {
			console.log(e);
			return alert('Ugyldig tabell');
		}
	}

	function ImportExcelTableComponent() {
		/*import by pasting into input field*/
		return <textarea  placeholder={'Lim inn'} className={'excelTable'}
						  rows={1} cols={5} style={{resize: 'none'}}
			  onChange={(e) => convertExcelTableToArray(e.target.value)}>
		</textarea>
	}

	const addRow = () => {
		let newRow = Array(part.table[0].length).fill('');
		setPart({...part, table: [...part.table, newRow]});
	}

	const addColumn = () => {
		let newTable = [...part.table];
		for (let i = 0; i < newTable.length; i++) {
			newTable[i].push('');
		}
		setPart({...part, table: newTable});
	}

	const removeRow = () => {
		let newTable = [...part.table];
		newTable.splice(newTable.length - 1, 1);
		setPart({...part, table: newTable});
	}

	const removeColumn = () => {
		let newTable = [...part.table];
		for (let i = 0; i < newTable.length; i++) {
			newTable[i].splice(newTable[i].length - 1, 1);
		}
		setPart({...part, table: newTable});
	}
	const changeCell = (i, j, value) => {
		let newTable = [...part.table];
		newTable[i][j] = value;
		setPart({...part, table: newTable});
	}

	return <div>
		<span>Importer fra regneark (lim inn fra excel o.l)</span>
		<ImportExcelTableComponent/>
		<br/>
		<div className={'articleTable'}>
			{part.table.map((row, i) => {
				return <div className={'tableRow'} key={i}>{
					row.map((cell, i2) => {return (
						<input type={"text"} className={'tableCell'} key={i2}
					 	defaultValue={cell}
					 	onBlur={(e) => changeCell(i, i2, e.target.value)}/>)})
				}
				</div>
			})}
	</div>
		<button type={"button"} onClick={()=>addRow()}>Ny rad</button>
		<button type={"button"} onClick={()=>addColumn()}>Ny kolonne</button>
		<button type={"button"} onClick={()=>removeRow()}>Slett rad(siste)</button>
		<button type={"button"} onClick={()=>removeColumn()}>Slett kolonne(siste)</button>
	</div>}