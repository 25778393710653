import {useEffect, useRef, useState} from "react";
import classes from "../../../components/adminEdit/AdminEdit.module.scss";
import B64ImageUploader from "../../../components/ui/functional/B64ImageUploader/B64ImageUploader";
import AdminEdit from "../../../components/adminEdit/AdminEdit";

import AuthorService from "../../../services/AuthorService";
import SiteService from "../../../services/SiteService";

function AuthorEdit(){

	const [isLoading, setIsLoading] = useState(true);
	const [authors, setAuthors] = useState([]);

	useEffect(()=>{
		AuthorService.getAll((reqAuthors)=>{
			reqAuthors.forEach((author, i)=>{
				author.isEditable = false;
			})
			setAuthors(reqAuthors);
			setIsLoading(false);
		})
	}, [])


	function TableHeaders(){return(
		<div className={classes.th}>
			<div className={classes.td}></div>
			<div className={classes.td}>Navn</div>
			<div className={classes.td}>Bilde</div>
		</div>
	)}

	function UnEditableSectionChildren({obj}){
		return(<div className={classes.td}>
			{obj['imageB64'] ? (
				<img style={{maxHeight: "200px"}} src={obj.imageB64} alt="" />
			):(
				<img style={{maxHeight: "200px"}} src={`${SiteService.apiPath}/image/authors/${obj._id+obj.imageName}`} alt="" />
			)}
		</div>)
	}

	function EditableSectionChildren({obj, index, handleChange}) {return(<>
		<B64ImageUploader
			setImageB64={(b64) => {
				handleChange("imageB64", b64, index)
			}}
			setFileNameExtention={(name) => {
				handleChange("imageName", name, index)
			}}
		></B64ImageUploader>
	</>)}




	return(
		<AdminEdit
			pageName={"Forfattere"}
			mainService={AuthorService}
			mainArray={authors}
			setMainArray={setAuthors}
			NewSectionBool={true}
			TableHeaders={TableHeaders}
			EditableSectionChildren={EditableSectionChildren}
			UnEditableSectionChildren={UnEditableSectionChildren}>
		</AdminEdit>
	)
}

export default AuthorEdit;