import ArticleService from "../../services/ArticleService";
import React from "react";
import classes from '../../pages/Article.scss'

export default function RenderBodyParts({parts, article}) {
	const moduleTypes = ArticleService.moduleTypes;
	const partEls = [];

	const insertAdsInArticle = (parts)=>{
		if (article.isSponsored) return parts;
		// if textPoints is more than 800, insert an ad at between this article part and the next
		let textPoints = 0;
		parts.forEach((part,i)=>{
			let currPartPoints = 0;
			if (part.kind&&part.value&&part.kind === 'paragraph')
				currPartPoints+=part.value.length;

			if (part.kind&&part.value&&part.kind === 'quote')
				currPartPoints+=part.value.length;

			if (currPartPoints > 800) {
				textPoints+=1000;
			}

			textPoints+=currPartPoints;
			if (textPoints > 1000){
				textPoints = 0;
				//insert a div between this article part and the next
				parts.splice(i+1,0,{kind:'ad',value:''});
			}
		})

		return parts;
	}

	parts = insertAdsInArticle([...parts]);
	
	parts.map((part, index) => {
		const moduleType = moduleTypes[part.kind];

		if (moduleType.component && part)
			return partEls.push(
				<div className={'articleModule'} id={'module'+index} key={index}>{
					React.createElement(moduleType.component,
						{
							part: part,
							article: article,

							parts:[{id: '000000000000000000000004'}]//for advert
						})}</div>
			);
	});

	return (<div className={classes.articleContainer}>
		<div className={classes.bodyPartsContainer}>
			{partEls}
		</div>
	</div>)}