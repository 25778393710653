import {createContext, useEffect} from "react";
import {useState} from "react";
import userContext from "./userContext";
import SiteService from "../services/SiteService";

const FavouritesContext = createContext({
	favouriteArticles: [],
	totalFavourites: 0,
	addFavourite: (articleId) => {},
	removeFavourite: (articleId) => {},
	itemIsFavourite: (articleId) => {},
});


export function FavouritesContextProvider(props){
	const [favouriteArticles, setFavouriteArticles] = useState([]);

	useEffect(()=>{
		console.log('fetching articlefavouriteIds');
		fetch(
			`${SiteService.apiPath}/user/articlefavouritesids`,
			{
				method: "GET",
			}
		).then((response)=> {
			return response.json();
		})
		.then((data)=>{
			if(data.success === false){
				return;
			}
			console.log(data);
			setFavouriteArticles(data);
		})
	}, [userContext.displayName])


	async function addFavouriteHandler(articleId) {
		if (articleId in favouriteArticles) {
			return true;
		}

		try {
			const response = await fetch(SiteService.apiPath + '/user/addarticlefavourite', {
				method: 'POST',
				body: JSON.stringify({ id: articleId }),
				headers: {
					'Content-Type': 'application/json',
				},
			});

			const data = await response.json();
			setFavouriteArticles(favouriteArticles.concat(articleId));
			return true;
		} catch (error) {
			console.log(error);
			return false;
		}
	}

	async function removeFavouriteHandler(articleId) {
		try {
			const response = await fetch(SiteService.apiPath + '/user/deletearticlefavourite', {
				method: "POST",
				body: JSON.stringify({ id: articleId }),
				headers: {
					'Content-Type': 'application/json'
				}
			});

			const data = await response.json();

			if (data.success) {
				setFavouriteArticles(favouriteArticles.filter((el) => el !== articleId));
				return true;
			} else {
				alert('Feil under fjerning av favoritt');
				return false;
			}
		} catch (error) {
			console.error('An error occurred:', error);
			return false;
		}
	}

	function itemIsFavouriteHandler(articleId){
		if(favouriteArticles.length > 0)
			return favouriteArticles.includes(articleId);
		else
			return false;
	}

	const context = {
		favourites: favouriteArticles,
		favouriteArticles: favouriteArticles,
		totalFavourites: favouriteArticles.length,
		addFavourite: addFavouriteHandler,
		removeFavourite: removeFavouriteHandler,
		itemIsFavourite: itemIsFavouriteHandler,
	};

	return (
		<FavouritesContext.Provider value={context}>
			{props.children}
		</FavouritesContext.Provider>
	)
}

export default FavouritesContext;