import { useEffect } from 'react';

const useSwipe = (ref, onSwipeUp, onSwipeDown, onSwipeLeft, onSwipeRight) => {
	useEffect(() => {
		const element = ref?.current;
		if (!element) {
			return;
		}
		let touchStartX = 0;
		let touchStartY = 0;
		let touchEndX = 0;
		let touchEndY = 0;

		const handleTouchStart = (e) => {
			touchStartX = e.touches[0].clientX;
			touchStartY = e.touches[0].clientY;
		};

		const handleTouchEnd = (e) => {
			touchEndX = e.changedTouches[0].clientX;
			touchEndY = e.changedTouches[0].clientY;
			handleSwipe();
		};

		const handleSwipe = () => {
			const deltaX = touchEndX - touchStartX;
			const deltaY = touchEndY - touchStartY;

			if (Math.abs(deltaX) > Math.abs(deltaY)) {
				if (deltaX > 0 && onSwipeRight) {
					onSwipeRight();
				} else if (deltaX < 0 && onSwipeLeft) {
					onSwipeLeft();
				}
			} else {
				if (deltaY > 0 && onSwipeDown) {
					onSwipeDown();
				} else if (deltaY < 0 && onSwipeUp) {
					onSwipeUp();
				}
			}
		};

		element.addEventListener('touchstart', handleTouchStart);
		element.addEventListener('touchend', handleTouchEnd);

		return () => {
			element.removeEventListener('touchstart', handleTouchStart);
			element.removeEventListener('touchend', handleTouchEnd);
		};
	}, [ref, onSwipeUp, onSwipeDown, onSwipeLeft, onSwipeRight]);
};

export default useSwipe;
