import {useEffect, useRef, useState} from "react";
import SiteService from "../../services/SiteService";
import classes from "../batperm.module.scss";
import B64ImageUploader from "../../components/ui/functional/B64ImageUploader/B64ImageUploader";
import Helpers from "../../helpers/Helpers";

export default function BatpermLaunchingSubmenu({boat, setBoat}){
	const [launchingPosts, setLaunchingPosts] = useState([]);

	useEffect(() => {
		if (boat.launchingPosts) setLaunchingPosts(boat.launchingPosts);
	}, []);

	const deleteLaunchingPost = async (postId) => {
		const deleteRes = await fetch(`${SiteService.apiPath}/batperm/boat/${boat._id}/launching-post/${postId}`, {
			method: 'DELETE'
		});
		if (!deleteRes.ok) return console.error(deleteRes);

		let newLaunchingPosts = launchingPosts.filter((post) => post._id !== postId);
		setLaunchingPosts(newLaunchingPosts);
		setBoat({...boat, launchingPosts: newLaunchingPosts});
	}



	function LaunchingPostUploader({postProp, setEditMode}) {
		if (!postProp)
			postProp = {};

		const [imageB64, setImageB64] = useState(null);
		const [imageName, setImageName] = useState(postProp.imageName);
		const text = useRef(postProp.text);

		const [uploadProgress, setUploadProgress] = useState(0);
		const [isUploading, setIsUploading] = useState(false);

		const onUploadEnd = (data) => {
			if (data) {
				//remove old post if it exists
				let newLaunchingPosts = launchingPosts.filter((post) => post._id!== data._id);

				setLaunchingPosts(newLaunchingPosts.concat(data));
				setBoat({...boat, launchingPosts: newLaunchingPosts.concat(data)});
			}
			setIsUploading(false);
		}

		const uploadPost = async () => {
			setIsUploading(true);
			let uploadObj = {
				post:{
					imageName: imageName,
					text: text.current.value
			}};
			if (imageB64) uploadObj.b64 = imageB64;

			let postUrl = `${SiteService.apiPath}/batperm/boat/${boat._id}/launching-post`;
			if (postProp._id) postUrl += `/${postProp._id}`;

			Helpers.uploadWithProgress(uploadObj,
				postUrl,
				'POST',
				setUploadProgress,
				onUploadEnd
			);
		};

		return (<div className={classes.launchingPostUploader}>
			{<>
				{!isUploading && <div className={classes.launchingPostUploader_imageWrapper}>
					{(!imageB64 && !imageName) && <>
						<div>Valgfritt bilde: </div>
						<B64ImageUploader setImageB64={setImageB64} setFileNameExtention={setImageName}/>
					</>}
					{(imageB64 || imageName) && <div className={classes.launchingPostUploader_imageContainer}>
						<button className={classes.deleteFileAttachmentButton} onClick={()=> {
							setImageB64(null);
							setImageName(null);
						}}>
							<span>×</span>
						</button>
						 <img src={ imageB64 ?
							imageB64 :
							`${SiteService.apiPath}/image/batperm-boat/${imageName}`
						}/>
					</div>}
				</div>}

				<div className={
					[classes.launchingPostUploaderTextContainer, (isUploading && 'hidden')].join(' ')
				}>
					<textarea ref={text} defaultValue={postProp.text}></textarea>
				</div>

				{(!isUploading) && <div className={classes.launchingPostActions}>
					{setEditMode && <button onClick={() => setEditMode(false)}>Avbryt Redigering</button>}
					<button onClick={uploadPost}>{postProp._id ? "Lagre" : "Legg til"}</button>
				</div>}

			</>}

			{isUploading && <div className={classes.launchingPostUploaderProgress}>
				Laster opp...
				<progress max={100} value={uploadProgress}>
					{uploadProgress}%
				</progress>
			</div>}
		</div>);
	}

	function LaunchingPost({post}, {index}) {
		const [isEditMode, setEditMode] = useState(false);

		return (!isEditMode ?
		<div className={classes.launchingPost} key={index}>
			{post.imageName && <div className={classes.launchingImageContainer}>
				<img src={`${SiteService.apiPath}/image/batperm-boat/${post.imageName}`}/>
			</div>}

			{post.text && <div className={classes.launchingImageText}>{post.text}</div>}

			<div className={classes.launchingPostActions}>
				<button onClick={() => setEditMode(!isEditMode)}>Rediger</button>
				<button onClick={() => deleteLaunchingPost(post._id)}>Slett post</button>
			</div>
		</div> : <LaunchingPostUploader postProp={post} setEditMode={setEditMode}/>
	)}

	return(<div className={classes.launchingSubmenu}>
		<h2>Sjøsetting</h2>
		<LaunchingPostUploader/>

		<h3>Innlegg</h3>
		{(launchingPosts && launchingPosts.length>0) && launchingPosts.reverse().map((post, index) => {
			return <LaunchingPost post={post} key={index} index={index}/>;
		})}

	<br/>
	</div>)}