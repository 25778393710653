import SiteService from "./SiteService";

const AdminUserService = {
	getAll: async function(cb) {
		try {
			const response = await fetch(
				`${SiteService.apiPath}/admin/getuserspage`,
				{
					method: "POST",
					body: JSON.stringify({
						pageOptions: { page: 0 }
					}),
					headers: { 'Content-Type': 'application/json' }
				}
			);
			const data = await response.json();
			
			if (cb)
				cb(data);
			else
				return data;
		} catch (reason) {
			console.error(reason);
			alert('Feil under henting av brukere');
		}
	},

	getPage: function(pageNumber, sortBy, searchObj, cb){
		fetch(
			`${SiteService.apiPath}/admin/getuserspage`,
			{
				method: "POST",
				body: JSON.stringify({
					pageOptions: {page: pageNumber, sortBy: sortBy, searchObj: searchObj}
				}),
				headers: {'Content-Type': 'application/json'}
			}
		).then((response)=> {
			return response.json();
		})
			.then((data)=> {
				if(cb) cb(data);
			})
			.catch(reason => {
				console.error(reason);
				alert('Feil under henting av artikler');
			})
	},

	create: function(author, cb){
		return;
	},

	save: async function(updateObj, cb){
		console.log(updateObj)
		let res = await fetch(SiteService.apiPath + '/admin/user/propertychange',
			{
				method: 'POST',
				body: JSON.stringify({updateObj: updateObj}),
				headers: {
					'Content-Type': 'application/json'
				}
			});
		if (!res.ok){
			alert('Feil under lagring');
		}
		return cb(true);
	},

	delete: function (id, cb) {
		alert('Funksjon ikke implimentert');
	}
}

export default AdminUserService;