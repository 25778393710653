import {useContext, useRef} from "react";
import useTooltip from "../../hooks/useTooltip";
import BellIcon from "../../svg/BellIcon";
import {Link} from "react-router-dom";
import UserContext from "../../store/userContext";
import MinusIcon from "../../svg/MinusIcon";

export default function TagBox({tag}) {
	const userContext = useContext(UserContext);
	if (tag.hidden) return null;

	const bellTooltipRef = useRef(null);
	const bellTooltip = useTooltip(bellTooltipRef, 'Få varsel om nye artikler fra emnet');

	let isFollowed = userContext.isLoggedIn;
	if (userContext.isLoggedIn)
		isFollowed = tag.topicId ? true :
			userContext.followedTopics.some(topic => topic.topicType === 'tag' && topic.topicId === tag._id);

	return (
		<div className={'tag'}>
			{userContext.isLoggedIn && (
				<>
					{isFollowed ? (
						<button
							className={'tagBellContainer'}
							onClick={() => {
								userContext.unfollowTopic('tag', tag.topicId?tag.topicId:tag._id);
							}}
						>
							<MinusIcon/>
						</button>
					) : (
						<button
							ref={bellTooltipRef}
							className={'tagBellContainer'}
							onClick={() => {
								userContext.followTopic('tag', tag.topicId?tag.topicId:tag._id);
							}}
						>
							<BellIcon stroke={'var(--theme-color1)'}/>
						</button>
					)}
				</>
			)}
			<Link to={`/kategorier/?tag=${tag.name}`}>{tag.name && tag.name}</Link>
		</div>
	);
}