import VideoPlayer from "../../ui/VideoPlayer";

export default function ArticleVideo({part}){
	if (!part)
		return null;

	return <div className={'bodyPartVideo'}>
		<VideoPlayer
			videoId={part.value?part.value:null}
			autoPlay={part.autoplay?part.autoplay:false}
			disableControls={part.autoplay?part.autoplay:false}
		/>
	</div>
}