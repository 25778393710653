import {useContext, useEffect, useState} from "react";
import siteContext from "../../../store/siteContext";
import Checkbox from "../../../components/ui/Checkbox";
import {DuplicateButton} from "../../../components/ui/DuplicateButton";

export default function BatsokPartsEdit({part, module, setEditingParts, editingParts, moduleOptions, index}){
	const SiteContext = useContext(siteContext);
	const [isLoadingCats, setIsLoadingCats] = useState(false);

	const [subType, setSubType] = useState(module.type);
	const [id, setId] = useState(part.id?part.id:null);
	const [random, setRandom] = useState(part.random?part.random:false);

	module.type = 'batsok'

	const loadCats = async () => {
		setIsLoadingCats(true);
		await SiteContext.fetchBatsokCategories();
		setIsLoadingCats(false);
	}
	const loadTags = async () => {
		setIsLoadingCats(true);
		await SiteContext.fetchBatsokTags();
		setIsLoadingCats(false);
	}

	useEffect(() => {
		if (!part.parts)
			part.parts = [];

		if (!SiteContext.batsokCategories || !SiteContext.batsokCategories.length)
			loadCats();
		if (!SiteContext.batsokTags || !SiteContext.batsokTags.length){
			loadTags();
		}
	}, []);

	useEffect(() => {
		setEditingParts([...editingParts])
	},[subType,id])

	const removePart = (index) => {
		setEditingParts([...editingParts].filter((el,i) => i!==index));
	}

	const duplicatePart = (index) => {
		const partToDuplicate = editingParts[index];
		const duplicatedPart = JSON.parse(JSON.stringify(partToDuplicate)); // Create a deep copy
		setEditingParts([...editingParts, duplicatedPart]);
	};


	return <div>Båtsøk:
	<select defaultValue={part.subType} onChange={(ev) => {
		part.subType = ev.target.value;
		setSubType(ev.target.value);
	}}>
		<option value={'category'}>Kategori</option>
		<option value={'tag'}>Stikkord</option>
	</select>

	<select value={id} onChange={(ev) => {
		part.id = ev.target.value;
		setId(ev.target.value);
	}}>
		<option value={null}>{isLoadingCats?'Laster...':'Ingen'}</option>
		{(part.subType=='category'&&SiteContext.batsokCategories)&&SiteContext.batsokCategories.map((tag) => <option key={tag._id} value={tag._id}>{tag.name}</option>)}
		{(part.subType=='tag'&&SiteContext.batsokTags)&&SiteContext.batsokTags.map((tag) => <option key={tag._id} value={tag._id}>{tag.name}</option>)}
	</select>
	<Checkbox state={random}  setState={setRandom} changeFunc={(bool)=>part.random = bool} label="Tilfeldig" />

	{(moduleOptions.minParts&&editingParts.length>moduleOptions.minParts) && <button className="frontpageEdit-chosenModulesList-removeButton"
																					 onClick={() => removePart(index)}>×</button>}
	{moduleOptions.minParts && <button type={"button"} onClick={()=>duplicatePart(index)}><DuplicateButton/></button>}
</div>}