import SiteService from "../services/SiteService";

const Helpers = {};

Helpers.scrollToTargetOffset = function (element, offset){
	if (!offset)
		offset = 62; //height of header
	let elementPosition = element.getBoundingClientRect().top;
	let offsetPosition = elementPosition + window.pageYOffset - offset;

	window.scrollTo({
		top: offsetPosition,
		behavior: "smooth"
	});
}

Helpers.getISODate = function (date) {//defaults to today
	if(!date)
		date = new Date();
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');

	return `${year}-${month}-${day}`;
}

Helpers.loadScript = async function (src) {
	return new Promise((resolve, reject) => {
		const script = document.createElement('script');
		script.src = src;
		script.async = true;
		script.onload = () => resolve();
		script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
		document.body.appendChild(script);
	});
}

Helpers.getWeekNumber = (date) => {
	const d = new Date(date);
	const yearStart = new Date(d.getFullYear(), 0, 1);
	const weekNo = Math.ceil((((d - yearStart) / 86400000) + yearStart.getDay() + 1) / 7);
	return `${d.getFullYear()}-W${weekNo < 10 ? '0' + weekNo : weekNo}`;
};

Helpers.convertToCSV = (data, includedFields = null) => {
	if (!data || !data.length) {
		return null;
	}

	let transformedData = [...data]
	transformedData.forEach((obj)=>{
		if (obj.date)
			obj.date=obj.date.substring(0,19);
	})

	// Determine the fields to include
	const keys = includedFields ? includedFields : Object.keys(transformedData[0]);
	const csvRows = [];

	// Add the header row
	csvRows.push(keys.join(','));

	// Add the data rows
	transformedData.forEach(row => {
		const values = keys.map(key => {
			const escapedValue = ('' + row[key]).replace(/"/g, '""');
			return `"${escapedValue}"`;
		});
		csvRows.push(values.join(','));
	});

	return csvRows.join('\n');
};

Helpers.downloadCSV = (csvContent, fileName) => {
	const blob = new Blob([csvContent], { type: 'text/csv' });
	const url = URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.setAttribute('hidden', '');
	a.setAttribute('href', url);
	a.setAttribute('download', fileName);
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
}

Helpers.uploadWithProgress = function (body, url, httpMethod, setProgress, onEnd, extraPostProperties) {
	const xhr = new XMLHttpRequest();

	xhr.upload.onprogress = function(event) {
		if (!setProgress)
			return;
		const progress = Math.round((event.loaded / event.total) * 100);
		setProgress(progress);
	};

	xhr.onreadystatechange = function() {
		if(xhr.readyState === 4) {
			if(xhr.status === 200) {

				if (onEnd)
					onEnd(JSON.parse(xhr.responseText));
			}
			else {
				onEnd(null);
				alert('Feil under opplasting.')
			}
		}
	}

	xhr.open(httpMethod, url, true);
	xhr.setRequestHeader('Content-Type', 'application/json');

	let sendObj = body;
	console.log(extraPostProperties)
	if (extraPostProperties)
		sendObj = Object.assign(sendObj, extraPostProperties);
	console.log(extraPostProperties)
	xhr.send(JSON.stringify(sendObj));
}

Helpers.downloadWithDifferentName = function (url, fileName) {
	fetch(url)
		.then(res => {
			return res.blob();
		})
		.then(blob => {
			const href = window.URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.download = fileName;
			a.href = href;
			a.click();
			a.href = "";
		})
		.catch(err => console.error(err));
}



Helpers.splitDragElement = function (element, direction){
	//	https://stackoverflow.com/a/55202728

	var   md; // remember mouse down info
	const first  = document.getElementById("first");
	const second = document.getElementById("second");

	element.onmousedown = onMouseDown;

	function onMouseDown(e)
	{
		//console.log("mouse down: " + e.clientX);
		md = {e,
			offsetLeft:  element.offsetLeft,
			offsetTop:   element.offsetTop,
			firstWidth:  first.offsetWidth,
			secondWidth: second.offsetWidth
		};

		document.onmousemove = onMouseMove;
		document.onmouseup = () => {
			//console.log("mouse up");
			document.onmousemove = document.onmouseup = null;
		}
	}

	function onMouseMove(e)
	{
		//console.log("mouse move: " + e.clientX);
		var delta = {x: e.clientX - md.e.clientX,
			y: e.clientY - md.e.clientY};

		if (direction === "H" ) // Horizontal
		{
			// Prevent negative-sized elements
			delta.x = Math.min(Math.max(delta.x, -md.firstWidth),
				md.secondWidth);

			element.style.left = md.offsetLeft + delta.x + "px";
			first.style.width = (md.firstWidth + delta.x) + "px";
			second.style.width = (md.secondWidth - delta.x) + "px";
		}
	}
}

Helpers.splitLastOccurrence = function (str, substring){
	const lastIndex = str.lastIndexOf(substring);

	const before = str.substring(0, lastIndex);
	const after = str.substring(lastIndex + 1);
	return [before, after];
}

Helpers.convertDateToInputValue = function (date){
	let dateArr = date.toLocaleDateString().split('/');
	return dateArr[2] + '-' + dateArr[1] + '-' + dateArr[0]
}

Helpers.prepareArticleForFrontpageParts = function (part, categories){
	if(part.image) part.imageLink = `${SiteService.apiPath}/image/articles/${part._id}/${part.image.url}`

	if(part.urlString) part.linkTo = `/artikkel/${part.urlString}`

	if (part.categories && part.categories.length > 0) {try{
		// find the category for the part's first category
		part.category = categories.find((cat) => {
			return cat._id === part.categories[0];
		}).name;
	}catch(e){
		console.error('Could not find category for part', part);
	}}
}

export default Helpers;