
export default function ArticleYoutube({part}){
	let videoId = "";
	try {
		if(part.value.includes('youtu.be/')){
			videoId = part.value.split('.be/')[1];
		}else{
			videoId = part.value.split('?v=')[1].split('&')[0].split('?')[0];
		}
	}catch (e){console.error(e)}

	const autoPlayParameters = part.autoplay? "mute=1&autoplay=1&amp;controls=0" : "controls=1";

	return <div className={['youtubeEmbedContainer', 'youtubeEmbedContainer'+part.variant].join(' ')}>
		<iframe id="youtube-1905" allowFullScreen="1"
				allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				src={"https://www.youtube-nocookie.com/embed/" + videoId + "?"+autoPlayParameters+"&amp;disablekb=1&amp;playsinline=1&amp;cc_load_policy=0&amp;cc_lang_pref=auto&amp;widget_referrer=https%3A%2F%2Fb-v.no%2Fad%2Fsunseeker-superhawk-55%2F%3Fpreview%3Dtrue&amp;noCookie=true&amp;rel=0&amp;showinfo=0&amp;iv_load_policy=3&amp;modestbranding=1&amp;enablejsapi=1&amp;widgetid=1"}
				frameBorder="0"></iframe>
	</div>;
}