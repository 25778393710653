import FileUpload from "../../../../services/FileUpload";
import classes from "./ImageUploader.module.scss";
import {useState} from "react";

function ImageUploader({path, newName, cb}){

	const [currentFile, setCurrentFile] = useState(undefined);
	const [previewImage, setPreviewImage] = useState("");
	const [progress, setProgress] = useState(0);
	const [message, setMessage] = useState("");

	function selectFile(event){
		setCurrentFile(event.target.files[0]);
		setPreviewImage(URL.createObjectURL(event.target.files[0]));
		setProgress(0);
		setMessage("");
	}

	function upload(){
		setProgress(0);
		setMessage("Laster opp...");

		FileUpload.upload(path, currentFile, (event)=>{
			if(event === "finished"){
				setMessage('Lastet opp!');
				setProgress(0);
				setCurrentFile(undefined);
				if(cb) {cb()}
			}else if(event === "error") {
				setProgress(0);
				setMessage('Kunne ikke laste opp');
				setCurrentFile(undefined);
			}else {
				setProgress(Math.round((100 * event.loaded) / event.total))
			}
		}, newName)
	}


	return(
		<div>
			<div className="row">
				<div className="col-8">
					<label className="btn btn-default p-0">
						<input type="file" accept="image/*" onChange={selectFile} />
					</label>
				</div>

				<div className="col-4">
					<button
						className="btn btn-success btn-sm"
						disabled={!currentFile}
						onClick={(ev)=>{ev.preventDefault(); upload()}}
					>
						Last opp
					</button>
				</div>
			</div>

			{currentFile && (
				<div className="progress my-3">
					<progress max={100} value={progress}>
						{progress}%
					</progress>
				</div>
			)}

			{previewImage && (
				<div>
					<img className={classes.preview} src={previewImage} alt="" />
				</div>
			)}

			{message && (
				<div className="alert alert-secondary mt-3" role="alert">
					{message}
				</div>
			)}
		</div>
	)
}

export default ImageUploader;