import classes from './ArticleList.module.scss'
import ArticleItem from "./ArticleItem";

function ArticleList(props){
	if(!props.articles){
		return (<ul className={classes.list}></ul>)
	}else return(
		<ul className={classes.list}>
			{props.articles.map(article =>
				<ArticleItem
					article={article}
					articleClickAction={props.articleClickAction}
					key={article._id}
				>
				</ArticleItem>)
			}
		</ul>
	)
}

export default ArticleList;