import {Link} from "react-router-dom";
import classes from "./AdminPanel.module.scss";

function AdminPanel(){
	document.title = "Admin-panel | Båtens Verden";

	return (
        <div className={classes.adminPanelContainer}>
			<h1 className={classes.adminPanelTitle}>Admin panel</h1>
			<nav className={classes.adminLinksBox}>
				<ul>
					<Link to={'/forside'}><li><span className={classes.emoji}></span>Forside</li></Link>
					<Link to={'/admin/forside-rediger'}><li><span className={classes.emoji}></span>Forside redigering</li></Link>
					<Link to={'/batperm'}><li><span className={classes.emoji}>🛟</span>Båtperm</li></Link>
					<br/>
					<Link to={'/admin/kategorier'}><li><span className={classes.emoji}></span>Kategorier</li></Link>
					<Link to={'/admin/stikkord'}><li><span className={classes.emoji}>#️⃣</span>Stikkord</li></Link>
					<hr/>
					<Link to={'/admin/annonsorer'}><li><span className={classes.emoji}></span>Annonsører</li></Link>
					<Link to={'/admin/annonser'}><li><span className={classes.emoji}>🎫</span>Annonser</li></Link>
					<Link to={'/admin/annonseplasseringer'}><li><span className={classes.emoji}></span>Annonse-plasseringer</li></Link>
					<hr/>
					<Link to={'/admin/artikler'}><li><span className={classes.emoji}>📄</span>Artikler</li></Link>
					<Link to={'/admin/ny_artikkel'}><li><span className={classes.emoji}>🆕</span>Ny artikkel</li></Link>
					{/*<Link to={'/artikler'}>
						<li><span className={classes.emoji}>🔍</span>SØK Artikler</li>
					</Link>*/}
					<Link to={'/admin/forfattere'}><li><span className={classes.emoji}>🖋</span>Forfattere</li></Link>
					<br/>
					<Link to={'/admin/videoer'}><li><span className={classes.emoji}>📺</span>Videoer</li></Link>
					<hr/>
					<Link to={'/admin/brukere'}><li><span className={classes.emoji}>👤</span>Brukere</li></Link>
					<hr/>
					<Link to={'/admin/standardpriser'}><li><span className={classes.emoji}>💲</span>Standard-priser</li></Link>
					<Link to={'/admin/abonnement-produkter'}><li><span className={classes.emoji}>💲</span>Abonnement-produkter</li></Link>
					<Link to={'/admin/abonnement-planer'}><li><span className={classes.emoji}>💲</span>Abonnement-planer</li></Link>
					<hr/>
					<Link to={'/admin/statistikk'}><li><span className={classes.emoji}>📊</span>Statistikk</li></Link>
					<Link to={'/admin/lenkeklikk'}><li><span className={classes.emoji}></span>Lenkeklikk</li></Link>
					<br/>


					<Link to={'/login'}><li><span className={classes.emoji}>➡</span>Login</li></Link>
					<Link to={'/registrer'}><li><span className={classes.emoji}></span>Registrer</li></Link>
				</ul>
			</nav>
        </div>
    )
}

export default AdminPanel;