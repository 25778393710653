import classes from "../batperm.module.scss";

/*
* needs a ref in paren component to work: const editModalRef = useRef();
* show modal with ref.current.openModal();
* close modal with ref.current.close();
* */

export default function Modal({modalRef, title, children, onClose}){
	return <dialog className={[classes.modal, {}].join(' ')} ref={modalRef}>
		<button className={classes.modalCloseButton} onClick={()=>{
			modalRef.current.close();
			if (onClose) onClose();
		}}><span>×</span></button>
		<h2>{title}</h2>
		{children}
	</dialog>
}