import {useState} from "react";

function B64ImageUploader({setFileNameExtention, setImageB64, refParam}){

	const [currentFile, setCurrentFile] = useState(undefined);
	const [progress, setProgress] = useState(0);

	function selectFile(event){
		setCurrentFile(event.target.files[0]);
		getBase64(event.target.files[0])
		setFileNameExtention("." + event.target.files[0].name.split('.')[name.split('.').length])
	}

	function getBase64(file) {
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			setImageB64(reader.result);
		};
		reader.onerror = function (error) {
			console.log('Error: ', error);
		};
	}

	return(
		<div>
			<div className="row">
				<div className="col-8">
					<label className="btn btn-default p-0">
						<input ref={refParam} type="file" accept="image/*" onChange={selectFile} />
					</label>
				</div>
			</div>
		</div>
	)
}

export default B64ImageUploader;