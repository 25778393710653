import {Link, useParams, useSearchParams} from 'react-router-dom';
import {useEffect, useRef, useState} from "react";
import classes from './Articles.module.scss';

import ArticleList from "../components/articles/ArticleList";
import SiteService from "../services/SiteService";

function ArticlesSearch({articleClickAction}){
	document.title = "Artikler | Båtens Verden";

	var { pageNumber } = useParams();

	const [metaMarkup, setMetaMarkup] = useState([]);
	const [articleMeta, setArticleMeta] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [loadedArticles, setLoadedArticles] = useState([]);
	const [loadedTags, setLoadedTags] = useState([]);
	const [loadedCategories, setLoadedCategories] = useState([]);

	const [tagBarTags, setTagBarTags] = useState({});
	const [catBarCats, setCatBarCats] = useState({});

	const searchTextInput = useRef();

	useEffect(()=>{
		getTags();
		getCategories();
	},[]);

	function loadArticles(data){
		let out = [];

		if( data._meta.currentPage > 0)
			out.push(<Link className={'button6'}
						   to={'/artikler/'+(data._meta.currentPage-1).toString()}
			>{'|< Første'}</Link>);

		if( data._meta.currentPage > 0)
			out.push(<Link className={'button6'}
				to={'/artikler/0'}
			>{'< Forrige'}</Link>);

		for(let i = 0;  i < data._meta.totalPages; i++){
			let currentPageClass = '';
			if(i == data._meta.currentPage)
				currentPageClass = classes.articleCountLinkCurrent;
			out.push(
				<Link className={['button1', currentPageClass].join(' ')}
					  to={'/artikler/'+i.toString()}>{(i+1).toString()}
				</Link>
			);
		}

		if((data._meta.totalPages).toString() !== (parseInt(data._meta.currentPage)+1).toString()){
			out.push(
				<Link className={'button6'}
					  to={'/artikler/'+(parseInt(data._meta.currentPage)+1).toString()}>Neste{' >'}</Link>
			);
		}

		if( data._meta.currentPage < data._meta.totalPages-1)
			out.push(<Link className={'button6'}
						   to={'/artikler/'+(data._meta.totalPages-1).toString()}
			>{'Siste >|'}</Link>);

		setMetaMarkup([out]);
	}

	useEffect(()=>{
		setIsLoading(true);
		if(typeof pageNumber == 'undefined')
			pageNumber = 0;
		fetch(
			`${SiteService.apiPath}/article/all/` + pageNumber,
			{
				method: "GET",
			}
		).then((response)=> {
			return response.json();
		})
		.then((data)=>{
			if(data._meta == undefined || data.articles == undefined){
				setIsLoading(false);
				return window.alert('Feil under henting av artikler');
			}
			setLoadedArticles(data.articles);
			setArticleMeta(data._meta);
			setIsLoading(false);
			loadArticles(data);
		})
	},[useParams()]);

	function getCategories(){
		fetch(
			`${SiteService.apiPath}/categories`,
			{
				method: "GET",
			}
		).then((response)=> {
			return response.json();
		})
		.then((data)=> {
			setLoadedCategories(data);

			let tempObj = {};

			data.forEach((catObj)=>{
				if(catBarCats[catObj.name] !== true)
					tempObj[catObj.name] = false;
			})

			setCatBarCats(tempObj);
		})
	}

	function getTags(){
		fetch(
			`${SiteService.apiPath}/tags/tagsfreq`,
			{
				method: "GET",
			}
		).then((response)=> {
			return response.json();
		})
		.then((data)=>{
			setLoadedTags(data);
			let tempObj = {};


			let queries = window.location.search.replaceAll('?','').split('&');
			queries.forEach((query)=>{
				let querySplit = query.split('=');
				if (querySplit[0] === "stikkord"){
					tempObj[querySplit[1]] = true;
				}
				else if(querySplit[0] === "tittel"){
					setQueryTitle(querySplit[1]);
				}
			})

			data.forEach((tagObj)=>{
				if(tagBarTags[tagObj.name] !== true)
					tempObj[tagObj.name] = false;
			})

			setTagBarTags(tempObj);
		})
	}

	function onTagCheck(ev, tagObj){
		if(ev.currentTarget.checked){
			setFilterTags(filterTags.concat(tagObj.name));
		}else{
			setFilterTags((tags) => tags.filter((_, index) => index !== filterTags.indexOf(tagObj.name)));
		}
	}

	function search(){
		makeURL();
		pageNumber = 0;
		/*if(searchTextInput.current.value.length < 1)
			return;*/
		setIsLoading(true);
		let fetchString = `${SiteService.apiPath}/article/search/`+pageNumber;
		let queryString = '/?';
		if(searchTextInput.current.value.length > 1)
			queryString += '&tittel='+searchTextInput.current.value;

		// Tags
		Object.keys(tagBarTags).forEach((key)=>{
			if(tagBarTags[key])
				queryString += '&stikkord='+key;
		});
		// Categories
		Object.keys(catBarCats).forEach((key)=>{
			if(catBarCats[key]) {
				queryString += '&kategori=' + key;
			}
		});

		console.log(fetchString+queryString);

		fetch(fetchString+queryString,
			{
				method: "GET",
			}
		).then((response)=> {
			return response.json();
		})
		.then((data)=>{
			console.log(data);
			if(data._meta == undefined || data.articles == undefined){
				setIsLoading(false);
				return window.alert('Feil under henting av artikler');
			}
			setLoadedArticles(data.articles);
			setArticleMeta(data._meta);
			setIsLoading(false);
			loadArticles(data);
		})
	}


	const [queryTitle,setQueryTitle] = useState('');
	const [filterTags, setFilterTags] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();

	function makeURL(){
		function setURL(){
			if (!pageNumber)
				pageNumber = 0;
			let query = searchParams.toString();
			window.history.replaceState(null, '', '/artikler/' + pageNumber.toString()
				+ '/?' + query);
		}

		if(filterTags.length < 1){
			searchParams.delete("stikkord");
		} else if (filterTags.length > 0){
			searchParams.delete("stikkord");
			filterTags.forEach((name)=>{
				searchParams.append("stikkord", name);
			});
		}


		if (queryTitle.length < 1){
			searchParams.delete("tittel");
		}else if (queryTitle.length > 0) {
			searchParams.delete("tittel");
			searchParams.append("tittel", queryTitle);
		}

		//setURL();
	}

	useEffect(() => {
		makeURL();
	}, [filterTags,queryTitle])


	function tagsBar(){
		return (<>
			<div>
				<h4>Stikkord</h4>
				{loadedTags.map((tagObj)=>{
					console.log(tagBarTags[tagObj.name])
					return(<>
						<div key={tagObj.name} className={classes.searchTagContainer}>
							<input className={classes.searchTagCheckbox} checked={tagBarTags[tagObj.name]} id={'tag'+tagObj.name} type={"checkbox"}
								   onChange={(ev)=> {
									   tagBarTags[tagObj.name] = !tagBarTags[tagObj.name];
									  // onTagCheck(ev, tagObj)
									   search();
								   }
							}/>
							<label className={classes.searchTagCheckboxFor}  htmlFor={'tag'+tagObj.name}></label>
							<span className={classes.searchTagText}>{tagObj.name}</span>
							<span className={classes.searchTagFreq}>{tagObj.articleIds.length}</span>
						</div>
					</>);
				})}
			</div>
		</>)
	}

	function categoriesBar(){
		return (<>
			<div>
				<h4>Kategorier</h4>
				{loadedCategories.map((catObj)=>{
					return(<>
						<div className={classes.searchTagContainer}>
							<input className={classes.searchTagCheckbox} checked={catBarCats[catObj.name]} id={'cat'+catObj.name} type={"checkbox"}
								   onChange={(ev)=> {
									   catBarCats[catObj.name] = !catBarCats[catObj.name];
									   onTagCheck(ev, catObj)
									   search();
								   }
								   }/>
							<label className={classes.searchTagCheckboxFor}  htmlFor={'cat'+catObj.name}></label>
							<span className={classes.searchTagText}>{catObj.name}</span>
						</div>
					</>);
				})}
			</div>
		</>)
	}

	function perPageMarkup(){
		if(isLoading) {
			return (<div></div>)
		}
		else{
			return(
				<div>{'Viser '+ loadedArticles.length +' av ' + articleMeta.totalResults.toString()} resultater</div>
			)
		}
	}

	return(
		<div className={classes.contentContainer}>


			<section className={classes.section}>
				<h1>Artikler</h1>
				<br/>
				<form onSubmit={(ev) => {
					ev.preventDefault();
					search();
				}} className={classes.searchParamsContainer}>
					<div>
						<input placeholder={'Søketekst'} className={classes.searchTextInput} value={queryTitle}
							   ref={searchTextInput} onChange={(ev) => {
							setQueryTitle(ev.currentTarget.value);
						}} type={"text"}></input>
						<button className={[classes.searchGoButton, 'button1'].join(' ')} onClick={(ev) => {
							ev.preventDefault();
							search()
						}}>Søk
						</button>
					</div>
					{/*tagsBar()*/}
					{/*categoriesBar()*/}
				</form>
				{/*<section className={[classes.articleCountLinks, classes.section].join(' ')}>
					{metaMarkup}
				</section>*/}
				{perPageMarkup()}
				<ArticleList articles={loadedArticles} articleClickAction={articleClickAction}></ArticleList>
			</section>

			{/*<section className={[classes.articleCountLinks, classes.section].join(' ')}>
				{metaMarkup}
			</section>*/}

		</div>
	)
}

export default ArticlesSearch;