import "../../pages/Article.scss";
import ArticleService from "../../services/ArticleService";
import RenderBodyParts from "./RenderBodyParts";
import AuthorService from "../../services/AuthorService";
import FavouriteButton from "../ui/functional/FavouriteButton";
import ShareButton from "../ui/functional/ShareButton";
import {useContext, useEffect, useRef} from "react";
import SiteContext from "../../store/siteContext";
import "../../pages/Article.scss";
import "./article-dark.scss";
import SiteService from "../../services/SiteService";
import ArticleImageCarousel from "./rendered/ArticleImageCarousel";
import VideoPlayer from "../ui/VideoPlayer";
import ErrorFallBack from "../ErrorFallBack";
import {ErrorBoundary} from "react-error-boundary";
import FadeIn from "../ui/FadeIn";
import AdvertBlock6 from "../frontpage/content-blocks/AdvertBlock6";
import ContentBlock2 from "../frontpage/content-blocks/ContentBlock2";
import Helpers from "../../helpers/Helpers";
import ContentBlock1 from "../frontpage/content-blocks/ContentBlock1";
import siteService from "../../services/SiteService";
import {Helmet} from "react-helmet";
import UserContext from "../../store/userContext";
import advertService from "../../services/AdvertService";
import TagsContainer from "../ui/TagsContainer";
import KampanjeTilbud from "../ui/KampanjeTilbud";
import CategoriesContainer from "../ui/CategoriesContainer";

export default function ArticleRenderer({article, isPluss, loadedAuthor}) {
	const siteContext = useContext(SiteContext);
	const userContext = useContext(UserContext);

	//window.scrollTo(0,0);

	// prepare Related articles
	if (article.relatedArticles && article.relatedArticles.length > 0)
		for (let i = 0; i < article.relatedArticles.length; i++){
			if (article.relatedArticles[i] && typeof article.relatedArticles[i] === 'object')
				Helpers.prepareArticleForFrontpageParts(article.relatedArticles[i], siteContext.categories);
		}

	// prepare Latest news articles
	if (article.newsArticles && article.newsArticles.length > 0)
		for (let i = 0; i < article.newsArticles.length; i++){
			if (article.newsArticles[i] && typeof article.newsArticles[i] === 'object')
				Helpers.prepareArticleForFrontpageParts(article.newsArticles[i], siteContext.categories);
		}

	// prepare Random articles
	if (article.randomArticles && article.randomArticles.length > 0)
		for (let i = 0; i < article.randomArticles.length; i++){
			if (article.randomArticles[i] && typeof article.randomArticles[i] === 'object')
				Helpers.prepareArticleForFrontpageParts(article.randomArticles[i], siteContext.categories);
		}

	useEffect(()=>{
	// add CSS SPONSOR color variables
		if (article.sponsor) {
			if (article.sponsor.color1)
				document.documentElement.style.setProperty('--sponsorColor1', article.sponsor.color1);
			else
				document.documentElement.style.removeProperty('--sponsorColor1');

			if (article.sponsor.color2)
				document.documentElement.style.setProperty('--sponsorColor2', article.sponsor.color2);
			else
				document.documentElement.style.removeProperty('--sponsorColor2');

			if (article.sponsor.color3)
				document.documentElement.style.setProperty('--sponsorColor3', article.sponsor.color3);
			else
				document.documentElement.style.removeProperty('--sponsorColor3');
		}
		// Cleanup function to remove the CSS variable when component unmounts
		return () => {
			document.documentElement.style.removeProperty('--sponsorColor1');
			document.documentElement.style.removeProperty('--sponsorColor2');
			document.documentElement.style.removeProperty('--sponsorColor3');
		};
	}, [article]);




	function HeaderAuthorDateSection(){
		if (article.isSponsored) return null;
		// article.date is in YYYY-MM-DD format. Make displayDate be that but DD.MM.YYYY
		let displayDate = null;
		if (article.date)
			displayDate = article.date.substring(0,10).split('-').reverse().join('.');
		return(<div className={'headerAuthorDateSection'}>
			{loadedAuthor ? <>
				{loadedAuthor.imageName &&
					<img className={'articleAuthorImage'} src={AuthorService.getAuthorImage(loadedAuthor)}/>}
					<div>Av: <span rel={"author"} itemProp={"author"}>{loadedAuthor.name && loadedAuthor.name}</span></div>
			</>
				: <><img className={'articleAuthorImage'}/> Forfatter: Eksempel</>
			}
			<span>-</span>
			<div>
				Dato:
				<time itemProp={"datePublished"} className={'date'} dateTime={article.date&&article.date.substring(0,10)}>
					{' '+displayDate}
				</time>
			</div>
		</div>)}


	function AnnonseInnholdHeader(){
		return <div className={'annonseinnholdHeader'}>
			<div>ANNONSØRINNHOLD{article.sponsor&&<> AV <b>{article.sponsor.name.toUpperCase()}</b></>}</div>
		</div>
	}
	function SponsorLogo() {
		if (!(article.isSponsored && article.sponsor && article.sponsor.logoImage)) return null;

		return (<>{
			article.sponsor.url ?
				<a href={article.sponsor.url ? article.sponsor.url : null} className={'articleSponsorImage'}
				   target={'_blank'}>
					<img
						src={`${siteService.apiPath}/image/sponsors/${article.sponsor._id}${article.sponsor.logoImage}`}
						alt={article.sponsor.name + ' logo'}/>
				</a> :
				<a href={article.sponsor.url ? article.sponsor.url : null} className={'articleSponsorImage'}
				   target={'_blank'}>
					<img
						src={`${siteService.apiPath}/image/sponsors/${article.sponsor._id}${article.sponsor.logoImage}`}
						alt={article.sponsor.name + ' logo'}/>
				</a>
		}</>)
	}

	function IntroBlock() {
		if (!article.intro) return;
		return (
			<div className={'introBlock'}>
				<p className={'introText'}>
					{article.intro}
				</p>
				<div className={'articleAside'}>
					<SponsorLogo/>
					<div className={'articleInteractions'}>
						<FavouriteButton articleId={article._id} addText={true}></FavouriteButton>
						<ShareButton articleTitle={article.name} articleId={article._id} addText={true}/>
					</div>
					<div className={'divider'}></div>
					<CategoriesContainer categories={article.categories}/>
				</div>
			</div>
		)
	}

	let imageSrc = null;
	if (article.image && article.image.url)
		imageSrc = ArticleService.getUrlHeaderImage(article);


	const boundaryRef = useRef(null);
	useEffect(() => {
		// set onscroll event
		function changeScrollSnap(){try{
			//if no longer in this component, set to none and remove the event listener
			if (!boundaryRef.current){
				//remove event listener
				document.body.style.scrollSnapType = 'none';
				document.documentElement.style.scrollSnapType = 'none';
				document.removeEventListener('scroll', changeScrollSnap);
			}
			if(window.scrollY > boundaryRef.current.offsetTop - 150){
				document.body.style.scrollSnapType = 'none';
				document.documentElement.style.scrollSnapType = 'none';
			}
			else{
				// The element is in view, update the style
				document.body.style.scrollSnapType = 'y mandatory';
				document.documentElement.style.scrollSnapType = 'y mandatory';
			}
		}catch(e){
			//remove event listener
			document.body.style.scrollSnapType = 'none';
			document.documentElement.style.scrollSnapType = 'none';
			document.removeEventListener('scroll', changeScrollSnap);
		}}

		if (article.headerType && article.headerType==2)
			document.addEventListener('scroll', changeScrollSnap);
		else {
			try{
				document.body.style.scrollSnapType = 'none';
				document.documentElement.style.scrollSnapType = 'none';
				document.removeEventListener('scroll', changeScrollSnap);
			}catch (e) {}
		}

		return () => {
			document.removeEventListener('scroll', changeScrollSnap);
			document.body.style.scrollSnapType = 'none';
			document.documentElement.style.scrollSnapType = 'none';
		}
	}, [article.headerType]);


	function HeaderType7(){
		if (!article.headerParts || article.headerParts.length < 1) return;

		return (
			<div className={'headerType7 headerType2'}>
				<div className={'articleFrontImageWrapper headerImageWrapper'}>
					<div className={'headerImageWrapper header7Image'}
						 style={{backgroundImage: 'url("' + (article.headerB64 ? article.headerB64 : imageSrc) + '")'}}
					>
						<img
							alt={(article.image && article.image.alt) && article.image.alt}
							src={article.headerB64 ? article.headerB64 : imageSrc}
							className={'articleFrontImage'}
						/>

						<swiper-container free-mode="true" mousewheel="true" mousewheel-release-on-edges="true"
										  direction="vertical" touch-release-on-edges="true"
										  style={{backgroundImage: 'url("' + (article.headerB64 ? article.headerB64 : imageSrc) + '")'}}
						>
							<swiper-slide>
								<div className={'header7TextWrapper'}>
									<h1 className={'articleTitle'}>{article.name && article.name}</h1>
								</div>
							</swiper-slide>
							{article.headerParts.map((part, i) => {
								return <swiper-slide key={i}>
									<p>{part.text}</p>
								</swiper-slide>
							})}
						</swiper-container>

					</div>
				</div>


				{(article.image && article.image.alt) &&
					<p className={'headerImageText'}>{article.image.alt}</p>
				}

				<div className={'articleHeaderTextSection'} ref={boundaryRef}>
					<p className={'preface'}>{article.preface}</p>
					<HeaderAuthorDateSection/>
					{!article.isSponsored && <AdvertBlock6 parts={[{id: '000000000000000000000003'}]}/>}
					<IntroBlock/>
				</div>
			</div>
		)
	}


	function HeaderType6() {
		let title = article.name ? article.name.split(' ') : [];
		let preface = article.preface ? article.preface.split(' ') : [];
		let emphasizedStart = article.titleEmphasis ? article.titleEmphasis[0] : null;
		let emphasizedEnd = article.titleEmphasis ? article.titleEmphasis[1] : null;
		let emphasizePrefaceStart = article.prefaceEmphasis ? article.prefaceEmphasis[0] : null;
		let emphasizePrefaceEnd = article.prefaceEmphasis ? article.prefaceEmphasis[1] : null;

		const titleRef = useRef(null);
		const prefaceRef = useRef(null);

		useEffect(() => {
			function fadeOutOnScroll(element) {
				if (!element) {
					console.log('Element not found');
					return;
				}

				var elementHeight = element.offsetHeight;
				var scrollTop = document.documentElement.scrollTop;
				var opacity = 1;

				if (scrollTop > 0) {
					opacity = 1 - ((scrollTop - (window.innerHeight / 2.6))) / (elementHeight);
					if (opacity < 0.1)
						opacity = 0;
				}

				element.style.opacity = opacity;
			}

			function scrollHandler() {
				try {
					fadeOutOnScroll(titleRef.current.parentNode);
				}catch (e) {}
			}

			window.addEventListener('scroll', scrollHandler);
			return () => {
				window.removeEventListener('scroll', scrollHandler);
			};

		},[]);

		function TitleEmhpasis() {return (
			<h1 ref={titleRef} className={'articleTitle'}>
          		<span>
				{title.map((word, index) => {
					if (emphasizedStart !== null && index >= emphasizedStart && index + 1 <= emphasizedEnd) {
						return <span key={index} className={'articleTitleEmphasis'}>{word} </span>;
					} else {
						return <span key={index}>{word} </span>;
					}
				})}
				</span>
			</h1>
		)}

		function PrefaceEmphasis(){return(
			<p ref={prefaceRef}><FadeIn threshold={0.3}>{preface.map((word, index) => {
				if (emphasizePrefaceStart !== null && index >= emphasizePrefaceStart && index + 1 <= emphasizePrefaceEnd) {
					return <span key={index} className={'articleTitleEmphasis'}>{word} </span>;
				} else {
					return <span key={index}>{word} </span>;
				}
			})}</FadeIn></p>
		)}

		return (
			<div className={'headerType2'}>
				<div className={'articleFrontImageWrapper headerImageWrapper'}>
					<div className={'headerImageWrapper header6Image'} style={{backgroundImage:'url("'+(article.headerB64 ? article.headerB64 : imageSrc)+'")'}}>
						<img
							alt={(article.image && article.image.alt) && article.image.alt}
							src={article.headerB64 ? article.headerB64 : imageSrc}
							className={'articleFrontImage'}
						/>
						<div className={'header6TextWrapper'}><TitleEmhpasis/></div>
					</div>
				</div>

				{(article.image && article.image.alt) &&
					<p className={'headerImageText'}>{article.image.alt}</p>
				}

				<div className={'articleHeaderTextSection'} ref={boundaryRef}>
					<p className={'preface'}>{article.preface}</p>
					<HeaderAuthorDateSection/>
					{!article.isSponsored && <AdvertBlock6 parts={[{id: '000000000000000000000003'}]}/>}
					<IntroBlock/>
				</div>
			</div>
		);
	}

	/*function HeaderType6() { scrollover 2, scapped but was really good
		let title = article.name ? article.name.split(' ') : [];
		let preface = article.preface ? article.preface.split(' ') : [];
		let emphasizedStart = article.titleEmphasis ? article.titleEmphasis[0] : null;
		let emphasizedEnd = article.titleEmphasis ? article.titleEmphasis[1] : null;
		let emphasizePrefaceStart = article.prefaceEmphasis ? article.prefaceEmphasis[0] : null;
		let emphasizePrefaceEnd = article.prefaceEmphasis ? article.prefaceEmphasis[1] : null;

		const titleRef = useRef(null);
		const prefaceRef = useRef(null);

		useEffect(() => {
			function fadeOutOnScroll(element) {
				if (!element) {
					console.log('Element not found');
					return;
				}

				var elementHeight = element.offsetHeight;
				var scrollTop = document.documentElement.scrollTop;
				var opacity = 1;

				if (scrollTop > 0) {
					opacity = 1 - ((scrollTop - (window.innerHeight / 2.6))) / (elementHeight);
					if (opacity < 0.1)
						opacity = 0;
				}

				element.style.opacity = opacity;
			}

			function scrollHandler() {
				try {
					fadeOutOnScroll(titleRef.current.parentNode);
				}catch (e) {}
			}

			window.addEventListener('scroll', scrollHandler);
			return () => {
				window.removeEventListener('scroll', scrollHandler);
			};

		},[]);

		function TitleEmhpasis() {return (
			<h1 ref={titleRef} className={'articleTitle'}>
          		<span>
				{title.map((word, index) => {
					if (emphasizedStart !== null && index >= emphasizedStart && index + 1 <= emphasizedEnd) {
						return <span key={index} className={'articleTitleEmphasis'}>{word} </span>;
					} else {
						return <span key={index}>{word} </span>;
					}
				})}
				</span>
			</h1>
		)}

		function PrefaceEmphasis(){return(
			<p ref={prefaceRef}><FadeIn threshold={0.3}>{preface.map((word, index) => {
				if (emphasizePrefaceStart !== null && index >= emphasizePrefaceStart && index + 1 <= emphasizePrefaceEnd) {
					return <span key={index} className={'articleTitleEmphasis'}>{word} </span>;
				} else {
					return <span key={index}>{word} </span>;
				}
			})}</FadeIn></p>
		)}

		return (
			<div className={'headerType2'}>
				<div className={'articleFrontImageWrapper headerImageWrapper'}>
					<div className={'headerImageWrapper header6Image'} style={{backgroundImage:'url("'+(article.headerB64 ? article.headerB64 : imageSrc)+'")'}}>
						<img
							alt={(article.image && article.image.alt) && article.image.alt}
							src={article.headerB64 ? article.headerB64 : imageSrc}
							className={'articleFrontImage'}
						/>
						<div className={'header6TextWrapper'}><PrefaceEmphasis/></div>
						<div className={'header6TextWrapper'}><TitleEmhpasis/></div>
					</div>
				</div>

				{(article.image && article.image.alt) &&
					<p className={'headerImageText'}>{article.image.alt}</p>
				}

				<div className={'articleHeaderTextSection'} ref={boundaryRef}>
					<HeaderAuthorDateSection/>
					{!article.isSponsored&&<AdvertBlock6 parts={[{id: '000000000000000000000003'}]}/>}
					<IntroBlock/>
				</div>
			</div>
		);
	}*/


	function HeaderType4(){return <>
		<CategoriesContainer categories={article.categories}/>
		<h1 className={'articleTitle'}>{article.name}</h1>

		<div className={'articleHeaderTextSection'} ref={boundaryRef}>
			<p className={'preface'}>{article.preface}</p>
			<div className={'interactionsContainer'}>
				<SponsorLogo/>
				<HeaderAuthorDateSection/>
				<div className={'interactionsContainer interactionsContainerInner'}>
				<FavouriteButton articleId={article._id} addText={true}></FavouriteButton>
				<ShareButton articleTitle={article.name} articleId={article._id} addText={true}/>
				</div>
			</div>
		</div>

		<div className={'articleFrontImageWrapper headerImageWrapper'}>
			<img alt={(article.image && article.image.alt) && article.image.alt}
				 src={article.headerB64 ? article.headerB64 : imageSrc}
				 className={'articleFrontImage'}/>
		</div>
		{(article.image && article.image.alt) &&
			<p className={'headerImageText'}>{article.image.alt}</p>
		}
		{!article.isSponsored&&<AdvertBlock6 parts={[{id: '000000000000000000000003'}]}/>}
		<p className={'introText'}>
			{article.intro}
		</p>
	</>
	}

	function HeaderType3() {
		return <>
			<div className={'articleHeaderTextSection'} ref={boundaryRef}>
				<CategoriesContainer categories={article.categories}/>
				<h1 className={'articleTitle'}>{article.name}</h1>
				<p className={'preface'}>{article.preface}</p>
				<HeaderAuthorDateSection/>
			</div>

			<ArticleImageCarousel images={article.headerImages} article={article}/>
			<SponsorLogo/>
			<div className={'interactionsContainer'}>
				<FavouriteButton articleId={article._id} addText={true}></FavouriteButton>
				<ShareButton articleTitle={article.name} articleId={article._id} addText={true}/>
			</div>

			{!article.isSponsored&&<AdvertBlock6 parts={[{id: '000000000000000000000003'}]}/>}
			<p className={'introText'}>
				{article.intro}
			</p>
		</>
	}

	function HeaderType2() {
		return <>
			<div className={'articleFrontImageWrapper'}>
				<img alt={(article.image && article.image.alt) && article.image.alt}
					 src={article.headerB64 ? article.headerB64 : imageSrc}
					 className={'articleFrontImage'}/>
				{!!(article.headerType && article.headerType === 2) &&
					<h1 className={'articleTitle'}>{article.name}</h1>}
			</div>

			{(article.headerImages && article.headerImages.length > 0) && <>
				{article.headerImages.map((img, i) => {
					const pRef = useRef(null);
					return <div key={i} className={'headerImageWrapper'}>
						{img.type=='video' ?
							<VideoPlayer className={'articleFrontImage'} disableControls={true} videoId={img.video} autoPlay={true}/>
							:
							<img key={i}
								 style={img.desc?{}:{filter:'none'}}
								 alt={(img && img.alt) && img.alt}
								 src={(img.value && img.value.startsWith('data:image')) ? img.value : `${SiteService.apiPath}/image/articles/${article._id}/` + img.value}
								 className={'articleFrontImage'}
							/>
						}
						{img.desc && <FadeIn fadeType={'2'} propRef={pRef}><p ref={pRef} style={img.textboxStyle?img.textboxStyle:null}>{img.desc}</p></FadeIn>}
					</div>
				})}</>
			}

			<div className={'articleHeaderTextSection'} ref={boundaryRef}>
				<p className={'preface'}>{article.preface}</p>
				<HeaderAuthorDateSection/>
				{!article.isSponsored&&<AdvertBlock6 parts={[{id: '000000000000000000000003'}]}/>}
				<IntroBlock/>
			</div>
	</>
	}

	function HeaderType1() {return <>
			<div className={'articleFrontImageWrapper headerImageWrapper'}>
				<img alt={(article.image && article.image.alt) && article.image.alt}
					 src={article.headerB64 ? article.headerB64 : imageSrc}
					 className={'articleFrontImage'}/>
					<h1 className={'articleTitle'}>
						<span>{article.name}</span>
					</h1>
			</div>

			{(article.image && article.image.alt) &&
				<p className={'headerImageText'}>{article.image.alt}</p>
			}

			<div className={'articleHeaderTextSection'} ref={boundaryRef}>
				<p className={'preface'}>{article.preface}</p>
			<HeaderAuthorDateSection/>
			{!article.isSponsored&&<AdvertBlock6 parts={[{id: '000000000000000000000003'}]}/>}
			<IntroBlock/>
		</div>
	</>
	}

	function HeaderType0() {return <>
		<div className={'articleFrontImageWrapper headerImageWrapper'}>
			<img alt={(article.image && article.image.alt) && article.image.alt}
				 src={article.headerB64 ? article.headerB64 : imageSrc}
				 className={'articleFrontImage'}/>
		</div>

		{(article.image && article.image.alt) &&
			<p className={'headerImageText'}>{article.image.alt}</p>
		}

		<div className={'articleHeaderTextSection'} ref={boundaryRef}>
			<h1 className={'articleTitle'}>{article.name}</h1>
			<p className={'preface'}>{article.preface}</p>
		<HeaderAuthorDateSection/>
		{!article.isSponsored&&<AdvertBlock6 parts={[{id: '000000000000000000000003'}]}/>}
		<IntroBlock/>
	</div>
	</>}


	return <>
	<Helmet>
	<title>{article.name}</title>
	<script type="application/ld+json">
		{`
            {
              "@context": "https://schema.org",
              "@type": "Article",
              "headline": "${article.name.replaceAll('"', '')}",
              "image": "${siteService.apiPath}/image/articles/${article._id?article._id:""}/header.webp",
              "datePublished": "${article.date?article.date:""}",
              "dateModified": "${article.date?article.date:""}",
              "author": {
                "@type": "Person",
                "name": "${loadedAuthor?loadedAuthor.name:""}"
              },
              "publisher": {
                "@type": "Organization",
                "name": "Båtens Verden",
                "logo": {
                  "@type": "ImageObject",
                  "url": "${SiteService.path}/favicon.png"
                }
              },
              "description": "${article.preface.replaceAll('"', '')}"
            }
          `}
	</script>
	</Helmet>
	<div
		className={['articleContainer', article.isSponsored ? 'sponsored' : '', (article.theme && article.theme == 1) ? 'article-dark' : ''].join(' ')}>
		<article className={['articleArticle', isPluss ? "blurred" : ""].join(' ')}>
			{article.isSponsored && <AnnonseInnholdHeader/>}
			<div
				className={['articleHeaderSection', (article.headerType) ? 'headerType' + article.headerType : ''].join(' ')}>
				{!!(!article.headerType || article.headerType === 0) && <HeaderType0/>}
				{!!(article.headerType && article.headerType === 1) && <HeaderType1/>}
				{!!(article.headerType && article.headerType === 5) && <HeaderType1/>}
				{!!(article.headerType && article.headerType === 2) && <HeaderType2/>}
				{!!(article.headerType && article.headerType === 3) && <HeaderType3/>}
				{!!(article.headerType && article.headerType === 4) && <HeaderType4/>}
				{!!(article.headerType && article.headerType === 6) && <HeaderType6/>}
				{!!(article.headerType && article.headerType === 7) && <HeaderType7/>}
				{!!(article.headerType && article.headerType === 8) && <HeaderType0/>}
				{!!(article.headerType && article.headerType === 9) && <HeaderType4/>}
			</div>

			{/*todo remove this temporary ad */}{/*6601b1ea589d427d59409c22*/}
			{article.tags && article.tags.some(tag => tag._id === "6601b1ea589d427d59409c22") && (<div className={'bislogosWrapper'}><div className={'bislogosContainer'}>
				<img src={'/bislogos/1.svg'} style={{position: 'absolute', top: '-28px', maxWidth: '91%', width:'400px', margin:'0 auto'}}/>
				<a onClick={(ev)=>{advertService.incrementTempad(ev)}} target={'_blank'} href={'https://www.mercurymarine.com/no/no.html'}><img src={'/bislogos/2.png'}/></a>
				<a onClick={(ev)=>{advertService.incrementTempad(ev)}} target={'_blank'} href={'https://hankomarina.no/'}><img src={'/bislogos/3.png'}/></a>
				<a onClick={(ev)=>{advertService.incrementTempad(ev)}} target={'_blank'} href={'https://www.cormate.com/'}><img src={'/bislogos/4.png'}/></a>
				<a onClick={(ev)=>{advertService.incrementTempad(ev)}} target={'_blank'} href={'https://www.gigantmarine.no/'}><img src={'/bislogos/5.png'}/></a>
				<a onClick={(ev)=>{advertService.incrementTempad(ev)}} target={'_blank'} href={'https://www.stormermarine.no/'}><img src={'/bislogos/6.png'}/></a>
				<a onClick={(ev)=>{advertService.incrementTempad(ev)}} target={'_blank'} href={'https://www.sturdyyachts.com/'}><img src={'/bislogos/7.png'}/></a>
				<a onClick={(ev)=>{advertService.incrementTempad(ev)}} target={'_blank'} href={'https://marinasolutions.no/'}><img src={'/bislogos/8.svg'}/></a>
				<a onClick={(ev)=>{advertService.incrementTempad(ev)}} target={'_blank'} href={'https://kalesjer.no/'}><img src={'/bislogos/9.png'}/></a>
				<a onClick={(ev)=>{advertService.incrementTempad(ev)}} target={'_blank'} href={'https://www.yamaha-motor.eu/no/nb/boats/boat-show/'}><img src={'/bislogos/10.png'}/></a>
				<a onClick={(ev)=>{advertService.incrementTempad(ev)}} target={'_blank'} href={'https://www.benningtonbater.no/'}><img src={'/bislogos/11.png'}/></a>
				<a onClick={(ev)=>{advertService.incrementTempad(ev)}} target={'_blank'} href={'https://www.vikorsta.no/marina/'}><img src={'/bislogos/12.png'}/></a>
				<a onClick={(ev)=>{advertService.incrementTempad(ev)}} target={'_blank'} href={'https://www.viknes.no/'}><img src={'/bislogos/13.png'}/></a>
				<a onClick={(ev)=>{advertService.incrementTempad(ev)}} target={'_blank'} href={'https://www.scancat.no/'}><img src={'/bislogos/14.png'}/></a>
			</div></div>)}

			<div id='bodyPartsContainer' className={'bodyPartsContainer'}>
				<ErrorBoundary FallbackComponent={ErrorFallBack}>{article.bodyParts &&
					<RenderBodyParts article={article} parts={article.bodyParts} loadedAuthor={loadedAuthor}/>
				}
				</ErrorBoundary>
			</div>

			{article.isSponsored && <div className={'articleSponsorFooter'}>
				Annonsørinnholdet er produsert av VB Media AS. Båtens Verdens redaksjon har ingen rolle i publiseringen av dette innholdet.
			</div>}


			{(article.tags && article.tags.length > 0 && article.tags.some(item => item.hidden === false)) && (
				<TagsContainer tags={article.tags}/>
			)}


		</article>

{!article.isSponsored && <FadeIn>
	<KampanjeTilbud/>
</FadeIn>}

		{
			!article.isSponsored && <div className={'articleFooter frontpage'}>
			{(article.relatedArticles && article.relatedArticles.length > 0) &&
				<ContentBlock2 parts={article.relatedArticles} sectionTitle={'Relaterte artikler'}/>}
			{(article.newsArticles && article.newsArticles.length > 0) &&
				<ContentBlock2 slidesPerView={3} parts={article.newsArticles} sectionTitle={'Siste nytt'}
							   link={{link: '/', text: 'Til forsiden'}}/>}
			{(article.randomArticles && article.randomArticles.length > 2) &&
				<ContentBlock1 parts={article.randomArticles.slice(0, 3)} sectionTitle={'Fra arkivet'} reverse={true}/>}
			{(article.randomArticles && article.randomArticles.length > 0) &&
				<ContentBlock2 parts={article.randomArticles.slice(3)}/>}
		</div>}
	</div>
</>}