
function AdminUploadImage(){


	return(
		<>
		<h1>Last opp Bilde</h1>
		<form onSubmit="" method="post" encType="multipart/form-data" action="/api/imageupload">
			<input type="file" name="file"/>
			<input type="submit" value="Submit"/>
		</form>
		</>
	)
}

export default AdminUploadImage;