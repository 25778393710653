import {useEffect, useRef, useState} from "react";
import AdminEdit from "../../../components/adminEdit/AdminEdit";
import AdminUserService from "../../../services/AdminUserService";
import classes from "../../../components/adminEdit/AdminEdit.module.scss";

function AdminUsers(){

	const [isLoading, setIsLoading] = useState(true);
	const [users, setUsers] = useState([]);

	useEffect(()=>{

	}, [])


	function TableHeaders(){
		return(<div className={classes.th}>
			<div className={classes.td}></div>
			<div className={classes.td}>Navn</div>
			<div className={classes.td}>Rolle</div>
			<div className={classes.td}>E-post</div>
			<div className={classes.td}>Abonnent</div>
		</div>)
	}

	function UnEditableSectionChildren({obj, index, handleChange, refObj}){
		refObj.role = useRef(obj.role);

		return(<>
			<div className={classes.td} style={{textAlign:'center'}}>
				{obj.isEditable ?
					<select ref={refObj.role} defaultValue={obj.role}>
						<option value={0}>Bruker</option>
						<option value={1}>Redaktør</option>
						<option value={2}>Admin</option>
					</select>
					:<>
					{obj.role == 0 && <></>}
					{obj.role == 1 && <>Redaktør</>}
					{obj.role == 2 && <>Admin</>}
					</>
				}
			</div>
			<div className={classes.td}>{obj.email}</div>
			<div className={classes.td} style={{width:'15px', textAlign:'center'}}>
				{obj.subscribed ? <span>✔️</span>: <span>❌</span>}
			</div>
		</>)
	}

	function EditableSectionChildren({obj, index, handleChange}) {return(<>

	</>)}



	return(
		<AdminEdit
			pageName={"Brukere"}
			mainService={AdminUserService}
			mainArray={users}
			setMainArray={setUsers}
			TableHeaders={TableHeaders}
			EditableSectionChildren={EditableSectionChildren}
			UnEditableSectionChildren={UnEditableSectionChildren}
			PaginationBool={true}
			SortObject={{role:'Rolle', email:'E-post', subscribed:'Abonnert'}}
			SearchObject={{email:'E-post'}}
		></AdminEdit>
	)
}

export default AdminUsers;