export default function HeartIcon({width = '100%', height = '100%'}) {
	return (
		<svg
			className={'heartIcon'}
			width={width}
			height={height}
			viewBox="0 -2 28 30"
			xmlns="http://www.w3.org/2000/svg"
			fill={'inherit'}
		>
			<g>
				<path
					id="vector-18"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M13.9892 24.1904L8.63285 18.7894L3.31864 13.3884C0.443592 10.4034 0.443592 5.73153 3.31864 2.74655C4.72643 1.4173 6.63321 0.722662 8.58277 0.828843C10.5323 0.935025 12.3493 1.83247 13.5989 3.30646L13.9892 3.68273L14.3759 3.29025C15.6255 1.81627 17.4424 0.918822 19.392 0.81264C21.3415 0.706459 23.2483 1.40109 24.6561 2.73035C27.5312 5.71532 27.5312 10.3872 24.6561 13.3721L19.3419 18.7732L13.9892 24.1904Z"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	);
}
