import ImageEditor from "./ImageEditor";
import {TextEditor} from "./TextEditor";
import React, {useEffect, useRef, useState} from "react";
import QuoteEditor from "./QuoteEditor";
import OnTopBox from "../../ui/onTopBox";
import ColorPresetPicker from "../../ui/functional/ColorPresetPicker";
import ArticleTableEditor from "./ArticleTableEditor";

export default function SplitEditor({valueRef, part, setPart, article, index}){
	if (!part.part1) part.part1 = {kind: "text"};
	if (!part.part2) part.part2 = {kind: "image"};

	const ref1 = useRef(part.part1);
	const ref2 = useRef(part.part2);

	const [background, setBackground] = useState((part.style&&part.style.background)?part.style.background:'');
	const [textColor, setTextColor] = useState((part.style&&part.style.color)?part.style.color:'')
	const [isBGColorModemOpen, setIsBGColorModemOpen] = useState(false);
	const [isTextColorModemOpen, setIsTextColorModemOpen] = useState(false);

	useEffect(() => {
		if (valueRef.current){
			valueRef.current.style = {background:background, color: textColor};
		}

	}, [background, textColor]);

	const reverseParts = () => {
		setPart(prevPart => ({
			...prevPart,
			style: {...prevPart.style, background: background, color: textColor},
			part1: {
				...prevPart.part1,
				...prevPart.part2
			},
			part2: {
				...prevPart.part2,
				...prevPart.part1
			}
		}));
	}

	const updatePart = (newPart, index) => {
		if (index === 1) {
			setPart(prevPart => ({
				...prevPart,
				style: {...prevPart.style, background: background, color: textColor},
				part1: {
					...prevPart.part1,
					...newPart
				}
			}));
		}
		else{
			setPart(prevPart => ({
				...prevPart,
				style: {...prevPart.style, background: background, color: textColor},
				part2: {
					...prevPart.part2,
					...newPart
				}
			}));
		}
	}

	const overWritePart = (newPart, index) => {
		if (index === 1) {
			setPart(prevPart => ({
				...prevPart,
				style: {...prevPart.style, background: background, color: textColor},
				part1: newPart
			}));
		}else{
			setPart(prevPart => ({
				...prevPart,
				style: {...prevPart.style, background: background, color: textColor},
				part2: newPart
			}));
		}

	}

	function PartSelect({subPart, index}){
		return <select defaultValue={subPart.kind} onChange={(e) => {
			overWritePart({kind: e.target.value}, index);
		}}>
			<option value="text">Tekst</option>
			<option value="image">Bilde</option>
			<option value="quote">Sitat</option>
			<option value="table">Tabell</option>
		</select>
	}

	function PartEditor({subPart, index}){
		return <>
		{subPart.kind === "text" && <TextEditor valueRef={index===1?ref1:ref2} part={subPart} setPart={(newPart)=>updatePart(newPart, 1)} article={article} index={index} />}
		{subPart.kind === "image" && <ImageEditor valueRef={(newPart) => updatePart(newPart, index)} part={subPart} setPart={(newPart)=>updatePart(newPart, 2)} article={article} index={index} disableVariant={true} />}
		{subPart.kind === "quote" && <QuoteEditor valueRef={index===1?ref1:ref2} part={subPart} index={index} />}
		{subPart.kind === "table" && <ArticleTableEditor part={subPart} setPart={(newPart)=>overWritePart(newPart,index)} index={index} />}
		</>
	}

	return <div>
		<div>
			<details>
				<summary>Stil</summary>
				<div>
					<label>Bakgrunn: </label>
					<input type={"color"} value={background} onInput={(ev) => {
						setBackground(ev.target.value)
					}}/>
					<button onClick={() => {
						setIsBGColorModemOpen(true)
					}}>Palett
					</button>
					<input type={"text"} value={background} onInput={(ev) => {
						setBackground(ev.target.value)
					}}/>
					<button onClick={() => {
						setBackground('')
					}}>Slett
					</button>
				</div>
				<div>
					<label>Tekst: </label>
					<input type={"color"} value={textColor} onInput={(ev) => {
						setTextColor(ev.target.value)
					}}/>
					<button onClick={() => {
						setIsTextColorModemOpen(true)
					}}>Palett
					</button>
					<input type={"text"} value={textColor} onInput={(ev) => {
						setTextColor(ev.target.value)
					}}/>
					<button onClick={() => {
						setTextColor('')
					}}>Slett
					</button>
				</div>
			</details>
		</div>
		<button onClick={reverseParts}>Reverser rekkefølge</button>
		<h3>Del 1</h3>
		<PartSelect subPart={part.part1} index={1}/>
		<PartEditor subPart={part.part1} index={1}/>

		<hr/>
		<h3>Del 2</h3>
		<PartSelect subPart={part.part2} index={2}/>
		<PartEditor subPart={part.part2} index={2}/>

		{isBGColorModemOpen && <OnTopBox setOnTopEnabled={setIsBGColorModemOpen} onTopEnabled={isBGColorModemOpen}>
			<ColorPresetPicker chooseColorFunc={(c) => {
				setBackground(c.value);
				setIsBGColorModemOpen(false);
			}}/>
		</OnTopBox>}
		{isTextColorModemOpen && <OnTopBox setOnTopEnabled={setIsTextColorModemOpen} onTopEnabled={isTextColorModemOpen}>
			<ColorPresetPicker chooseColorFunc={(c) => {
				setTextColor(c.value);
				setIsTextColorModemOpen(false);
			}}/>
		</OnTopBox>}
	</div>
}