import ContentPart1 from "../ContentPart1";
import ContentRibbon from "../ContentRibbon";
import ContentBlockWrapper from "../ContentBlockWrapper";
import {useState} from "react";
export default function ContentBlock1({
	sectionTitle, parts, reverse, customStyle, link
}) {
	if (!parts) parts = [
		{
			imageLink: null,
			category: "Kategori",
			name: "se alle de store båtene på båter i sjøen 2023",
			authorText: "vetle børresen"
		},{
			imageLink: null,
			category: "Kategori",
			name: "se alle de store båtene på båter i sjøen 2023",
			authorText: "vetle børresen"
		},{
			imageLink: null,
			category: "Kategori",
			name: "se alle de store båtene på båter i sjøen 2023",
			authorText: "vetle børresen",
			preface: "se alle de store båtene på båter i sjøen 2023båter i sjøen 2023"
		}
	]
	if (parts.length < 3)
		console.log('Cannot find 3 content parts for ContentBlock1');


	const reversedParts = ()=>{
		let firstPart = {...parts[0]};
		let lastPart = {...parts[2]};

		lastPart.preface = null;
		parts[1].preface = null;

		return [parts[1], lastPart, firstPart];
	}
	const nonReversedParts = ()=>{
		parts[0].preface = null;
		parts[1].preface = null;
		return parts;
	}

	const [modifiedParts, setModifiedParts] = useState(reverse?reversedParts():nonReversedParts());

	function InnerContent(){
		const blocks = [
			<div key={1} className={'contentBlock1Area1'}>
				<ContentPart1 part={modifiedParts[0]}/>
				<ContentPart1 part={modifiedParts[1]}/>
			</div>,

			<div key={2} className={'contentBlock1Area2'}>
				<ContentPart1 part={modifiedParts[2]}/>
			</div>
		];
		if (reverse) return blocks.reverse();
		return blocks;
	}

	 return (
		 <ContentBlockWrapper customStyle={customStyle?customStyle:null}>
			<div className={'contentBlock1 contentBlock'}>
				{sectionTitle&&<ContentRibbon ribbonText={sectionTitle ? sectionTitle : 'Kategoriboks 1'} link={link}></ContentRibbon>}

				<div className={['contentBlock1Inner', reverse?'reverse':''].join(' ')}>
					<InnerContent/>
				</div>
			</div>
		 </ContentBlockWrapper>
    )
}