import {useEffect, useRef, useState} from "react";
import classes from "../../../components/adminEdit/AdminEdit.module.scss";
import ImageUploader from "../../../components/ui/functional/ImageUploader/ImageUploader";
import AdvertService from "../../../services/AdvertService";
import AdminEdit from "../../../components/adminEdit/AdminEdit";
import SiteService from "../../../services/SiteService";
import AdPlacementService from "../../../services/AdPlacementService";
import {CrossButton} from "../../../components/ui/CrossButton";
import SelectManyFromList from "../../../components/ui/functional/SelectManyFromList";
import {Link} from "react-router-dom";

function AdvertEdit(){
	const [isLoading, setIsLoading] = useState(true);
	const [loadedAds, setLoadedAds] = useState([]);
	const [loadedAdPlacements, setLoadedAdPlacements] = useState(null);

	useEffect(()=>{
		AdvertService.getAll((data)=>{
			data.forEach((ad)=>{
				ad.isEditable = false;
			})
			setLoadedAds(data);

			AdPlacementService.getAll((data)=>{
				//sort by name
				data.sort((a, b) => a.name.localeCompare(b.name));
				setLoadedAdPlacements(data);
				setIsLoading(false);
			})
		});

	}, [])

	const handleChange = (old, newValue, index) => {
		setLoadedAds((oldList) => {
			oldList[index][old] = newValue;
			return [...oldList];
		});
	};

	function adToggleActive(e, obj, index){
		AdvertService.toggleActive(obj._id, (successBool)=>{
			if(successBool === true){
				//obj.active = !obj.active;
				handleChange('active', !obj.active, index);
			}else{
				ev.target.checked = obj.active;
			}
		})
	}


	function TableHeaders(){return(
		<div className={classes.th}>
			<div className={classes.td}></div>
			<div className={classes.td}>Navn</div>
			<div className={classes.td}>Visninger</div>
			<div className={classes.td}>Klikk</div>
			<div className={classes.td}>Detaljer</div>
			<div className={classes.td}>Plassering</div>
			<div className={classes.td}>Tid</div>
		</div>
	)}


	function ObjAdvertPlacements({obj, refObj}){
		// first, make array of all the adPlacements that have this obj._id in it's .adverts
		let tmpAdPlacements = [];
		refObj.adPlacements = useRef(obj.adPlacements);

		loadedAdPlacements&&loadedAdPlacements.forEach((placement)=>{
			if(placement.adverts.includes(obj._id)){
				tmpAdPlacements.push(placement._id);
			}
		})
		const [selectedPlacements, setSelectedPlacements] = useState(tmpAdPlacements);
		useEffect(()=>{
			refObj.adPlacements.current = {};
			refObj.adPlacements.current.value = selectedPlacements
		}, [selectedPlacements])

		if(!obj.isEditable && loadedAdPlacements) return(
			<ul style={{paddingLeft: '10px'}}>
				{tmpAdPlacements.map((placement)=>{
					return(
						<li key={placement}>
							{loadedAdPlacements.find((adPlacement)=>{
								return adPlacement._id === placement
							}).name
						}</li>
					)})}
			</ul>
		)

		else if (obj.isEditable && loadedAdPlacements && selectedPlacements) return(
			<SelectManyFromList list={loadedAdPlacements} selectedListState={selectedPlacements} setSelectedListState={setSelectedPlacements} />
	)}

	const convertDateToInputValue = (date)=>{
		if (!date) return null;
		return date.toISOString().substring(0,10);
	}
	function AdvertDates({obj, refObj}){
		let today = convertDateToInputValue(new Date());
		refObj.activationDate = useRef(new Date(obj.activationDate));
		refObj.expirationDate = useRef(new Date(obj.discountExpirationDate));
		let expirationDateAsInputValue = null, activationDateAsInputValue = null;
		try{	expirationDateAsInputValue = convertDateToInputValue(obj.expirationDate);
		}catch{	expirationDateAsInputValue = convertDateToInputValue(new Date(obj.expirationDate));}
		try{	activationDateAsInputValue = convertDateToInputValue(obj.activationDate);
		}catch{	activationDateAsInputValue = convertDateToInputValue(new Date(obj.activationDate));}

		return(<>
		{(!obj.isEditable) ? <>
			{obj.activationDate&& <div>Start: {activationDateAsInputValue}</div>}
			{obj.expirationDate&& <div>Slutt: {expirationDateAsInputValue}</div>}
		</>:<>
			<label>Start</label>
			<div style={{display:"flex"}}>
				<input style={{width: "fit-content"}} type="date" defaultValue={activationDateAsInputValue} placeholder={"start"} ref={refObj.activationDate}/>
				<CrossButton onclick={()=>{activationDateAsInputValue = null;	refObj.activationDate.current.value = null;}}/>
			</div>
			<br/>
			<label>Slutt</label>
			<div style={{display:"flex"}}>
				<input style={{width: "fit-content"}} type="date" min={today} defaultValue={expirationDateAsInputValue} placeholder={"utløp"} ref={refObj.expirationDate}/>
				<CrossButton onclick={()=>{expirationDateAsInputValue = null;	refObj.expirationDate.current.value = null;}}/>
			</div>
		</>}
	</>)}


	function UnEditableSectionChildren({obj, index, refObj}){
		let descRef = useRef(obj.description);
		let linkRef = useRef(obj.link);

	return(<>
		<div className={[classes.td, classes.alignTop].join(' ')}>
			<a target={'_blank'} href={`/annonse-visninger/${obj._id}`}>
				<button type={"button"}>📊</button>
			</a>
			{obj.viewCount}
		</div>
		<div className={[classes.td, classes.alignTop].join(' ')}>{obj.clickCount}</div>
		<div className={[classes.td, classes.alignTop].join(' ')}>
			<div style={{float: 'right'}}>
				<span>Aktiv:</span>
				<input key={Math.random()} type={"checkbox"} checked={obj.active}
					   style={{height: '25px', width: '25px'}}
					   onChange={(ev) => {
						   adToggleActive(ev, obj, index)
					   }}/>
			</div>


			{obj.isEditable && <>
				<div style={{padding: '5px'}}>Deskripsjon:</div>
				<textarea ref={descRef}
						  id={obj._id + "Description"}
						  defaultValue={obj.description}
						  disabled={!obj.isEditable}
						  className={classes['isEditable' + obj.isEditable.toString()]}
						  style={{display: 'block', minWidth: '100%', maxWidth: '100%'}}
						  maxLength={999}
						  onInput={() => {
							  obj.description = descRef.current.value
						  }}
				/>

				<div style={{padding: '5px'}}>Lenke:</div>
				<input type={"text"}
					   id={obj._id + "Link"}
					   defaultValue={obj.link}
					   disabled={!obj.isEditable}
					   className={classes['isEditable' + obj.isEditable.toString()]}
					   style={{display: 'block', width: '100%'}}
					   ref={linkRef}
					   onInput={() => {
						   obj.link = linkRef.current.value
					   }}
				/>
			</>}
		</div>

		<div className={[classes.td, classes.alignTop].join(' ')}>
			<ObjAdvertPlacements obj={obj} refObj={refObj}/>
		</div>
		<div className={[classes.td, classes.alignTop].join(' ')}>
			<AdvertDates obj={obj} index={index} refObj={refObj}/>
		</div>
	</>)
	}

	function EditableSectionChildren({obj, index, handleChange}) {
		return (<>

			<span>Bilde bredt:</span><br style={{margin: '5px 0px 5px 0px'}}/>
		{obj.wideImage && <img src={`${SiteService.apiPath}/image/adv/` + obj.wideImage}
							   style={{maxWidth: '100%', maxHeight: '200px'}}/>}

		<div>
			<ImageUploader path={'ad'} newName={obj._id + "_wide"}></ImageUploader>
		</div>
		<br/>


		<span>Bilde smalt:</span><br style={{margin: '5px 0px 5px 0px'}}/>
		{obj.mobileImage && <img src={`${SiteService.apiPath}/image/adv/` + obj.mobileImage}
								 style={{maxWidth: '100%', maxHeight: '200px'}}/>}

		<div>
			<ImageUploader path={'ad'} newName={obj._id + "_mobile"}></ImageUploader>
		</div>
		<br/>

		
		<span>Bilde logo (for native ad):</span><br style={{margin: '5px 0px 5px 0px'}}/>
		{obj.logoImage && <img src={`${SiteService.apiPath}/image/adv/` + obj.logoImage}
								 style={{maxWidth: '100%', maxHeight: '200px'}}/>}

		<div>
			<ImageUploader path={'ad'} newName={obj._id + "_logo"}></ImageUploader>
		</div>
	</>)
	}

	function BeforeTableChildren(){
		return (<>
			<p><u><b>Aktiv</b></u>: Når en annonse er aktiv, vises den i plasseringene den ligger i. Når annonsen ikke er aktiv, vises den ikke uansett om den har plassering.</p>
		</>)
	}

	return (
		<AdminEdit
			pageName={'Annonser'}
			BeforeTableChildren={BeforeTableChildren}
			mainService={AdvertService}
			mainArray={loadedAds}
			setMainArray={setLoadedAds}
			TableHeaders={TableHeaders}
			EditableSectionChildren={EditableSectionChildren}
			UnEditableSectionChildren={UnEditableSectionChildren}
			NewSectionBool={true}
			SortObject={{active:'Aktiv', viewCount:'Visninger', clickCount:'Klikk', activationDate:'Start-dato', expirationDate:'Slutt-dato'}}
			SearchObject={{}}
			PaginationBool={true}
		></AdminEdit>
	)
}

export default AdvertEdit;