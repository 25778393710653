import classes from "./TagArchive.module.scss";
import {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import ArticleService from "../../services/ArticleService";
import PlussIconReactive from "../../components/ui/PlussIconReactive";
import SiteService from "../../services/SiteService";

function TagArchive(){
	var params = useParams();

	const [loadedArticles, setLoadedArticles] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(()=>{
		getArticles();
	},[params])

	function getArticles(){
		let fetchURL = "";
			fetchURL = `${SiteService.apiPath}/article/tagall/`+params.name;

		fetch(fetchURL)
			.then((response)=>{
				return response.json();
			})
			.then((data)=>{
				setLoadedArticles(data);
			})
	}

	function relevantTags(){
		let tags = new Set();
		loadedArticles.forEach((article)=>{
			article.tags.forEach((tag)=>{
				tags.add(tag);
			})
		})
		return(<>
			<div className={classes.tagsContainer}>
			{Array.from(tags).map((name)=>{return(
				<Link to={'/stikkord/'+name} className={classes.tag}>{name}</Link>
			)})}
			</div>
		</>)
	}

	return(<>
		<h1 className={classes.headerTitle}>#{params.name}</h1>
			{relevantTags()}
		<div className={classes.articlesContainer}>
			{loadedArticles.map((article)=>{return(
				<div className={classes.articleWrapper}>
					<Link to={'/artikkel/' + article.urlString} className={classes.articleContent}>
						<div className={classes.imageWrapper} style={{position:'relative'}}>
							<img className={classes.articleImage} alt={article.image.alt} src={ArticleService.getUrlHeaderImage(article)}></img>
							<PlussIconReactive article={article}></PlussIconReactive>
						</div>
						<h2 className={classes.articleTitle}>{article.name}</h2>
					</Link>
				</div>
			)})}
		</div>
	</>)
}



export default TagArchive;