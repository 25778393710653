import {TextEditor} from "./TextEditor";
import React, {useEffect, useRef, useState} from "react";
import StyleEditor from "../../ui/functional/StyleEditor";

export default function ArticleTextEditor({valueRef, part, setPart}){
	const editorRef = useRef(null)
	const [isEditorToolsFixed, setIsEditorToolsFixed] = useState(false);

	const [style, setStyle] = useState(part.style?part.style:{});

	useEffect(() => {
		if (valueRef.current){
			valueRef.current.style = {};
			valueRef.current.style.background = style.background?style.background:null;
			valueRef.current.style.color = style.color?style.color:null;
		}
	}, [style]);

	const [isFirstLoad,setIsFirstLoad] = useState(true);
	useEffect(() => {
		if (isFirstLoad) {
			setIsFirstLoad(false);


			// observe if the banner is visible, hide and make nav fixed when not visible
			const observer = new IntersectionObserver(
				([entry]) => {
					if (entry.isIntersecting) {
						if (editorRef.current && editorRef.current) {
							setIsEditorToolsFixed(true)
						}
						//observer.disconnect();
					}
				},
				{
					threshold: 0.01, // Adjust threshold as needed
					rootMargin: '0px 0px' // Adjust rootMargin
				}
			);
			const observer2 = new IntersectionObserver(([entry]) => {
				if (entry.intersectionRatio === 0) {
					setIsEditorToolsFixed(false);
				}
			})

			if (editorRef.current) {
				observer.observe(editorRef.current);
				observer2.observe(editorRef.current);
			}
		}
		return () => {
			if (editorRef.current) {
				if (observer)
					observer.unobserve(editorRef.current);
				if (observer2)
					observer2.unobserve(editorRef.current);
			}
		};
	}, []);
	

	const copyLinkToClipBoard = (textToCopy, buttonRef) => {
		// Simulate copying to clipboard
		if (!navigator.clipboard) {
			return alert('Kan ikke kopiere til utklippstavle når nettstedet bruker HTTP. Prøv HTTPS');
		}
		navigator.clipboard.writeText(textToCopy);
		buttonRef.current.innerText = 'Kopiert!';
		setTimeout(() => {
			buttonRef.current.innerText = 'Kopier';
		}, 1000);
	}

	function CopyToClipboardButton({textToCopy}) {
		const buttonRef = useRef(null);
		return (
			<button ref={buttonRef} onClick={()=>copyLinkToClipBoard(textToCopy, buttonRef)}>
				Kopier
			</button>
		);
	}

return(<>
	<StyleEditor style={style} setStyle={setStyle}/>
	<p>
		<b>- Underoverskrift</b>: Let etter knappen som vanligvis heter <b>Normal</b>. Velg <b>H2</b>.
		<br/>
		<b>- Lim inn</b>: Høyreklikk og velg <i>"Lim inn uten formatering"</i>. Det kan hete noe annerledes avgengig
		av nettleseren du bruker, eks. <i>"..som
		ren tekst"</i> eller <i>"..med tilpasset stil"</i>.
	</p>
	<div ref={editorRef}></div>
	<div className={['articleTextEditor', !isEditorToolsFixed ? 'fixedEditor' : ''].join(' ')}>
		<TextEditor part={part} valueRef={valueRef} setPart={setPart}/>
	</div>
	<p>
		<h4>Ofte brukte tegn</h4>
		Tankestrek/replikkstrek: <span>–</span> <CopyToClipboardButton textToCopy={'–'}/>
	</p>
</>
)}