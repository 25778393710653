import React, { useState, useEffect } from "react";

export default function Abonner(){
const [message, setMessage] = useState("");

const Message = ({ message }) => (
	<section>
		<p>{message}</p>
	</section>
);


useEffect(() => {
	// Check to see if this is a redirect back from Checkout
	const query = new URLSearchParams(window.location.search);

	if (query.get("success")) {
		setMessage("Betaling fullført. Du vil få en e-post med bekreftelse.");
	}

	if (query.get("canceled")) {
		setMessage(
			"Betaling kansellert"
		);
	}
}, []);

if (message) return(
	<Message message={message} />
)

return(
	<section>
		<div className="product">
			<img
				src="https://i.imgur.com/EHyR2nP.png"
				alt="The cover of Stubborn Attachments"
			/>
			<div className="description">
				<h3>Stubborn Attachments</h3>
				<h5>$20.00</h5>
			</div>
		</div>
		<form action="/api/transaction/create-checkout-session" method="POST">
			<button type="submit">
				Checkout
			</button>
		</form>
	</section>
);
}

