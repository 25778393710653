import UserCard from "../../components/user/UserCard";
import classes from "./MinSide.module.scss";
import {memo, useRef, useState} from "react";
import SiteService from "../../services/SiteService";
import ValidateInput from "../../helpers/ValidateInput";
import OnTopBox from "../../components/ui/onTopBox";

export default function Profil(){

	const [passwordBoxOpen, setPasswordBoxOpen] = useState(false);

	async function logout() {try {
		if (!confirm('Logg ut?')) {
			return;
		}
		const response = await fetch(`${SiteService.apiPath}/user/logout`);
		const data = await response.json();
		if (data.success) {
			if (data.destination) {
				return window.location = data.destination;
			}
			window.location = '/';
		} else {
			confirm('Feil under utlogging');
		}
	} catch (error) {
		console.error(error);
		confirm('Feil under utlogging');
	}}

	const ChangePasswordForm = memo(() => {
		const [passwordTestResults, setPasswordTestResults] = useState([]);
		const oldPasswordRef = useRef('');
		const newPasswordRef = useRef('');
		const newPassword2Ref = useRef('');

		async function changePassword(){try {
			let res = await fetch(SiteService.apiPath + '/user/change-password', {
				method: 'POST',
				body: JSON.stringify({updateObj:{
						oldPassword: oldPasswordRef.current.value,
						newPassword: newPasswordRef.current.value
					}}),
				headers: {
					'Content-Type': 'application/json'
				}
			});
			let data = null;
			try {data = await res.json();}catch (e) {}
			if(res.ok)
				return true;
			if (data.error && data.error.reason) {
				setPasswordTestResults(<span style={{color:'red'}}>{data.error.reason}</span>)
			}
		}catch (e) {
			console.error(e);
		}}

		const testPassword = function () {
			const password = newPasswordRef.current.value;

			let validateResults = ValidateInput('password', password);
			let oldPassword = oldPasswordRef.current.value;

			if (validateResults.passed) {
				if (password.toLowerCase() === oldPassword.toLowerCase()) {
					validateResults.passed = false;
					validateResults.message = 'Nytt og gammelt passord kan ikke være like';
				} else if (password !== newPassword2Ref.current.value) {
					validateResults.passed = false;
					validateResults.message = 'Gjentatt passord er ikke likt';
				}
			}

			if (!validateResults.passed)
				setPasswordTestResults(<span style={{color:'red'}}>{validateResults.message}</span>);
			else{
				setPasswordTestResults(<span style={{color:'limegreen'}}>OK</span>);
			}

			return validateResults.passed;
		}


		return(<OnTopBox successAnimationBool={true} onTopEnabled={passwordBoxOpen} setOnTopEnabled={setPasswordBoxOpen} onOk={changePassword}>
			<div className={classes.passwordChangeForm}>
				<h2>Endre passord</h2>
				<label htmlFor={"oldPassword"}>Nåværende Passord</label>
				<input name={"oldPassword"} className={'textInput1'} type="password" ref={oldPasswordRef} placeholder="Nåværende Passord" required={true}
					   minLength={8} maxLength={128} pattern={"(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"}
					   onChange={(ev)=>{testPassword(); }}
				/>
				<br/>

				<label htmlFor={"newPassword"}>Nytt Passord</label>
				<input name={"newPassword"} type="password" ref={newPasswordRef} placeholder="Nytt passord" required={true} className={'textInput1'}
					   minLength={8} maxLength={128} pattern={"(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"}
					   onChange={(ev)=>{testPassword();}}
				/>
				<br/>

				<label htmlFor={"newPassword"}>Gjenta nytt passord</label>
				<input type="password" ref={newPassword2Ref} placeholder="Gjenta nytt passord" required={true} className={'textInput1'}
					   minLength={8} maxLength={128} pattern={"(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"}
					   onChange={(ev)=>{testPassword();}}/>
				{passwordTestResults}
			</div>
		</OnTopBox>)
	})

	return <div className={classes.profil}>
		<h1>Profil</h1>
		<UserCard isEditable={true}></UserCard>
		<div className={'card'}>
			<br/>
			<button className={classes.link1} onClick={() => {
				setPasswordBoxOpen(!passwordBoxOpen)
			}}>Endre passord
			</button>
			<ChangePasswordForm></ChangePasswordForm>
			<br/>
			<button className={classes.link1} onClick={logout}>Logg ut</button>
		</div>
	</div>
}