import TagBox from "./TagBox";

export default function TagsContainer({tags}) {

return <div className={'tagsContainer'}>
		{window.location.toString().split('/')[3] === 'artikkel' && <span>Les mer om: </span>}
		{tags.map((tag, index) => {
			return <TagBox tag={tag} key={index}/>
		})}
	</div>
}