import {useEffect, useRef, useState} from "react";
import NextIcon from "../../svg/NextIcon";
import useEventListener from "../../hooks/useEventListener";

export default function ImagePopout2({src, imageIndex, bottomText, setImageIndex, changeSlideShowFunction, imageRef, maxImages}) {
	const popoutRef = useRef(null);
	const [isShowingImage, setIsShowingImage] = useState(false);
	const [isZoomed, setIsZoomed] = useState(false);
	const onClick = ()=>{
		setIsShowingImage(!isShowingImage)
	}

	useEffect(() => {
		let imageEl = imageRef?.current;
		if (!imageEl)
			return;
		imageEl.style.cursor = 'zoom-in';
		imageEl.addEventListener('click', onClick);
		// if unmounting, remove event listeners
		return () => {
			try {
				imageEl.removeEventListener('click', onClick);
			}catch (e) {
				console.log(e);
			}
		};
	}, []);

	useEventListener(popoutRef, 'key:escape', () => {
		setIsShowingImage(false)
		if (changeSlideShowFunction)
			changeSlideShowFunction(null)
	});
	if (changeSlideShowFunction){
		useEventListener(popoutRef, 'key:ArrowLeft', () => changeSlideShowFunction(-1));
		useEventListener(popoutRef, 'key:ArrowRight', () => changeSlideShowFunction(1));
	}


	function Popout(){
		return <div ref={popoutRef} className={'imagePopoutContainer'}><div className={'imagePopout'}>
			<div className={'imagePopoutInner'}>
				<img src={src&&src}/>
				{maxImages&&<div className={'imagePopoutIndex'}>{imageIndex + 1} / {maxImages}</div>}
				<button className={'button1 close'} onClick={()=>changeSlideShowFunction(null)}>𐌗</button>
				{/*<button className={'button1 zoom'}
						 onClick={() => setIsZoomed(!isZoomed)}>{isZoomed ? '-' : '+'}</button>*/}
				{changeSlideShowFunction&&<>
					<button className={'carouselArrow carouselArrowLeft'} onClick={()=>changeSlideShowFunction(-1)}>
						<NextIcon></NextIcon>
					</button>
					<button className={'carouselArrow carouselArrowRight'} onClick={()=>changeSlideShowFunction(1)}>
						<NextIcon></NextIcon>
					</button>
					{bottomText && <div className={'carouselDesc'}>{bottomText}</div>}
				</>}
			</div>
		</div>
		</div>
	}

	return (imageIndex > -1 && imageIndex!==null) ? <Popout/> : null;
}