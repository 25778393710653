import {Link, useParams} from "react-router-dom";
import { useContext, useEffect, useRef, useState} from "react";
import SiteContext from "../store/siteContext";
import './Category.scss';
import searchService from "../services/searchService";
import ContentBlock1 from "../components/frontpage/content-blocks/ContentBlock1";
import SiteService from "../services/SiteService";
import ContentBlock7 from "../components/frontpage/content-blocks/ContentBlock7";
import ContentBlock8 from "../components/frontpage/content-blocks/ContentBlock8";
import AdvertBlock6 from "../components/frontpage/content-blocks/AdvertBlock6";
import useQueryParam from "../hooks/useQueryParam";
import {CrossButton} from "../components/ui/CrossButton";
import FrontpageService from "../services/FrontpageService";
import tagService from "../services/TagService";
import AdvertBlock5 from "../components/frontpage/content-blocks/AdvertBlock5";
import AdvertBlock4 from "../components/frontpage/content-blocks/AdvertBlock4";
import TagChooser2 from "../components/ui/functional/TagChooser2";

export default function Category(){
	const { categoryParam } = useParams();
	const [searchTag, setSearchTag] = useQueryParam('tag', '');
	const [pageQueryParam, setPageQueryParam] = useQueryParam('p', '1');
	const [searchNameQueryParam, setSearchNameQueryParam] = useQueryParam('q', '');

	const siteContext = useContext(SiteContext);
	const categories = siteContext.categories;
	const organizedCategories = siteContext.organizedCategories;

	const [articles, setArticles] = useState([]);
	const [searchPage, setSearchPage] = useState(1);
	const [paginationOptions, setPaginationOptions] = useState(null);

	const [isLoading, setIsLoading] = useState(true);

	const searchNameRef = useRef(searchNameQueryParam);

	const [CategoryFrontpage,setCategoryFrontpage] = useState(null);
	const fetchedTag = useRef(null)

	useEffect(() => {
		getFrontpage();
		if (searchTag)
			document.title = searchTag.substring(0,1).toUpperCase()+searchTag.substring(1);
	}, [categoryParam, searchTag]);

	const getFrontpage=async ()=>{
		let frontpageId = null;
		if (!categoryParam && !searchTag) return setCategoryFrontpage(null);
		if (searchTag){
			fetchedTag.current = await tagService.getOneByName(searchTag);
			if (fetchedTag.current&&fetchedTag.current.frontpage)
				frontpageId = fetchedTag.current.frontpage;
		}
		else if (categoryParam && categories) {
			const cat = siteContext.categories.find(cat => cat.name.toLowerCase() === categoryParam.toLowerCase());
			if (cat && cat.frontpage)
				frontpageId = cat.frontpage;
		}

		if (!frontpageId) return setCategoryFrontpage(null);
		let frontpage = await FrontpageService.getOneWithContent(frontpageId);
		setCategoryFrontpage(frontpage);
	}

	useEffect(() => {// should not be able to search within a category
		if (categoryParam) {
			setSearchTag('');
			//set page's title head tag to the category's name
			document.title = categoryParam.substring(0,1).toUpperCase()+categoryParam.substring(1);
		}
	}, [categoryParam]);

	useEffect(() => {
		if (!categories)
			return;

		try { // set main category for main nav bar
			let mainBarCat = categories.find(cat => cat.name.toLowerCase() === categoryParam.toLowerCase());

			if (mainBarCat.parentCategory) {
				siteContext.setSelectedMainCategory(categories.find(cat => cat._id === mainBarCat.parentCategory).name);
			} else {
				siteContext.setSelectedMainCategory(mainBarCat.name);
			}
		}catch (e) {
			console.log('Failed to set main category for main nav bar')
		}

		if (!categoryParam) { // set default category
			const defaultCategory = categories.find(cat => cat._id === null);
			search({category: defaultCategory ? defaultCategory._id : null});
		} else {
			const cat = siteContext.categories.find(cat => cat.name.toLowerCase() === categoryParam.toLowerCase());
			if (cat) {
				setPageQueryParam('1');
				setSearchNameQueryParam('');
				search({category: cat._id});
			}
		}
	}, [categoryParam, categories]);

	const search = async (searchParams) => {
		setIsLoading(true);
		let searchObj = {};
		if (searchParams && searchParams.category) searchObj.categories = searchParams.category;
		if (searchNameRef.current && searchNameRef.current.value.length > 0)
			searchObj.text = searchNameRef.current.value;

		if (searchParams && searchParams.tag != null)
			searchObj.tag = searchParams.tag;
		else if (searchTag)
			searchObj = {tag: searchTag};

		let result = await searchService.search('articles', (searchParams && searchParams.pageNum ? searchParams.pageNum : pageQueryParam) - 1, searchObj);
		if (!result.mainObj)
			return;

		if (result.pagination) setPaginationOptions(result.pagination);

		for (let i = 0; i < result.mainObj.length; i++) {
			let part = result.mainObj[i];
			if (!part)
				return;
			if (part.image) result.mainObj[i].imageLink = `${SiteService.apiPath}/image/articles/${part._id}/${part.image.url}`

			if (part.urlString) part.linkTo = `/artikkel/${part.urlString}`

			if (part.categories && part.categories.length > 0) {
				try {
					result.mainObj[i].category = categories.find((cat) => {
						return cat._id === part.categories[0];
					}).name;
				} catch (e) {
				}
			}
		}

		setArticles(result.mainObj);
		setIsLoading(false);
	}

	const changePagination = (pageOffset, exactPage, currPage)=>{
		window.scrollTo(0,0);
		let destinationPage = currPage+pageOffset;

		if (exactPage) destinationPage = exactPage
		console.log(destinationPage)
		setPageQueryParam(destinationPage.toString());

		let cat = null;
		if (categoryParam)
			cat = siteContext.categories.find(cat => cat.name.toLowerCase() === categoryParam.toLowerCase());
		search({pageNum:destinationPage, category:cat?cat:null});
	}

	function PaginationBlock(){
		if (!articles || articles.length===0) return <div className={'paginationBlock'}></div>;
		if (!paginationOptions) return <div className={['paginationBlock', isLoading?'disabled':''].join(' ')}></div>;

		const {totalPages, totalResults} = paginationOptions;
		const currPage = pageQueryParam?parseInt(pageQueryParam):searchPage;

		return (<div className={['paginationBlock', isLoading?'disabled':''].join(' ')}>
			{currPage > 1 && <>
				<button onClick={()=>changePagination(null,1, currPage)}>{'|<'}</button>
			</>}

			{currPage > 1 && <>
				<button onClick={()=>changePagination(-1, null, currPage)}>{'Forrige'}</button>
			</>}
			{currPage > 2 && <span onClick={()=>changePagination(-2, null, currPage)}>{currPage-2}</span>}
			{currPage > 1 && <span onClick={()=>changePagination(-1, null, currPage)}>{currPage-1}</span>}

			<span className={'currPageNum'}>{currPage}</span>

			{currPage < totalPages-1 && <span onClick={()=>changePagination(1, null, currPage)}>{currPage+1}</span>}
			{currPage < totalPages-1 && <span onClick={()=>changePagination(2, null, currPage)}>{currPage+2}</span>}

			{currPage < totalPages && <>
				<span onClick={()=>changePagination(null, totalPages, null)}>{totalPages}</span>
				<button onClick={()=>changePagination(1, null, currPage)}>{'Neste'}</button>
			</>}

			{currPage < totalPages && <>
				<button onClick={()=>changePagination(null,totalPages, currPage)}>{'>|'}</button>
			</>}
		</div>)}

	function SearchNameBlock(){
		const onSubmit = (ev) => {
			ev.preventDefault();
			setSearchNameQueryParam(searchNameRef.current.value);
			setSearchTag('');
			search();
		}

		return(<form className={'nameSearch'} onSubmit={ev=>onSubmit}>
			<input type={"text"} defaultValue={searchNameQueryParam} ref={searchNameRef} placeholder={'Søk etter...'}/>
			<button className={'button1'} style={{borderRadius:'0 6px 6px 0', border: '1px solid black'}} onClick={onSubmit}>{'Søk'}</button>
		</form>)
	}

	function SubCategories(){
		if (!categoryParam || !organizedCategories) return null;
		const selectedCat = categories.find(cat => cat.name.toLowerCase() === categoryParam.toLowerCase());
		if (!selectedCat || (!selectedCat.parentCategory && !selectedCat.children)) return null;
		let mapObj = null;
		if (selectedCat.parentCategory)
			mapObj = organizedCategories[selectedCat.parentCategory].children;
		else mapObj = organizedCategories[selectedCat._id].children;

		//remove duplicate children
		mapObj = mapObj.filter((cat, i, arr) => arr.indexOf(cat) === i);

		return <div className={'categoriesScroller'}>
			<swiper-container className="swiper-1" space-between="0" slides-per-view="auto" grab-cursor="true" direction="horizontal" style={{width:'100%',height:'100%'}}>
				{mapObj.map((category, i) => {
					const isSelected = category.name.toLowerCase() === categoryParam.toLowerCase();
					return <swiper-slide style={{width:'fit-content'}} key={i}>
						<Link draggable={false}
							 to={`/kategorier/${encodeURIComponent(category.name.toLowerCase())}?p=1`}
							 className={['catItem', isSelected ? 'catItemSelected' : ''].join(' ')}
							 onClick={() => {
								 setSearchTag('');
								 setSearchPage('1');
								 setPageQueryParam('1');
							 }}>
						{category.name}
					</Link>
					</swiper-slide>
				})}
			</swiper-container></div>
	}

	function CategoryArticles({articles}){
		if (isLoading) return <div className={'frontpage animationFade-in'}>
			<div className={'centered'}>Laster inn artikler..</div>
		</div>;

		if (!articles || articles.length === 0) return <div className={'frontpage animationFade-in'}>
			<div className={'centered'}>Ingen artikler her ennå.</div></div>;
		let bulks = [];
		const makeBulks =()=>{
			for (let i = 0; i < articles.length; i++) {
				//if i is 0, slice off 3
				let tempBulk = [];
				if (i === 0) {
					tempBulk = articles.slice(i, i + 3);
					i += 2;
				} else if(i === 3){
					bulks.push(null);
					// slice off two
					tempBulk = articles.slice(i, i + 2);
					i+=1;
				} else if(i === 5){
					//slice off one
					tempBulk = articles.slice(i, i + 1);
				}
				//slice off two if there are two more available
				else if (articles.length - i >= 2) {
					tempBulk = articles.slice(i, i + 2);
					i+=1;
				}
				//else slice off only one
				else tempBulk = articles.slice(i, i + 1);

				bulks.push(tempBulk);
			}
		}
		makeBulks();
		var hasCreatedBrandboardXL = false;

		return <div className={'frontpage'}>
			{bulks.map((bulk, i) => {
				let content = [];
				if (i === 0 && bulk.length===3) content.push(
					<>	<AdvertBlock6 parts={[{id: '000000000000000000000001'}]}/>
						<ContentBlock1 key={i} parts={bulk} reverse={true}/></>)
				else if(i === 1 && bulk==null) content.push(<AdvertBlock5 key={i} adPlacementId={'000000000000000000000002'}/>)
				else if(i === 2 && bulk.length===2) content.push(<ContentBlock8 key={i} parts={bulk}/>)
				else if(i === 3 && bulk.length===1) content.push(<ContentBlock7 key={i} parts={bulk}/>)
				else if (bulk.length === 2) content.push(<ContentBlock8 key={i} parts={bulk}/>)
				else content.push(<ContentBlock7 key={i} parts={bulk} reverse={true}/>)
				// add Ad for every 3 blocks
				if (i % 3 === 0 && i!== 0 && hasCreatedBrandboardXL) content.push(<AdvertBlock5 key={i} adPlacementId={'000000000000000000000002'}/>)
				else if (i % 3 === 0 && i!== 0) {
					hasCreatedBrandboardXL = true;
					content.push(<AdvertBlock4 key={i} adPlacementId={'000000000000000000000005'}/>)
				}
				// if not the last block
				if (i < bulks.length - 1)
					content.push(<div key={i+'divider'} className={'divider'}></div>)
				return content
			})}
		</div>
	}

	return(<div className={['categoryPage', isLoading?'isLoading':''].join(' ')}>
		<div className={'categoriesContainer'}>
			<div className={'categoriesAndSearchBlock'}>
				<SubCategories/>
				<SearchNameBlock/>
			</div>
			{searchTag&&<div><h3 onClick={()=>{setSearchTag('');setPageQueryParam('');search({tag:''})}}>Artikler med <b>#{searchTag}</b>  <CrossButton/></h3></div>}
		</div>


		<div className={'frontpage'} style={{marginTop:'1.5em',marginBottom:'1.5em'}}>
			{(CategoryFrontpage && (pageQueryParam<2) && !searchNameQueryParam) && FrontpageService.FrontpageRender({
				frontpageModules: CategoryFrontpage.modules,
				isLive: true,
				categories: categories
			})}
		</div>

		<div id={'categoryArticles'} className={'categoryArticlesContainer'}>
			<CategoryArticles articles={articles}/>
			{paginationOptions && articles.length>0 && paginationOptions.totalPages > 1 ?
				<PaginationBlock/>
				:
				<>{(!isLoading&&articles.length>0)&& <p style={{marginTop:'3em'}} className={'centered'}>Ingen flere artikler her..</p>}</>
			}
		</div>
	</div>)}
