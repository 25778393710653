import {useState} from "react";
import classes from "../../../components/adminEdit/AdminEdit.module.scss";
import B64ImageUploader from "../../../components/ui/functional/B64ImageUploader/B64ImageUploader";
import AdminEdit from "../../../components/adminEdit/AdminEdit";

import SponsorService from "../../../services/SponsorService";
import SiteService from "../../../services/SiteService";

function SponsorEdit(){
	const [sponsors, setSponsors] = useState([]);

	function TableHeaders(){return(
		<div className={classes.th}>
			<div className={classes.td}></div>
			<div className={classes.td}>Navn</div>
			<div className={classes.td}>Farger</div>
			<div className={classes.td}>Logo-lenke</div>
			<div className={classes.td}>Logo</div>
		</div>
	)}

	function ColorEditor({ obj, handleChange, number, index }) {
		const [color, setColor] = useState(obj['color' + number]);

		const handleColorChange = (e) => {
			const newColor = e.target.value;
			setColor(newColor);
			// Update both color inputs
		};
		const saveColorChange = (e) => {
			obj['color'+number] = color; // Update the object with the new color
		}

		return (
			<div>
				<span>
					<input
						disabled={!obj.isEditable}
						type={"color"}
						value={color} // Use local state for color value
						onChange={handleColorChange}
						onBlur={saveColorChange} // Add onBlur event to ensure consistent behavior
						style={{ width: "40px", height: "30px" }}
					/>
				</span>
				{obj.isEditable&&<span>
					<input
						disabled={!obj.isEditable}
						type={"text"}
						value={color} // Use local state for color value
						onChange={handleColorChange} // Sync the text input with color input
						onBlur={saveColorChange} // Add onBlur event to ensure consistent behavior
						style={{ width: "100px", height: "24px" }}
					/>
				</span>}
			</div>
		);
	}




	function UnEditableSectionChildren({obj, handleChange, index}){
		return(<>
			<div className={classes.td}>
				{obj.isEditable&&<span>Tekst-farge</span>}
				<ColorEditor obj={obj} number={"1"} handleChange={handleChange} index={index}/>
				{obj.isEditable&&<span>Bakgrunns-farge</span>}
				<ColorEditor obj={obj} number={"2"} handleChange={handleChange} index={index}/>
				{obj.isEditable&&<span>Aksent-farge (understrek, sitat)</span>}
				<ColorEditor obj={obj} number={"3"} handleChange={handleChange} index={index}/>
			</div>

			<div className={[classes.td, classes.alignTop].join(' ')}>
				<input disabled={!obj.isEditable} type={"text"} defaultValue={obj.url} onBlur={(ev)=>handleChange("url", ev.target.value, index)} style={{width: "200px"}}/>
			</div>

			<div className={[classes.td, classes.alignTop].join(' ')}>
				{obj['imageB64'] ? (
					<img style={{maxHeight: "100px", maxWidth:"200px"}} src={obj.imageB64} alt="" />
				):(
					<img style={{maxHeight: "100px", maxWidth:"200px"}} src={`${SiteService.apiPath}/image/sponsors/${obj._id+obj.logoImage}`} alt="" />
				)}
				{obj.isEditable && <B64ImageUploader
					setImageB64={(b64) => {
						handleChange("imageB64", b64, index)
					}}
					setFileNameExtention={(name) => {
						handleChange("logoImage", name, index)
					}}
				></B64ImageUploader>}
			</div>

	</>)}

	function EditableSectionChildren({obj, index, handleChange}) {return(<>

	</>)}




	return(
		<AdminEdit
			pageName={"Annonsører"}
			mainService={SponsorService}
			mainArray={sponsors}
			setMainArray={setSponsors}
			NewSectionBool={true}
			TableHeaders={TableHeaders}
			EditableSectionChildren={EditableSectionChildren}
			UnEditableSectionChildren={UnEditableSectionChildren}>
		</AdminEdit>
	)
}

export default SponsorEdit;