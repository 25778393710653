import React from 'react';

const BellIcon = ({stroke, strokeWidth="1.7", fill}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 25 25"
		width="50px"  // Set explicit pixel size or use "em" or "rem" units
		height="50px" // Same here
		fill={fill?fill:'none'}
		stroke={stroke?stroke:"var(--theme-color2)"}
		strokeWidth={strokeWidth}
		strokeLinecap="round"
		strokeLinejoin="round"
	>
		<path d="M18 8a6 6 0 0 0-12 0c0 5-3 7-3 7h18s-3-2-3-7"></path>
		<path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
	</svg>
);

export default BellIcon;
