import classes from "./TagChooser2.module.scss";
import {useRef, useState} from "react";
import TagService from "../../../services/TagService";

export default function TagChooser2({allInputTags, setAllInputTags, chosenTags, setChosenTags, maxChosenTags}){
	const inputRef = useRef('');
	const [suggestionList, setSuggestionList] = useState([]);
	const [listPosition, setListPosition] = useState(0);
	const [suggestionListHidden, setSuggestionListHidden] = useState(false);

	const tagSelect = (tag)=>{
		if(maxChosenTags === undefined || maxChosenTags > chosenTags.length) {
			for (let i = 0; i < chosenTags.length; i++) {
				if (tag.name === chosenTags[i].name) {
					return;
				}
			}
			setChosenTags([...chosenTags, tag]);
		}
		hideSuggestionList();
	}
	const tagUnselect = (index)=>{
		setChosenTags(chosenTags.filter((_, i) => i !== index))
	}

	const onInput = async (ev)=>{
		if (ev.key === "Escape"){
			setSuggestionListHidden(true);
		}else if (suggestionListHidden)
			setSuggestionListHidden(false);

		if (ev.key === "ArrowDown"){
			if (listPosition < suggestionList.length-1) {
				inputRef.current.value = suggestionList[listPosition+1].name;
				setListPosition(listPosition + 1);
			}
			ev.preventDefault();
		}else if (ev.key === "ArrowUp") {
			if (listPosition > 0) {
				inputRef.current.value = suggestionList[listPosition-1].name;
				setListPosition(listPosition - 1);
			}
			ev.preventDefault();
		}else{
			setListPosition(-1);
		}

		if(ev.key !== "Enter")
			return;
		ev.preventDefault();

		if(inputRef.current.value.length < 1)
			return;

		let isDuplicate = false;

		// Check if tag already chosen
		chosenTags.forEach((tag)=>{
			if(tag.name === inputRef.current.value)
				return isDuplicate = true;
		});

		// Check if tag already exists
		allInputTags.forEach((inTag)=>{
			if(inTag.name === inputRef.current.value)
				return isDuplicate = true;
		});


		if (isDuplicate){
			tagSelect(allInputTags.find(t=>t.name === inputRef.current.value));
			inputRef.current.value = "";
			setSuggestionListHidden(true);
			return;
		}

		let newTag = await TagService.create2(inputRef.current.value);
		if (!newTag)
			return;

		tagSelect(newTag.tag)

		if (inputRef.current)
			inputRef.current.value = "";
	}


	const onInputChange = (ev)=>{
		if (ev.target.value.length < 1)
			return setSuggestionList([]);
		// make a list of suggestions for tag names that are similiar to the one the user is typing
		let suggestions = allInputTags.filter(tag => (
			// Filter out tags that are already chosen
			!chosenTags.find(t=>t.name === tag.name)
			&&
			tag.name.toLowerCase().includes(ev.target.value.toLowerCase()))
		);

		setSuggestionList(suggestions);
	}


	function Tag({tag}){return(
		<div onClick={()=>tagSelect(tag)} className={[classes.tag].join(' ')}>{tag.hidden&&<span>👁️ | </span>}{tag.name} <span> | </span> {tag.articleIds.length}
		</div>)
	}
	function ChosenTag({tag, index}){return(
		<div onClick={()=>tagUnselect(index)} className={[classes.tag, classes.chosenTag].join(' ')}>
			{tag.hidden&&<span>👁️ | </span>}{tag.name}	{tag.articleIds.length>0 && <><span> | </span> {tag.articleIds.length}</>}
		</div>)
	}

	function SuggestionList(){
		if (!suggestionList.length) return null;
		return <div className={[classes.suggestionList, suggestionListHidden?'hidden':''].join(' ')}>
			{suggestionList.map((tag, index)=>{
				let selected = listPosition === index;
				return(<div key={'0'+index} className={selected?classes.selected:''}><Tag key={index} tag={tag}></Tag></div>);
			})}
		</div>
	}
	const hideSuggestionList = ()=>{
		setSuggestionList([])
	}

return(<>
	<div><i>👁️ - Vises ikke i artikkel</i></div>
	<br/>

<div>
	{maxChosenTags && <div>Max stikkord: {maxChosenTags}</div>}
	<div className={classes.tagsContainer}>
		{chosenTags && chosenTags.sort((a, b) => a.name.localeCompare(b.name)).map((tag, index)=>{
			return(<ChosenTag key={index} index={index} tag={tag}></ChosenTag>);
		})}
	</div>

	{chosenTags.length>0 && <hr style={{width: '100%'}}/>}
	<div className={classes.inputContainer}>
		<input ref={inputRef} className={'input1'} placeholder={"stikkord"} type={"text"}
			   onKeyDown={(ev)=>{onInput(ev)}}
			   onInput={(ev)=>{onInputChange(ev)}}
		/>
		<button type={"button"} className={'button1'} onClick={(ev)=>{ev.key="Enter";onInput(ev)}}>Legg til</button>
		<SuggestionList/>
	</div>

	<details>
		<summary>Stikkord-liste</summary>
		<div className={classes.tagsContainer}>
	{allInputTags && allInputTags.sort((a, b) => a.name.localeCompare(b.name)).map((tag, index)=>{
		// if tag in chosenTags, return
		if(chosenTags.find(t=>t.name === tag.name))
			return;
		return(<Tag key={index} tag={tag}></Tag>);
	})}
	</div></details>

</div>

</>)}