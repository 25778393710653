import {useRef} from "react";
import classes from "../../../pages/admin/ArticleEdit.module.scss";

export default function EmbedEditor({valueRef, part, setPart}){
	const ref = useRef(part.value)

	const handleChange = (e)=>{
		valueRef.current.value = e.target.value;
	}

	return(<>
		<textarea placeholder="Innbygg" defaultValue={part.value} ref={ref} className={classes.bodyPart} required onChange={handleChange}/>
	</>)
}