import {useEffect, useRef, useState} from "react";
import AdminEdit from "../../../components/adminEdit/AdminEdit";
import classes from "../../../components/adminEdit/AdminEdit.module.scss";
import AdminSubscriptionPlanService from "../../../services/AdminSubscriptionPlanService";

function SubscriptionPlanEdit(){
	const [isLoading, setIsLoading] = useState(true);
	const [subscriptionPlans, setSubscriptionPlans] = useState([]);
	const [subscriptionCampaigns, setSubscriptionCampaigns] = useState([]);

	useEffect(()=>{

	}, [])


	function TableHeaders(){
		return(<div className={classes.th}>
			<div className={classes.td}></div>
			<div className={classes.td}>Navn</div>
			<div className={classes.td}>Deskripsjon</div>
			<div className={classes.td}>Intervall</div>
			<div className={classes.td}>Intervall-frekvens</div>
			<div className={classes.td}>Pris</div>
			<div className={classes.td}>Rabatt-kampanje</div>
			<div className={classes.td}>Aktiv</div>
		</div>)
	}


	function CheckCross({bool}){return <>
		{bool ? <span>✔️</span>: <span>❌</span>}
	</>}

	function CheckingBox({bool, boxRef}){return <>
		<input type={"checkbox"}  defaultChecked={bool} ref={boxRef}/>
	</>}

	function UnEditableSectionChildren({obj, index, handleChange, refObj}){
		refObj.intervalString = useRef(obj.intervalString);
		refObj.description = useRef(obj.description);
		refObj.intervalString = useRef(obj.intervalString);
		refObj.intervalAmount = useRef(obj.intervalAmount);
		refObj.price = useRef(obj.price);
		refObj.subscriptionCampaignId = useRef(obj.subscriptionCampaignId);
		refObj.active = useRef(obj.active);

		return(<>
			<div className={classes.td}>{obj.isEditable ?
				<input ref={refObj.description} type={'text'} defaultValue={obj.description}/>
				:
				<>{obj.description}</>
			}</div>

			<div className={classes.td}>
				{obj.isEditable ?
					<select ref={refObj.intervalString} defaultValue={obj.intervalString}>
						<option value={'day'}>Dag</option>
						<option value={'week'}>Uke</option>
						<option value={'month'}>Måned</option>
						<option value={'year'}>År</option>
					</select>
					:<>
					{obj.intervalString === 'day' && <>Dag</>}
					{obj.intervalString === 'week' && <>Uke</>}
					{obj.intervalString === 'month' && <>Måned</>}
					{obj.intervalString === 'year' && <>År</>}
					</>
				}
			</div>

			<div className={classes.td}>
				{obj.isEditable ?
					<input ref={refObj.intervalAmount} type={"number"} defaultValue={obj.intervalAmount}/>
					:
					<>{obj.intervalAmount}</>
				}
			</div>

			<div className={classes.td}>
				{obj.isEditable ?
					<input ref={refObj.price} type={"number"} defaultValue={obj.price}/>
					:
					<>{obj.price}</>
				}
			</div>

			<div className={classes.td}>
				{obj.isEditable ?
					<select ref={refObj.subscriptionCampaignId} defaultValue={obj.subscriptionCampaignId}>
						<option style={{display: 'none'}}></option>
						{subscriptionCampaigns.map((item, index) => {return(
							<option key={index} value={item._id}>{item.name || item}</option>
						)})}
					</select>
					:<>
						{obj.subscriptionCampaignId}
					</>
				}
			</div>

			<div className={[classes.td, classes.centerCell].join(' ')}>
				{obj.isEditable ?
					<CheckingBox boxRef={refObj.active} bool={obj.active}/>
					:
					<CheckCross bool={obj.active}/>
				}
			</div>
		</>)
	}

	function EditableSectionChildren({obj, index, handleChange}) {return(<>

	</>)}



	return(
		<AdminEdit
			pageName={"Abonnerings-planer"}
			mainService={AdminSubscriptionPlanService}
			mainArray={subscriptionPlans}
			setMainArray={setSubscriptionPlans}
			TableHeaders={TableHeaders}
			EditableSectionChildren={EditableSectionChildren}
			UnEditableSectionChildren={UnEditableSectionChildren}
			PaginationBool={false}
			NewSectionBool={true}
		></AdminEdit>
	)
}

export default SubscriptionPlanEdit;