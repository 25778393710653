import {useContext} from "react";

import Card from "../ui/Card";
import classes from './ArticleItem.module.scss';
import FavouritesContext from "../../store/favourites-context";
import {Link} from "react-router-dom";
import UserContext from "../../store/userContext";
import ArticleService from "../../services/ArticleService";

function ArticleItem({article, articleClickAction}){
	const user = useContext(UserContext);
	const favouritesCtx = useContext(FavouritesContext);

	const itemIsFavourite = true //favouritesCtx.itemIsFavourite(article._id);

	function toggleFavouriteStatusHandler(){
		if(itemIsFavourite){
			favouritesCtx.removeFavourite(article._id);
		}else{
			favouritesCtx.addFavourite(article._id);
		}
	}

	function FavButton(){
		if(user.isLoggedIn){
			return(
				<button className={`${itemIsFavourite ? classes.isFavourite : classes.isNotFavourite}`}  onClick={toggleFavouriteStatusHandler}>
					{itemIsFavourite ? '⭐' : '⭐'}
				</button>
			)
		}
	}

	return(
		<li className={classes.item}>
			{/*<div className={classes.actions}>
				<FavButton/>
			</div>*/}
			<Card>
				<Link className={classes.cardLink} to={'/artikkel/' + article.urlString} onClick={(ev)=>{
					if (articleClickAction) {
						ev.preventDefault();
						articleClickAction(article);
					}
				}}>
					<div>
						<div className={classes.imageOverlay}></div>
						<img className={classes.image} alt={article.image.alt} src={ArticleService.getUrlHeaderImage(article)}></img>
					</div>
					<div className={classes.content}>
						<h3>{article.name}</h3>
						<time dateTime={article.date.substring(0,10)}>{article.date.substring(0,10)}</time>
						<u>{articleClickAction?'Velg':'Les mer'}</u>
					</div>
				</Link>
			</Card>
		</li>
	)
}

export default ArticleItem;