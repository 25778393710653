import {useEffect, useState} from "react";
import VideoPlayer from "../../ui/VideoPlayer";
import OnTopBox from "../../ui/onTopBox";
import VideosEdit from "../../../pages/VideosEdit";
import ImageEditor from "../bodypartEditors/ImageEditor";
function HeaderTextboxStyleEditor({image, setImage, index}) {
	const [imageStyle, setImageStyle] = useState(image.textboxStyle?image.textboxStyle:{});
	const rightOrLeft = imageStyle.right?'r':'l';
	const setSide = (side)=>{
		if (side === 'l') {
			delete imageStyle.right;
			imageStyle.left = "0";
		}
		else if (side === 'r') {
			delete imageStyle.left;
			imageStyle.right = "0";
		}
		setImageStyle(imageStyle);
		setImage({...image, textboxStyle: imageStyle}, index);
	}

	return(<details>
		<summary>Tekst-stil</summary>
		<label>Side</label>
		<select value={rightOrLeft} onChange={(ev) => {setSide(ev.currentTarget.value)}}>
			<option value={'l'}>Venstre</option>
			<option value={'r'}>Høyre</option>
		</select>
	</details>)
}

function HeaderImageEditor({image, setImage, article}) {
	return <div style={{backgroundColor:'#dfdfdf', padding:'5px', marginBottom:'5px', display:'flex', gap:'1em'}}>
		<ImageEditor article={article} part={image} setPart={setImage} disableVariant={true}/>
	</div>
}

export default function HeaderMutlipleImageEditor({article,editArticleProperty, headerType}){
	useEffect(()=>{
		if (!article.headerImages)
			editArticleProperty('headerImages', []);
	}, []);
	const addImage = ()=>{
		editArticleProperty('headerImages', [...article.headerImages, {type:'image'}]);
	}
	const removeImage = (indexToRemove) => {
		console.log(indexToRemove);
		editArticleProperty('headerImages', article.headerImages.filter((_, index) => index !== indexToRemove));
	}



	const setImage = (newImg, index)=>{
		// headerImages is an array of objects with url and alt
		// replace headerImages[index] with newImg. Then save article.headerImages
		editArticleProperty('headerImages', article.headerImages.map((img, i) => {
			if (i === index)
				return newImg;
			else
				return img;
		}));
	}

	const toggleTypeIsVideo = (index, newType)=>{
		// replace headerImages[index] with {video: undefined}
		editArticleProperty('headerImages', article.headerImages.map((img, i) => {
			if (i === index) {
				//if img has video property, even if null or undefined
				if (newType === 'video')
					return {type: 'video', video: undefined};
				else
					return {type: 'image'};
			}else {
				return img;
			}
		}));
	}

	const onChooseVid = (videoId, index) => {
		editArticleProperty('headerImages', article.headerImages.map((img, i) => {
			if (i === index) {
				return {...img, video: videoId};
			}else {
				return img;
			}
		}));
	}
	const setDesc = (desc, index) => {
		editArticleProperty('headerImages', article.headerImages.map((img, i) => {
			if (i === index) {
				return {...img, desc: desc};
			}else {
				return img;
			}
		}));
	}

	const reOrder = (index, moveBy) => {
		const newIndex = index + moveBy;
		console.log(newIndex);

		// Check if the new index is within the bounds of the array
		if (newIndex < 0 || newIndex >= article.headerImages.length) {
			// If not, return without reordering
			return;
		}

		// Swap the positions of the images at the original index and the new index
		const updatedImages = [...article.headerImages];
		console.log(updatedImages);
		const temp = updatedImages[newIndex];
		updatedImages[newIndex] = updatedImages[index];
		updatedImages[index] = temp;

		// Update the state with the reordered images
		editArticleProperty('headerImages', updatedImages);
	}

	function HeaderVideoChooser({index}){
		const [isVideoChooserOpen, setIsVideoChooserOpen] = useState(false);
		return <><div className={'videoChooser'}>
			<div>
				{article.headerImages[index].video&&<VideoPlayer videoId={article.headerImages[index].video}/>}
				<button type={"button"} onClick={()=>{setIsVideoChooserOpen(true)}}>Velg video</button>
			</div>
			<div>
				<label>Bildetekst:</label>
				<textarea placeholder={"Tekst"} defaultValue={article.headerImages[index].desc} onBlur={(ev)=>{
					setDesc(ev.target.value, index)}}>
					</textarea>
			</div>
		</div>
			{isVideoChooserOpen&& <OnTopBox setOnTopEnabled={setIsVideoChooserOpen} onTopEnabled={isVideoChooserOpen}>
				<VideosEdit chooseVideo={(videoId)=> {onChooseVid(videoId, index)}}/>
			</OnTopBox>}
		</>}

	return <div><h3>Header bilder</h3>
		<div>
			<div>Feil: "Fjern" knappen fjerner alltid siste bilde.Flytt opp/ned oppdaterer ikke.</div>
			{(article.headerImages&&article.headerImages.length>0)&& article.headerImages.map((image, i2) => {
				return <div key={i2} style={{marginTop:'2em'}}>
					<span>{i2+1}.</span>
					<button type={"button"} onClick={()=>{removeImage(i2)}}>Fjern</button>
					<button type={"button"} onClick={()=>{reOrder(i2, -1)}}>Flytt opp</button>
					<button type={"button"} onClick={()=>{reOrder(i2, +1)}}>Flytt ned</button>
					{headerType==2 &&<select onChange={(ev) => {
						toggleTypeIsVideo(i2, ev.currentTarget.value)
					}} defaultValue={image.type}>
						<option value="image">Bilde</option>
						<option value="video">Video</option>
					</select>}

					{image.type=='video'? <HeaderVideoChooser index={i2}/> :
						<HeaderImageEditor key={i2} article={article} image={article.headerImages[i2]} setImage={(newImg) => {
							setImage(newImg, i2)
						}}/>}
					{headerType==2&&<HeaderTextboxStyleEditor image={image} setImage={setImage} index={i2}/>}
				</div>
			})}
			<br/>
			<button type={"button"} onClick={addImage}>Legg til nytt bilde</button>
		</div>
	</div>
}