export default function PrivacyPolicy(){
	return <div className="centered">
		<h1>Personværnerklæring</h1>

		<p><strong>Båtens Verden tar ditt personvern på alvor, og her kan du lese mer om hvordan vi forholder oss til
			GDPR-regelverket og ditt personvern.</strong></p>


		<p>VB Media AS og Båtens Verden vil sørge for at vi bruker opplysningene vi har om deg i tråd med EU-direktivet
			GDPR. Dette kan være navn, bosted, telefon, epostadresse og kjøpshistorikk.</p>


		<p><strong>Behandlingsansvarlig</strong><br/>VB Media AS er behandlingsansvarlig for personopplysningene dine.
		</p>


		<p><strong>Hvordan vi benytter dataen</strong><br/>VB Media bruker personopplysninger
			til:<br/>–&nbsp;&nbsp; &nbsp;Utsendelse av Båtens Verden iht.
			abonnementsbestilling<br/>–&nbsp;&nbsp; &nbsp;Utsendelse av nyhetsbrev iht. påmelding. Tjenesten er gratis,
			men vi gjør oppmerksom på at nyhetsbrevet kan inneholde annonser fra våre annonsører.</p>


		<p><strong>Hvilke opplysninger vi har</strong><br/>I forbindelse med at du tegner abonnement på Båtens Verden
			eller melder deg på vårt nyhetsbrev, mottar vi opplysninger om deg. Dette dreier seg om navn, adresse,
			telefonnr. og epostadresse. Videre får vi kjøpshistorikk hvis du handler fra oss.<br/>Ved
			nyhetsbrev-påmelding mottar vi kun ditt navn og epostadresse.</p>


		<p><strong>Tredjeparter</strong><br/>Vi benytter seriøse leverandører i forbindelse med produksjon av Båtens
			Verden, og til å sende ut nyhetsbrev, fakturaer og liknende – men oversikten over hvilke er under
			kontinuerlig endring og forbedring. En komplett liste kan du få ved henvendelse til vår kundeservice.</p>


		<p><strong>Rett til å vite opplysninger</strong><br/>Ifølge personvernloven (GDPR) har du rett til å få vite
			hvilke opplysninger vi har registrert om deg. Hvis disse opplysningene er feil, irrelevante eller
			ufullstendige, kan du be om at informasjonen endres eller slettes.</p>

	</div>
}