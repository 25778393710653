import {Link} from "react-router-dom";
import classes from "./BatpermBoatCardFullHarbor.module.scss";
import SiteService from "../../services/SiteService";
import shipIcon from "../../images/icons/ship.png";

export default function BatpermBoatCardFullHarbor({boat}){
	return(

		<Link className={classes.batpermBoatCard} to={`/batperm/bat/${boat._id}`}>
			<div className={classes.boatImageContainer}>
				{boat.imageName ? <img src={`${SiteService.apiPath}/image/batperm-boat/${boat._id}/${boat.imageName}`}/>
					:<img style={{objectFit:'contain'}} src={shipIcon}/>
				}
			</div>
			<div className={classes.boatSecondContainer}>
				<div className={classes.textContainer}>
					<div className={classes.boatName}>
						{boat.modelName && boat.modelName}
					</div>
					<div>
						{boat.name && '«' + boat.name + '»'}
					</div>
					<div>
						L/B: {(boat.lengthMeters?boat.lengthMeters+'m':'?') + ' / ' +
						(boat.widthMeters?boat.widthMeters+'m':'?')}
					</div>
					<div>Eier: {boat.ownerId?boat.ownerId.name: <em>Havn</em>}</div>
					{/*<div className={classes.boatLink} to={`/batperm/bat/${boat._id}`}>Besøk</div>*/}
				</div>
			</div>
			<div className={classes.cardOverlayText}>Åpne</div>
		</Link>

	)}