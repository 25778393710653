import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = (props) => {
	const location = useLocation();
	const prevLocation = useRef(location);

	useEffect(() => {
		// Check if the pathname changed
		if (prevLocation.current.pathname !== location.pathname) {
			window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
		}
		// Update the previous location ref
		prevLocation.current = location;

		getSetBackgroundColorOfFooterWave();
	}, [location]);

	const getSetBackgroundColorOfFooterWave = ()=>{try {
		let newColor = "";
		let mainEl = document.getElementsByTagName('main')[0];
		newColor = mainEl.style.background;
		if (mainEl.childNodes[0].style.background)
			newColor = mainEl.childNodes[0].style.background;
		else if (mainEl.style.background)
			newColor = mainEl.childNodes[0].style.background;
		document.getElementsByClassName('footerWave')[0].style.background = newColor;
	}catch (e) {
	}}

	return <>{props.children}</>;
};

export default ScrollToTop;
