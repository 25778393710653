import {useNavigate, useParams} from "react-router-dom";
import {useRef, useState} from "react";
import SiteService from "../services/SiteService";
import styles from "../scss/styles.module.scss";

export default function FornyPassord(){
	const navigate = useNavigate();
	let {token} = useParams();

	const passwordInput = useRef('');
	const passwordRepeatInput = useRef('');

	const [password, setPassword] = useState('');
	const [passwordRepeat, setPasswordRepeat] = useState('');

	const [errorMessage, setErrorMessage] = useState(false);

	const [passwordTestResulsts, setPasswordTestResults] = useState([]);
	const testPassword = function (password){
		let tmpPwResults = [];

		let regex1 = /^[!"#$%&'()*+,-.:;<=>?@\\[\]\/^_`{|}~ 0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz]+$/i;
		let regex2 = /^(?=.*\d)/i;
		let regex3 = new RegExp("[A-Z]");

		if (password.length < 8){
			tmpPwResults = tmpPwResults.concat(<div><span style={{color:'red'}}>Passord må være minst 8 tegn</span></div>)
		}else if (password.length > 34) {
			tmpPwResults = tmpPwResults.concat(<div><span
				style={{color: 'red'}}>Passord kan ikke være mer enn 128 tegn</span></div>)
		}else if(!regex1.test(password)) {
			tmpPwResults = tmpPwResults.concat(<div><span style={{color:'red'}}>{`Passord kan kun inneholde tall, bokstaver fra A-Z, og spesialtegnene: !"#$%&'()*+,-.:;<=>?@[]/\^_\`{|}~`}</span></div>)
		}else if(!regex2.test(password)) {
			tmpPwResults =  tmpPwResults.concat(<div><span style={{color:'red'}}>Minst ett tall</span></div>)
		}
		else if(!regex3.test(password)) {
			tmpPwResults =  tmpPwResults.concat(<div><span style={{color:'red'}}>Minst èn stor bokstav</span></div>)
		}
		else if(password !== passwordRepeatInput.current.value){
			tmpPwResults = tmpPwResults.concat(<div><span style={{color:'red'}}>Passordene er ikke like</span></div>)
		}


		if(tmpPwResults.length === 0) {
			setPasswordTestResults(<div><span style={{color: 'limegreen'}}>OK</span></div>);
			return true;
		}
		else {
			setPasswordTestResults(tmpPwResults);
			return false;
		}
	};

	const resetPass = async (pass) => {
		if (!testPassword(password)) {
			return;
		}
		try {
			const response = await fetch(`${SiteService.apiPath}/user/passreset`, {
				method: "POST",
				body: JSON.stringify({
					pass: pass,
					token: token
				}),
				headers: {
					'Content-Type': 'application/json'
				}
			});
			const data = await response.json();
			if (data.success) {
				return navigate('/login');
			} else if (data.error.reason) {
				setErrorMessage(data.error.reason);
			} else {
				setErrorMessage("Ukjent feil");
			}
		} catch (error) {
			console.error(error);
			setErrorMessage("Ukjent feil");
		}
	}

	return(<>
		<section className={[styles.centered, styles.loginBox].join(' ')}>
			<h1>Forny Passord</h1>
			<form onSubmit={(ev)=>{ev.preventDefault(); resetPass(password)}}>
				<p>Skriv inn nytt passord</p>
				<input type="password" placeholder={"Nytt passord"} value={password} onChange={e => {
					testPassword(e.target.value);
					setPassword(e.target.value);
				}} ref={passwordInput} required={true}/>

				{passwordTestResulsts && <div style={{color:"red"}}>{passwordTestResulsts}</div>}

				<input type="password" placeholder={"Gjenta nytt passord"} value={passwordRepeat} onChange={e => {
					testPassword(passwordInput.current.value);
					setPasswordRepeat(e.target.value);
				}} ref={passwordRepeatInput} required={true}/>


				<br/><button className={'buttonDull2'} type="submit">Send </button>
				{errorMessage && <div style={{color:"red"}}>{errorMessage}</div>}
			</form>
		</section>
	</>)
}