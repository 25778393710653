import {useContext, useRef, useState} from "react";
import UserContext from "../../store/userContext";
import styles from "../../scss/styles.module.scss";
import adminEditClasses from "../adminEdit/AdminEdit.module.scss";
import animations from "../../scss/animations.scss";
import editableIcon from "../../images/icons/editable.svg"

export default function UserCard({isEditable}){
	const userContext = useContext(UserContext);

	const [isNameEditable, setIsNameEditable] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const nameRef = useRef(userContext.name);
	console.log(userContext.name);

	async function changeName(){try {
		setIsLoading(true);
		await userContext.changeName(nameRef.current.value);
		setIsNameEditable(false);
		setIsLoading(false);
	}catch (e) {
		setIsLoading(false);
	}}

return(<div className={[styles.userCard, isLoading?animations.animationShimmerWhite:''].join(' ')}>
	<form id={'userCardNameForm'} onSubmit={(ev)=>{ev.preventDefault();changeName()}}>
		{isEditable && <img alt={'rediger'} className={adminEditClasses.editToggleButton} src={editableIcon}
							onClick={()=>{setIsNameEditable(!isNameEditable)}}
		/>}
		{!isNameEditable ?
			<div className={styles.userCardName}>{userContext.name}</div>
				:
			<input disabled={isLoading} ref={nameRef} required={true} minLength={3} maxLength={50} type={"text"} defaultValue={userContext.name} className={styles.userCardName}/>
		}
		{isNameEditable &&
			<button form={'userCardNameForm'} className={styles.editToggleButton}
			   style={{backgroundColor: '#dffbc3', border: '3px solid black'}}
			   type={"submit"}
			>
				<span>💾</span>
			</button>
		}
	</form>



	<div className={styles.userCardEmail}>{userContext.email}</div>
</div>)}