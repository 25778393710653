import {useContext, useRef, useState} from "react";
import UserContext from "../../../store/userContext";
import {Link} from "react-router-dom";

import styles from "../../../scss/styles.module.scss";
import LoginGoogle from "./LoginGoogle";
import ClickToOpen from "../ClickToOpen";
import LoginFacebook from "./LoginFacebook";
import MailIcon from "../../../svg/MailIcon";


function Loginbox({propUsername, propMessage, sendToRefreshBool}){
	if(!propUsername)
		propUsername = "";

	const [username, setUsername] = useState(propUsername);
	const [password, setPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	const [isLoading, setIsLoading] = useState(false);
	const [isSuccessful, setIsSuccesful] = useState(false);

	const usernameInput = useRef();
	const passwordInput = useRef();

	const user = useContext(UserContext);

	function loginUser(event){
		event.preventDefault();
		setIsLoading(true);
		user.login(usernameInput.current.value, passwordInput.current.value, (success, resObj)=>{
			setIsLoading(false);

			if (!success && resObj?.message)
				return setErrorMessage(resObj.message);
			if(!success)
				return setErrorMessage('Feil brukernavn eller passord');
			if (success)
				setIsSuccesful(true);

			if(sendToRefreshBool)
				window.location = window.location;
		});
	}

	return(
		<section className={[styles.centered, styles.loginBox].join(' ')}>
			<h1>Logg inn</h1>
			{propMessage && <div style={{marginBottom: '10px'}}>{propMessage}</div>}
			<ClickToOpen buttonText={<><MailIcon/>Logg inn med E-post</>} buttonClassName={'button1 centered'}>
				<form onSubmit={loginUser}>
					<input type="email" placeholder={"E-post adresse"} value={username}
						   onChange={e => setUsername(e.target.value)} ref={usernameInput} required={true}
						   disabled={isLoading}></input>
					<br/><

					input type="password" placeholder={"Passord"} value={password}
						  onChange={e => setPassword(e.target.value)} ref={passwordInput} required={true}
						  disabled={isLoading}/>
					{errorMessage && <div style={{color: "red"}}>{errorMessage}</div>}
					<br/>

					<button type="submit" disabled={isLoading}
							className={['buttonDull2', isLoading ? 'animationShimmerBlack' : '', isSuccessful ? 'animationSuccess animationShimmerOnce' : ''].join(' ')
							}>Logg inn
					</button>
				</form>
			</ClickToOpen>
			<LoginFacebook/>
			<LoginGoogle/>
			<hr/>
			<Link to={'/glemtpassord'}
				  style={{margin: '4px auto', display: 'inline-block'}}
			>Glemt Passord</Link>
			<div style={{marginTop: '10px', fontSize: "1.2em"}}><Link to={"/registrer"}>Opprett bruker</Link></div>
		</section>
	)
}

export default Loginbox;