import ToggleSwitch from "../ui/ToggleSwitch/ToggleSwitch";
import SiteService from "../../services/SiteService";
import classes from "../../pages/min_side/MinSide.module.scss";
import {useContext, useEffect, useState} from "react";
import UserContext from "../../store/userContext";

export default function AbonnemangInfo(){
	const userContext = useContext(UserContext);
	const [subscriptionInfo, setSubscriptionInfo] = useState({});
	const [subscriptionRenew, setSubscriptionRenew] = useState(subscriptionInfo.renewSubscription);

	useEffect(() => {
		async function fetchSubInfo() {
			let subInfo = await userContext.getSubscriptionInfo();
			setSubscriptionInfo(subInfo);
			setSubscriptionRenew(subInfo.renewSubscription);
		}
		fetchSubInfo();
	}, [])

	function toggleSubscribe(cb){
		if(subscriptionRenew)
			unsubscribe(cb);
		else
			resubscribe(cb);
	}

	async function  unsubscribe(cb) {try{
		let res = await fetch(SiteService.apiPath + '/user/unsubscribe', {
			method: 'POST'
		});

		if(res.ok)
			setSubscriptionRenew(false);
	}catch (e) {
		console.error(e);
		cb();
		alert('Feil under avslutting av abonnemang');
	}}
	async function  resubscribe(cb) {try{
		let res = await fetch(SiteService.apiPath + '/user/resubscribe', {
			method: 'POST'
		});

		if(res.ok)
			setSubscriptionRenew(true);
	}catch (e) {
		console.error(e);
		cb();
		alert('Feil under avslutting av abonnemang');
	}}

	return(<>
	<div><b>Aktiv: </b>{subscriptionInfo.subscribed ? <>✔️</> : <>❌</>}</div>
	{subscriptionInfo.subscribed && <div>
		<div><b>Forny ved utløp: </b>{subscriptionRenew ? <>✔️</> : <>❌</>}
			<ToggleSwitch isToggled={subscriptionRenew} onToggle={toggleSubscribe} setIsToggled={setSubscriptionRenew}></ToggleSwitch></div>
		<div><b>Utløper: </b>{subscriptionInfo.subscriptionExpireDate.substring(0,10)}</div>
	</div>}
	<form method="POST" action={`${SiteService.apiPath}/create-customer-portal-session`}>
		<button className={classes.link1} type="submit">Administrer betalinger</button>
	</form>
</>)}