import {useEffect} from "react";
import {CrossButton} from "../../ui/CrossButton";

/*
*	headerParts = [{text: ''}]
*
* */

export default function HeaderMutlitpleTextBoxesEditor({article, editArticleProperty}) {
	if (!article)return null;
	useEffect(()=>{
		if (!article.headerParts)
			editArticleProperty('headerParts', []);
	}, []);


	const setCardProperty = (propertyName, property, index) => {
		editArticleProperty('headerParts', article.headerParts.map((item, i) => {
			if (i === index) {
				return {...item, [propertyName]: property};
			}else {
				return item;
			}
		}));
	}

	const removeCard = (index)=>{
		editArticleProperty('headerParts', article.headerParts.filter((item, i) => i!== index));
	}

	return <div>
		{article.headerParts&&article.headerParts.map((card, i) => {
			return <div key={i}>
				<h4>Kort {i+1}<CrossButton onclick={()=>removeCard(i)}/></h4>
				<textarea defaultValue={card.text} onBlur={e => setCardProperty('text', e.target.value, i)}/>
			</div>
		})}
		<button type={"button"} onClick={() => editArticleProperty('headerParts', article.headerParts.concat({text: ''}))}>Nytt tekstkort</button>
	</div>
}