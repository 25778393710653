import {useContext, useEffect, useState} from "react";
import FrontpageService from "../services/FrontpageService";
import SiteContext from "../store/siteContext";
import ContentBlock1 from "../components/frontpage/content-blocks/ContentBlock1";
import DividerLine from "../components/frontpage/content-blocks/DividerLine";
import ContentBlockWrapper from "../components/frontpage/ContentBlockWrapper";
import AdvertBlock6 from "../components/frontpage/content-blocks/AdvertBlock6";

export default function Frontpage({propFrontpage}){
	const siteContext = useContext(SiteContext);
	const categories = useContext(SiteContext).categories;
	const frontpage = propFrontpage ? propFrontpage : siteContext.frontpageContent;

	document.title = "Båtens Verden";

	if (!frontpage)
		return <div inert="true" className={'frontpage isLoading animationShine'} style={{userSelect:'none',pointerEvents:'none',opacity:'40%'}}>
			<DividerLine customStyle={{color: 'rgb(255, 255, 255)',paddingTop: '13px',paddingBottom: '13px'}}/>
			<AdvertBlock6/>
			<DividerLine customStyle={{color: 'rgb(255, 255, 255)',paddingTop: '13px',paddingBottom: '13px'}}/>
			<section>
				<ContentBlockWrapper><div className={'advertBlock6 contentBlock'}>
					<div className={'advertBlock6Image'} style={{background:'#d3d3d3',aspectRatio: '7.29/1'}}></div>
				</div></ContentBlockWrapper>
			</section>
			<DividerLine customStyle={{color: 'rgb(255, 255, 255)',paddingTop: '13px',paddingBottom: '13px'}}/>
			<section>
				<ContentBlock1 customStyle={{color:'#d3d3d3',wordBreak:'break-word'}} parts={[{name:'───────────────'},{name:'───────────────'},{name:'──────────────────────────────'}]}/>
			</section>
		</div>

	return	<div className={'frontpage'}>
		{FrontpageService.FrontpageRender({frontpageModules: frontpage.modules, isLive: true, categories:categories})}
	</div>
}