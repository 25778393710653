import {useContext, useEffect, useRef, useState} from "react";
import Checkbox from "../../ui/Checkbox";
import {DuplicateButton} from "../../ui/DuplicateButton";
import siteContext from "../../../store/siteContext";

export default function BatsokBoatsEditor({part, setPart}){
	const SiteContext = useContext(siteContext);
	const [isLoadingCats, setIsLoadingCats] = useState(false);

	const [sectionTitle, setSectionTitle] = useState(part.sectionTitle?part.sectionTitle:'');
	const [link, setLink] = useState(part.link?part.link:'');
	const [slidesPerView, setSlidesPerView] = useState(part.slidesPerView?part.slidesPerView:'');


	useEffect(() => {
		if (!part.parts)
			part.parts = [];

		if (!SiteContext.batsokCategories || !SiteContext.batsokCategories.length)
			loadCats();
		if (!SiteContext.batsokTags || !SiteContext.batsokTags.length){
			loadTags();
		}
	}, []);


	const loadCats = async () => {
		setIsLoadingCats(true);
		await SiteContext.fetchBatsokCategories();
		setIsLoadingCats(false);
	}
	const loadTags = async () => {
		setIsLoadingCats(true);
		await SiteContext.fetchBatsokTags();
		setIsLoadingCats(false);
	}

	const addPart = ()=>{
		// add a part to part.parts
		setPart(prevPart => ({
			...prevPart,
			parts: [...prevPart.parts, {id: null, subType:'category'}]
		}));
	}

	const changeProperty = (propertyName, value)=>{
		setPart(prevPart => ({...prevPart, [propertyName]: value}));
	}

	const changePartProperty = (propertyName, value, index)=>{
		part.parts[index][propertyName] = value;
		setPart(prevPart => ({...prevPart}));
	}


	const BatsokPartEdit = ({batsokPart, changePartProperty, index})=>{
		const idRef = useRef(part.id);
		const subTypeRef = useRef(part.subType);

		const [random, setRandom] = useState(part.random?part.random:false);

		useEffect(() => {
			if (!SiteContext.batsokCategories || !SiteContext.batsokCategories.length)
				loadCats();
			if (!SiteContext.batsokTags || !SiteContext.batsokTags.length){
				loadTags();
			}
		}, []);


		const removePart = (index)=>{
			setPart(prevPart => ({...prevPart, parts: [...prevPart.parts.filter((article, i) => i!== index)]}));
		}

		const duplicatePart = (index)=>{
			setPart(prevPart => ({...prevPart, parts: [...prevPart.parts, {...part.parts[index]}]}));
		}

		return <div>
			<select value={batsokPart.subType} ref={subTypeRef} onChange={(ev) => changePartProperty('subType', ev.currentTarget.value, index)}>
				<option value={'category'}>Kategori</option>
				<option value={'tag'}>Stikkord</option>
			</select>

			<select defaultValue={batsokPart.id} ref={idRef} onChange={(ev) => changePartProperty('id', ev.currentTarget.value, index)}>
				<option defaultValue={null}>{isLoadingCats?'Laster...':'Ingen'}</option>
				{(batsokPart.subType=='category'&&SiteContext.batsokCategories)&&SiteContext.batsokCategories.map((tag) => <option key={tag._id} value={tag._id}>{tag.name}</option>)}
				{(batsokPart.subType=='tag'&&SiteContext.batsokTags)&&SiteContext.batsokTags.map((tag) => <option key={tag._id} value={tag._id}>{tag.name}</option>)}
			</select>
			<Checkbox state={random}  setState={setRandom} changeFunc={(bool)=>batsokPart.random = bool} label="Tilfeldig" />

			<button className="frontpageEdit-chosenModulesList-removeButton" onClick={() => removePart(index)}>×</button>
			<button type={"button"} onClick={()=>duplicatePart(index)}><DuplicateButton/></button>
		</div>
	}

	return (<div>
		<div>
			<div><label>Tittel</label>
			<input defaultValue={sectionTitle} onChange={(ev) => {
				setSectionTitle(ev.currentTarget.value);
				part.sectionTitle = ev.currentTarget.value;}}
			/></div>
			<div><label>Lenke</label>
			<input defaultValue={link} onChange={(ev) => {
				setLink(ev.currentTarget.value);
				part.link = ev.currentTarget.value;}}
			/></div>
			<div><label>Antall synlige deler</label>
			<input type={"number"} defaultValue={slidesPerView} onChange={(ev) => {
				setSlidesPerView(ev.currentTarget.value);
				part.slidesPerView = ev.currentTarget.value;}}
			/></div>
		</div>
		<button onClick={addPart}>Legg til båt</button>
		{part.parts && part.parts.map((part, index) => <BatsokPartEdit batsokPart={part} changePartProperty={changePartProperty} index={index} key={index}/>)}
	</div>)
}