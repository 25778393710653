import classes from "../batperm.module.scss";
import BatpermBoats from "./Batperm-boats";
import {useContext, useState} from "react";
import UserContext from "../../store/userContext";
import BatpermContext from "../BatpermContext";
import BatpermHarborCardFull from "../components/BatpermHarborCardFull";
import waveIcon from "../../images/icons/wave.png";

export default function BatpermHome (){
	const [menuIsBoats, setMenuIsBoats] = useState(true);
	const userContext = useContext(UserContext);
	const batpermContext = useContext(BatpermContext);

	function BatpermMyHarbors(){
		return(<div className={classes.myHarborsPage}>
			<h2>Velg havn</h2>

			<img src={waveIcon}/>
			{
				batpermContext.harbors.map((harbor, i) => {
					if (userContext.adminHarborIds.includes(harbor._id)) {
						return	<BatpermHarborCardFull harbor={harbor} key={harbor._id}/>
					}
				})
			}
		</div>)
	}


return(<div className={classes.homePage}>

	{userContext.adminHarborIds.length>0 && <nav className={classes.homePageNav}>
		<button onClick={() => setMenuIsBoats(true)}
				className={!menuIsBoats? classes.homePageNavActiveButton:''}
		>Mine Båter</button>
		<button onClick={() => setMenuIsBoats(false)}
				className={menuIsBoats? classes.homePageNavActiveButton:''}
		>Mine Havner</button>
	</nav>
	}

	{menuIsBoats ?	<BatpermBoats/>
		:
		<BatpermMyHarbors/>
	}
</div>)}