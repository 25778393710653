import React, { useState, useEffect } from 'react';
import SiteService from "../../../services/SiteService";
import fbLogo from "../../../images/external/fb-logo.svg";

export default function LoginFacebook() {
	const [loadingStatus, setLoadingStatus] = useState('Laster Facebook...');

	useEffect(() => {
		// Load the Facebook SDK script
		const script = document.createElement('script');
		script.src = 'https://connect.facebook.net/en_US/sdk.js';
		script.async = true;
		script.defer = true;
		script.crossOrigin = "anonymous";

		script.onload = () => {
			// Initialize the Facebook SDK
			window.FB.init({
				appId: process.env.REACT_APP_FACEBOOK_CLIENT_ID, // Replace with your Facebook App ID
				cookie: false,
				xfbml: true,
				version: 'v20.0'
			});
			// Attach the Facebook login button
			const button = document.getElementById('facebook-login-button');
			if (button)
				button.addEventListener('click', handleFacebookLogin);
			setLoadingStatus('Fortsett med Facebook');
		};

		script.onerror = () => {
			setLoadingStatus('Feil under lasting av Facebook');
		};

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	const handleFacebookLogin = () => {
		// Login with Facebook and handle the response
		window.FB.login(
			response => {
				if (response.authResponse) {
					const { accessToken } = response.authResponse;
					console.log(accessToken)

					// Send the token to your server for validation and user login
					fetch(`${SiteService.apiPath}/auth/facebook`, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json'
						},
						body: JSON.stringify({ token: accessToken })
					})
						.then(response => response.json())
						.then(data => {
							if (data.success) {
								window.location = '/min-side';
							}
							// Handle the response from your server
							console.log('Server response:', data);
							// You can also extract user info like name and email from data if provided by your server
						})
						.catch(error => {
							console.error('Error during Facebook login:', error);
							alert('Feil under Facebook login');
						});
				} else {
					console.error('User cancelled the login or did not fully authorize.');
				}
			},
			{ scope: 'email' }
		);
	};

	return (
		<div>
			<button id="facebook-login-button" className={'centered button1'} disabled={loadingStatus !== 'Fortsett med Facebook'}>
				<img style={{height:'35px',margin:'-5px 5px -5px 0'}} src={fbLogo}/>{loadingStatus}
			</button>
		</div>
	);
}
