import {useState} from "react";
import SiteService from "../../services/SiteService";
import Helpers from "../../helpers/Helpers";
import classes from "../batperm.module.scss";
import ProgressUploader from "./ProgressUploader";

// resourceName should be plural
export default function AttachmentMultiUploadShower({selectedItem, boat, resourceName, maxAttachments}){
	const [attachments, setAttachments] = useState(selectedItem.attachments);
	if (!attachments) setAttachments([]);

	const postUrl = `${SiteService.apiPath}/batperm/boat/${boat._id}/${resourceName}/${selectedItem._id}/attachments`;
	const getUrl = `${SiteService.apiPath}/batperm/boat/${boat._id}/attachments`;

	const deleteEquipmentAttachment = async (attachmentId) => {
		if (!confirm('Er du sikker på at du vil slette vedlegget?')) return;
		let res = await fetch(`${SiteService.apiPath}/batperm/boat/${boat._id}/${resourceName}/${selectedItem._id}/attachments/${attachmentId}`, {
			method: 'DELETE'
		});
		if (res.ok) {
			setAttachments(attachments.filter(item => item._id !== attachmentId));
		} else {
			alert('Feil under sletting av vedlegg');
		}
	}

	const onAttachmentUploadFinish = (json)=>{
		setAttachments([...attachments, json.attachment]);

	};

	const acceptedImageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg'];

	const onAttachmentClick = (ev,attachment)=>{
		ev.preventDefault();
		Helpers.downloadWithDifferentName(getUrl+'/'+attachment._id, attachment.fileName+attachment.fileExtension);
	}

	return (<div className={classes.equipmentAttachments}>
		{(!maxAttachments || (attachments&& attachments.length<maxAttachments)) ? <ProgressUploader maxMegabytes={5} postUrl={postUrl} onSuccess={onAttachmentUploadFinish}/>
			:
			<div style={{width:'100%'}}>Max {maxAttachments} vedlegg. Slett et for å legge til flere.</div>
		}

		{attachments&&attachments.map((attachment, index) => {
			let attachmentId = attachment._id;
			let displayFilename = attachment.fileName+'.'+attachment.fileExtension;
			if (displayFilename.length > 30)
				displayFilename = displayFilename.substring(0, 15) + "..." + displayFilename.substring(displayFilename.length - 15);
			return (
				<div className={classes.equipmentAttachmentContainer} key={index}>
					<button className={classes.deleteFileAttachmentButton} onClick={(ev)=>{
						ev.preventDefault();
						deleteEquipmentAttachment(attachmentId);
					}}><span>×</span>
					</button>
					<a href={`${getUrl}/${attachmentId}/${attachment.fileName}.${attachment.fileExtension}`} target="_blank">
						{acceptedImageExtensions.includes(attachment.fileExtension) ?
							<img src={`${getUrl}/${attachmentId}/${attachment.fileName}.${attachment.fileExtension}`}/>
							:
							<img/>
						}

						<div className={classes.equipmentAttachmentFilename}>
							{displayFilename}
						</div>
					</a>
				</div>
			)})}
	</div>)}