import {memo, useContext, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import SiteService from "../../services/SiteService";
import classes from "../batperm.module.scss";
import SelectManyFromList from "../../components/ui/functional/SelectManyFromList";
import B64ImageUploader from "../../components/ui/functional/B64ImageUploader/B64ImageUploader";
import UserContext from "../../store/userContext";
import Loginbox from "../../components/ui/functional/Loginbox";
import BatpermLaunchingSubmenu from "../components/BatpermLaunchingSubmenu";
import BatpermEquipmentSubmenu from "../components/BatpermEquipmentSubmenu";
import BatpermServiceSubmenu from "../components/BatpermServiceSubmenu";
import BatpermTodoSubmenu from "../components/BatpermTodoSubmenu";
import BatpermContext from "../BatpermContext";
import MainMenuLink from "../components/MainMenuLink";
import ToggleSwitch from "../../components/ui/ToggleSwitch/ToggleSwitch";
import shipIcon from "../../images/icons/ship.png";
import pencilIcon from "../../images/icons/pencil.png";
import waveIcon from "../../images/icons/wave.png";

export default memo(function BatpermBoat({boatIdProp, fromHarbor, isNew}){
	const navigate = useNavigate();
	const params = useParams();

	const userContext = useContext(UserContext);
	const batpermContext = useContext(BatpermContext);

	const [selectedSubMenu, setSelectedSubMenu] = useState('fakta');

	useEffect(() => {
		intitializePage();
	}, []);

	useEffect(()=>{
		if(params.submenu)
			setSelectedSubMenu(params.submenu);
	}, [params.submenu])

	const intitializePage = async () => {
		if (params.boatId) boatIdProp = params.boatId;

		if (!boat.ownerId) {
			boat._harborOwned = true;
			setBoat(boat);
		}

		if (boatIdProp || params.boatId)
			await getBoat();

		await getHarbors();


		setIsLoading(false);
	};

	const [boat, setBoat] = useState({});
	const [harbors, setHarbors] = useState([]);

	const [selectedHarborIds, setSelectedHarborIds] = useState(null);

	const [isLoading, setIsLoading] = useState(true);
	const [isHarborsLoading, setIsHarborsLoading] = useState(true);

	const [imageB64, setImageB64] = useState(null);
	const [imageName, setImageName] = useState(null);

	const [editAble, setEditAble] = useState(false);

	let currentYear = new Date().getFullYear();

	useEffect(() => {
		if(boat)
		batpermContext.setAdditionalNavElements(submenuEntries.current.map((item) => {
			if(item.renderCondition === undefined || item.renderCondition(boat)===true)
				return <MainMenuLink key={item.value} label={item.label}
							 onClick={() => {setSelectedSubMenu(item.value);}}
							 to={'bat/'+boat._id+'/'+item.value}
				/>
		}))
	}, [boat]);

	let [boatRefObj, setBoatRefObj] = useState(
		{
			name: useRef(),
			modelName: useRef(),
			productionYear: useRef(),
			lengthMeters: useRef(),
			widthMeters: useRef(),
			depthMeters: useRef(),
			weightKilograms: useRef(),
			motorName: useRef(),
			motorProductionYear: useRef(),

			isContactPublic: useRef(),
			contactPhoneNumber: useRef(),
			contactEmail: useRef(),
			contactName: useRef(),
		}
	);

	const boatFormObj= {
		info: [{
			label: 'Båtmerke og modell',
			propertyName: 'modelName',
			type: 'text',
			maxLength: 99,
			minLength: 1,
			required: true,
		}, {
			label: 'Navn',
			propertyName: 'name',
			type: 'text',
			maxLength: 99,
			minLength: 1,
		}, {
			label: 'Produksjons-år',
			propertyName: 'productionYear',
			type: 'number',
			max: currentYear,
			min: 1,
		}, {
			label: 'Dimensjoner',
			type: 'h3'
		}, {
			label: 'Lengde (meter)',
			propertyName: 'lengthMeters',
			type: 'number',
			max: 100000,
			min: 0,
		}, {
			label: 'Bredde (meter)',
			propertyName: 'widthMeters',
			type: 'number',
			max: 100000,
			min: 0,
		}, {
			label: 'Dybde (meter)',
			propertyName: 'depthMeters',
			type: 'number',
			max: 100000,
			min: 0,
		}, {
			label: 'Vekt (kilogram)',
			propertyName: 'weightKilograms',
			type: 'number',
			max: 100000,
			min: 0,
		}, {
			label: 'Motor',
			type: 'h3',
		}, {
			label: 'Motormerke og type',
			propertyName: 'motorName',
			type: 'text',
			maxLength: 99,
			minLength: 1,
		}, {
			label: 'Produksjons-år',
			propertyName: 'motorProductionYear',
			type: 'number',
			max: currentYear,
			min: 1,
		}],

		contact: [{
			label: 'Vis kontaktinfo i QR-kode',
			propertyName: 'isContactPublic',
			type: 'checkbox',
		},
		{
			label: 'Telefon',
			propertyName: 'contactPhoneNumber',
			type: 'text',
			maxLength: 99,
			minLength: 1,
		},
		{
			label: 'E-post',
			propertyName: 'contactEmail',
			type: 'text',
			maxLength: 99,
			minLength: 1,
		},
		{
			label: 'Navn',
			propertyName: 'contactName',
			type: 'text',
			maxLength: 99,
			minLength: 1,
		}],
	};
	function infoValidateFunction() {
		if (boatRefObj.name.current.value.length < 1
			&& boatRefObj.modelName.current.value.length < 1
		)
			alert('Båten må ha Navn og/eller Båtmerke/modell');
		return false;
	}

	const getBoat = async () => {
		const response = await fetch(`${SiteService.apiPath}/batperm/boat/${boatIdProp}`);
		if (!response.ok){
			alert('Båt ikke funnet');
			setBoat(null);
			return setIsLoading(false);
		}

		const data = await response.json();
		setBoat(data);
		if (data._editable)
			setEditAble(true);

		setSelectedHarborIds(data.batpermHarborIds);
		setIsLoading(false);
	};

	const getHarbors = async () => {
		setIsHarborsLoading(true);

		const response = await fetch(`${SiteService.apiPath}/batperm/harbors`);
		const data = await response.json();
		setHarbors(data);
		setIsHarborsLoading(false);
	};


	const saveBoat = async () => {
		setIsLoading(true);

		const sendBoatObj = boat;
		sendBoatObj.batpermHarborIds = selectedHarborIds;

		const sendObj = {boat: sendBoatObj};
		if (imageB64) {
			sendBoatObj.imageName = imageName;
			sendObj.imageB64 = imageB64;
		}


		let urlBoatIdParam = "";
		if (boat._id) urlBoatIdParam = boat._id;


		let fetchPath = `${SiteService.apiPath}/batperm/boat/${urlBoatIdParam}`;
		if (fromHarbor) fetchPath = `${SiteService.apiPath}/batperm/harbor/${params.harborId}/new-boat`;
		const response = await fetch(fetchPath, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(sendObj)
		});

		if(response.ok) {
			const data = await response.json();
			data._editable = true;
			setBoat(data)
			setEditAble(true);
			navigate('/batperm/bat/' + data._id)
		}else {
			alert('Noe gikk galt under lagring av båt')
		}
		setIsLoading(false);
	};


	const deleteBoat = async () => {
		if (!confirm('Slett?')) return;
		setIsLoading(true);
		const response = await fetch(`${SiteService.apiPath}/batperm/boat/${boat._id}`, {
			method: 'DELETE'
		});
		if(response.ok) {
			navigate(-1);
		}
	}


	const transferOwnerShip = async () => {
		const userEmail = window.prompt('Hva er brukerens e-postadresse?');
		if (userEmail === null) return;

		const response = await fetch(SiteService.apiPath + `/batperm/boat/${boat._id}/transfer-ownership`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				userEmail: userEmail,
			})
		})

		if (response.status === 401)
			return confirm('Du har ikke tilgang til funksjonen');
		if (response.status === 404)
			return confirm('Brukeren finnes ikke');
		if (response.status === 200)
			return confirm('Fullført. Brukeren er tildelt til Havnen');
	};

	const updateBoatState = (formObj) => {

		formObj.forEach((item) => {
			if (!boatRefObj[item.propertyName])return;
			if(item.type === 'checkbox'){
				boat[item.propertyName] = boatRefObj[item.propertyName].current.checked;
			}
			else if (boatRefObj[item.propertyName].current.value.length < 0 || boatRefObj[item.propertyName].current.value === "") {
				boat[item.propertyName] = null;
			}else if (item.type === 'number') {
				boat[item.propertyName] = Number(boatRefObj[item.propertyName].current.value);
			}else
				boat[item.propertyName] = boatRefObj[item.propertyName].current.value;
		});
		setBoat(boat);
	};

	function HarborSelectMany (){
		if (!isHarborsLoading) {
			return (
				<SelectManyFromList list={harbors} setSelectedListState={setSelectedHarborIds}
									selectedListState={selectedHarborIds}></SelectManyFromList>
			)
		}else{
			return(
				<div className={classes.loading}>
					<p>Laster havner...</p>
				</div>
			)
		}
	}

	function BoatImageEl (){return(
		boat.imageName && <img className={classes.boatInfoBoatImage} src={`${SiteService.apiPath}/image/batperm-boat/${boat._id}/${boat.imageName}`}/>
	)}




	const BoatForm = memo(function({boatFormObj: formObj, validationFunction}){
		const formInput = (item)=> {
			if (item.type === "checkbox")
				return <ToggleSwitch toggleRef={boatRefObj[item.propertyName]}></ToggleSwitch>

			return	<input
				key={item.propertyName}
				id={item.propertyName}
				type={item.type}
				ref={boatRefObj[item.propertyName]}
				defaultValue={boat[item.propertyName]}
				defaultChecked={boat[item.propertyName]}
				maxLength={item.maxLength}
				minLength={item.minLength}
				max={item.max}
				min={item.min}
				step={'any'}
				required={item.required}
				onChange={()=>{updateBoatState(formObj)}}
				onBlur={()=>{updateBoatState(formObj)}}
			/>
		}

		return(
		<form id={'boatform'} className={classes.boatForm} name="boatForm"
			  onSubmit={(ev)=>{
				  ev.preventDefault();
				  //if(validationFunction) {
				//	  if (!validationFunction()) return;
				  //}
				  saveBoat();
			  }}>

			{formObj.map((item, index) => {return (
				<div className={classes.boatFormItem} key={index}>
					{item.type==='h3'?
						<h3>{item.label}</h3>
						:
						<><label htmlFor={item.propertyName}>
							{item.label}{item.required&&' *'}
						</label>
					{formInput(item)}</>}
				</div>
			)})}
		</form>
	)})

	const MainImageUploader = memo(function({setImageB64, setImageName}){
		const [isImageEditing, setIsImageEditing] = useState(false);
		let imageUrl = null;
		if (boat.imageName)
			imageUrl = `${SiteService.apiPath}/image/batperm-boat/${boat._id}/${boat.imageName}`;

		return(<div className={classes.batpermInfoImageContainer}>

		{isImageEditing&&<B64ImageUploader setImageB64={setImageB64} setFileNameExtention={setImageName}></B64ImageUploader>}

		{imageB64 ? <img className={classes.boatInfoBoatImage} src={imageB64}/>
				:
				<img style={!imageUrl?{objectFit:'contain'}:{}} className={classes.boatInfoBoatImage}
					 src={imageUrl?imageUrl : shipIcon}/>
		}
			<button className={classes.boatInfoBoatImageEditPen} onClick={()=>{setIsImageEditing(!isImageEditing)}}>
				<img src={pencilIcon}/>
			</button>
		</div>
	)})


	function SaveDeleteButtons(){return(<>
		<button className={classes.fullWidthButtonInvert} form={'boatform'} type={"submit"} onClick={(ev)=>{
			if (!ev.target.getAttribute('form')) return;
			saveBoat();
		}}>Lagre båt</button>

		<button className={classes.fullWidthButtonInvert} onClick={(ev) => {
			ev.preventDefault();
			deleteBoat()
		}}>Slett båt
		</button>
	</>)}

	const submenuEntries = useRef([
		{label: 'Fakta', value: 'fakta'},
		{label: 'Kontaktinfo', value: 'kontakt',
			renderCondition:(boat)=>{if (boat._requesterIsOwner||boat._harborOwned)return true}},
		{label: 'Tilgang', value: 'tilgang',
			renderCondition:(boat)=>{if(boat._harborOwned||boat._requesterIsOwner)return true}},
		{label: 'Sjøsetting', value: 'sjosetting'},
		{label: 'Utstyr', value: 'utstyr',
			renderCondition:(boat)=>{if(boat._harborOwned||boat._requesterIsOwner)return true}},
		{label: 'Service', value: 'service',
			renderCondition:(boat)=>{if(boat._harborOwned||boat._requesterIsOwner)return true}},
		{label: 'Gjøremål', value: 'gjoremal',
			renderCondition:(boat)=>{if(boat._harborOwned||boat._requesterIsOwner)return true}}
	]);
	function SubMenu(){
		function SubMenuEntry({item}) {
			return <a className={selectedSubMenu===item.value ? classes.subMenuSelected : ""} onClick={() => {setSelectedSubMenu(item.value)}}>{item.label}</a>
		}

		return(
			<div className={classes.subMenu}>
				<nav className={classes.subMenuNav}>
					{submenuEntries.current.map((item) => {
						if(item.renderCondition === undefined || item.renderCondition()===true)
							return <SubMenuEntry key={item.value} item={item}></SubMenuEntry>
					})}
				</nav>
			</div>
		)
	}

	function BoatFormUneditable({boatFormObj}){return(<>
		{boatFormObj.map((item) => {return (
			<div className={classes.boatFormItem} key={item.propertyName}>
				<p>{item.label}: <span>{boat[item.propertyName]}</span></p>

			</div>
		)})}
	</>)}

	function InfoSubmenu(){return(<>
		{(boat._harborOwned || boat._requesterIsOwner) ?
			<MainImageUploader setImageB64={setImageB64} setImageName={setImageName}></MainImageUploader>
			:
			<BoatImageEl></BoatImageEl>
		}

		<h2>Fakta</h2>
		<img className={classes.waveImage} src={waveIcon}/>
		{(boat._harborOwned || boat._requesterIsOwner) ?
			<BoatForm boatFormObj={boatFormObj.info} validationFunction={infoValidateFunction}></BoatForm>
			:
			<BoatFormUneditable boatFormObj={boatFormObj.info}></BoatFormUneditable>
		}
		{(boat._harborOwned || boat._requesterIsOwner) && <SaveDeleteButtons></SaveDeleteButtons>}
	</>)}

	function ContactSubmenu(){return(<>
		<h3>Kontaktinfo</h3>
		<BoatForm boatFormObj={boatFormObj.contact}></BoatForm>
		<SaveDeleteButtons></SaveDeleteButtons>
	</>)}

	function AccessSubmenu(){return(<>
		{(!boat._harborOwned) && <div>
			<h3>Havner med begrenset tilgang</h3>
			<HarborSelectMany></HarborSelectMany>
			</div>
		}

		<br/>
			<button className={classes.batpermButton} onClick={transferOwnerShip}>Overfør eierskap</button>
		<br/>

		{(!boat._harborOwned) &&<SaveDeleteButtons></SaveDeleteButtons>}
	</>)}




	function BoatEditable({boat}){return(<div className={classes.boatEditable}>
		{/*<SubMenu></SubMenu>*/}
		<h2 className={classes.boatEditableName}>{boat.modelName}
			{boat._harborLimitedPermissions &&
				<div className={classes.permissionSpan}>begrenset tilgang</div>}
		</h2>

		{selectedSubMenu === "fakta" 		&& <InfoSubmenu/>}
		{selectedSubMenu === "kontakt" 		&& <ContactSubmenu/>}
		{selectedSubMenu === "tilgang" 		&& <AccessSubmenu/>}
		{selectedSubMenu === "sjosetting" 	&& <BatpermLaunchingSubmenu boat={boat} setBoat={setBoat}/>}
		{selectedSubMenu === "utstyr" 		&& <BatpermEquipmentSubmenu boat={boat} setBoat={setBoat}/>}
		{selectedSubMenu === "service"	 	&& <BatpermServiceSubmenu boat={boat} setBoat={setBoat}/>}
		{selectedSubMenu === "gjoremal"	 	&& <BatpermTodoSubmenu boat={boat} setBoat={setBoat}/>}

	</div>)}


	function BoatNew(){return(
		<div>
			<h1>Båt - Ny</h1>

			<MainImageUploader setImageB64={setImageB64} setImageName={setImageName}></MainImageUploader>

			<BoatForm boatFormObj={boatFormObj.info} validationFunction={infoValidateFunction}></BoatForm>

			<button className={classes.fullWidthButtonInvert} form={'boatform'} type={"submit"}>Lagre båt</button>
		</div>
	)}

if (isLoading) return (<div className="loader">Laster...</div>)
if (!boat)
	return (<div>Båt ikke funnet</div>);
if (isNew){ // If NEW BOAT
	return <BoatNew></BoatNew>
}

if (boat){
	if (boat._editable) // if boatRes has _editable property
		return <BoatEditable boat={boat}></BoatEditable>

	else return (
	<div className={classes.publicBoatContainer}>
		<div className={classes.publicBoatHeader}>
			<h1>{boat.name? boat.name : boat.modelName}</h1>
		</div>
		<div className={classes.publicBoat}>
			<BoatImageEl />
			<div>
				<h2>Fakta</h2>
				{boat.name && <p>Navn: {boat.name}</p>}
				{boat.modelName && <p>Modell: {boat.modelName}</p>}
				{boat.productionYear && <p>Produktionsår: {boat.productionYear}</p>}
				{boat.lengthMeters && <p>Lengde (m): {boat.lengthMeters}</p>}
				{boat.widthMeters && <p>Bredde (m): {boat.widthMeters}</p>}
				{boat.depthMeters && <p>Dybde (m): {boat.depthMeters}</p>}
				{boat.weightKilograms && <p>Vekt (kg): {boat.weightKilograms}</p>}
				{boat.motorName && <p>Motor: {boat.motorName}</p>}
				{boat.motorProductionYear && <p>Motor-produksjonsår: {boat.motorProductionYear}</p>}
			</div>
			{boat.isContactPublic && <div>
				<h2>Kontakt-info</h2>
				{boat.contactName && <p>Navn: {boat.contactName}</p>}
				{boat.contactPhoneNumber && <p>Telefon: {boat.contactPhoneNumber}</p>}
				{boat.contactEmail && <p>E-post: {boat.contactEmail}</p>}
			</div>}

			{!userContext.isLoggedIn && <Loginbox sendToRefreshBool={true}></Loginbox>}
		</div>
	<div className={classes.publicBoatFooter}></div>
	</div>)
}})