import {useRef, useState} from "react";
import B64ImageUploader from "../../ui/functional/B64ImageUploader/B64ImageUploader";
import SiteService from "../../../services/SiteService";

export default function ImageEditor({valueRef, part, setPart, article, disableVariant, disableDesc}){
	// valueRef can also be a function which is called with the new value.

	const imageDescRef = useRef(part.desc)
	const imageAltRef = useRef(part.alt)
	const imageRef = useRef(part.value)
	const variantRef = useRef(part.variant?part.variant:'2')

	const [image, setImage] = useState(part.value);

	if (!part.variant)
		part.variant = '2';

	const onSetimageB64 = (b64)=>{
		const newObj = {
			value: b64,
			alt: imageAltRef.current.value,
		};
		if (!disableDesc)
			newObj.desc = imageDescRef.current.value;
		if (typeof valueRef === "function")
			valueRef(newObj);
		else {
			if (valueRef&&valueRef.current)
				valueRef.current = {...valueRef.current,...newObj};
			//overwrite part but keep unaltered values. For example, keep part.url which already exists in part
			if (setPart)
				setPart({ ...part, ...newObj});
		}
		setImage(b64);
	}

	return(<>
		<div>
		<div>
			{(part.value && !part.value.startsWith('data:image')) ? (article &&
				<img style={{maxHeight: "200px"}} src={`${SiteService.apiPath}/image/articles/${article._id+'/'+part.value}`} alt="" />
			):(
				<img style={{maxHeight: "200px"}} src={image} alt="" />
			)}
		</div>
		<span style={{width:'50%', display:'inline-block'}}>
		<B64ImageUploader refParam={imageRef} setFileNameExtention={()=>{}}
						  setImageB64={onSetimageB64}></B64ImageUploader>
		</span>
		</div>

		<div>
		{!disableVariant && <>
			<label style={{display: "block"}}>Utseende</label>
			<select ref={variantRef} defaultValue={part.variant} onChange={(ev) => {
				part.variant = ev.target.value
			}} name={"variant"}>
				<option value={2}>Medium</option>
				<option value={null}>Bredt</option>
				<option value={1}>Avsnitt-bredde</option>
				<option value={3}>Full-skjermbredde (så langt det går uten at bildet beskjæres)</option>
				<option value={5}>Full-skjermbredde (beskjær høyde om nødvendig)</option>
				<option value={4}>Original (for at logoer O.L ikke blåses opp)</option>
			</select></>
		}

		<br/><label style={{display:"block"}}>Bildebeskrivelse:</label>
		<textarea style={{resize: "vertical"}}
				  defaultValue={part.alt}
				  ref={imageAltRef}
				  name={"imageAlt"}
				  placeholder={"Bildebeskrivelse (alt-tekst for søkemotorer og blinde)"}
				  onChange={(ev) => {
					part.alt = ev.target.value;
				  }}
		/>

		{!disableDesc && <>
			<br/><label style={{display:"block"}}>Undertekst <i>(valgfritt)</i>: </label>
			<textarea name={'imageDesc'} style={{resize: "vertical"}}
			  ref={imageDescRef} placeholder={'Bildetekst (undertekst)'}
			  defaultValue={part.desc}
			  onChange={(ev) => {
				  part.desc = ev.target.value;
			  }}
			/></>
		}
		</div>
	</>)
}