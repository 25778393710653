import {useState} from "react";
import SiteService from "../../services/SiteService";

export default function TempAdClicks() {
    const [loading, setLoading] = useState(false);
	const [list, setList] = useState([]);

	const getList = async () => {
		let res = await fetch(`${SiteService.apiPath}/tempadviews`);
		let data = await res.json();
		setList(data);
		setLoading(false);
	}

	if (loading) return <div>Laster...</div>

	return <div>
		<h1>Lenke-klikk</h1>
		<button onClick={getList}>Hent</button>
		<table>
			<thead>
				<tr>
					<th>Lenke</th>
					<th>Klikk</th>
				</tr>
			</thead>
			<tbody>
				{list.map((item, index) => <tr key={index}>{item.name}<td>{item.clicks}</td></tr>)}
			</tbody>
		</table>
	</div>
}