import {useContext, useEffect, useRef, useState} from "react";
import OnTopBox from "../../ui/onTopBox";
import ArticlesSearch from "../../../pages/ArticlesSearch";
import SiteContext from "../../../store/siteContext";
import {DuplicateButton} from "../../ui/DuplicateButton";


export default function ArticleSuggestionsEditor({valueRef, part, setPart, article, index}){
	const siteContext = useContext(SiteContext);
	useEffect(() => {
		console.log(part);
		if (!part.articles)
			part.articles = [];

		if (siteContext.tags.length < 1)
			siteContext.getAndAssignTags();
	}, []);

	const addArticle = ()=>{
		// add a part to part.articles
		setPart(prevPart => ({
			...prevPart,
			articles: [...prevPart.articles, {id: null, subType:'category'}]
		}));
	}

	const changeArticleProperty = (propertyName, value, index)=>{
		part.articles[index][propertyName] = value;
		setPart(prevPart => ({...prevPart}));
	}

	const ArticlesPartEdit = ({articlePart, changeArticleProperty, index})=>{
		const [isChoosingArticle, setIsChoosingArticle] = useState(false);
		const idRef = useRef(articlePart.id);
		const subTypeRef = useRef(articlePart.subType);

		const removePart = (index)=>{
			setPart(prevPart => ({...prevPart, articles: [...prevPart.articles.filter((article, i) => i!== index)]}));
		}

		const duplicatePart = (index) => {
			setPart(prevPart => ({
				...prevPart,
				articles: [
					...prevPart.articles,
					JSON.parse(JSON.stringify(prevPart.articles[index])) // Create a deep copy of the article
				]
			}));
		};


		return(<div>
				Artikkel fra:
				<select defaultValue={articlePart.subType} ref={subTypeRef} onChange={() => {
					changeArticleProperty('subType', subTypeRef.current.value, index)
				}}>
					<option value={'specific'}>Spesifik</option>
					<option value={'category'}>Kategori</option>
					<option value={'tag'}>Stikkord</option>
				</select>


				{articlePart.subType === 'specific' && <>
					<button onClick={() => {
						setIsChoosingArticle(true)
					}}>Velg artikkel
					</button>
					<input onChange={(ev) => changeArticleProperty('id', ev.currentTarget.value)} type={"text"}
						   defaultValue={articlePart.id} ref={idRef}/>
					{isChoosingArticle &&
						<OnTopBox onTopEnabled={isChoosingArticle} setOnTopEnabled={setIsChoosingArticle}>
							<ArticlesSearch articleClickAction={(art) => {
								changeArticleProperty('id', art._id, index)
							}}/>
						</OnTopBox>}
				</>}
				{articlePart.subType === 'category' &&
					<select onChange={(ev) => changeArticleProperty('id', ev.currentTarget.value, index)}
							defaultValue={articlePart.id} ref={idRef}>
						<option value={null}>Ingen</option>
						{siteContext.categories.map((category, index) => {
							return <option key={index} value={category._id}>{category.name}</option>
						})}
					</select>}

				{articlePart.subType === 'tag' && <select onChange={(ev) => changeArticleProperty('id', ev.currentTarget.value, index)}
					   defaultValue={articlePart.id} ref={idRef}>
					<option value={null}>Ingen</option>
					{siteContext.tags.map((tag, index) => {
						return <option key={index} value={tag._id}>{tag.name}</option>
					})}
				</select>}

				<button className="frontpageEdit-chosenModulesList-removeButton"
						onClick={() => removePart(index)}>×
				</button>
				<button type={"button"} onClick={() => duplicatePart(index)}>
					<DuplicateButton/>
				</button>
			</div>
		)
	};

	return (<div>
		<button onClick={addArticle}>Legg til artikkel</button>
		{part.articles && part.articles.map((article, index) => <ArticlesPartEdit articlePart={article} changeArticleProperty={changeArticleProperty} index={index} key={index}/>)}
</div>)}