import classes from "../batperm.module.scss";
import {Link} from "react-router-dom";
import {useContext} from "react";
import BatpermContext from "../BatpermContext";
import UserContext from "../../store/userContext";
import HamburgerIcon from "../../svg/HamburgerIcon";
import MainMenuLink from "../components/MainMenuLink";
import NotificationBell from "../components/NotificationBell";
import batpermLogo from "../../images/batperm-logo.svg";

export default function BatpermNav(){
	const batpermContext = useContext(BatpermContext);
	const userContext = useContext(UserContext);

	const closeMenu = () => batpermContext.setIsMenuOpen(false);

	return(<>
		<div className={classes.batpermHeader}>
			<button className={[classes.hamburgerButton, batpermContext.isMenuOpen ? classes.hamburgerButtonOpened : classes.hamburgerButtonClosed].join(' ')} onClick={(ev)=>{
				ev.preventDefault();
				batpermContext.setIsMenuOpen(!batpermContext.isMenuOpen)}}>
				<HamburgerIcon/>
			</button>
			<Link to={''}><img className={classes.batpermHeaderLogo} src={batpermLogo} alt="logo"
				onClick={closeMenu}
			/></Link>
			<NotificationBell classes={classes}/>
		</div>


		<div className={[
			classes.batpermNavContainer,
			batpermContext.isMenuOpen?classes.batpermNavContainerOpen:''
		].join(' ')}>
			<nav className={classes.batpermNav}>
				<MainMenuLink to={''} label={'Hjem'}/>

				{userContext.role >= 2 && <MainMenuLink to={'admin'} label={'Admin'}/>}

				{(document.location.pathname.split('/')[2] === "bat") && <>
					<hr/>
					{batpermContext.additionalNavElements}
				</>}
			</nav>
		</div>
	</>)
}