import { useEffect, useState } from 'react';

const useInView = (ref, options = {}, onInView, runOnce = false) => {
	const [inView, setInView] = useState(false);
	const [hasRun, setHasRun] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setInView(true);
					if (onInView && !hasRun && !runOnce) {
						onInView();
					}
					if (runOnce && !hasRun) {
						if (onInView) {
							onInView();
						}
						setHasRun(true);
					}
				} else {
					setInView(false);
				}
			},
			{
				threshold: options&&options.threshold || 0,
				rootMargin: options&&options.rootMargin || '0px 0px 0px 0px'
			}
		);

		if (ref.current) {
			observer.observe(ref.current);
		}

		return () => {
			if (ref.current) {
				observer.unobserve(ref.current);
			}
		};
	}, [ref, onInView, runOnce]);

	return inView;
};

export default useInView;
