import MyFavourites from "../../components/user/MyFavourites";
import classes from "./MinSide.module.scss";

export default function Favoritter() {
	return <div className={classes.favoritter}>
		<h1>Favoritter</h1>

		<MyFavourites/>

	</div>
}