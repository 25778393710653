import React, {useContext, useEffect, useRef, useState} from "react";
import FavouritesContext from "../../../store/favourites-context";
import HeartIcon from "../../../svg/HeartIcon";
import ShareIcon from "../../../svg/ShareIcon";
import {Link} from "react-router-dom";
import LinkIcon from "../../../svg/LinkIcon";
import MailIcon from "../../../svg/MailIcon";
import fbLogo from "../../../images/external/fb-logo.svg";

export default function ShareButton({articleId, articleTitle, addText}) {
	const [isClicked, setIsClicked] = useState(false);
	const buttonRef = useRef(null);
	const [shareButtonText, setShareButtonText] = useState("Kopier lenke");

	const [copyLinkWasJustClicked, setCopyLinkWasJustClicked] = useState(false);

	useEffect(() => {
		// Attach the event listener when the component mounts
		document.addEventListener("click", handleClickOutside);

		// Detach the event listener when the component unmounts
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);
	const handleClickOutside = (event) => {
		if (buttonRef.current && !buttonRef.current.contains(event.target)) {
			// Clicked outside the button, close the menu
			setIsClicked(false);
		}
	};
	const onclick = async () => {
		setIsClicked(!isClicked);
	}
	// use onfocus instead of onClick?

	const copyLinkToClipBoard = () => {
		// Simulate copying to clipboard
		if (!navigator.clipboard) {
			return alert('Kan ikke kopiere lenke når nettstedet er HTTP. Prøv HTTPS');
		}
		navigator.clipboard.writeText(window.location.href);
		setCopyLinkWasJustClicked(true);
		setShareButtonText("Kopiert!");

		// Reset the text after 1 second
		setTimeout(() => {
			setShareButtonText("Kopier lenke");
			setCopyLinkWasJustClicked(false);
		}, 1000);
	};
	const sendEmail = ()=>{
		// create a new email containing the current URL
		const emailBody = `Se denne artikkelen fra Båtens Verden - %0D%0A${articleTitle}%0D%0A${window.location.href}`;

		window.location.href = `mailto:?subject=Artikkel - Båtens Verden&body=${emailBody}`;
	}

	const shareFacebook = () => {
		const currentUrl = window.location.href;
		const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`;
		window.open(facebookShareUrl, '_blank');
	};


	return <span ref={buttonRef} className={'pointer favouriteButtonContainer'} onClick={()=>setIsClicked(true)}>
		<button className={[
		'favouriteButton',
		isClicked? 'animationHeartbeat':'',
		].join(' ')} onClick={onclick}>
		<ShareIcon/>
	</button>
	{addText && <span>
		Del artikkel
	</span>}
		{isClicked && <div className={'shareOptions'}>
			<button onClick={copyLinkToClipBoard} className={copyLinkWasJustClicked?'copyLinkClicked':' '}>
				<LinkIcon/>{shareButtonText}
			</button>
			<button onClick={sendEmail}>
				<MailIcon/>
				Send e-post
			</button>
			<button onClick={shareFacebook}>
				<img style={{height:'35px',margin:'-5px 5px -5px 0'}} src={fbLogo}/>Facebook
			</button>
		</div>}
</span>
}