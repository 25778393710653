import {useState, useRef, useEffect} from 'react';
import FrontpagePartEdit from './FrontpagePartEdit';
import BatsokPartsEdit from './BatsokPartsEdit';
import OnTopBox from '../../../components/ui/onTopBox';
import ColorPresetPicker from '../../../components/ui/functional/ColorPresetPicker';
import PaddingEdit from "./PaddingEdit";
import SiteService from "../../../services/SiteService";

function FrontpageModuleEdit({ module, moduleTypes, frontpageModules,
							setFrontpageModules, editingModuleIndex,
							 tags, adPlacements, categories, setEditingModule, setEditingModuleIndex,
}) {
	const [editingParts, setEditingParts] = useState(module.parts || []);
	const [videoDetails, setVideoDetails] = useState([]);
	const fetchedVideoIds = useRef(new Set());
	const refName = useRef();
	const refSectionTitle = useRef();
	const refLink = useRef();
	const refAdvertiserName = useRef();

	const [slidesPerView, setSlidesPerView] = useState(module.slidesPerViewMin || null);

	const [background, setBackground] = useState(module.style ? module.style.background : null);
	const [textColor, setTextColor] = useState(module.style ? module.style.color : null);
	const [paddingLeft, setPaddingLeft] = useState(module.style ? module.style.paddingLeft : null);
	const [paddingTop, setPaddingTop] = useState(module.style? module.style.paddingTop : null);
	const [paddingRight, setPaddingRight] = useState(module.style? module.style.paddingRight : null);
	const [paddingBottom, setPaddingBottom] = useState(module.style? module.style.paddingBottom : null);

	const [isBGColorModemOpen, setIsBGColorModemOpen] = useState(false);
	const [isTextColorModemOpen, setIsTextColorModemOpen] = useState(false);

	let moduleOptions = moduleTypes[module.moduleType];

	const saveModule = () => {
		module.name = refName.current.value;
		if (moduleOptions.sectionTitle) {
			module.sectionTitle = refSectionTitle.current.value;
			if (refLink.current.value)
				module.link = refLink.current.value;
			else
				delete module.link;
		}

		if (moduleOptions.advertiserName)
			module.advertiserName = refAdvertiserName.current.value;

		if (moduleOptions.slidesPerViewMin && slidesPerView)
			module.slidesPerView = slidesPerView;

		if (moduleOptions.customStyle) {
			module.style = {};
			if (background)
				module.style.background = background;
			if (textColor)
				module.style.color = textColor;

			if (paddingLeft)
				module.style.paddingLeft = paddingLeft;
			if (paddingTop)
				module.style.paddingTop = paddingTop;
			if (paddingRight)
				module.style.paddingRight = paddingRight;
			if (paddingBottom)
				module.style.paddingBottom = paddingBottom;
		}

		// parts
		//remove empty parts
		editingParts.forEach((part, index) => {
			if (!part.id)
				editingParts.splice(index, 1);
		});

		module.parts = editingParts;

		frontpageModules[editingModuleIndex] = module;
		setFrontpageModules([...frontpageModules]);

		setEditingModule(null);
		setEditingModuleIndex(null);
	};

	const addNewPart = () => {
		let newPart = {
			type: module.parts[0].type,
			id: ""
		}
		setEditingParts([...editingParts, newPart]);
	}
	const addNewPartAbove = () => {
		let newPart = {
			type: module.parts[0].type,
			id: ""
		}
		setEditingParts([newPart, ...editingParts]);
	}

	const movePartUp = (index) => {
		if (index > 0) {
			const newEditingParts = [...editingParts];
			[newEditingParts[index], newEditingParts[index - 1]] = [newEditingParts[index - 1], newEditingParts[index]];
			setEditingParts(newEditingParts);
		}
	};

	const movePartDown = (index) => {
		if (index < editingParts.length - 1) {
			const newEditingParts = [...editingParts];
			[newEditingParts[index], newEditingParts[index + 1]] = [newEditingParts[index + 1], newEditingParts[index]];
			setEditingParts(newEditingParts);
		}
	};

	const getVideoDetails = async (videoId) => {
		if (fetchedVideoIds.current.has(videoId)) {
			return;
		}
		try {
			let res = await fetch(`${SiteService.apiPath}/video/${videoId}`);
			if (!res.ok) {
				return null;
			}
			let newVideoDetails = await res.json();
			setVideoDetails(prevDetails => {
				if (!prevDetails.find(video => video._id === videoId)) {
					return [...prevDetails, newVideoDetails];
				}
				return prevDetails;
			});
			fetchedVideoIds.current.add(videoId);
		} catch (error) {
			console.error("Error fetching video details:", error);
		}
	}

	useEffect(() => {
		editingParts.forEach(part => {
			if (part.type==="video"&&part.id) {
				getVideoDetails(part.id);
			}
		});
	}, [editingParts]);

	useEffect(() => {
		console.log(videoDetails);
	}, [videoDetails]);

	//ModuleEdit
	return (
		<div>
			<h2>Rediger modul
				<button onClick={() => { saveModule() }}>Lagre</button>
				<button onClick={() => { setEditingModule(null); }}>Avbryt</button>
			</h2>

			<div>Type: {moduleOptions.name}</div>
			<div><label>Navn: </label><input ref={refName} type={"text"} defaultValue={module.name}></input></div>

			{moduleOptions.sectionTitle && <div>
				<label>Seksjon-Tittel: </label>
				<input ref={refSectionTitle} type={"text"} defaultValue={module.sectionTitle} />
				<br/>
				<label>Seksjon-tittel lenke: </label>
				<input ref={refLink} type={"text"} defaultValue={module.link} />
			</div>}

			{moduleOptions.advertiserName && <div>
				<label>Annonsør-Navn: </label>
				<input ref={refAdvertiserName} type={"text"} defaultValue={module.advertiserName} />
			</div>}

			{moduleOptions.slidesPerViewMin && <div>
				<label>Antall synlige deler: </label>
				<input type={"number"} defaultValue={module.slidesPerView} onInput={(ev) => {
					setSlidesPerView(ev.target.value);
				}}/>
			</div>}

			{moduleOptions.customStyle && <div className={'frontpageEdit-part-style'}>
				<details>
					<summary>Stil</summary>
					<div>
						<label>Bakgrunn: </label>
						<input type={"color"} value={background} onInput={(ev) => {
							setBackground(ev.target.value)
						}} />
						<button onClick={() => {
							setIsBGColorModemOpen(true)
						}}>Palett
						</button>
						<input type={"text"} value={background} onInput={(ev) => {
							setBackground(ev.target.value)
						}} />
						<button onClick={() => {
							setBackground('')
						}}>Slett
						</button>
					</div>
					<div>
						<label>Tekst: </label>
						<input type={"color"} value={textColor} onInput={(ev) => {
							setTextColor(ev.target.value)
						}} />
						<button onClick={() => {
							setIsTextColorModemOpen(true)
						}}>Palett
						</button>
						<input type={"text"} value={textColor} onInput={(ev) => {
							setTextColor(ev.target.value)
						}} />
						<button onClick={() => {
							setTextColor('')
						}}>Slett
						</button>
					</div>
					<PaddingEdit paddingLeft={paddingLeft} paddingTop={paddingTop} paddingRight={paddingRight} paddingBottom={paddingBottom}
								 setPaddingLeft={setPaddingLeft} setPaddingTop={setPaddingTop} setPaddingRight={setPaddingRight} setPaddingBottom={setPaddingBottom}
					/>
				</details>
			</div>
			}
			<hr/>
			<h3>Deler: </h3>
			{moduleOptions.minParts&& <button onClick={addNewPartAbove}>Ny Del 🞁</button>}
			{moduleOptions.minParts&& <button onClick={addNewPart}>Ny Del 🞃</button>}

			{!moduleOptions.type && <div>
				{editingParts.map((part, index) =><div className={"frontpageEdit-part-wrapper"}>
					<FrontpagePartEdit part={part} index={index}
					   setEditingParts={setEditingParts}
					   editingParts={editingParts}
					   tags={tags}
					   adPlacements={adPlacements}
					   key={index}
					   categories={categories}
					   moduleOptions={moduleOptions}
					   videoDetails={videoDetails}
					/>
					<div className={"combinedButton"}>
						<button onClick={() => {movePartUp(index)}}>🞁</button>
						<button onClick={() => {movePartDown(index)}}>🞃</button>
					</div>
				</div>
				)}
			</div>}

			{moduleOptions.type == 'batsok' && <>{editingParts.map((part, i) => <BatsokPartsEdit
				key={i} module={module} part={part} editingParts={editingParts} index={i}
				setEditingParts={setEditingParts} moduleOptions={moduleOptions}
			/>)}</>}

			{moduleOptions.type == 'dividerLine' && <></>}

			{isBGColorModemOpen && <OnTopBox setOnTopEnabled={setIsBGColorModemOpen} onTopEnabled={isBGColorModemOpen}>
				<ColorPresetPicker chooseColorFunc={(c) => {
					setBackground(c.value);
					setIsBGColorModemOpen(false);
				}} />
			</OnTopBox>}
			{isTextColorModemOpen && <OnTopBox setOnTopEnabled={setIsTextColorModemOpen} onTopEnabled={isTextColorModemOpen}>
				<ColorPresetPicker chooseColorFunc={(c) => {
					setTextColor(c.value);
					setIsTextColorModemOpen(false);
				}} />
			</OnTopBox>}
		</div>)
}

export default FrontpageModuleEdit;
