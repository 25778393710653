import styles from "../../scss/styles.module.scss"
import {useContext} from "react";
import userContext from "../../store/userContext";

export default function PlussIconReactive({article}) {
	const user = useContext(userContext);

return(article.pluss &&
		<div className={user.isSubscribed || user.ownedArticles.includes(article._id) ?
			styles.plussIcon + ' ' + styles.plussIconSubscribed
			:
			styles.plussIcon
		}>
			{user.isSubscribed || user.ownedArticles.includes(article._id) ?
				'✓' : '+'
			}
		</div>
)}