import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import SiteService from "../services/SiteService";
import Loginbox from "../components/ui/functional/Loginbox";

export default function VerifyEmailToken(){
	let {token} = useParams();
	let [isLoading, setIsLoading] = useState(true);
	let [isVerified, setIsVerified] = useState(false);
	let [errorReason, setErrorReason] = useState(false);

	useEffect(()=>{
		if (token)
			verifyEmail(token)
	},[])

	const verifyEmail = (token)=>{
		fetch(SiteService.apiPath+'/user/verifyemail/'+token)
		.then((response)=> {
			return response.json();
		})
		.then((data)=> {
			if(data.verified)
				setIsVerified(true);

			if(data.expired){
				setErrorReason('Eposten er utløpt.');
			}else if(data.success === false && data.reason){
				setErrorReason(data.reason);
			}else if(data.success === false){
				setErrorReason('Feil. Koden er ikke gyldig.');
			}
			setIsLoading(false);
		})
		.catch((err)=>{
			console.log(err);
			if(err)
				setErrorReason('Server-feil. Koden er sannsynligvis ikke gyldig.')
			setIsLoading(false);
		})
	}


if(isLoading)return (
	<div style={{textAlign:'center'}}>
		<h1>Verifisering</h1>
		{isLoading && <div>Laster...</div>}
	</div>
)

return(<>
	<div style={{textAlign:'center'}}>
		<h1>Verifisering</h1>
		{isVerified && <>
			<div style={{color:"limegreen"}}>Verifisert! Du kan nå logge inn.</div>
			<Loginbox></Loginbox>
		</>}
		{errorReason && <div>{errorReason}</div>}
	</div>
</>)}