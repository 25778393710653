import {Component, memo, useEffect, useRef, useState} from "react";
import SiteService from "../../../services/SiteService";
import CategoryService from "../../../services/CategoryService";
import TagService from "../../../services/TagService";
import FrontpageService from "../../../services/FrontpageService";
import AdPlacementService from "../../../services/AdPlacementService";
import FrontpageModuleEdit from "./FrontpageModuleEdit";

const moduleOptionOptionExamples = {
	name: String,
	component: Component,
	categoryOptional: Boolean, // adds a checkbox
	sectionTitle: String, // adds a title like ribbon
}

function AdminFrontpageEdit(){
	document.title = "Forsideredigering | Båtens Verden";

	const [isLive, setIsLive] = useState(true); // if true, get frontPage articles from server
	const [liveFrontpageModules, setLiveFrontpageModules] = useState([]);

	const [isSplitPanel, setIsSplitPanel] = useState(true);
	const [editingModule, setEditingModule] = useState(null);
	const [editingModuleIndex, setEditingModuleIndex] = useState(null);

	const [selectedFrontpageId, setSelectedFrontpageId] = useState(null);
	const [frontpageModules, setFrontpageModules] = useState([]);

	const [frontpages, setFrontpages] = useState([]);

	const [categories, setCategories] = useState([]);
	const [tags, setTags] = useState([]);
	const [adPlacements, setAdPlacements] = useState([]);

	const moduleTypes = FrontpageService.moduleTypes;

	useEffect(()=>{
		CategoryService.getAll((categories)=>{
			setCategories(categories);
		})
		TagService.getAllFreq((tags)=>{
			// alphabetisize tags
			tags.sort((a, b) => {
				return a.name.localeCompare(b.name)
			});
			setTags(tags);
		})
		AdPlacementService.getAll((adPlacements)=>{
			setAdPlacements(adPlacements);
		})
		getFrontpages();
	},[]);

	useEffect(() => {
		console.log('getLiveFrontpages');
		if(!isLive) return;
		else getLiveFrontpage();
	}, [frontpageModules]);

	const getFrontpages = async ()=>{
		const res = await fetch(`${SiteService.apiPath}/frontpages`);
		const data = await res.json();
		// data is array. Set element with property active:true to top of array
		// splice out the active element.
		let activeFrontpage = data.find(fp => fp.active);
		if(activeFrontpage) {
			data.splice(data.indexOf(activeFrontpage), 1);
			data.unshift(activeFrontpage);
		}
		setFrontpages(data);
	}

	const getLiveFrontpage = async ()=>{
		let page = frontpages.find(fp => fp._id === selectedFrontpageId);
		if(!page) return;
		page.modules = frontpageModules;
		let pageRes = await FrontpageService.getFrontpageWithContent(page);
		setLiveFrontpageModules(pageRes.modules);
	}


	function SelectAvailableModule() {
		const refAvailableModules = useRef();

		const newModule = () => {
			let moduleOptions = moduleTypes[refAvailableModules.current.value];
			let parts = [];
			// add parts equals to moduleTypes' parts .length
			if (moduleOptions.parts)
				for (let i = 0; i < moduleOptions.parts.length; i++) {
					parts.push({type: moduleOptions.parts[i]});
					console.log(parts);
				}

			setFrontpageModules([...frontpageModules, {
				moduleType: refAvailableModules.current.value,
				name: moduleTypes[refAvailableModules.current.value].name,
				parts: parts,
			}]);
		}

	return(<div>
		<h3>Moduler</h3>
		<select ref={refAvailableModules}>
			{Object.keys(moduleTypes).map((module, index) => {
				return <option key={index} value={module}>{moduleTypes[module].name}</option>
			})}
		</select>
		<button onClick={newModule}>Legg til</button>
	</div>)}



	function SelectFrontpage(){
		const selectedFrontpage = frontpages.find(frontpage => frontpage._id === selectedFrontpageId);
		let activeFrontpage = frontpages.find(frontpage => frontpage.active === true);
		let selectedFrontpageName = "";
		try {
			selectedFrontpageName = frontpages.find(frontpage => frontpage._id === selectedFrontpageId).name;
		}catch (e){
			selectedFrontpageName = "";
		}
		const refFrontpageSelect = useRef();
		const refFrontpageNameInput = useRef();
		const refFronpageTitleInput = useRef();
		const onSelectFrontpage = ()=>{
			setEditingModule(null);
			let newFrontpage = frontpages.find(frontpage => frontpage._id === refFrontpageSelect.current.value);
			setSelectedFrontpageId(newFrontpage._id);
			setFrontpageModules(newFrontpage.modules);
		}

		const deleteFrontpage = async ()=>{
			let req = await fetch(`${SiteService.apiPath}/frontpages/${selectedFrontpageId}`, {
				method: 'DELETE'
			});
			if (req.status === 200) {
				let newFrontpage = frontpages.find(frontpage => frontpage._id === selectedFrontpageId);
				let index = frontpages.indexOf(newFrontpage);
				frontpages.splice(index, 1);
				setFrontpages([...frontpages]);
				setSelectedFrontpageId(null);
				return;
			}
			alert('Feil under sletting av forside')
		}

		const saveFrontpage = async (activate)=>{
			let newFrontpage = frontpages.find(frontpage => frontpage._id === selectedFrontpageId);
			newFrontpage.modules = frontpageModules;
			newFrontpage.name = refFrontpageNameInput.current.value;
			if (activate) newFrontpage.active = true;

			let req = await fetch(`${SiteService.apiPath}/frontpages/${newFrontpage._id}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(newFrontpage)
			});
			if (req.status === 200) {
				if(activate) {
					//set all other frontpages to not active
					let otherFrontpages = frontpages.filter(frontpage => frontpage._id!== selectedFrontpageId);
					otherFrontpages.forEach(frontpage => {
						frontpage.active = false;
					});
				}
				setEditingModule(null);
				setFrontpages(frontpages);
				setSelectedFrontpageId(null);
				return;
			}

			alert('Feil under lagring av forside')
		}

		const createFrontpage = async () => { //replace with new frontpage response
			let newFrontpage = {
				name: refFronpageTitleInput.current.value,
				modules: [],
			};
			let createRes = await fetch(`${SiteService.apiPath}/frontpages`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(newFrontpage)
			});
			if (createRes.status === 200) {
				let data = await createRes.json();

				setEditingModule(null);
				setFrontpages([...frontpages, data]);
				setSelectedFrontpageId(data._id);
				setFrontpageModules(data.modules);
			}
		}

		const duplicateFrontpage = async ()=>{
			let newFrontpage = {...frontpages.find(frontpage => frontpage._id === refFrontpageSelect.current.value)};
			newFrontpage.name = newFrontpage.name + " (Kopi)";
			newFrontpage.active = false;
			delete newFrontpage._id;
			let req = await fetch(`${SiteService.apiPath}/frontpages/`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
					},
				body: JSON.stringify(newFrontpage)
			});
			if (req.status === 200) {
				let data = await req.json();
				setEditingModule(null);
				setFrontpages([...frontpages, data]);
				setFrontpageModules(data.modules);
				setSelectedFrontpageId(data._id);
				return;
			}
			alert('Feil under kopiering av forside');
		}

		return(<div>
			{!selectedFrontpageId&&<>
				<div>Aktiv: <i>{activeFrontpage && activeFrontpage.name}</i></div>
				<h2>Forsider:
				<select ref={refFrontpageSelect} defaultValue={selectedFrontpageId}>
			{frontpages.map((frontpage, index) => {
				return <option style={frontpage.active?{color: '#00b10b'}:{}} key={index} value={frontpage._id}>{frontpage.name}</option>
			})}
		</select>
		<button onClick={onSelectFrontpage}>Rediger</button>
		<button onClick={duplicateFrontpage}>Dupliser</button>
		</h2>

		<div>
			<input type={"text"} ref={refFronpageTitleInput} placeholder={'Forside navn'}/>
			<button onClick={createFrontpage}>Lag ny</button>
		</div>
			</>}

			{selectedFrontpageId&&<>
				<h2>Forside:
					<input type={"text"} defaultValue={selectedFrontpageName} ref={refFrontpageNameInput}/>
					<button onClick={()=>saveFrontpage(false)}>Lagre</button>
					{<button onClick={() => {saveFrontpage(true)}}>Lagre og sett aktiv</button>}
					<button onClick={deleteFrontpage}>Slett</button>
					<button onClick={()=>{setSelectedFrontpageId(null)}}>Avbryt</button>
				</h2>
			</>}
		</div>)
	}


	function ChosenModulesList(){
			const [draggedOverIndex, setDraggedOverIndex] = useState(null);
			const [draggingIndex, setDraggingIndex] = useState(null);
			const onDragStart = (ev, index)=>{
				setDraggingIndex(index);
			}
			const onDragOver = (ev, index)=>{
				setDraggedOverIndex(index)
				ev.preventDefault();
			}
			const onDrop = (ev, index)=>{
				reorderArray(frontpageModules, draggingIndex, draggedOverIndex);
			}
			const onDragEnd = (ev, index)=>{
				setDraggedOverIndex(null);
				setDraggingIndex(null);
			}
		return(
		<div className={'frontpageEdit-chosenModulesList'}>
			{frontpageModules.map((module, index) => {return (
			<div className={['frontpageEdit-chosenModulesList-item',
				draggedOverIndex===index ? 'frontpageEdit-draggedOver' : ''
			].join(' ')} key={index}
				 onDrop={(ev)=>onDrop(ev, index)}
				 onDragOver={(ev)=>onDragOver(ev, index)}
				 onDragEnd={(ev, index)=>onDragEnd(ev, index)}
			>
				<div className={'frontpageEdit-chosenModulesList-index'}>{index+1}.</div>

				<div className={'frontpageEdit-chosenModulesList-inner'}>
					<div>{module.name}</div>
					<div className={'frontpageEdit-chosenModulesList-actions'}>
					<button className={'frontpageEdit-chosenModulesList-editButton'} onClick={()=>{clickEditModule(index)}}>Rediger</button>
					<button className={'frontpageEdit-chosenModulesList-removeButton'} onClick={()=>{removeModule(index)}}>×</button>
					</div>
				</div>

				<div className={'frontpageEdit-chosenModulesList-handle'} draggable={true} onDragStart={(ev)=>onDragStart(ev,index)}>&nbsp;</div>

				{isSplitPanel&&<button onClick={() => {
					if(isSplitPanel)document.getElementById('module' + index).scrollIntoView({behavior: 'smooth'})
				}}>👁</button>}
			</div>)
			})}
		</div>)
	}
	const reorderArray = (arr, from, to)=>{
		arr.splice(to, 0, arr.splice(from, 1)[0])
		setFrontpageModules([...arr]);
	}
	const clickEditModule = (index) => {
		setEditingModule({...frontpageModules[index]});
		setEditingModuleIndex(index);
		if (isSplitPanel) try {
		document.getElementById('module' + index).scrollIntoView({behavior: 'smooth'})
		} catch (e) {
			console.log(e)
		}
	}
	const removeModule = (index) => {
		setFrontpageModules(frontpageModules.filter((module, i) => i!== index))
	}

	const SplitPanel1 = memo(() => {
		return(<>
			{!editingModule&&<SelectFrontpage/>}
			{selectedFrontpageId&&<>
			{!editingModule&&<><SelectAvailableModule/>
				<ChosenModulesList/></>}
				{editingModule&&<FrontpageModuleEdit module={editingModule} frontpageModules={frontpageModules}
									  setFrontpageModules={setFrontpageModules}
									  categories={categories} adPlacements={adPlacements} tags={tags}
									  clickEditModule={clickEditModule} setEditingModule={setEditingModule}
										setEditingModuleIndex={setEditingModuleIndex}
									  editingModuleIndex={editingModuleIndex} moduleTypes={moduleTypes}/>}
			</>}
		</>)})

	function SplitPanel2({liveFrontpageModules, frontpageModules}){
	return (
		<div className={'Frontpage-splitPanel2 frontpage'}>
			{FrontpageService.FrontpageRender({
				frontpageModules: isLive?liveFrontpageModules : frontpageModules,
				isLive: isLive,
				categories: categories
			})}
		</div>
	)}

	const [flexBasis, setFlexBasis] = useState('35%'); // Initial width of the pane, example 40%
	const [isResizing, setIsResizing] = useState(false);

	const handleMouseMove = (e) => {
		if (isResizing) {
			const newFlexBasis = `${(e.clientX / window.innerWidth) * 100}%`;
			document.getElementById('panel1').style.flexBasis = newFlexBasis;
			//setFlexBasis(newFlexBasis);
		}
	};
	const handleMouseDown = () => {
		setIsResizing(true);
	};
	const handleMouseUp = () => {
		setIsResizing(false);
	};

	return (<><div className={'frontpageEdit-settingsBand'}>
		<div>
			<label htmlFor={'splitview'}>Splittet visning:</label>
			<input id={'splitview'} type={"checkbox"} onChange={(ev)=>{setIsSplitPanel(ev.target.checked)}} checked={isSplitPanel} />
		</div>
		{/*<div>
			<label htmlFor={'isLive'}>LIVE:</label>
			<input id={'isLive'} type={"checkbox"} onChange={(ev) => {
				setIsLive(ev.target.checked)
			}} checked={isLive}/>
		</div>*/}
	</div>

		<div className={'frontpageEdit splitter'}
		onMouseMove={handleMouseMove}
		onMouseUp={handleMouseUp}
	>


		<div id={'panel1'}>
			<SplitPanel1/>
		</div>

		{isSplitPanel&&<><div id={'rpanResize'}	  onMouseDown={handleMouseDown}
		>&nbsp;</div>

		<div id={'panel2'}>
			<SplitPanel2 liveFrontpageModules={liveFrontpageModules} frontpageModules={frontpageModules}/>
        </div></>}
	</div></>)
}

export default AdminFrontpageEdit;