import {Link} from "react-router-dom";
import ContentImage from "./ContentImage";

export default function ContentPart1({part, customStyle}){
	if (part.style) customStyle = part.style;
	if (!part.imageLink) part.imageLink = null;

	const shortenedPreface = ()=>{
		//find first dot in the preface
		let firstPunctuation = part.preface.indexOf('. ');
		//find if next character is upperCase
		let isUpperCase = part.preface.charAt(firstPunctuation+2).toUpperCase() === part.preface.charAt(firstPunctuation+2);
		if (isUpperCase) part.preface = part.preface.split('. ')[0]+'.';
		if (part.preface.length > 126) return part.preface.substring(0, 123) + "...";
		else return part.preface;
	}

	return (
		<div className={'contentPart1'} style={part.style?part.style:null}>
			<Link to={part.linkTo?part.linkTo:''} draggable="false">
				<ContentImage part={part} customStyle={customStyle}>
					<img loading="lazy" draggable="false" className={'contentPart1Image'} src={part.imageLink}/>
				</ContentImage>
				<div className={'contentTextContainer'} style={(customStyle&&customStyle.background) && {padding:'1em',paddingTop:'0'}}>
					<h2 className={'contentPart1Title'} style={customStyle?customStyle:null}>
						{part.frontpageTitle?
							<div dangerouslySetInnerHTML={{__html:part.frontpageTitle}}></div>
							:
							<>{part.name?part.name:'Tittel'}</>
						}
					</h2>
					{part.preface && <p>{shortenedPreface()}</p>}
				</div>
			</Link>
		</div>
	)
}