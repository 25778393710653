export default function NyhetsbrevAbonner(){//todo make functional
	 return <div className={'nyhetsbrevAbonner contentBlock'}>
		  <form action="https://pub.dialogapi.no/s/MTYyMzI6YTFkZjRmMGYtODc5YS00ZWJjLTgwZmUtN2NhMWRlMzE4MGUx" acceptCharset="utf-8" method="post" className="mnm-embed-form">
			  <div className={'nyhetsbrevAbonnerFirst'}>Abonner for siste nyhetsbrev</div>
			 <div className="mnm-form-fields nyhetsbrevAbonnerSecond">
				 <input type="email" name="email" placeholder="E-postadresse" required/>
				 <input type="submit" value="Abonner"/>
			 </div>
			 <div className="mnm-form-confirmation" style={{display: 'none'}}></div>
		 </form>
		 <script src={"https://cdn.dialogapi.no/form.min.js"} crossOrigin="anonymous" async defer></script>

	 </div>
}