import AuthorService from "../../services/AuthorService";

export default function AuthorChoice({author, setAuthor, authors, setAuthors}){

	if(!authors) return(<div>Laster forfattere...</div>)

return(<div>
	<select required={true} className={'buttonDull1'} defaultValue={author} onChange={(ev)=>{setAuthor(ev.target.value)}}>
		<option style={{display: 'none'}}></option>
		{authors.map((author)=>{return(
		<option key={author._id} value={author._id}>{author.name}</option>
	)})}
	</select>
	<button className={'buttonDull1'} style={{width:'max-content',display:'inline-block'}} onClick={(ev)=>{ev.preventDefault();AuthorService.getAll(setAuthors)}}>
		↻
	</button>
	<a href={'/admin/forfattere'} target={'_blank'}>Rediger forfattere</a>
	<br/>

</div>)}