import {useEffect, useState} from "react";
import SiteService from "../services/SiteService";
import animationClasses from "../scss/animations.scss";
import siteService from "../services/SiteService";

function VippSubscribe() {
	const [vippsSessionLoading, setVippsSessionLoading] = useState(false);
	const [paymentOptions, setPaymentOptions] = useState([]);

	useEffect(()=>{
		async function getPaymentOptions(){
			let paymentOptions = await fetch(siteService.apiPath + '/defaultprices');
			if (!paymentOptions.ok)
				return alert('Feil under henting av betalings-alternativ');
			else
				paymentOptions = await paymentOptions.json();
			setPaymentOptions(paymentOptions);
		}
		getPaymentOptions();
	}, []);

	async function startVippsAgreement(productId) {try {
		setVippsSessionLoading(true);
		const createCheckoutSessRes = await fetch(`${SiteService.apiPath}/vipps-create-recurring-agreement/${productId}`);
		const data = await createCheckoutSessRes.json();

		if (data.vippsConfirmationUrl)
			window.location.href = data.vippsConfirmationUrl;

		setVippsSessionLoading(false);
	}catch (e) {
		console.error(e);
		alert('Feil under oppretting av Vipps-sessjon')
		setVippsSessionLoading(false);
	}}

	return (<div style={{margin: 'auto auto', width:'fit-content', textAlign:'center'}}>
		<h1>Vipps Abonner</h1>

		<div id={'subscriptionOptions'}>
			{paymentOptions.map((paymentOption)=>{if (paymentOption.productType === 'subscription')
				return (
					<button className={['buttonDull1',
					!vippsSessionLoading ? animationClasses.animationShimmerWhite : ''
				].join(' ')} style={{borderBottom: "2px solid gray", paddingBottom: "10px"}} onClick={() => {
					startVippsAgreement(paymentOption._id);
				}}>{paymentOption.name}<div>{paymentOption.price} kr.-</div></button>)
			})}
		</div>

		<div id={'vippsIframeContainer'} style={{width: '500px', maxWidth: '95vw'}}></div>
	</div>)
}

export default VippSubscribe;