import { useState, useEffect } from 'react';

export const useTooltip = (ref, textString) => {
	const [showTooltip, setShowTooltip] = useState(false);
	const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

	useEffect(() => {
		if (!ref?.current) return;

		const handleMouseEnter = () => {
			if (!ref.current) return;

			const rect = ref.current.getBoundingClientRect();
			const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

			setTooltipPosition({
				top: rect.top + scrollTop - 50, // Adjusting for spacing
				left: rect.left + scrollLeft + rect.width / 2,
			});
			setShowTooltip(true);
		};

		const handleMouseLeave = () => {
			setShowTooltip(false);
		};

		const element = ref.current;
		element.addEventListener('mouseenter', handleMouseEnter);
		element.addEventListener('mouseleave', handleMouseLeave);

		return () => {
			element.removeEventListener('mouseenter', handleMouseEnter);
			element.removeEventListener('mouseleave', handleMouseLeave);
		};
	}, [ref]);

	useEffect(() => {
		let tooltip;

		if (showTooltip) {
			tooltip = document.createElement('div');
			tooltip.className = 'tooltip';
			tooltip.style.top = `${tooltipPosition.top}px`;
			tooltip.style.left = `${tooltipPosition.left}px`;
			tooltip.textContent = textString;

			document.body.appendChild(tooltip);
		}

		return () => {
			if (tooltip) {
				document.body.removeChild(tooltip);
			}
		};
	}, [showTooltip, tooltipPosition, textString]);

	return { showTooltip, tooltipPosition };
};

export default useTooltip;