import React, {useRef, useState} from 'react';
import ArticleService from "../../services/ArticleService";
import useFileDrop from "../../hooks/useFileDrop";


function ArticleBodyEditor({parts, setParts, endEditingFunc, article, setArticle, isSplitPanel}) {
	if (article) {
		if(!article.bodyParts) {
			setArticle({...article, bodyParts: []})
		}

		[parts, setParts] = [article.bodyParts, setArticle]
	}
	else if(!parts) [parts, setParts] = useState([])

	const moduleTypes = ArticleService.moduleTypes;

	const [currentEditingModule, setCurrentEditingModule] = useState(null);
	const [currentEditingModuleIndex, setCurrentEditingModuleIndex] = useState(null);

	const bodyEditorRef = useRef();
	useFileDrop(bodyEditorRef, (b64)=>{
		//create new image part
		let newParts = [];
		if (Array.isArray(b64)){
			b64.forEach((b64)=> {
				newParts.push({kind: 'image', value: b64, alt: ''});
			});
		}
		else
			newParts.push({kind: 'image', value: b64, alt: ''});
		setArticle({...article, bodyParts: [...parts,...newParts]});

	}, currentEditingModuleIndex!==null || currentEditingModule!==null);

	function handleDeletePart(index) {
		if (article)
			setArticle({...article, bodyParts: parts.filter((_, i) => i!== index)})
		else
			setParts(parts.filter((_, i) => i !== index));
	}

	function handleChangePart(index, newPart) {
		if (article)
			setArticle({...article, bodyParts: parts.map((part, i) => (i === index? newPart : part))})
		else
			setParts(prevParts => prevParts.map((part, i) => (i === index ? newPart : part)));
	}


	function SelectAvailableModule(){
		const refAvailableModules = useRef();
		const addNewModule = ()=>{
			const newPart = {
				kind: refAvailableModules.current.value,
				value: null
			}
			if(article)
				setArticle({...article, bodyParts: [...parts, newPart]})
			else
				setParts([...parts, newPart])
		}

		return(<div>
			<h3>Moduler</h3>
			<select ref={refAvailableModules}>
				{Object.keys(moduleTypes).map((module, index) => {
					if (moduleTypes[module].selectable === false) return null;
					return <option key={index} value={module}>{moduleTypes[module].name}</option>
				})}
			</select>
			<button onClick={addNewModule}>Legg til</button>
		</div>)
	}


	function ModuleList({ parts, setParts }) {
		const reorderArray = (arr, from, to)=>{
			arr.splice(to, 0, arr.splice(from, 1)[0])
			if(article)
				setArticle({...article, bodyParts: [...arr]})
			else
				setParts([...arr]);
		}
		const [draggedOverIndex, setDraggedOverIndex] = useState(null);
		const [draggingIndex, setDraggingIndex] = useState(null);
		const onDragStart = (ev, index)=>{
			setDraggingIndex(index);
		}
		const onDragOver = (ev, index)=>{
			setDraggedOverIndex(index)
			ev.preventDefault();
		}
		const onDrop = ()=>{
			reorderArray(parts, draggingIndex, draggedOverIndex);
		}
		const onDragEnd = ()=>{
			setDraggedOverIndex(null);
			setDraggingIndex(null);
		}

		return (<>
			{parts&& parts.map((part, index) => {
				return (
				<div className={['articleBodyEdit frontpageEdit-chosenModulesList-item',
					draggedOverIndex === index ? 'frontpageEdit-draggedOver' : ''
				].join(' ')} key={index}
					 onDrop={(ev) => onDrop(ev, index)}
					 onDragOver={(ev) => onDragOver(ev, index)}
					 onDragEnd={(ev, index) => onDragEnd(ev, index)}
				>
					<div className={'frontpageEdit-chosenModulesList-index'}>{index + 1}.</div>
					<div className={'frontpageEdit-chosenModulesList-inner'}>
						<div>{moduleTypes[part.kind].name} | </div>
						{part.value && <span style={{wordBreak: "break-all"}}>{part.value.substring(0, 15)}</span>}
						<div className={'frontpageEdit-chosenModulesList-actions'}>
							<button className={'frontpageEdit-chosenModulesList-editButton'} onClick={() => {
								setCurrentEditingModule({...part});
								setCurrentEditingModuleIndex(index)
							}}>Rediger
							</button>
							<button className={'frontpageEdit-chosenModulesList-removeButton'} onClick={() => {
								handleDeletePart(index)
							}}>×
							</button>
						</div>
					</div>

					<div className={'frontpageEdit-chosenModulesList-handle'} draggable={true}
						 onDragStart={(ev) => onDragStart(ev, index)}>&nbsp;</div>

					{isSplitPanel&&<button onClick={() => {
						if(isSplitPanel)document.getElementById('module' + index).scrollIntoView({behavior: 'smooth'})
					}}>👁</button>}
				</div>
			)})}
		</>	);
	}

	function PartEditor({part, setPart, index}) {
		// editor for currentEditingModule
		const valueRef = useRef(part);
		let EditorComponent = moduleTypes[part.kind].editorComponent;

		return (
			<div>
				<button onClick={() => {
					setCurrentEditingModule(null)
					setCurrentEditingModuleIndex(null)
				}}>Avbryt</button>
				<button onClick={() => {
					handleChangePart(index, valueRef.current);
					setCurrentEditingModule(null);
					setCurrentEditingModuleIndex(null);
				}}>Oppdater</button>
				<h3>{moduleTypes[part.kind].name}</h3>
				<EditorComponent valueRef={valueRef} part={part} setPart={setPart} article={article?article:null}/>
			</div>
	)}



	return(<div ref={bodyEditorRef}>
		<h1>Rediger artikkel-kropp</h1>
		{!currentEditingModule&& <button onClick={endEditingFunc}>🞀 Tilbake til artikkel-redigering</button>}
		<div className={'frontpageEdit-chosenModulesList'}>
			{!currentEditingModule ? <>
				<SelectAvailableModule/>
				<ModuleList parts={parts} setParts={setParts}/>
				</>:
				<PartEditor part={currentEditingModule} setPart={setCurrentEditingModule}
							  index={currentEditingModuleIndex}></PartEditor>
			}
		</div>
	</div>)
}



export default ArticleBodyEditor;