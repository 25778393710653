import {useContext} from "react";
import UserContext from "../../store/userContext";
import CategoriesContainer from "../../components/ui/CategoriesContainer";
import TagsContainer from "../../components/ui/TagsContainer";
import classes from "./MinSide.module.scss";

export default function Varsler() {
	const userContext = useContext(UserContext);

	if (!userContext.followedTopics) return null;

	const followedCategories = userContext.followedTopics.filter(topic => topic.topicType === "category");
	console.log(followedCategories)
	const followedTags = userContext.followedTopics.filter(topic => topic.topicType === "tag");

	function UnfollowButton({topic}) {
		return <button onClick={()=>userContext.unfollowTopic(topic.topicType, topic.topicId)}>➖</button>
	}

	function TopicDiv({topic}) {
		return <div><UnfollowButton topic={topic}/>{topic.name}</div>
	}

return (
	<div className={classes.varsler}>
		<h1>Varsler</h1>
		<h3>Fulgte Kategorier</h3>
		{followedCategories.length > 0 ? (
			<CategoriesContainer categories={followedCategories}/>
		) : (
			<p>Ingen fulgte kategorier</p>
		)}

		<h3>Fulgte Stikkord</h3>
		<TagsContainer tags={followedTags} />
	</div>
);
}