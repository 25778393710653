import React, {useState} from "react";
import ArticleRenderer from "./ArticleRenderer";
import ArticleEdit from "../../pages/admin/ArticleEdit";
import {Link} from "react-router-dom";

export default function ArticleEditor2(){
	const [article, setArticle] = useState({});


	const [isSplitPanel, setIsSplitPanel] = useState(false);
	const [flexBasis, setFlexBasis] = useState('40%'); // Initial width of the pane
	const [isResizing, setIsResizing] = useState(false);

	const handleMouseMove = (e) => {
		if (isResizing) {
			const newFlexBasis = `${(e.clientX / window.innerWidth) * 100}%`;
			document.getElementById('panel1').style.flexBasis = newFlexBasis;
		}
	};
	const handleMouseDown = () => {
		setIsResizing(true);
	};
	const handleMouseUp = () => {
		setIsResizing(false);
	};

	return (<>
		<div className={'frontpageEdit-settingsBand'}>
			<Link to={'/artikkel/' + article.urlString} className={'frontpageEdit-settingsBand-link'}>Avbryt redigering</Link>
			<div>
				<label htmlFor={'splitview'}>Splittet visning:</label>
				<input id={'splitview'} type={"checkbox"} onChange={(ev)=>{setIsSplitPanel(ev.target.checked)}} checked={isSplitPanel} />
			</div>
		</div>

		<div className={'frontpageEdit splitter'}
			 onMouseMove={handleMouseMove}
			 onMouseUp={handleMouseUp}
		>
			<div id={'panel1'}>
				<ArticleEdit article={article} setArticle={setArticle} isSplitPanel={isSplitPanel}/>
			</div>

			{isSplitPanel&&<>
				<div id={'rpanResize'} onMouseDown={handleMouseDown}>&nbsp;</div>

				<div id={'panel2'}>
					<ArticleRenderer article={article}/>
				</div></>
			}
		</div>
	</>);
}