import {Link, useParams} from 'react-router-dom';
import {useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import "./Article.scss";
import UserContext from "../store/userContext";
import {Helmet} from "react-helmet";

import articleService from "../services/ArticleService";
import ArticleService from "../services/ArticleService";

import PlussBox from "../components/ui/PlussBox";
import SiteService from "../services/SiteService";


import ArticleRenderer from "../components/articleEdit2/ArticleRenderer";


function Article({article, setArticle}) {
	const userContext = useContext(UserContext);

	const { urlString } = useParams();
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);
	const [isPluss, setIsPluss] = useState(false);

	if(!article)
		[article, setArticle] = useState({});
	else{
		setIsLoading(false);
	}

	const [loadedAuthor, setLoadedAuthor] = useState({});

	const [fetchErrorCode, setFetchErrorCode] = useState(undefined);

	useEffect(()=>{
		async function fetchArticle() {try {
				const response = await fetch(`${SiteService.apiPath}/article/urlstring/${urlString}/aggregate`, { method: "GET" });
				if (!response.ok){
					setFetchErrorCode(response.status);
					return console.log(response.status)
				}
				const data = await response.json();
				setArticle(data.articleInfo);
				setIsPluss(data.notSubscribed);
				if(data.author) setLoadedAuthor(data.author);
				document.title = data.articleInfo.name;
				setIsLoading(false);
		} catch (error) {
			console.error(error);
		}}
		fetchArticle();
	},[urlString]);



	function EditLink(){
		if(userContext.role > 0){
			return <nav className={'adminModerateLinks'}>
				<Link to={'/admin/artikkel-rediger/'+urlString}>Rediger artikkel</Link>
				<button className={'deleteArticleButton'}
					onClick={()=>articleService.delete(article._id,
					(data)=>{
						if (data.destination) {
						return navigate(data.destination);
					}	navigate('/');
					})}
				>Slett artikkel</button>
			</nav>
		}
	}



	if (fetchErrorCode != undefined){return(
		<div className={'errorContainer'}>
			{fetchErrorCode===404 && <h1>Artikkelen finnes ikke</h1>}
			{fetchErrorCode===500 && <h1>Oops.. En feil har oppstått</h1>}
			<h2>Feilkode: {fetchErrorCode}</h2>
			<Link to={'/'}>Til forside</Link>
		</div>
	)}

	if(isLoading)
		return <p>Laster...</p>

	if (!article) return <p>Kunne ikke laste artikkelen..</p>

	return(<>
		{/*<Helmet>
			<meta property="og:title" content={article.name}/>
			<meta name="description" content={article.preface}/>
			<meta property='og:image' content={ArticleService.getUrlHeaderImage(article)}/>
			<meta property='og:type' content='article'/>
		</Helmet>*/}
		<EditLink></EditLink>

		<div className={'articlePlussContainer'}>
			{isPluss && <PlussBox productId={article._id} article={article}></PlussBox>}
		</div>

		<ArticleRenderer article={article} isPluss={isPluss} loadedAuthor={loadedAuthor} />

		</>)
}

export default Article;