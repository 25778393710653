import {Link} from "react-router-dom";
import classes from "../batperm.module.scss";


export default function BatpermAdmin (){


return(<div>
	<h1>Båtperm Admin</h1>
	<p>
		<Link to={"/batperm/admin/havner"}>Havner</Link>
	</p>
	<p>
		<Link to={"/batperm/admin/qr-koder"}>QR-koder</Link>
	</p>
</div>)}