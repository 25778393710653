import SiteService from "./SiteService";

const AdminArticleService = {
	getAll: async function(cb) {
		try {
			const response = await fetch(
				`${SiteService.apiPath}/admin/getarticlepage`,
				{
					method: "POST",
					body: JSON.stringify({
						pageOptions: { page: 0 }
					}),
					headers: { 'Content-Type': 'application/json' }
				}
			);
			const data = await response.json();

			if (cb)
				cb(data);
			else
				return data;
		} catch (reason) {
			console.error(reason);
			alert('Feil under henting av artikler');
		}
	},

	getPage: function(pageNumber, sortBy, searchObj, cb){
		fetch(
			`${SiteService.apiPath}/admin/getarticlepage`,
			{
				method: "POST",
				body: JSON.stringify({
					pageOptions: {page: pageNumber, sortBy: sortBy, searchObj: searchObj}
				}),
				headers: {'Content-Type': 'application/json'}
			}
		).then((response)=> {
			return response.json();
		})
		.then((data)=> {
			if(cb) cb(data);
		})
		.catch(reason => {
			console.error(reason);
			alert('Feil under henting av artikler');
		})
	},

	create: function(author, cb){
		return alert('funksjon ikke implementert');
	},

	save: async function(updateObj, cb){
		console.log(updateObj)
		let res = await fetch(SiteService.apiPath + '/admin/article/propertychange',
			{
				method: 'POST',
				body: JSON.stringify({updateObj: updateObj}),
				headers: {
					'Content-Type': 'application/json'
				}
			});
		if (!res.ok){
			alert('Feil under lagring');
		}
		return cb(true);
	},

	delete: async function(id, cb){
		if (!confirm('Slett artikkel?'))
			return;
		fetch(`${SiteService.apiPath}/article/`+id+'/delete',
			{
				method: "POST",
			}).then((response)=> {
			return response.json();
		}).then((data)=>{
			if(data.success) {
				if(cb)
					cb(data);
			}else{
				confirm('Feil under sletting');
				if(cb)
					cb();
			}
		})
	}
}

export default AdminArticleService;