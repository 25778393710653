import {useEffect, useState} from "react";
import SiteService from "../services/SiteService";
import animationClasses from "../scss/animations.scss";

function VippsBuyArticle() {
	const [vippsScriptLoaded, setVippsScriptLoaded] = useState(false);
	const [vippsSessionOpen, setVippsSessionOpen] = useState(false);
	const [vippsSessionLoading, setVippsSessionLoading] = useState(false);

	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://checkout.vipps.no/vippsCheckoutSDK.js";
		script.async = true;
		script.onload = () => {console.log('scriptLoaded'); setVippsScriptLoaded(true)};

		document.body.appendChild(script);
	}, []);

	async function createVippsCheckoutSess() {try {
		setVippsSessionLoading(true);
		const createCheckoutSessRes = await fetch(`${SiteService.apiPath}/vipps-artikkel-create-session`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			}
		});
		const data = await createCheckoutSessRes.json();

		if (!window['VippsCheckout'])
			return console.error('Feil under start av VippsCheckoutSession');

		window.vippsCheckout = window['VippsCheckout']({
			checkoutFrontendUrl: data.checkoutFrontendUrl,
			iFrameContainerId: "vippsIframeContainer",
			language: "no",
			token: data.token,
		});

		setVippsSessionOpen(true);
		setVippsSessionLoading(false);
	}catch (e) {
		console.error(e);
		alert('Feil under oppretting av Vipps-sessjon')
	}}

	return (<div style={{margin: 'auto auto', width:'fit-content', textAlign:'center'}}>
		<h1>Vipps Kjøp Artikkel</h1>
		{vippsScriptLoaded && <p style={{color:'green'}}>> Vipps script loaded.</p>}
		{!vippsSessionOpen && <button className={['buttonDull1',
			!vippsSessionLoading ? animationClasses.animationShimmerWhite : ''
		].join(' ')} style={{borderBottom: "2px solid gray", paddingBottom: "10px"}} onClick={() => {
			createVippsCheckoutSess();
		}}>Start VippsSession</button>}
		<div id={'vippsIframeContainer'} style={{width: '500px', maxWidth: '95vw'}}></div>
	</div>)
}

export default VippsBuyArticle;