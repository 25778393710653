import SiteService from "./SiteService";

const SponsorService = {
	getSponsorImage: function(sponsor){
		return `${SiteService.apiPath}/image/sponsors/${sponsor._id}`+sponsor.imageName;
	},

	getAll: async function(cb) {
		try {
			const response = await fetch(
				`${SiteService.apiPath}/sponsors/`,
				{
					method: "GET",
				}
			);
			const data = await response.json();
			if (cb)
				cb(data);
			else
				return data;
		} catch (reason) {
			console.error(reason);
			alert('Feil under henting av sponsorer');
		}
	},

	create: function(sponsor, cb){
		if(!sponsor)
			return alert('Feil under oppretting');

		let bodyObj = {'name': sponsor.name};

		fetch(
			`${SiteService.apiPath}/sponsors/`,
			{
				method: "POST",
				body: JSON.stringify(bodyObj),
				headers: {
					'Content-Type': 'application/json'
				}
			}
		).then((response)=> {
			return response.json();
		})
		.then((data)=> {
			if(data.sponsor && cb) {
				cb(data.sponsor);
			}
		}).catch((reason)=>{
			console.error(reason);
			alert('Feil under oppretting')
		})
	},

	save: function(sponsor: {_id:String, name:String, imageB64:String, imageName:String}, cb){
		if(!sponsor)
			return alert('Feil under lagring');

		fetch(
			`${SiteService.apiPath}/sponsors/${sponsor._id}`,
			{
				method: "POST",
				body: JSON.stringify({
					...sponsor
				}),
				headers: {
					'Content-Type': 'application/json'
				}
			}
		).then((response)=> {
			return response.json();
		})
		.then((data)=> {
			if(data.success && cb) {
				cb(data.success);
			}else{
				cb(false);
			}
		}).catch((reason)=>{
			console.error(reason);
			alert('Feil under oppretting')
		})
	},

	delete: function (id, cb){
		fetch(
			`${SiteService.apiPath}/sponsors/delete`,
			{
				method: "POST",
				body: JSON.stringify({
					_id: id
				}),
				headers: {
					'Content-Type': 'application/json'
				}
			}
		).then((response)=> {
			if (response.status == 200) {
				if (cb)
					cb(true);
				else
					return true;
			}
		})
	},
}

export default SponsorService;