import {useEffect, useRef, useState} from "react";
import classes from './ColorPresetPicker.module.scss';
import SiteService from "../../../services/SiteService";
import {set} from "mongoose";

export default function ColorPresetPicker({chooseColorFunc}){
	const [isLoading, setIsLoading] = useState(true);
	const [colors, setColors] = useState(null);

	useEffect(() => {
		loadColors();
	}, []);

	const saveColors = async (colors) => {
		setIsLoading(true);
		let res = await fetch(`${SiteService.apiPath}/config/colors`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
				},
			body: JSON.stringify({colors:colors})
		});
		if (res.status === 200) {
			setIsLoading(false);
		}else {
			alert('Noe gikk galt ved lagring av farger, prøv igjen senere.');
		}
	}

	const loadColors = async ()=>{try {
		setIsLoading(true);
		let res = await fetch(`${SiteService.apiPath}/config/colors`);
		let data = await res.json();
		setColors(data);
		setIsLoading(false);
	}catch (e) {
		console.error(e);
		alert('Noe gikk galt ved henting av farger, prøv igjen senere.');
		setIsLoading(false);
	}}

	const addColor = () => {
		setColors([{name: 'Ny farge', value: '#000000'}, ...colors]);
		saveColors([...colors, {name: 'Ny farge', value: '#000000'}]);
	}


	function SingleColor({color, index}) {
		const [isLoading, setIsLoading] = useState(false);
		const [tmpColor, setTmpColor] = useState(color);
		const [isEdited, setIsEdited] = useState(false);

		const saveColor = (index, newObj) => {
			setIsLoading(true);
			let newColors = colors.map((color, i) => i===index?newObj:color);
			setColors(newColors);
			saveColors(newColors);
			setIsLoading(false);
		}
		const deleteColor = (index) => {
			if (!window.confirm('Er du sikker på at du vil slette fargen?')) return;
			setIsLoading(true);
			let newColors = colors.filter((color, i) => i!==index);
			setColors(newColors);
			saveColors(newColors);
			setIsLoading(false);
		}

		return <div {...{ inert: isLoading ? 'true' : undefined }} className={[classes.colorSingle, isLoading?'loading':''].join(' ')} style={{background:tmpColor.value}}>
			<input className={[classes.nameText, 'input1'].join(' ')} type={"text"} value={tmpColor.name} onInput={(e) => {
				setTmpColor({...tmpColor, name: e.target.value})
				setIsEdited(true);
			}}/>
			<div>
				<input className={classes.colorPreview} type="color" value={tmpColor.value} onInput={(e) => {
					setTmpColor({...tmpColor, value: e.target.value}); setIsEdited(true);
				}} />
				<input className={[classes.colorText, 'input1'].join(' ')} type={"text"} value={tmpColor.value} onInput={(e)=>{
					setTmpColor({...tmpColor, value: e.target.value}); setIsEdited(true);
				}}/>
			</div>

				<div>
					{chooseColorFunc&& <button style={{background:'#d0d0d0',color:'black'}} className={'button1'} onClick={() => chooseColorFunc(tmpColor)}>Velg</button>}
					<button className={'button1'} onClick={() => deleteColor(index)}>Slett</button>
				</div>
				{isEdited&& <div>
					<button className={'button1'} onClick={() => {setTmpColor(color); setIsEdited(false);}}>Avbryt</button>
					<button className={'button1'} onClick={()=>{saveColor(index, tmpColor)}}>Lagre</button>
				</div>}

		</div>
	}

	function ColorSelectList({colors}) {
		if (!colors) return <div>Ingen farger</div>
		return <div className={classes.colorList}>
			{colors.map((color, index) => <SingleColor key={index} color={color} index={index}/>)}
		</div>
	}

	if (isLoading) return <div>Laster...</div>
	return (<div>
		<button type={"button"} className={'button1'} onClick={addColor}>Ny farge</button>
		<ColorSelectList colors={colors} />
	</div>)
}