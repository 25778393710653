import SiteService from "./SiteService";

const CategoryService = {
	sortCategoriesAdjacentToParent : function (categories){
		let categoriesWithoutParent = categories.filter((cat)=>{return cat.parentCategory == null});
		let categoriesWithParent = categories.filter((cat)=>{return cat.parentCategory!= null});

		// go through all categoriesWithoutParent, insert it into categoriesWithParent next to its parentCategory
		categoriesWithParent.forEach((cat)=>{
			let parentIndex = categoriesWithoutParent.findIndex((cat2)=>{return cat2._id === cat.parentCategory});
			categoriesWithoutParent.splice(parentIndex+1, 0, cat);
		})
		return categoriesWithoutParent;
	},

	getAll: async function(cb){try {
		const response = await fetch(SiteService.apiPath+'/categories/', {
			method: "GET",
		});
		const data = await response.json();
		if (!data) return null;

		//sort by data.name
		data.sort((a, b) => a.name.localeCompare(b.name));

		if(cb)
			cb(data);
		else
			return data;
	} catch (error) {
		console.error(error);
		return null;
	}},

	getActive: async function(cb){
		try {
			const response = await fetch(SiteService.apiPath+'/categories/active', {
				method: "GET",
			});
			const data = await response.json();

			if(cb)
				cb(data);
			else
				return data;
		} catch (error) {
			console.error(error);
		}
	},

	getByName: function (name: String, cb) {
		fetch(SiteService.apiPath + '/category/name/'+ name)
		.then((response)=>{
			return response.json();
		})
		.then((data)=>{
			if(cb && data)
				cb(data)
		})
	},


	create: function(category: {name:String}, cb){
		let bodyObj = {name: category.name};

		console.log(bodyObj);
		fetch(
			SiteService.apiPath+'/categories/upsert',
			{
				method: "POST",
				body: JSON.stringify(bodyObj),
				headers: {
					'Content-Type': 'application/json'
				}
			}
		).then((response)=> {
			return response.json();
		})
		.then((data)=> {
			if(data.success) {
				console.log('created cat: ' + category.name + ' with Id: ' + category._id);
				if(cb)
					cb(data.category);
			}else{
				return alert('Feil under oppretting av kategori')
			}
		})
	},

	save: function(category: {name:String, parentCategory: String, _id: String,
		imageB64: String, imageName: String, frontpage: String, description: String
	}, cb){
		let bodyObj = {name: category.name, parentCategory: category.parentCategory, frontpage: category.frontpage, description:category.description};
		if(category.imageB64 && category.imageName) {
			bodyObj.imageB64 = category.imageB64;
			bodyObj.imageName = category.imageName;
		}
		if (category._id){
				bodyObj._id = category._id;
		}

		console.log(category);
		fetch(
			SiteService.apiPath+ '/categories/upsert/',
			{
				method: "POST",
				body: JSON.stringify(bodyObj),
				headers: {
					'Content-Type': 'application/json'
				}
			}
		).then((response)=> {
			return response.json();
		})
		.then((data)=> {
			if(data.success) {
				console.log('created cat: ' + category.name + ' with Id: ' + category._id);
				if(cb)
					cb(true);
			}else{
				return alert('Feil under lagring av kategori')
			}
		})
	},

	delete: async function (catId, cb) {try {
			const response = await fetch(SiteService.apiPath+'/categories/'+catId, {
				method: "DELETE",
			});
			if (response.ok) {
				console.log('deleted cat with id: ' + catId);
				if (cb) {
					cb(true);
				}
				return true;
			} else {
				if (cb)
					cb(false);
				return false;
			}
		} catch (error) {
			console.error(error);
			if (cb) {
				cb(false);
			}
			return false;
		}
	},

	getPage: function(pageNumber, sortBy, searchObj, cb){
		fetch(
			`${SiteService.apiPath}/admin/getcategorypage`,
			{
				method: "POST",
				body: JSON.stringify({
					pageOptions: {page: pageNumber, sortBy: sortBy, searchObj: searchObj}
				}),
				headers: {'Content-Type': 'application/json'}
			}
		).then((response)=> {
			return response.json();
		})
			.then((data)=> {
				if(cb) cb(data);
			})
			.catch(reason => {
				console.error(reason);
				alert('Feil under henting av artikler');
			})
	},
};

export default CategoryService;