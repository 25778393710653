const PaddingEdit = ({
						 paddingLeft, paddingTop, paddingRight, paddingBottom,
						 setPaddingLeft, setPaddingTop, setPaddingRight, setPaddingBottom,
					 }) => {
	const handleInputChange = (side, value) => {
		if (value === '' || value === '0') {
			value = null;
		} else {
			value += 'px';
		}
		switch (side) {
			case 'top':
				setPaddingTop(value);
				break;
			case 'right':
				setPaddingRight(value);
				break;
			case 'bottom':
				setPaddingBottom(value);
				break;
			case 'left':
				setPaddingLeft(value);
				break;
			default:
				break;
		}
	};

	return (
		<div className={'paddingEdit'}>Padding:
			<div>
				<label htmlFor="top">Topp:</label>
				<input
					type="number"
					id="top"
					value={parseInt(paddingTop) || ''}
					onChange={(e) => handleInputChange('top', e.target.value)}
				/>
			</div>
			<div>
				<label htmlFor="right">Høyre:</label>
				<input
					type="number"
					id="right"
					value={parseInt(paddingRight) || ''}
					onChange={(e) => handleInputChange('right', e.target.value)}
				/>
			</div>
			<div>
				<label htmlFor="bottom">Bunn:</label>
				<input
					type="number"
					id="bottom"
					value={parseInt(paddingBottom) || ''}
					onChange={(e) => handleInputChange('bottom', e.target.value)}
				/>
			</div>
			<div>
				<label htmlFor="left">Venstre:</label>
				<input
					type="number"
					id="left"
					value={parseInt(paddingLeft) || ''}
					onChange={(e) => handleInputChange('left', e.target.value)}
				/>
			</div>
		</div>
	);
};

export default PaddingEdit;
