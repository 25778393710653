import {useContext, useEffect, useRef, useState} from "react";
import Helpers from "../../../helpers/Helpers";
import SiteContext from "../../../store/siteContext";
import ContentRibbon from "../../frontpage/ContentRibbon";
import {Link} from "react-router-dom";

export default function ArticleArticleSuggestions({part}){
	if (!part || !part.articles) return null;
	const siteContext = useContext(SiteContext);

	for (let i = 0;i<part.articles.length;i++){
		if (!part.articles[i] || !part.articles[i].name){
			// delete the part
			part.articles.splice(i,1);
			i--;
			continue;
		}
		Helpers.prepareArticleForFrontpageParts(part.articles[i], siteContext.categories)
	}
	function ArticleSuggestion({articlePart, isLast}) {
		return <Link className={'articleSuggestion'} to={articlePart.linkTo?articlePart.linkTo:''} style={{borderRight:isLast?'none':null}}>
			<div className={'articleSuggestionPart1'}>
				<img loading="lazy" src={articlePart.imageLink} alt={articlePart.image&&articlePart.image.alt}/>
			</div>
			<div className={'articleSuggestionPart2'}>
				<div className={'categoryBoxThemed'}>{articlePart.category&&articlePart.category}</div>
				<div className={'articleSuggestion-title'}>{articlePart.name&&articlePart.name}</div>
			</div>
		</Link>
	}

	const swiperRef = useRef(null);
	const [slidesPerViewState, setSlidesPerViewState] = useState('auto');

	useEffect(() => {
		let slidesPerView = '3.2';
		const handleResize = () => {
			if (window.innerWidth < 810 && !slidesPerViewState <= "1.7" && (!slidesPerView <= '1.7')) {
				swiperRef.current.setAttribute('slides-per-view', '1.7');
				setSlidesPerViewState('1.7');
			} else if (window.innerWidth >= 810) {
				swiperRef.current.setAttribute('slides-per-view', slidesPerViewState);
				setSlidesPerViewState(slidesPerViewState);
			}
		};

		// Initial call to set correct slides-per-view on mount
		//handleResize();

		// Event listener for window resize
		//window.addEventListener('resize', handleResize);

		// Cleanup function to remove event listener
		return () => {
			//window.removeEventListener('resize', handleResize);
		};
	}, [slidesPerViewState]);

	useEffect(() => {
		swiperRef.current.swiper.slideTo(0);
	}, [location]);

	console.log(part)

	return(<div className={'articleSuggestions'}>
			<ContentRibbon ribbonText={'LES OGSÅ'}></ContentRibbon>
		<swiper-container className="swiper-1" slides-per-view={slidesPerViewState} navigation="true" ref={swiperRef} style={{width: '100%',height: '100%'}}
						  space-between="40" grab-cursor="true">
			{part.articles.map((articlePart,i) => {
				const isLast = i === part.articles.length - 1;
				return(
				<swiper-slide key={i} style={{width: 'fit-content',height: '100%'}}>
					<ArticleSuggestion articlePart={articlePart} isLast={isLast}/>
				</swiper-slide>)
			})}
		</swiper-container>
	</div>
)
}