import SiteService from "./SiteService";

const AuthorService = {
	getAuthorImage: function(author){
		return `${SiteService.apiPath}/image/authors/${author._id}`+author.imageName;
	},

	getAll: async function(cb) {
		try {
			const response = await fetch(
				`${SiteService.apiPath}/author/all`,
				{
					method: "GET",
				}
			);
			const data = await response.json();
			if (cb)
				cb(data);
			else
				return data;
		} catch (reason) {
			console.error(reason);
			alert('Feil under henting av forfattere');
		}
	},

	create: function(author, cb){
		if(!author)
			return alert('Feil under oppretting');

		let bodyObj = {'name': author.name};

		fetch(
			`${SiteService.apiPath}/author/create`,
			{
				method: "POST",
				body: JSON.stringify(bodyObj),
				headers: {
					'Content-Type': 'application/json'
				}
			}
		).then((response)=> {
			return response.json();
		})
		.then((data)=> {
			if(data.author && cb) {
				cb(data.author);
			}
		}).catch((reason)=>{
			console.error(reason);
			alert('Feil under oppretting')
		})
	},

	save: function(author: {_id:String, name:String, imageB64:String, imageName:String}, cb){
		if(!author)
			return alert('Feil under lagring');

		fetch(
			`${SiteService.apiPath}/author/update`,
			{
				method: "POST",
				body: JSON.stringify({
					_id: author._id,
					name: author.name,
					imageB64: author.imageB64,
					imageName: author.imageName
				}),
				headers: {
					'Content-Type': 'application/json'
				}
			}
		).then((response)=> {
			return response.json();
		})
		.then((data)=> {
			if(data.success && cb) {
				cb(data.success);
			}else{
				cb(false);
			}
		}).catch((reason)=>{
			console.error(reason);
			alert('Feil under oppretting')
		})
	},

	delete: function (id, cb){
		fetch(
			`${SiteService.apiPath}/author/delete`,
			{
				method: "POST",
				body: JSON.stringify({
					_id: id
				}),
				headers: {
					'Content-Type': 'application/json'
				}
			}
		).then((response)=> {
			return response.json();
		})
		.then((data)=> {
			if(data.success) {
				console.log('deleted cat with id: ' + id);
				if(cb)
					cb(true);
			}else{
				cb(false);
			}
		})
	},
}

export default AuthorService;