import SiteService from "../../services/SiteService";

export default function AdminOpen(){
	async function open(arg)  {
		let open = true;
		if(arg==="close"){
			open = false;
		}
		const req = await fetch(`${SiteService.apiPath}/config/open`,{
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({open:open})
		});
		if(req.status === 200){
			if (arg === "close") {
				return alert("Lukket");
			}
			return alert("Åpnet");
		}
	}

	return <div style={styles.wrapper}>
		<button style={styles.button} onClick={() => {open()}}>ÅPNE</button>
		<button style={styles.button2} onClick={() => {open('close')}}>Lukk</button>
	</div>
}
//make the buttons realistic
const styles={
	wrapper:{
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		margin: 20,
		height: "100%",
		width: "auto",
	},
	button:{
		margin: 10,
		padding: 10,
		borderRadius: 5,
		backgroundColor: "limegreen",
		color: "white",
		cursor: "pointer",
		border: "none",
		boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
		fontSize: 16,
		fontWeight: "bold",
		width: 150,
		height: 50,
	},
	button2:{
		margin: 10,
		padding: 10,
		borderRadius: 5,
		backgroundColor: "black",
		color: "white",
		cursor: "pointer",
	}
}