import classes from "./PlussBox.module.scss";
import React, {useContext, useEffect, useState} from "react";
import UserContext from "../../store/userContext";
import Loginbox from "./functional/Loginbox";
import siteService from "../../services/SiteService";

export default function PlussBox({productId, article}){
	const userContext = useContext(UserContext);
	const [paymentOptions, setPaymentOptions] = useState();
	const [awaitingLogin, setAwaitingLogin] = useState(false);

	useEffect(()=>{
		async function getPaymentOptions(){
			let paymentOptions = await fetch(siteService.apiPath + '/defaultprices');
			if (!paymentOptions.ok)
				return alert('Feil under henting av betalings-alternativ');
			else
				paymentOptions = await paymentOptions.json();
			setPaymentOptions(paymentOptions);
		}
		getPaymentOptions();
	}, []);


	function days_between(date1, date2) {
		// The number of milliseconds in one day
		const ONE_DAY = 1000 * 60 * 60 * 24;
		// Calculate the difference in milliseconds
		const differenceMs = Math.abs(date1 - date2);
		// Convert back to days and return
		return Math.round(differenceMs / ONE_DAY);
	}


	const BuySingleArticle = ({index, option, discountExpired}) => {return(
	<div key={index} className={classes.paymentOption}>
		<form action={`${siteService.apiPath}/transaction/create-checkout-session-article/${productId}`} method="POST">
			<button className={classes.subscribeButton}
					type={userContext.isLoggedIn ? "submit" : ''}
					onClick={() => {
						if (!userContext.isLoggedIn) setAwaitingLogin(true)
					}}>
				Kjøp denne artikkelen
			</button>

			{!article.useDefaultPrice ?
				<div>{!article.useDefaultPrice ? article.price : option.price} kr</div>
				:
				<div>{(option.discountPercent && !discountExpired) ? <>
					<s>{option.price} kr</s> {option.price - option.price * (option.discountPercent * 0.01)} kr</>
					:
					<>{option.price} kr</>}
				</div>}
		</form>
		{(article.useDefaultPrice && option.discountPercent && !discountExpired) &&
			<div className={classes.discountTag}>- {option.discountPercent}%!</div>}
	</div>)}

	return(<div className={classes.plussBox}>
		<h2>bli abonnent</h2>
		<p>
			Få full tilgang til alt digitalt innhold av Båtens Verden!
		</p>
		{(!awaitingLogin && paymentOptions) ? <div className={classes.paymentOptions}>
			{paymentOptions.map((option, index) => {
				let discountDate = null;
				if (option.discountExpirationDate)
					discountDate = new Date(option.discountExpirationDate);


				let daysBetween = 0;
				if (discountDate)
					daysBetween = days_between(new Date(), discountDate);
				
				let discountExpired = false;
				if(new Date() > discountDate)
					discountExpired = true;

				if (option.name !== 'artikkel')
				return (<div key={index} className={classes.paymentOption}>
						{(option.discountPercent && !discountExpired) && <>{daysBetween<8  ?
							<div style={{fontSize:'1.1em'}}>Kun <span className={classes.daysBetween}>{daysBetween}</span> dager igjen!</div>
							:
							<div>Tilbud varer frem til {discountDate.toISOString().substr(0, 10)}</div>}</>}
						<form action={
							option.name !== 'artikkel' ? `${siteService.apiPath}/transaction/create-checkout-session-subscription/${option._id}`
								: `${siteService.apiPath}/transaction/create-checkout-session-article/${productId}`
						} method="POST">
							<button style={{padding: '8px'}} className={classes.subscribeButton}
									type={userContext.isLoggedIn ? "submit" : ''}
									onClick={() => {
										if (!userContext.isLoggedIn) setAwaitingLogin(true)
									}}
							>
								{option.name !== 'artikkel' ? <>{option.name}</> : <>Kjøp denne artikkelen</>}
							</button>

							<div>{(option.discountPercent && !discountExpired) ?
								<>
									<s>{option.price} kr</s> {option.price - option.price * (option.discountPercent * 0.01)} kr</>
									:
									<>{option.price} kr</>}
							</div>
						</form>
						{(option.discountPercent && !discountExpired) &&
							<div className={classes.discountTag}>- {option.discountPercent}%!</div>}
				</div>)

				else return (
					<BuySingleArticle discountExpired={discountExpired} index={index} option={option}></BuySingleArticle>
				)
			})}
		{!userContext.isLoggedIn && <><p>Du må være innlogget for å abonnere</p>
			<button style={{padding: '8px',	fontSize: '1.3em'}} className={'buttonDull1'} onClick={()=>{setAwaitingLogin(true)}}>Logg inn</button>
		</>}
		</div>:
			<>
				<Loginbox sendToRefreshBool={true}></Loginbox>
			</>
		}

	</div>)
}