import {useContext, useState} from "react";
import FavouritesContext from "../../../store/favourites-context";
import UserContext from "../../../store/userContext";
import HeartIcon from "../../../svg/HeartIcon";


export default function FavouriteButton({articleId, addText}) {
	const userContext = useContext(UserContext);
	if (!userContext.isLoggedIn) return null;
	const favouritesContext = useContext(FavouritesContext);
	const [wasJustAdded, setWasJustAdded] = useState(false);
	const [wasJustRemoved, setWasJustRemoved] = useState(false);

	const [isLoading, setLoading] = useState(false);

	const onclick = async () => {
		setWasJustAdded(false);
		setWasJustRemoved(false);
		setLoading(true);

		if (!favouritesContext.itemIsFavourite(articleId)) {
			let result = await favouritesContext.addFavourite(articleId);
			if (result) setWasJustAdded(true);
		}
		else{
			let result = await favouritesContext.removeFavourite(articleId);
			if (result) setWasJustRemoved(true);
		}
		setLoading(false);
	}

	return <span className={'pointer favouriteButtonContainer'} onClick={onclick}><button className={[
		'favouriteButton',
		favouritesContext.itemIsFavourite(articleId)?'isFavourite':'',
		wasJustAdded? 'animationHeartbeat':'',
		wasJustRemoved? 'animationFallFade':'',
		isLoading? 'animationPulseColor':'',
		].join(' ')} onClick={onclick}>
		<HeartIcon/>
	</button>
	{addText && <span>
		{favouritesContext.itemIsFavourite(articleId) ? 'Fjern fra favoritter' : 'Legg til i favoritter'}
	</span>}
</span>
}