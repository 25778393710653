import Checkbox from "../ui/Checkbox";

export default function CategoryCheckbox({category, categories, setCategories, classes}){
	let checked = false;
	if (categories.includes(category._id)) checked = true;
	let hasParent = false;
	if (category.parentCategory != null) hasParent = true;

	const onChange = ()=>{
		//remove or add the category to the categories array. Categories only contains the _id of the category that is checked
		if (checked) {
			categories.splice(categories.indexOf(category._id), 1);
		} else {
			categories.push(category._id);
		}
		setCategories(categories.concat());
	}

	return(
		<div className={[classes.categoryCheckbox, hasParent?'':classes.categoryParent].join(' ')}>
			{hasParent&&<span>-</span>}
			<Checkbox state={checked} changeFunc={onChange} label={category.name}/>{checked&&<b> - [{categories.indexOf(category._id)+1}]</b>}
		</div>
	)
}