import {useContext, useRef, useState} from "react";
import UserContext from "../store/userContext";
import {useNavigate} from "react-router-dom";

import styles from "../scss/styles.module.scss";
import SiteService from "../services/SiteService";
import Loginbox from "../components/ui/functional/Loginbox";
import ValidateInput from "../helpers/ValidateInput";

function Register(){
	const navigate = useNavigate();
	const user = useContext(UserContext);

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [name, setName] = useState('');

	const [failReason, setFailReason] = useState('');
	const [userExists, setUserExists] = useState(false);

	const emailInput = useRef();
	const passwordInput = useRef();
	const nameInput = useRef();


	const [passwordTestResulsts, setPasswordTestResults] = useState([]);
	const [usernameTestResults, setUsernameTestResults] = useState([]);

	function registerUserHandler(event){//TODO put in userService
		event.preventDefault();

		fetch(
			SiteService.apiPath+'/user/register',
			{
				method: "POST",
				body: JSON.stringify(
					{
						username: 	emailInput.current.value,
						password: 	passwordInput.current.value,
						name: 		nameInput.current.value
					}),
				headers: {
					'Content-Type': 'application/json'
				}
			}
		).then((response)=> {
			return response.json();
		})
		.then((data)=>{
			if(data.exists){
				return setUserExists(true);
			}
			if(data.name)
				//todo user.setName(data.name);

			if(data.destination)
				navigate(data.destination);
			if(data.reason)
				setFailReason(data.reason);
		})
		.catch((err)=>{
			console.error(err);
			if(err)
				alert('Feil under registrering')
		})
	}


	const testEmail = function (email) {
		let tmpUsernameResults = [];

		if(email.length < 6) {
			tmpUsernameResults = tmpUsernameResults.concat(<div><span style={{color: 'red'}}>Minst 5 tegn</span></div>)
		}else if(email.length > 34){
			tmpUsernameResults = tmpUsernameResults.concat(<div><span style={{color: 'red'}}>Maks 34 tegn</span></div>)
		}


		if(tmpUsernameResults.length === 0){
			setUsernameTestResults(<div><span style={{color: 'limegreen'}}>OK</span></div>);
		}else {
			setUsernameTestResults(tmpUsernameResults);
		}
	}

	const testPassword = function () {
		const password = passwordInput.current.value;

		let validateResults = ValidateInput('password', password);
		let name = nameInput.current.value;
		let email = emailInput.current.value;

		if (name.toLowerCase() != password.toLowerCase()
			&& email.toLowerCase() != password.toLowerCase()
		){}
		else{
			validateResults.passed = false;
			validateResults.message = 'Passord kan ikke være lik epost/navn';
		}

		if (!validateResults.passed)
			setPasswordTestResults(<span style={{color:'red'}}>{validateResults.message}</span>);
		else{
			setPasswordTestResults(<span style={{color:'limegreen'}}>OK</span>);
		}

		return validateResults.passed;
	}


	if(userExists) return(<>
		<div style={{
			margin:"20px auto",
			display:'block',
			width:'fit-content'
		}}>
			<span style={{
				fontWeight:'bold',
				fontSize: '20px',
				paddingRight: '3px',
			}}>{`<`}</span>
			<button className={'buttonDull2'} onClick={(ev)=>{
				ev.preventDefault(); setUserExists(!userExists);
			}}>Tilbake</button>
		</div>
		<Loginbox propUsername={email} propMessage={'Brukeren eksisterer allerede'}></Loginbox>
	</>)

	return(
		<section style={{marginBottom:'20px'}} className={[styles.centered, styles.loginBox].join(' ')}>
		<h1>Registrer</h1>
			<form style={{display: 'grid', textAlign: 'left'}} onSubmit={registerUserHandler}>
				<label htmlFor={"epost"}>E-post</label>
				<input name={"epost"} type="email" placeholder={"E-post"} value={email} maxLength={34} minLength={5} required={true}
					onChange={e => {
					setEmail(e.target.value);
					testEmail(e.target.value)
					testPassword()
				}} ref={emailInput}></input>
				{usernameTestResults}
				<br/>

				<label htmlFor={"passord"}>Passord</label>
				<input name={'passord'} type="password" placeholder={"Passord"} value={password} onChange={e => {
					setPassword(e.target.value);
					testPassword()
				}} ref={passwordInput} required={true} minLength={8} maxLength={128} pattern={"(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"}></input>
				{passwordTestResulsts}
				<br/>

				<label htmlFor={"visningsnavn"}>Visningsnavn</label>
				<input name={'visningsnavn'} type="text" placeholder={"Visningsnavn"} value={name} onChange={(e) => {
					setName(e.target.value);
					testPassword()
				}} ref={nameInput} required={true} minLength={3} maxLength={50}></input>
				<hr style={{width: '100%'}}/>
				<button className={'buttonDull2'} type="submit">Registrer</button>
				{failReason && <div style={{color:'red'}}>{failReason}</div>}
			</form>
		</section>
	)
}

export default Register;