import ArticleTableEditor from "./ArticleTableEditor";
import ImageCarouselEditor from "./ImageCarouselEditor";

export default function ArticleBoatFactsEditor({part, setPart, article}) {
	if (!part)
		part = {...part, table: null, images: []}

	return <div>
		<h3>Tabell</h3>
		<ArticleTableEditor part={part} setPart={setPart}/>
		<hr/>
		<h3>Bilder</h3>
		<ImageCarouselEditor part={part} setPart={setPart} article={article} disableVariantChooser={true}/>
	</div>}