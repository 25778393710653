export default function ArticleTable({part}){
	let table = part;
	if (!table) table = [];
	else if (table.table) table = table.table;

	return	<table className={'articleTable'}>
			<tbody>
			{table&&table.length&&table.map((row, i) => {
				if(i === 0) return <tr key={i}>{row.map((cell, i2) => <th key={i2} className={cell?'has-content':null}>{cell}</th>)}</tr>
				else return(
				<tr key={i}>{row.map((cell, i2) => <td key={i2}>{cell}</td>)}</tr>
			)})}</tbody>
		</table>
	}