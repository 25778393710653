import {useEffect, useState} from "react";
import Helpers from "../../helpers/Helpers";
import classes from "./ProgressUploader.module.scss"


// onlyKeepFileExtention: true = remove fileName except the last ".FILETYPE" when sending the fileName to the server

export default function ProgressUploader({postUrl, onSuccess, maxMegabytes, onlyKeepFileExtention, onlyAcceptImages, postObjB64Name, postObjFileName, extraPostProperties}) {
	const [currentFile, setCurrentFile] = useState(undefined);
	const [fileName, setFileName] = useState(undefined);

	const [isUploading, setIsUploading] = useState(false);
	const [progress, setProgress] = useState(0);

	const [isDraggedOver, setIsDraggedOver] = useState(false);

	function onFileSelected(event){
		if (maxMegabytes && checkFileSize(event.target.files[0], maxMegabytes) !== true) {
			event.target.value = null;
			setIsDraggedOver(false);
			return;
		}
		getBase64(event.target.files[0])

		if(onlyKeepFileExtention)
			//do not upload file, only keep the last extension
			setFileName(Helpers.splitLastOccurrence(event.target.files[0].name, '.')[1]);
		else {
			setFileName(event.target.files[0].name);
		}
	}

	function checkFileSize(file, maxMegabytes) {
		if(file.size > maxMegabytes * 1024 * 1024) {
			alert('Filen må være mindre enn '+maxMegabytes+ ' megabyte.');
			return false;
		}else{
			return true;
		}
	}

	function getBase64(file) {
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			setCurrentFile(reader.result);
		};
		reader.onerror = function (error) {
			console.log('Error: ', error);
		};
	}

	const [isFirstRender, setIsFirstRender] = useState(true);
	useEffect(() => {
		if(isFirstRender)
			return setIsFirstRender(false);

		if(currentFile)
			setIsUploading(true);
			setIsDraggedOver(false);
			const uploadObj = {};
			if(postObjB64Name)
				uploadObj[postObjB64Name] = currentFile;
			else
				uploadObj['b64'] = currentFile;

			if(postObjFileName)
				uploadObj[postObjFileName] = fileName;
			else
				uploadObj['fileName'] = fileName;

			Helpers.uploadWithProgress(uploadObj,
				postUrl, 'POST', setProgress,
				(resultJson)=>{
				setIsUploading(false);
				onSuccess(resultJson);
			}, extraPostProperties?extraPostProperties:null)
	}, [currentFile]);


	let accept = onlyAcceptImages? "image/*" : "*";

	return(
		<div className={[classes.uploaderContainer, (isDraggedOver ? classes.draggedOver:'')].join(' ')}
			 onDragOver={()=>{setIsDraggedOver(true)}}
			 onDragLeave={()=>{setIsDraggedOver(false)}}
		>
			{!isUploading && <label className="btn btn-default p-0">
				<input type="file" accept={accept} onChange={onFileSelected}/>
			</label>}
			{isUploading && (
				<div className="progress my-3">
					<progress max={100} value={progress}>
						{progress}%
					</progress>
				</div>
			)}
		</div>
	)
}