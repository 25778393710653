import {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import ArticleList from "../articles/ArticleList";
import UserContext from "../../store/userContext";
import FavouritesContext from "../../store/favourites-context";
import styles from "../../scss/styles.module.scss";
import SiteService from "../../services/SiteService";

function MyFavourites(){
	const favouritesCtx = useContext(FavouritesContext);

	const [isLoading, setIsLoading] = useState(true);
	const [loadedArticles, setLoadedArticles] = useState([]);


	useEffect(()=>{
		setIsLoading(true);
		fetch(
			`${SiteService.apiPath}/user/articlefavourites`,
			{
				method: "GET",
			}
		).then((response)=> {
			return response.json();
		})
		.then((data)=>{
			if(!data.success === false)
				console.log(data)
			setLoadedArticles(data);
			setIsLoading(false);
		})
	},[useParams()]);



	if(isLoading) {
		return (<>
			<span>Laster...</span>
		</>)
	} else if(loadedArticles.length < 1) {
		return (<>
			<span>Du har ingen favoritter ennå.</span>
		</>)
	}

		return (<>
			<ArticleList articles={loadedArticles}></ArticleList>
			<button className={styles.link1} onClick={()=>{console.log(favouritesCtx)}}>TEST</button>
		</>)

}

export default MyFavourites;