import SiteService from "../../../services/SiteService";
import {useEffect, useRef, useState} from "react";
import NextIcon from "../../../svg/NextIcon";
import ImagePopout from "../../ui/ImagePopout";
import useSwipe from "../../../hooks/useSwipe";

// images is to override part in case it's not in an article body
export default function ArticleImageCarousel({part, article, images}){try {
	const [cPart, setCPart] = useState(part || { images: images?[...images]:[] || [] });
	const [isMobile, setIsMobile] = useState(false);

	const [isFirstRender, setIsFirstRender] = useState(true);
	useEffect(() => {
		if (isFirstRender)
			setIsFirstRender(false);
		else
			return;
		// If images exist but part doesn't, set cPart to a default value
		if (images && !part) {
			let newPart = {images: [...images]};
			if (article&&article.image)
				newPart.images.unshift({
					value: article.image.url,
					desc: article.image.alt,
					alt: article.image.alt
				});
			setCPart(newPart);
		} else {
			setCPart(part);
		}
	}, [part, images]);


	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < 810) {
				setIsMobile(true)
			} else if (window.innerWidth >= 810) {
				setIsMobile(false)
			}
		};

		// Initial call
		handleResize();

		// Event listener for window resize
		window.addEventListener('resize', handleResize);

		// Cleanup function to remove event listener
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	let baseUrl = null;
	if (article && article._id)
		baseUrl = `${SiteService.apiPath}/image/articles/` + article._id + '/';
	const [selectedIndex, setSelectedIndex] = useState(0);
	const imagesRef = useRef();
	const carouselContainerRef = useRef();
	const currentImageRef = useRef(null);

	const changeIndex = (diff) => {
		let newIndex = selectedIndex + diff;

		if (newIndex > cPart.images.length - 1) {
			newIndex = 0;
		} else if (newIndex < 0) {
			newIndex = cPart.images.length - 1;
		}
		setSelectedIndex(newIndex);

		if (!isMobile)
			imagesRef.current.childNodes[newIndex].scrollIntoView({
				behavior: 'smooth',
				block: "nearest",
				inline: "center"
			});
		//Helpers.scrollToTargetOffset(carouselContainerRef.current);
	}

	if ((!cPart || !cPart.images) || !cPart.images.length)
		return null;

	const currentImageSrc = (cPart.images[selectedIndex].value && !cPart.images[selectedIndex].value.startsWith('data:image')) ? baseUrl + cPart.images[selectedIndex].value : cPart.images[selectedIndex].value;

	const getAspectRatio = (img) => {
		try {
			if (imagesRef.current)
			img = imagesRef.current.childNodes[selectedIndex]
		}catch(e){}
		if (!img) return '';

		let ratio = null;
		// find out if image's aspect ratio is more landscape than a square/portrait
		ratio =  img.naturalHeight / img.naturalWidth;
		if (ratio > 1) {
			if (!isMobile) ratio = 'landscape';
			else ratio = 'portrait';
		}else {
			if (!isMobile) ratio = 'portrait';
			else ratio = 'landscape';
		}
		return ratio;
	}

	useSwipe(carouselContainerRef,null,null,
		()=>changeIndex(1),
		()=>changeIndex(-1)
	);

	return (
		<div ref={carouselContainerRef} tabIndex={0}
			 className={['carouselContainer' + cPart.variant, 'carouselContainer'].join(' ')}
			 onKeyDown={(ev) => {
				 if (ev.key === "ArrowRight") {
					 changeIndex(1);
				 } else if (ev.key === "ArrowLeft") {
					 changeIndex(-1);
				 }
			 }} onClick={(ev) => {
			ev.currentTarget.focus()
		}} onMouseEnter={(ev) => {/*ev.currentTarget.focus()*/
		}}>

			<div className={'carouselDisplayedImageContainer'}>
				<img loading="lazy"
					style={{objectFit:getAspectRatio() === 'landscape'? 'contain' : 'cover'}}
					src={currentImageSrc}
					onLoad={(ev) => {
						ev.currentTarget.style.objectFit = getAspectRatio() === 'landscape'? 'contain' : 'cover';
					}}
					alt={cPart.images[selectedIndex].alt}
					ref={currentImageRef}/>
				<button className={'carouselArrow carouselArrowLeft'} onClick={() => changeIndex(-1)}>
					<NextIcon></NextIcon>
				</button>
				<button className={'carouselArrow carouselArrowRight'} onClick={() => changeIndex(+1)}>
					<NextIcon></NextIcon>
				</button>
				{(!isMobile&& cPart.images[selectedIndex].desc) &&
					<div className={'carouselDesc'}><span>{cPart.images[selectedIndex].desc}</span></div>}
			</div>
			{(isMobile && cPart.images[selectedIndex].desc) &&
				<div className={'carouselDesc'}><span>{cPart.images[selectedIndex].desc}</span></div>}
			<div ref={imagesRef} className={'carouselImages'}>
				{cPart.images.map((carouselPart, i) => {
					return (
						<img loading="lazy" key={i} draggable="false"
							 className={selectedIndex === i ? 'carouselSelectedImage' : undefined}
							 src={(carouselPart.value && !carouselPart.value.startsWith('data:image')) ? baseUrl + carouselPart.value : carouselPart.value}
							 alt={carouselPart.alt}
							 onClick={() => setSelectedIndex(i)}
						/>
					)
				})}
			</div>
			<ImagePopout imageRef={currentImageRef} changeSlideShowFunction={changeIndex} currentSlideShowSrc={currentImageSrc}
						 bottomText={<span>{cPart.images[selectedIndex].desc && cPart.images[selectedIndex].desc}</span>}
						 maxImages={cPart.images.length}
						 imageIndex={selectedIndex}
			/>
		</div>
	)
}catch(e){
	console.error(e)
	window.alert(e)
	window.alert('En Feil skjedde under visning av bildegalleri');
	return <div>{e}</div>
}}