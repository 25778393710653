import {useEffect, useState} from "react";
import SponsorService from "../../../services/SponsorService";

export default function SponsorChooser({chooseFunc}){
	const [sponsors, setSponsors] = useState(null);

	useEffect(() => {
		if (!sponsors) loadSponsors();
	}, []);
	const loadSponsors = async () => {
		let tmp = await SponsorService.getAll();
		if(tmp&&tmp.mainObj)
			setSponsors(tmp.mainObj);
	}
console.log(sponsors)
	return <div>
		<h1>Velg annonsør</h1>
		{(sponsors&&sponsors.length>0)&&sponsors.map((fp)=>{
			return <div key={fp.id}><button type={"button"} onClick={()=>{if(chooseFunc)chooseFunc(fp._id)}}>{fp.name}</button></div>
		})}
	</div>
}