import SiteService from "../../../services/SiteService";
import {Link} from "react-router-dom";
import ContentImage from "../ContentImage";
import ContentBlockWrapper from "../ContentBlockWrapper";

export default function ContentBlock7({parts, reverse, customStyle}) {
	let part = {};
	if (!parts) {
		console.log('ContentBlock6 has no parts');
		part = {
			imageLink: `${SiteService.apiPath}/image/articles/640f3f77081a28d9d3a1d4db/header.jpg`,
			name: 'Gå til bæsj og ta en drit i bar',
			preface: 'KJAWdh paiudhawiu dhOAWUd hawdh9 129hd921q nd+2" AHD aWd AdlkjAHWd hAK DwAd øhA',
		}
	}else{
		part = parts[0];
	}

	if(part.isSponsored)
		part.category = 'Annonsørinnhold';

	let content = [
		<div key={1} className={'contentBlock6Part1'}>
			<ContentImage part={part}>
				<img loading={"lazy"} src={part.imageLink}/>
			</ContentImage>
		</div>,
		<div key={2} className={'contentBlock6Part2'}>
			<h3>{part.name}</h3>
			<div className={['category categoryInImage categoryBoxThemed', part.isSponsored?'sponsored':''].join(' ')} style={part.style?part.style:null}>{part.category}</div>
		</div>
	]

	return <ContentBlockWrapper customStyle={customStyle?customStyle:null}>
		<Link to={part.linkTo?part.linkTo:''} style={part.style?part.style:null} className={['contentBlock6 contentBlock contentBlock7', reverse?'reverse':''].join(' ')}>
		{reverse? content.reverse():content}
	</Link>
	</ContentBlockWrapper>
}