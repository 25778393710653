import {useRef, useState} from "react";
import classes from "../../../components/adminEdit/AdminEdit.module.scss";
import AdminEdit from "../../../components/adminEdit/AdminEdit";
import TagService from "../../../services/TagService";
import {CrossButton} from "../../../components/ui/CrossButton";
import OnTopBox from "../../../components/ui/onTopBox";
import FrontpageChooser from "../../../components/ui/functional/FrontpageChooser";
import {Link} from "react-router-dom";

function TagsEdit(){

	const [isLoading, setIsLoading] = useState(true);
	const [loadedTags, setLoadedTags] = useState([]);

	function CheckCross({bool}){return <>
		{bool ? <span>✔️</span>: <span>❌</span>}
	</>}

	function CheckingBox({bool, boxRef}){return <>
		<input type={"checkbox"}  defaultChecked={bool} ref={boxRef}/>
	</>}


	function TableHeaders(){return(
		<div className={classes.th}>
			<div className={classes.td}></div>
			<div className={classes.td}>Navn</div>
			<div className={classes.td}>x Artikler</div>
			<div className={[classes.td, classes.centerCell].join(' ')}>Gjemt fra brukere</div>
			<div className={classes.td}>Forside</div>
		</div>
	)}

	function EditableSectionChildren({obj, index, handleChange}){
		return(<>

		</>)
	}

	function UnEditableSectionChildren({obj, refObj, handleChange, index}){
		refObj.hidden = useRef(obj.hidden);
		const [isChooseFrontpageModalOpen, setIsChooseFrontpageModalOpen] = useState(false);

		return(<>
			<div className={[classes.td, classes.centerCell].join(' ')}>
				<Link to={`/kategorier/?tag=${obj.name}`}>{obj.articleIds&&obj.articleIds.length}</Link>
			</div>
			<div className={[classes.td, classes.centerCell].join(' ')}>
				{obj.isEditable ?
					<CheckingBox boxRef={refObj.hidden} bool={obj.hidden}/>
					:
					<CheckCross bool={obj.hidden}/>
				}
			</div>
			<div className={classes.td}>
				{obj.isEditable?<>
						{obj.frontpage&&obj.frontpage} <button type={"button"} onClick={()=>{console.log(isChooseFrontpageModalOpen);setIsChooseFrontpageModalOpen(true)}}>Velg forside</button>
						{obj.frontpage&&<CrossButton onclick={()=>handleChange('frontpage', null, index)}/>}
					</>
					:<>
						{obj.frontpage&&obj.frontpage}
					</>}
			</div>
			{isChooseFrontpageModalOpen && <OnTopBox onTopEnabled={isChooseFrontpageModalOpen} setOnTopEnabled={setIsChooseFrontpageModalOpen}>
				<FrontpageChooser chooseFunc={(frontpage)=>{handleChange('frontpage', frontpage, index);setIsChooseFrontpageModalOpen(false)}} />
			</OnTopBox>}
	</>)}

	return(
		<AdminEdit
			pageName={'Stikkord'}
			mainService={TagService}
			mainArray={loadedTags}
			setMainArray={setLoadedTags}
			TableHeaders={TableHeaders}
			EditableSectionChildren={EditableSectionChildren}
			UnEditableSectionChildren={UnEditableSectionChildren}
			NewSectionBool={true}
		>
		</AdminEdit>
	)
}

export default TagsEdit;