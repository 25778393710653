import {Link} from "react-router-dom";

export default function ArticleButton({part}) {
	if (!part.link || !part.text) return <div></div>;
	if (part.link.startsWith('/')){
		return <Link style={part.style&&part.style} className={'articleButton'} to={part.link}>{part.text}</Link>
	}

	return (
		<a style={part.style&&part.style} target="_blank" href={part.link}>
			<button className={'articleButton'} type={"button"}>
				{part.text}
			</button>
		</a>
	)
}