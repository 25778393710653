import FadeIn from "../../ui/FadeIn";
import Quote from "../../../svg/Quote";

export default function ArticleQuote({part}){
	function Quote2() {
		return <div className={'quoteContainer'}><q><Quote/>{part.value}</q></div>
	}

	function Quote1() {
		return <div className={'quoteContainer quoteContainer2'}>
			<q>“ {part.value} ”</q>
		</div>

	}

	return <FadeIn threshold={0.9}>{part.variant==2? Quote2() : Quote1()}</FadeIn>
};