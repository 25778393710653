import {Link} from "react-router-dom";

function addMetaToHeader(){
	// attempt to not get url indexed by searchEngines
	document.head.insertAdjacentHTML('beforeend',
		'<meta name="robots" content="noindex" />');
}

function NotFound() {
	addMetaToHeader();
	return(<div className={'centered'}>
		<h1>Siden eksisterer ikke.</h1>
		<Link to={'/'}>Til forside</Link>
	</div>)
}

export default NotFound;