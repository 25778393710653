import adPlacementService from "../../../services/AdPlacementService";
import {useContext, useRef, useState} from "react";
import SiteService from "../../../services/SiteService";
import advertService from "../../../services/AdvertService";
import ContentBlockWrapper from "../ContentBlockWrapper";
import useInView from "../../../hooks/useInView";
import advertImage from "../../../images/adv-2.webp";
import UserContext from "../../../store/userContext";

export default function AdvertBlock2({parts, customStyle}) {
	if (!parts)
		parts = [{}, {}];

	const [ad, setAd] = useState(null);
	const [ad2, setAd2] = useState(null);


	const adRef = useRef();

	const role = useContext(UserContext).role;
	const handleInView = () => {
		if(!parts[0].id || ad) return;
		adPlacementService.getById(parts[0].id, (ad)=>{
			setAd(ad);
		}, role > 0);
		if(!parts[1].id || ad2) return;
		adPlacementService.getById(parts[1].id, (ad)=>{
			setAd2(ad);
		}, role > 0)
	};
	useInView(adRef, {rootMargin:'100px'}, handleInView, true);



	//if (!ad || !ad2)
	//	return null;
	return <ContentBlockWrapper customStyle={customStyle?customStyle:null}>
		<div ref={adRef} className={'advertBlock2 advBlock contentBlock'}>
		<a className={'advertBlock2Part1'} href={ad&&ad.link ? ad.link : ''} target={"_blank"}
		   onClick={() => advertService.advertOnclick(ad&&ad._id)}>
			<img className={'advertBlock2Image'} src={(ad&&ad.wideImage)?`${SiteService.apiPath}/image/adv/${ad.wideImage}`: advertImage}/>
		</a>
		<div></div>
		<a className={'advertBlock2Part2'} href={ad2&&ad2.link ? ad2.link : ''} target={"_blank"}
		   onClick={() => advertService.advertOnclick(ad2&&ad2._id)}>
			<img className={'advertBlock2Image'}
				 src={(ad2&&ad2.wideImage)?`${SiteService.apiPath}/image/adv/${ad2.wideImage}`: advertImage}/>
		</a>
	</div></ContentBlockWrapper>
}