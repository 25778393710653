import {useRef, useState} from "react";
import classes from "../../../pages/admin/ArticleEdit.module.scss";
import OnTopBox from "../../ui/onTopBox";
import VideosEdit from "../../../pages/VideosEdit";
import Checkbox from "../../ui/Checkbox";

export default function ArticleVideoEditor({valueRef, part, setPart}){
	const [isChoosingVideo, setIsChoosingVideo] = useState(false);

	const handleVideoChange = (videoId)=>{
		valueRef.current.value = videoId;
		setIsChoosingVideo(false);
	}

	const handleChangeAutoStart = (checked)=>{
		setPart({...part, autoplay: checked});
		part.autoplay = checked;
	}

	return(<>
		<button type={"button"} onClick={()=>setIsChoosingVideo(!isChoosingVideo)}>Velg video</button>
		{isChoosingVideo&& <OnTopBox setOnTopEnabled={setIsChoosingVideo} onTopEnabled={isChoosingVideo}>
			<VideosEdit chooseVideo={handleVideoChange}/>
		</OnTopBox>}

		<br/>
		<Checkbox label={"Autostart"} changeFunc={handleChangeAutoStart} state={part.autoplay} />
	</>)
}