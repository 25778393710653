import adPlacementService from "../../../services/AdPlacementService";
import {useContext, useRef, useState} from "react";
import SiteService from "../../../services/SiteService";
import advertService from "../../../services/AdvertService";
import ContentBlockWrapper from "../ContentBlockWrapper";
import useInView from "../../../hooks/useInView";
import advertImage from "../../../images/adv-1.webp"
import userContext from "../../../store/userContext";

export default function AdvertBlock1({parts, advertiserName, customStyle}) { // native ad
	if (!parts)
		parts = [{}]

	const [ad, setAd] = useState(null);
	const adRef = useRef();

	const role = useContext(userContext).role;

	const handleInView = () => {
		if(!parts[0].id || ad) return;
		adPlacementService.getById(parts[0].id, (ad)=>{
			setAd(ad);
		}, role > 0)
	};
	useInView(adRef, {rootMargin:'100px'}, handleInView, true);

	 return <ContentBlockWrapper customStyle={customStyle?customStyle:null}>
		 <a ref={adRef} href={(ad&&ad.link)?ad.link:''} target={"_blank"} className={'advertBlock1 advBlock contentBlock'}
			onClick={()=>advertService.advertOnclick(ad._id)}>
		 <div className={'advertBlock1Part1'}>
			 {advertiserName? 'ANNONSØR: '+advertiserName : 'ANNONSE'}
		 </div>
		 <div className={'advertBlock1Part2'}>
		 	<img className={'advertBlock1Image'} src={(ad&&ad.wideImage)?`${SiteService.apiPath}/image/adv/${ad.wideImage}`: advertImage}/>
		 </div>
		 <div className={'advertBlock1Part3'}>
			 <div className={'advertBlock1Part3-1'}>

				<h3>{(ad&&ad.description)?ad.description:'Annonse Annonse Annonse Annonse Annonse Annonse Annonse Annonse Annonse Annonse Annonse'}</h3>
			 </div>
			 {(ad&&ad.logoImage)&&<img className={'advertBlock1Logo'} src={`${SiteService.apiPath}/image/adv/${ad.logoImage}`}/>}
		 </div>
	 </a></ContentBlockWrapper>
}