import classes from "../../../pages/admin/ArticleEdit.module.scss"
import {useRef, useState} from "react";
import ImageEditor from "./ImageEditor";
import SiteService from "../../../services/SiteService";
import useFileDrop from "../../../hooks/useFileDrop";


export default function ImageCarouselEditor({part, article, setPart, disableVariantChooser, overridePropertyName}){
	const propertyName = overridePropertyName? overridePropertyName : 'images';

	const containerRef = useRef();
	if (!part[propertyName])
		part[propertyName] = []

	const [editingImage, setEditingImage] = useState(null);
	const [editingImageIndex, setEditingImageIndex] = useState(null);

	const changeVariant = (ev)=>{
		setPart({...part, variant: ev.target.value});
	}


	const addCarouselPart = (ev)=>{
		ev.preventDefault();
		if(!part[propertyName]) {
			console.log(!part.images)
			part[propertyName] = [];
		}

		let newEl = {
			kind: 'image',
			value: '',
			alt: '',
		}

		setPart({...part, [propertyName]: [...part[propertyName], newEl]});
	}

	function CarouselEdit({carouselIndex, carouselPart}){

		return (<>
			<button className={'buttonDull1'} type={"button"} onClick={(ev) => {
				ev.preventDefault();
				// replace part.images[carouselIndex] with editingImage
				setPart({...part, [propertyName]: [...part[propertyName].slice(0, carouselIndex), editingImage,...part[propertyName].slice(carouselIndex+1)]});
				setEditingImage(null);
			}}>OK</button>
			<ImageEditor part={carouselPart} valueRef={setEditingImage} article={article} index={carouselIndex}
						 disableVariant={true}></ImageEditor>
			<br/>
		</>)
	}

	function reorderArray(arr, from, to){
		arr.splice(to, 0, arr.splice(from, 1)[0]);
		setPart({...part, [propertyName]: arr});
	}
	function deleteImage(indexArr){
		setPart({...part, [propertyName]: [...part[propertyName].slice(0, indexArr),...part[propertyName].slice(indexArr+1)]});
	}

	useFileDrop(containerRef, (b64) => {
		let newParts = [];
		if (Array.isArray(b64)){
			b64.forEach((b64)=> {
				newParts.push({kind: 'image', value: b64, alt: ''});
			});
		}
		else
			newParts.push({kind: 'image', value: b64, alt: ''});
		setPart({...part, [propertyName]: [...part[propertyName],...newParts]});
	});

	function CarouselPart({carouselIndex, carouselPart}){
		return(
			<div className={classes.carouselPart}>
				<div className={classes.carouselImageContainer}
					 onClick={(ev)=>{
					ev.preventDefault();
					setEditingImage(carouselPart);
					setEditingImageIndex(carouselIndex);
				}}>
				{(carouselPart.value && carouselPart.value.startsWith('data:image')) ? (
						<img style={{maxHeight: "200px"}} src={carouselPart.value} alt="" loading="lazy" />
					):((article && carouselPart.value) && (
						<img style={{maxHeight: "200px"}} src={`${SiteService.apiPath}/image/articles/${article._id+'/'+carouselPart.value}`} alt="" loading="lazy" />)
					)}
					<button className={classes.deleteImageButton} type={"button"} onClick={(ev)=>{
						ev.stopPropagation();
						deleteImage(carouselIndex);
					}}>{"x"}</button>
				</div>

				<button className={[classes.carouselPartReorderButton, "buttonDull1"].join(' ')} type={"button"} onClick={()=>{
					reorderArray(part[propertyName], carouselIndex, carouselIndex-1)
				}}>{"<"}</button>

				<button className={[classes.carouselPartReorderButton, "buttonDull1"].join(' ')} type={"button"} onClick={()=>{
					reorderArray(part[propertyName], carouselIndex, carouselIndex+1)
				}}>{">"}</button>
			</div>
	)}

	if (editingImage){return(
		<CarouselEdit carouselPart={editingImage} carouselIndex={editingImageIndex}></CarouselEdit>
	)}

	return(<div ref={containerRef}>
		<div className={classes.carouselParts}>
		{part[propertyName] && part[propertyName].map((carouselPart, i)=>{return(
			<CarouselPart key={i} carouselPart={carouselPart} carouselIndex={i}></CarouselPart>)})}
		</div>
		<br/>
		<button type={"button"} className={'buttonDull1'} onClick={(ev)=>{addCarouselPart(ev)}}>Nytt Bilde</button>
		{!disableVariantChooser&&<>
			<label>Utseende</label>
			<select defaultValue={part.variant} onChange={(ev) => {
				changeVariant(ev)
			}}>
			<option value={1}>Normal</option>
			<option value={2}>Full skjermstørrelse</option>
		</select></>}
	</div>)
};