import "./onTopBox.scss";
import CrossIcon from "../../svg/CrossIcon";
import {useState} from "react";

export default function OnTopBox({successAnimationBool, children, onTopEnabled, setOnTopEnabled, onOk, onTopContent}){
	if(onTopEnabled !== true)
		return null;

	const [success, setSuccess] = useState(false);

	let successTimeout = 0;
	if (successAnimationBool)
		successTimeout = 700;

	async function onClick(ev){
		ev.preventDefault();
		let ok = false;
		ok = await onOk();
		if(ok) {
			setSuccess(true);
			setTimeout(()=> {
				setOnTopEnabled(false);
			}, successTimeout);
		}
		else {
			setSuccess(false);
		}
	}

	let formName = "onTopBoxForm"+Math.random();

return(
	<div className={"onTopBoxContainer"}>
		<div className={"onTopBox"}>
			<button className={"exitButton"} onClick={(ev)=>{ev.preventDefault(); setOnTopEnabled(false);}}><CrossIcon></CrossIcon></button>
			{onTopContent}
			<form id={formName} onSubmit={(ev)=>{
				onClick(ev);
			}}>{children}</form>
			<br/>

			{onOk && <input type={"submit"} form={formName}
					className={[success ? 'onTopBoxButtonSuccess' : '', "onTopBoxConfirmButton buttonDull1"].join(' ')}
					value={"Ok"}/>}

			<span className={'onTopBoxButtonSuccessCheckmark'}> ✓</span>
		</div>
		<div className={"onTopBoxBackdrop"}></div>
	</div>
)}