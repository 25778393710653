import React, {useState} from "react";
import draftToHtml from "draftjs-to-html";
import {convertToRaw, EditorState} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import ContentState from "draft-js/lib/ContentState";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export function TextEditor({valueRef, part, setPart}) {
	if (!part) part = {};
	const [editorState, setEditorState] = useState(initializeEditorState(part));

	const handleEditorStateChange = (editorState) => {
		setEditorState(editorState);
		valueRef.current.value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
	}

	function initializeEditorState(part){
		if (!part.value) return null;
		const blocksFromHtml = htmlToDraft(part.value);
		const {contentBlocks, entityMap} = blocksFromHtml;
		const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
		return EditorState.createWithContent(contentState)
	}

return(
	<Editor
		defaultEditorState={editorState}
		onEditorStateChange={(editorState) => handleEditorStateChange(editorState)}
		wrapperClassName="wrapper-class"
		editorClassName="editor-class"
		toolbar={{
			fontSize: {
				options: ['50%', '75%', '90%', '100%', '110%', '120%', '125%', '150%', '175%', '200%']
			},
			fontFamily: {
				options: ['lato-regular', 'lato-light', 'pt-sans-regular', 'LeckerLiOne', 'Inter', 'Inter-semibold', 'Inter-thin', 'Inter-light', 'Montserrat', 'Montserrat-SemiBold']
			}
		}}
	/>
)}