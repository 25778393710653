import {useEffect, useState} from "react";
import OnTopBox from "../onTopBox";
import ColorPresetPicker from "./ColorPresetPicker";

export default function StyleEditor({style, setStyle}) {
	const [isBGColorModemOpen, setIsBGColorModemOpen] = useState();
	const [isTextColorModemOpen, setIsTextColorModemOpen] = useState();

	if (!style) setStyle({});

	const editStyleProperty = (property, value) => {
		setStyle({
			...style,
			[property]: value
		})
	}
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		console.log('isOpen:', isOpen);
	}, [isOpen]);

	const handleToggle = () => {
		setIsOpen(prevOpen => !prevOpen);
	};

	if (!style) return <div>Laster...</div>
	return <div>
		{/*<div><i>Klikk to ganger</i></div>*/}
		<details open={isOpen} onClick={handleToggle}>
			<summary>Stil</summary>
			<div>
				<label>Bakgrunn: </label>
				<input type={"color"} value={style.background&&style.background} onInput={(ev) => {
					editStyleProperty('background', ev.target.value)
				}}/>
				<button onClick={() => {
					setIsBGColorModemOpen(true)
				}}>Palett
				</button>
				<input type={"text"} value={style.background&&style.background} onInput={(ev) => {
					editStyleProperty('background', ev.target.value)
				}}/>
				<button onClick={() => {
					editStyleProperty('background', '')
				}}>Slett
				</button>
			</div>
			<div>
				<label>Tekst: </label>
				<input type={"color"} value={style.color&&style.color} onInput={(ev) => {
					editStyleProperty('color',ev.target.value)
				}}/>
				<button onClick={() => {
					setIsTextColorModemOpen(true)
				}}>Palett
				</button>
				<input type={"text"} value={style.color&&style.color} onInput={(ev) => {
					editStyleProperty('color',ev.target.value)
				}}/>
				<button onClick={() => {
					editStyleProperty('color','')
				}}>Slett
				</button>
			</div>
		</details>

		{isBGColorModemOpen && <OnTopBox setOnTopEnabled={setIsBGColorModemOpen} onTopEnabled={isBGColorModemOpen}>
			<ColorPresetPicker chooseColorFunc={(c) => {
				editStyleProperty('background',c.value);
				setIsBGColorModemOpen(false);
			}} />
		</OnTopBox>}
		{isTextColorModemOpen && <OnTopBox setOnTopEnabled={setIsTextColorModemOpen} onTopEnabled={isTextColorModemOpen}>
			<ColorPresetPicker chooseColorFunc={(c) => {
				editStyleProperty('color',c.value);
				setIsTextColorModemOpen(false);
			}} />
		</OnTopBox>}
	</div>
}