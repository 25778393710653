import {useEffect, useState} from "react";
import BatpermBoatCard from "../components/BatpermBoatCard";
import classes from "../batperm.module.scss";
import batpermService from "../store/batpermService";
import {useNavigate} from "react-router-dom";
import BatpermBoatCardFull from "../components/BatpermBoatCardFull";
import waveIcon from "../../images/icons/wave.png";

export default function BatpermBoats(){
	const navigate = useNavigate();

	const [boats, setBoats] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		fetchBoats();
	}, []);

	const fetchBoats = async () => {
		setIsLoading(true);
		const data = await batpermService.boat.getAll();
		setBoats(data);
		setIsLoading(false);
	};


	const createNewBoat = () => {
		navigate('/batperm/bat/ny');
	};


	function NewBoatButton() {
		return <div>
			<button className={classes.fullWidthButtonInvert} onClick={(ev)=>{ev.preventDefault(); createNewBoat();}}>Legg til båt</button>
		</div>
	}



	if (isLoading) {return (<h3 className={classes.loadingHeader}>Laster båter...</h3>)}
return(

<div style={boats.length<2 ? {marginTop:'18%'}:{}} className={classes.myBoatsPage}>
	<h2>Velg båt</h2>

	<img className={classes.waveImage} src={waveIcon}/>

	<p>BÅTER DU EIER</p>
	<div className={classes.boatCards}>
		{boats.length>0 && boats.map((boat, index) => {return(
			<BatpermBoatCardFull boat={boat} key={index}/>
		)})}
	</div>
	{boats.length>1 && <NewBoatButton/>}
	{boats.length<2 && <NewBoatButton/>}
</div>

)}