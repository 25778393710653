import {useContext, useEffect, useRef, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import instagramLogo from "../../images/instagram.svg";
import bvLogo from "../../images/b-v-logo.svg";
import batensVerdenHeleNorge from "../../images/batens-verden_hele_norge.svg";
import facebookIcon from "../../images/icons/facebook.svg";
import captainHatIcon from "../../images/icons/captain_hat.svg";

import '../../fonts/Montserrat-Light.ttf';
import '../../fonts/Montserrat-Light.ttf';
import '../../fonts/font-inter-regular.woff';
import '../../fonts/font-inter-bold.woff';

import classes from './MainNavigationTop.module.scss';
import UserContext from "../../store/userContext";
import HamburgerIcon from "../../svg/HamburgerIcon";
import SiteContext from "../../store/siteContext";
import SearchIcon from "../../svg/SearchIcon";
import bannerImage2 from "../../images/banner-image2.webp"
import NotificationBell from "../user/NotificationBell";
import advertService from "../../services/AdvertService";

function MainNavigationTop({isBannerVisible, setIsBannerVisible}){
	const navigate = useNavigate();
	const location = useLocation();
	const userContext = useContext(UserContext);
	const siteContext = useContext(SiteContext);

	const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
	const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(false);
	const bannerRef = useRef(null);

	const noBannerList = ['admin', 'artikkel'];

	useEffect(() => {
		// observe if the banner is visible, hide and make nav fixed when not visible
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting && bannerRef && bannerRef.current)
					setIsBannerVisible(true)
			},
			{
				threshold: 0.01, // Adjust threshold as needed
				rootMargin: '0px 0px' // Adjust rootMargin
			}
		);
		const observer2 = new IntersectionObserver(([entry])=>{
			if (entry.intersectionRatio === 0)
				setIsBannerVisible(false);
		})

		if (bannerRef.current) {
			observer.observe(bannerRef.current);
			observer2.observe(bannerRef.current);
		}

		return () => {
			if (bannerRef.current) {
				observer.unobserve(bannerRef.current);
				observer2.unobserve(bannerRef.current);
			}
		};
	}, [location]);

	function hamburgerOnclick(){
		if(showHamburgerMenu === true)
			setShowHamburgerMenu(false);
	}

	const logoUrl =  userContext.role<2?'/':'/admin';

	function NavList(){
		let links = ['båter og utstyr', 'på tur', 'kunnskap', 'båtfolk og events', 'nyheter'];
		let activeLink = null;
		if (location.pathname.split('/')[1] === 'kategorier')
			try {activeLink = siteContext.selectedMainCategory.toLowerCase();}catch (e) {}

	return(<div className={classes.navList}><swiper-container className="swiper-1" space-between="0" slides-per-view="auto" grab-cursor="true" direction="horizontal" style={{width:'100%',height:'100%'}}>{links.map((link, index)=> {
			//turn first letter of link uppercase
			link = link.charAt(0).toUpperCase() + link.slice(1);
			let isSelected = false;
			if (activeLink === link.toLowerCase()) isSelected = true;
			return <swiper-slide key={index} style={{width:'fit-content'}}><Link to={'/kategorier/'+link} onClick={hamburgerOnclick} className={[classes.mainNavLink, isSelected?'selected':''].join(' ')}><span>{link}</span></Link></swiper-slide>
		})}
	</swiper-container></div>)}

	function HamburgerNavList(){
		if (!siteContext.organizedCategories||siteContext.organizedCategories.length<1) return null;
		return (
		<ul className={classes.hamburgerSection}>
			{siteContext.organizedCategories && Object.keys(siteContext.organizedCategories).map((catId, index) => {
				const cat = siteContext.organizedCategories[catId];
				return (
					<li key={index} style={{order:cat.order?cat.order:null}}>
						<Link to={'/kategorier/' + cat.name} onClick={hamburgerOnclick}>{cat.name}
							<svg width="16" height="14" viewBox="0 0 11 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<path
									d="M0.38296 20.0762C0.111788 19.805 0.111788 19.3654 0.38296 19.0942L9.19758 10.2796L0.38296 1.46497C0.111788 1.19379 0.111788 0.754138 0.38296 0.482966C0.654131 0.211794 1.09379 0.211794 1.36496 0.482966L10.4341 9.55214C10.8359 9.9539 10.8359 10.6053 10.4341 11.007L1.36496 20.0762C1.09379 20.3474 0.654131 20.3474 0.38296 20.0762Z"
									fill="currentColor"></path>
							</svg>
						</Link>
						<ul>
							{cat.children && cat.children.map((subcat, index2) => {
								return (
									<li key={index2}><Link to={'/kategorier/' + subcat.name}
														   onClick={hamburgerOnclick}>{subcat.name}</Link></li>
								)
							})}
						</ul>
					</li>
				)
			})}
		</ul>
		)
	}

	function SearchBox() {
		const searchRef = useRef(null);
		useEffect(() => {
			if (searchRef.current)
				searchRef.current.focus();
		})
		return (
			<form className={[classes.searchBox, 'nameSearch animationFade-in'].join(' ')} onSubmit={(ev)=>{
				ev.preventDefault();
				setIsSearchBoxOpen(false);
				return navigate(`/kategorier/?q=${searchRef.current.value.trim()}`)
			}}>
				<SearchIcon/>
				<input ref={searchRef} className={classes.searchInput} type="text" placeholder="Søk i artikler.."/>
			</form>)
	}


	return (<>
		{!(noBannerList.indexOf(window.location.toString().split('/')[3]) > -1) &&
			<div ref={bannerRef} className={['banner1', isBannerVisible ? 'banner1Hidden' : ''].join(' ')}>
				<div className={'part1Container'}>
					<div className={'part1'}>
						<img className={'part1-1'} src={bannerImage2}/>
						<div className={'part1-2'}>
							<a href={"https://abo.b-v.no/august24/"} target="_blank" rel="noreferrer"
							   onClick={()=>{advertService.incrementTempad('Topp knapp-Abonner nå-august24')}}>Abonner nå</a>
							<a href={"https://abo.b-v.no/kontakt"} target="_blank" rel="noreferrer">Tips oss</a>
					</div>
				</div>
			</div>
			<Link className={'part2'} to={logoUrl}><img className={'part2'} src={batensVerdenHeleNorge} alt='Båtens verden logo'/></Link>
			<div className={'part3'}>
				<div className={'part3-1'}>
					<a href={"https://instagram.com/batensverden/"} target="_blank" rel="noreferrer" style={{
						backgroundImage: `url("${instagramLogo}")`
					}}></a>
					<a href={"https://www.facebook.com/batensverden/"} target="_blank" rel="noreferrer" style={{
						backgroundImage: `url(${facebookIcon})`
					}}></a>
				</div>
			</div>
		</div>}

		<div className={classes.navHolder}>
			<nav className={[classes.grid_navContainer, isBannerVisible?'':'fixed'].join(' ')}>

				<div className={classes.navContainer}>
					<div className={[classes.navbarMenu, classes.navContainer1, isBannerVisible?'transparent':''].join(' ')}>
						<Link onClick={hamburgerOnclick} to={logoUrl} className={classes.logoLink}>
							<img src={bvLogo} alt='Båtens verden logo'/>
						</Link>
					</div>

					<div className={[classes.navbarMenu, classes.navContainer2].join(' ')}>
						{isSearchBoxOpen?
							<SearchBox/>
							:<NavList/>
						}
					</div>

					<div className={classes.navContainer3}>
						{location.pathname.split('/')[1] !== 'kategorier'&&
							<button className={classes.searchButton} type={"button"}
								 onClick={() => setIsSearchBoxOpen(!isSearchBoxOpen)}>
								{isSearchBoxOpen?<span className={'animationFade-in'}>×</span>:<SearchIcon/>}
						</button>}

						{userContext.isLoggedIn&&<NotificationBell/>}

						{userContext.isLoggedIn&&<Link to={userContext.isLoggedIn ? "/min-side" : "/login"}
							   className={['noLink', classes.navSpacer, classes.personContainer].join(' ')}>
							<div style={{display: 'contents', position:'relative'}}>
								<div className={classes.personIconContainer}
									 style={
										userContext.isLoggedIn?{filter:'brightness(1)'}
											:
										{backgroundImage: `url(${captainHatIcon})`}}>
									<div>{userContext.name&&userContext.name[0]}</div>
								</div>
							</div>
						</Link>}

						<button className={[classes.hamburgerButton, showHamburgerMenu ? classes.hamburgerButtonOpened : classes.hamburgerButtonClosed].join(' ')} onClick={(ev)=>{
							ev.preventDefault();
							setShowHamburgerMenu(!showHamburgerMenu)}}>
							<HamburgerIcon></HamburgerIcon>
						</button>
					</div>
				</div>

				<nav {...{ inert: !showHamburgerMenu ? 'true' : undefined }} className={[classes.hamburgerMenu, showHamburgerMenu ? classes.hamburgerMenuOpen : ""].join(' ')}>
					{/*<span className={classes.hamburgerMainNav}>
						<NavList></NavList>
					</span>*/}

					<HamburgerNavList/>
				</nav>
				<div className={[classes.hamburgerBelowBlock, showHamburgerMenu ? classes.hamburgerBelowBlockShown:''].join(' ')}
					 onClick={()=>{setShowHamburgerMenu(!showHamburgerMenu)}}
				></div>
			</nav>
		</div>
	</>)
}

export default MainNavigationTop;