import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Route, Routes} from "react-router-dom";

import './index.scss';
import App from './App';
import BatpermAdmin from "./batperm/pages/BatpermAdmin";
import BatpermBoats from "./batperm/pages/Batperm-boats";
import BatpermBoat from "./batperm/pages/Batperm-boat";
import BatpermHarbors from "./batperm/pages/Batperm-harbors";
import BatpermHarbor from "./batperm/pages/BatpermHarbor";
import BatpermQrCodes from "./batperm/pages/Batperm-qr-codes";
import BatpermQrCode from "./batperm/pages/Batperm-qr-code";
import BatpermLayout from "./batperm/layout/Batperm-layout";
import {UserContextProvider} from "./store/userContext";
import {BatpermContextProvider} from "./batperm/BatpermContext";
import BatpermHome from "./batperm/pages/BatpermHome";

document.title = "Båtens Verden";
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
import CountDown from "./components/CountDown";
// register Swiper custom elements
register();


const BatpermApp = () => {
	document.title = "Båtpermen";
	return(
	<BatpermContextProvider>
		<BatpermLayout>
			<Routes>
				<Route path={''} element={<BatpermHome></BatpermHome>}></Route>
				<Route path={'/bater'} element={<BatpermBoats></BatpermBoats>}></Route>
				<Route path={'/bat/ny'} element={<BatpermBoat isNew={true}></BatpermBoat>}></Route>
				<Route path={'/bat/:boatId/'} element={<BatpermBoat></BatpermBoat>}></Route>
				<Route path={'/bat/:boatId/:submenu'} element={<BatpermBoat></BatpermBoat>}></Route>
				<Route path={'/havn/:harborId'} element={<BatpermHarbor></BatpermHarbor>}></Route>
				<Route path={'/havn/:harborId/ny-bat'} element={<BatpermBoat fromHarbor={true} isNew={true}></BatpermBoat>}></Route>


				<Route path={'/admin'} element={<BatpermAdmin></BatpermAdmin>}></Route>
				<Route path={'/admin/havner'} element={<BatpermHarbors></BatpermHarbors>}></Route>
				<Route path={'/admin/qr-koder'} element={<BatpermQrCodes></BatpermQrCodes>}></Route>
			</Routes>
		</BatpermLayout>
	</BatpermContextProvider>
)};

const BatpermQR = () => {return( // people dont need to be logged in to see this so it has its own route
	<BatpermContextProvider>
		<Routes>
			<Route path={'/:QRCodeId'} element={<BatpermQrCode></BatpermQrCode>}></Route>
		</Routes>
	</BatpermContextProvider>
)};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

		<BrowserRouter basename={''}>
			{/*<Helmet>
				<meta property="og:title" content={'Båtens Verden'}/>
				<meta name="description" content={'Båtens Verden'}/>
				<meta property='og:locale' content='no,nb,no_NO,no_NB'/>
				<link rel="stylesheet" href="https://use.typekit.net/pib1fzb.css"/>
			</Helmet>*/}
			<UserContextProvider>
				<Routes>
					<Route path={'/*'} element={<App></App>}></Route>
					<Route path={'/batperm/qr/*'} element={<BatpermQR></BatpermQR>}></Route>
					<Route path={'/batperm/*'} element={<BatpermApp></BatpermApp>}></Route>
				</Routes>
			</UserContextProvider>
		</BrowserRouter>

);