import CategoryBox from "./CategoryBox";

export default function CategoriesContainer({categories}){
	if (!categories) return;

return(
	<div style={{flexDirection:'row', flexFlow:'wrap'}} className={'categoryBoxesContainer'}>
		{categories.map((category,index) => {
			return <CategoryBox category={category} key={index} />
		})}
	</div>
)}