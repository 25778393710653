import React from 'react';

const ArticleIcon = () => (
	<svg
		width="60"
		height="40"
		viewBox="0 0 24 24"
		fill="none"
		stroke="currentColor"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect
			x="2"
			y="4"
			width="20"
			height="16"
			rx="2"
			ry="2"
			stroke="inherit"
			strokeWidth="1"
			fill="none"
		/>
		<rect x="4" y="6" width="8" height="2" fill="inherit"  rx="1"/>
		<rect x="4" y="10" width="8" height="2" fill="inherit" rx="1"/>
		<rect x="4" y="14" width="8" height="2" fill="inherit" rx="1"/>
		<rect x="14" y="6" width="6" height="6" fill="inherit" rx="1"/>
	</svg>
);

export default ArticleIcon;
