import ArticleImage from "./ArticleImage";
import {useState} from "react";
import ArticleImageCarousel from "./ArticleImageCarousel";

function FactsTable({part}){
	let table = part;
	if (!table) table = [];
	else if (table.table) table = table.table;

	//let headerRow = Array(table[0].length).fill("");
	//headerRow[0] = "Spesifikasjoner";
	//const table = [headerRow, ...table]


	return <div>
		<table>
			<tbody>
			{table&&table.length&&table.map((row, i) => {
				if (i === 0) return <tr key={i}>{row.map((cell, i2) => <th key={i2}>{cell}</th>)}</tr>
				return(
					<tr key={i}>{row.map((cell, i2) => <td key={i2}>{cell}</td>)}</tr>
				)})}</tbody>
		</table>
	</div>}

export default function ArticleBoatFacts({part, article}){
	if (!part || !article) return null;
	const [currentPage, setCurrentPage] = useState(0);

	const images = {images: part.images.slice(1,9)}

	return <div className={'articleBoatFacts'}>
		<div>
			{part.images&&part.images.length>1&&<div className={'articleBoatFactsNavigation'}>
				<button type={"button"} className={[currentPage === 0 ? 'selected' : ''].join(' ')}
						onClick={() => setCurrentPage(0)}>SPESIFIKASJONER
				</button>
				<button type={"button"} className={[currentPage === 1 ? 'selected' : ''].join(' ')}
						onClick={() => setCurrentPage(1)}>PLANLØSNING
				</button>
			</div>}
			{(currentPage===0&&part.table)&&<FactsTable part={part} />}
			{(currentPage===1&&part.images)&&<div className={'animationFade-in'}><ArticleImageCarousel part={images} article={article}/></div>}
		</div>
		{currentPage===0&&<div>
			{part.images && <ArticleImage part={part.images[0]} article={article}/>}
		</div>}
	</div>
}