import { useEffect, useRef, useState } from "react";
import SiteService from "../../services/SiteService";
import siteService from "../../services/SiteService";
import Helpers from "../../helpers/Helpers";
import "./statistics.scss";

export default function Statistics() {
	const [scriptLoaded, setScriptLoaded] = useState(false);
	const chart = useRef(null);
	const [data, setData] = useState(null);
	const [timeRange, setTimeRange] = useState('day');
	const [startDate, setStartDate] = useState(
		new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0] //today minus one week
	);
	const [endDate, setEndDate] = useState(Helpers.getISODate());

	async function start() {
		await import('../../redis/chart.min.js');
		setScriptLoaded(true);
	}

	useEffect(() => {
		if (!scriptLoaded) start();
	}, [scriptLoaded]);

	const getData = async (resource) => {
		const queryParams = new URLSearchParams({
			timeRange,
			startDate,
			endDate
		}).toString();

		const response = await fetch(`${SiteService.apiPath}/statistics/${resource}?${queryParams}`);
		const data = await response.json();
		setData(data);
		return data;
	}

	const createChart = async (resource, label) => {
		let data = await getData(resource);
		if (data) {
			try {
				if (chart.current) chart.current.destroy();
				const ctx = document.getElementById("chart").getContext("2d");
				chart.current = new Chart(ctx, {
					type: 'bar',
					data: {
						labels: data.map(v => v._id),
						datasets: [{
							label,
							data: data.map(v => v.count),
							backgroundColor: 'rgba(75, 192, 192, 0.2)',
							borderColor: 'rgba(75, 192, 192, 1)',
							borderWidth: 1
						}]
					},
					options: {
						scales: {
							y: {
								beginAtZero: true
							}
						}
					}
				});
			} catch (error) {
				console.error('Failed to load Chart.js:', error);
			}
		}
	};

	const createVisitsPerDayChart = () => createChart('visits', 'Besøk');
	const createPageviewsPerDayChart = () => createChart('pageviews', 'Sidevisninger');

	const showMostViewedPages = async () => {
		let data = await getData('most-viewed-pages');
		if (data) {
			setData(data); // Assuming you want to set the data for display in a table or similar
		}
	};

	if (!scriptLoaded) {
		return <div>Laster...</div>
	}

	return (<div className={'adminStatistics'}>
		<h2>Statistikk</h2>
		<div>
			<h3>Graf</h3>
			<label>
				Tidsrom:
				<select value={timeRange} onChange={e => setTimeRange(e.target.value)}>
					<option value="day">Dag</option>
					<option value="week">Uke</option>
					<option value="month">Måned</option>
				</select>
			</label>
			<label>
				Startdato:
				{timeRange==="day"&&<input type="date" value={startDate}
						onChange={e => setStartDate(e.target.value)}/>}
				{timeRange==="month"&&<input type="date" value={startDate}
						onChange={e => setStartDate(e.target.value)}/>}
				{timeRange==="week"&&<input type="date" value={startDate}
						onChange={e => setStartDate(e.target.value)}/>}
			</label>
			<label>
				Sluttdato:
				<input type="date" value={endDate} onChange={e => setEndDate(e.target.value)} />
			</label>
			<div>
				<button onClick={createVisitsPerDayChart}>Besøk</button>
				<button onClick={createPageviewsPerDayChart}>Sidevisninger</button>
				<button onClick={showMostViewedPages}>Mest viste sider</button>
			</div>
		</div>

		<div>
			<canvas id="chart" width={'500'} height={'500'} style={{ width: '400px', height: '400px', maxHeight: '70vh' }} />
		</div>

		<div>
			<h3>Tabell</h3>
			{data && Array.isArray(data) && (
				<table>
					<thead>
					<tr>
						<th></th>
						<th></th>
					</tr>
					</thead>
					<tbody>
					{data.map((item, index) => (
						<tr key={index}>
							<td>{decodeURI(item._id)}</td>
							<td>{item.count}</td>
						</tr>
					))}
					</tbody>
				</table>
			)}
		</div>

		<div>
			<h3>Eksporter CSV</h3>
			<a href={`${siteService.apiPath}/statistics/visits/csv`} download="visits.csv">
				<button>Besøk</button>
			</a>
		</div>
	</div>)
}
