import ProgressUploader from "../batperm/components/ProgressUploader";
import SiteService from "../services/SiteService";
import {useEffect, useState} from "react";
import VideoPlayer from "../components/ui/VideoPlayer";
import Checkbox from "../components/ui/Checkbox";


const fetchVideos = async () => {
	let res = await fetch(`${SiteService.apiPath}/video/all`, {method: "GET"});
	// reverse order of res json
	let videos = await res.json();
	videos.reverse();
	return videos;
}

export default function VideosEdit({chooseVideo}){
	const [videos, setVideos] = useState([]);
	const [tempEditingVideo, setTempEditingVideo] = useState(null);

	useEffect(() => {
		fetchVideos().then(setVideos);
	}, []);

	const onUploadSuccess = (json) => {
		if (!json) return;
		// replace video with _id same as json._id
		setVideos(prev => prev.map(video => video._id === json._id? json : video));
	}

	const createNewVideo = async () => {
		let res = await fetch(`${SiteService.apiPath}/video`, {method: "POST"});
		if (!res.ok)
			throw new Error("Error creating video");
		//add res.json to videos state
		let newVid = await res.json();
		setVideos(prev => [newVid, ...prev]);
		setTempEditingVideo(newVid);
		return true;
	}

	const saveVideo = async (video) => {
		// remember header application/json
		let res = await fetch(`${SiteService.apiPath}/video/${video._id}`,
			{method: "POST", body: JSON.stringify(video), headers: {"Content-Type": "application/json"}});
		if (!res.ok) {
			if (res.statusCode === 413) // 413 payload too large
				return alert('Filen er for stor');
			return alert('Feil ved lagring av video');
		}
		//add res.json to videos state
		setVideos(prev => prev.map(video => video._id === video._id? video : video));
		setTempEditingVideo(null);
		return true;
	}

	const deleteVideo = async (videoId) => {
		if (!confirm('Er du sikker på at du vil slette video?')) return;
		let res = await fetch(`${SiteService.apiPath}/video/${videoId}`, {method: "DELETE"});
		if (!res.ok) {
			return alert('Feil ved sletting av video');
		}
		//remove res.json from videos state
		setVideos(prev => prev.filter(video => video._id!== videoId));
		return true;
	}
	const deleteThumbnail = async (videoId) => {
		if (!confirm('Er du sikker på at du vil slette thumbnail?')) return;
		let res = await fetch(`${SiteService.apiPath}/video/${videoId}/thumbnail`, {method: "DELETE"});
		if (!res.ok) {
			return alert('Feil ved sletting av thumbnail');
			}
		//remove thumbnailExtension from videos state
		setVideos(prev => prev.map(video => video._id === videoId? {...video, thumbnailExtension: null} : video));
		return true;
	}

	const VideoSingle = ({video, i}) => {
		const isEditing = tempEditingVideo === video;
		const [isLoading, setIsLoading] = useState(false);

		const [name, setName] = useState(video.name || "");
		const [description, setDescription] = useState(video.description || "");
		const [link, setLink] = useState(video.link || "");
		const [linkText, setLinkText] = useState(video.linkText || "");
		const [sponsored, setSponsored] = useState(video.sponsored || false);

		const [doCompress, setDoCompress] = useState(true);

		const resetValues = () => {
			setName(video.name);
			setDescription(video.description);
		}

		return(
			<div {...{ inert: isLoading ? 'true' : undefined }} className={['videoSingle',isLoading?'loading':''].join(' ')} key={video._id}>
				<div className={'videoEditButtons'}>
					<span>{i + 1}.</span>
					{!isEditing ? <>
						{chooseVideo && <button type={"button"} onClick={()=>chooseVideo(video._id)}>Velg</button>}
						<button type={"button"} onClick={() => {setTempEditingVideo(video);}}>
							Rediger
						</button>
						<button type={"button"} onClick={async ()=>{
							setIsLoading(true);
							await deleteVideo(video._id);
							setIsLoading(false);
						}}>Slett
						</button>

						</>: <>

						<button onClick={() => {
								setTempEditingVideo(null);
								resetValues();
							}}>
							Avbryt
						</button>
						<button type={"button"} onClick={async () => {
								setIsLoading(true);
								await saveVideo(tempEditingVideo)
								setIsLoading(false);
							}}>
							Lagre
						</button>
						</>
					}
				</div>
				<label>Tittel</label>
				<input disabled={!isEditing} type={"text"} value={name} onChange={(e) => {
					tempEditingVideo.name = e.target.value;
					setName(e.target.value);
				}}/>
				{/*<label>Beskrivelse</label>
				<input disabled={!isEditing} type={"text"} placeholder={'Ikke i bruk'} value={description} onChange={(e) => {
					tempEditingVideo.description = e.target.value;
					setDescription(e.target.value);
				}}/>*/}
				<label>Lenke</label>
				<input disabled={!isEditing} type={"text"} placeholder={'Ikke inkluder domenenavn om lokal artikkel'} value={link} onChange={(e) => {
					tempEditingVideo.link = e.target.value;
					setLink(e.target.value);
				}}/>
				<label>Lenke-tekst</label>
				<input disabled={!isEditing} type={"text"} placeholder={'Lenke'} value={linkText} onChange={(e) => {
					tempEditingVideo.linkText = e.target.value;
					setLinkText(e.target.value);
				}}/>
				{isEditing ? <Checkbox state={sponsored} setState={setSponsored} changeFunc={(newVal)=>{tempEditingVideo.sponsored = newVal}} label="Sponset" />
					: <>Sponset: {sponsored?'ja':'nei'}</>
				}
				{isEditing && <Checkbox state={doCompress} setState={setDoCompress} label="Komprimer video" />}
				{isEditing && <><label>Last opp Video (komprimering tar flere minutter)</label>
					<ProgressUploader maxMegabytes={500} postUrl={`${SiteService.apiPath}/video/${video._id}`} onSuccess={onUploadSuccess} onlyKeepFileExtention={true} extraPostProperties={{doCompress}}/></>
				}
				{video.fileExtension && <VideoPlayer videoId={video._id}/>}
				{isEditing && <><label>Last opp Thumbnail</label>
					<ProgressUploader maxMegabytes={500} postUrl={`${SiteService.apiPath}/video/${video._id}`} onSuccess={onUploadSuccess} onlyAcceptImages={true} onlyKeepFileExtention={true} postObjB64Name={'thumbnailB64'} postObjFileName={'thumbnailExtension'}/></>
				}
				<div>Har thumbnail?: {video.thumbnailExtension?"Ja ":"Nei "}
					{(isEditing && video.thumbnailExtension) && <button type={"button"} onClick={async ()=>{
						setIsLoading(true);
						await deleteThumbnail(video._id)
						setIsLoading(false);
					}}>Fjern</button>}
				</div>
				<div className={'videoStats'}>
					<div className={'smallId'}>Id: <i>{video._id}</i></div>
					{video.fileSizeMegabytes&&<div className={'smallId'}>{video.fileSizeMegabytes}MB</div>}
					{video.linkClickCount&&<div className={'smallId'}>Link-klikk: {video.linkClickCount}</div>}
				</div>
			</div>
		)
	}

	return <div className={'videoEdit'}><h1>Videoer</h1>
		<button type={"button"} onClick={createNewVideo}>Ny video</button>
		<br/>
		<br/>
		<div className={'videoList'}>{videos.map((video, i) => <VideoSingle key={video._id} video={video} i={i} />)}</div>
	</div>
}