import classes from "./BatPermBoatList.module.scss";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";

export default function BatpermBoatList({boats, actionButton}) {

const [sortObj, setSortObj] = useState({by: 'modelName', order: 'desc'});

const sortBoats = ()=>{
	return boats = boats.sort((a,b) => {
		if (sortObj.order === 'desc') {
			return a[sortObj.by] > b[sortObj.by]? 1 : -1;
		} else {
			return a[sortObj.by] < b[sortObj.by]? 1 : -1;
		}

	})
}

boats.forEach((boat, i) => {
	if (!boat.ownerId)
		return;

	if (boat.ownerId.name)
		boats[i].ownerName = boat.ownerId.name;
	if (boat.ownerId.email)
		return boats[i].ownerEmail = boat.ownerId.email;

	boats[i].isHarborOwned = true;
});

useEffect(() => {
	sortBoats();
	console.log(sortObj)
}, [sortObj]);

const changeSortBy = (propertyName)=>{
	let sortOrder = "";
	if (sortObj.by === propertyName)
		sortOrder = sortObj.order === 'asc'? 'desc':'asc';
	else sortOrder = 'desc';

	setSortObj({by: propertyName, order: sortOrder})
}

function TableHead({displayName, propertyName}) {
	return <th onClick={()=>changeSortBy(propertyName)}>
		<span className={sortObj.by===propertyName ? classes.sortActive:''}>{displayName}</span>
		{sortObj.by===propertyName && <>{
			sortObj.order === 'asc'? '↑':'↓'
		}</>}
	</th>
}

return(

<div className={classes.tableContainer}>
	<table>
		<tbody>
		<tr>
			<th>#</th>
			<TableHead displayName={'Model'} propertyName={'modelName'}/>
			<TableHead displayName={'Navn'} propertyName={'name'}/>
			<TableHead displayName={'Eier navn'} propertyName={'ownerName'}/>
			<TableHead displayName={'Eier e-post'} propertyName={'ownerEmail'}/>
			<TableHead displayName={'Telefon'} propertyName={'contactPhoneNumber'}/>
			<th></th>
		</tr>
			{boats.map((boat,index) => (
				<tr key={boat._id}>
					<td>{index+1}</td>
					<td>{boat.modelName}</td>
					<td>{boat.name}</td>
					<td>{boat.ownerName}</td>
					<td>{boat.ownerEmail}</td>
					<td>{boat.contactPhoneNumber}</td>
					<td className={classes.actions}>
						<Link to={`/batperm/bat/${boat._id}`}>Vis</Link>
						{actionButton &&
							<button  onClick={()=>actionButton.action(boat)}>
								{actionButton.text}
							</button>
						}
					</td>
				</tr>
			))}
		</tbody>
	</table>
</div>
)}
