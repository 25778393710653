import classes from "../batperm.module.scss";
import Loginbox from "../../components/ui/functional/Loginbox";
import SiteService from "../../services/SiteService";
import {useContext, useEffect, useState} from "react";
import UserContext from "../../store/userContext";
import {Link} from "react-router-dom";
import batpermLogoWithTextWhite from "../../images/batperm-logo-withtext-white.svg"

export default function BatpermQRBoat({boatId}){
	const [boat, setBoat] = useState(null);
	const userContext = useContext(UserContext);

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		getBoat();
	}, []);

	const getBoat = async () => {
		const response = await fetch(`${SiteService.apiPath}/batperm/boat/${boatId}`);
		if (!response.ok){
			alert('Båt ikke funnet');
			setBoat(null);
			return setIsLoading(false);
		}

		const data = await response.json();
		setBoat(data);

		setIsLoading(false);
	};

	function BoatImageEl (){return(
		boat.imageName && <img src={`${SiteService.apiPath}/image/batperm-boat/${boat._id}/${boat.imageName}`}/>
	)}

	if (isLoading) return <div>Laster båt...</div>;
	if (!boat) return <div>Båt ikke funnet.</div>;
	return(
	<div className={[classes.publicBoatContainer, classes.batpermContainer].join(' ')}>
		<img style={{width:'90%',margin:'1em'}} src={batpermLogoWithTextWhite}/>
		<div className={classes.publicBoat}>
			<div className={classes.publicBoatHeader}>
				<h1>{boat.modelName && boat.modelName}</h1>
			</div>
			<BoatImageEl />
			<div>
				<h2>Info</h2>
				{boat.modelName && <p>Modell: {boat.modelName}</p>}
				{boat.name && <p>{'Navn: «'+boat.name+'»'}</p>}
				{boat.productionYear && <p>Produktionsår: {boat.productionYear}</p>}
				{boat.lengthMeters && <p>Lengde (m): {boat.lengthMeters}</p>}
				{boat.widthMeters && <p>Bredde (m): {boat.widthMeters}</p>}
				{boat.depthMeters && <p>Dybde (m): {boat.depthMeters}</p>}
				{boat.weightKilograms && <p>Vekt (kg): {boat.weightKilograms}</p>}
				{boat.motorName && <p>Motor: {boat.motorName}</p>}
				{boat.motorProductionYear && <p>Motor-produksjonsår: {boat.motorProductionYear}</p>}
			</div>
			{boat.isContactPublic && <div>
				<h2>Kontakt-info</h2>
				{boat.contactName && <p>Navn: {boat.contactName}</p>}
				{boat.contactPhoneNumber && <p>Telefon: {boat.contactPhoneNumber}</p>}
				{boat.contactEmail && <p>E-post: {boat.contactEmail}</p>}
			</div>}

			{!userContext.isLoggedIn && <Loginbox></Loginbox>}
			{boat._requesterIsOwner && <Link className={classes.fullWidthButtonInvert} to={`/batperm/bat/${boat._id}`}>Åpne</Link>}
			{boat._editable && <Link className={classes.fullWidthButtonInvert} to={`/batperm/bat/${boat._id}/sjosetting`}>Opptak/uttak</Link>}
		</div>
		<div className={classes.publicBoatFooter}></div>
	</div>)}