export default function NextIcon() {
	return(
	<svg width="40" height="37" xmlns="http://www.w3.org/2000/svg">
		<g id={"hamburgerIcon"} stroke="inherit" strokeWidth={2} strokeLinecap={"round"}>
			<line
				  x2={8} y2={18} y1="10" x1="17"/>
			<line
				  x2={8} y2={18} y1="26" x1="17"/>
		</g>
	</svg>)
}