import ArticleImage from "./ArticleImage";
import ArticleQuote from "./ArticleQuote";
import FadeIn from "../../ui/FadeIn";
import ArticleTable from "./ArticleTable";

export default function ({part, key, article}) {
	function Main({subPart}) {
		if (subPart.kind === 'text')
			return <div dangerouslySetInnerHTML={{__html: subPart.value}}></div>
		else if (subPart.kind === 'image')
			return <ArticleImage article={article} part={subPart} key={key}/>
		else if (subPart.kind === 'quote')
			return <ArticleQuote part={subPart}/>
		else if (subPart.kind === 'table')
			return <ArticleTable part={subPart}/>
	}

	if (part.style && part.style.background) {
		part.style = {padding: '30px',...part.style};
	}
	if ((part.part1&&part.part1.kind) === 'text' && (part.part2&&part.part2.kind) === 'text'){
		part.style = {...part.style,...{alignItems:'baseline'}}
	}

	return <FadeIn><div key={key} className={'splitPart'} style={part.style?part.style:null}>
			{(part.part1) && <Main subPart={part.part1}/>}
			{(part.part2) && <Main subPart={part.part2}/>}
	</div></FadeIn>
}