import {useEffect, useRef, useState} from "react";
import AdminEdit from "../../../components/adminEdit/AdminEdit";
import AdminArticleService from "../../../services/AdminArticleService";
import classes from "../../../components/adminEdit/AdminEdit.module.scss";
import {Link} from "react-router-dom";
import AuthorService from "../../../services/AuthorService";

function AdminArticles(){

	const [isLoading, setIsLoading] = useState(true);
	const [articles, setArticles] = useState([]);
	const [authors, setAuthors] = useState({});

	useEffect(()=>{
		AuthorService.getAll((data)=>{
			let obj = {};
			data.forEach((author)=>{
				obj[author._id] = author;
			})
			setAuthors(obj)
			setIsLoading(false);
		})
	}, [])


	function TableHeaders(){
		return(<div className={classes.th}>
			<div className={classes.td}></div>
			<div className={classes.td}>Navn</div>
			<div className={[classes.td, classes.centerCell].join(' ')}>Aktiv</div>
			<div className={[classes.td, classes.centerCell].join(' ')}>Pluss</div>
			<div className={classes.td}>Visninger</div>
			<div className={[classes.td, classes.centerCell].join(' ')}>Forfatter</div>
			<div className={classes.td}>URL</div>
			<div className={classes.td}>Stikkord</div>
			<div className={classes.td}>Kategorier</div>
			<div className={classes.td}>(Pub)Dato</div>
		</div>)
	}

	function CheckCross({bool}){return <>
		{bool ? <span>✔️</span>: <span>❌</span>}
	</>}

	function CheckingBox({bool, boxRef}){return <>
		<input type={"checkbox"}  defaultChecked={bool} ref={boxRef}/>
	</>}

	function UnEditableSectionChildren({obj, refObj}){
		refObj.active = useRef(obj.active);
		refObj.pluss = useRef(obj.pluss);

		return(<>
			<div className={[classes.td, classes.centerCell].join(' ')}>
				{obj.isEditable ?
					<CheckingBox boxRef={refObj.active} bool={obj.active}/>
					:
					<CheckCross bool={obj.active}/>
				}
			</div>
			<div className={[classes.td, classes.centerCell].join(' ')}>
				{obj.isEditable ?
					<CheckingBox boxRef={refObj.pluss} bool={obj.pluss}/>
					:
					<CheckCross bool={obj.pluss}/>
				}
			</div>
			<div className={[classes.td, classes.centerCell].join(' ')}>
				{obj.viewCount}
			</div>
			<div className={[classes.td, classes.centerCell].join(' ')}>
				{authors[obj.author] ?
					<>{authors[obj.author].name}</>
					:
					<span style={{color:'darkred'}}>Feil (slettet?)</span>
				}
			</div>
			<div className={classes.td}><Link to={'/artikkel/'+obj.urlString} className={classes.cell}>{obj.urlString}</Link></div>
			<div className={classes.td}>{obj.tags&&obj.tags.map((tag)=>{return <span key={tag.name} className={classes.tag}>{tag.name}</span>})}</div>
			<div className={classes.td}>{obj.categories&&obj.categories.map((category)=>{return <span key={category.name} className={classes.tag}>{category.name}</span>})}</div>
			<div className={classes.td}>{obj.scheduledToActivate&&<b>Planlagt:</b>}{obj.date&&new Date(obj.date).toLocaleString().substring(0, 17)}</div>
		</>)
	}


	function EditableSectionChildren({obj, index, handleChange}) {return(<>

	</>)}

	function BeforeTableChildren(){return(
		<Link to={'/admin/ny_artikkel'}>
			<button className={'button1'}>Ny artikkel</button>
		</Link>
	)}


	return(
		<AdminEdit
			PaginationBool={true}
			SortObject={{editedDate:'Redigerings-dato', date:'Dato', active:'Aktiv', pluss:'Pluss', author:'Forfatter', urlString:'URL', viewCount:'Visninger'}}
			SearchObject={{urlString:'URL'}}
			DefaultSearch={'editedDate'}
			DefaultSortOrder={'-1'}

			isLoading={isLoading}
			pageName={"Artikler"}
			mainService={AdminArticleService}
			mainArray={articles}
			setMainArray={setArticles}
			TableHeaders={TableHeaders}
			EditableSectionChildren={EditableSectionChildren}
			UnEditableSectionChildren={UnEditableSectionChildren}
			BeforeTableChildren={BeforeTableChildren}
		></AdminEdit>
	)
}

export default AdminArticles;