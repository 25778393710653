import React, {useEffect, useState} from "react";
import CategoryService from "../services/CategoryService";
import SiteService from "../services/SiteService";
import tagService from "../services/TagService";
import FrontpageService from "../services/FrontpageService";

const SiteContext = React.createContext({
	isTouchDevice: false,
	categories: [],
	setCategories: (categories)=>{},
	organizedCategories: [],
	setOrganizedCategories: (organizedCategories)=>{},

	tags: [],
	setTags: (tags)=>{},
	getAndAssignTags: ()=>{},

	batsokCategories: [],
	batsokTags: [],
	fetchBatsokCategories: ()=>{},
	fetchBatsokTags: ()=>{},

	selectedMainCategory: null,
	setSelectedMainCategory: (mainCategory)=>{},

	frontpageContent: null,
	setFrontpageContent: (frontpageContent)=>{},
});



export function SiteContextProvider(props){
	//const [isTouchDevice, setIsTouchDevice] = useState(false)
	const [categories, setCategories] = useState([])
	const [tags, setTags] = useState([])
	const [organizedCategories, setOrganizedCategories] = useState([])
	const [batsokCategories, setBatsokCategories] = useState([])
	const [batsokTags, setBatsokTags] = useState([])
	const [selectedMainCategory, setSelectedMainCategory] = useState(null)
	const [frontpageContent, setFrontpageContent] = useState(null)

	const [isInitialized, setIsInitialized] = useState(false);
	useEffect(()=>{
		if (!isInitialized) setIsInitialized(true);
		initialize();
	},[]);

	const initialize = async ()=>{
		let cats = await CategoryService.getAll();
		if (!cats){
			alert('Nettverksfeil under lasting av siden. Forsøk å gjennopfrisk siden.')
			return console.error('Failed to fetch categories')
		}
		setCategories(cats);
		setOrganizedCategories(organizeCategoriesIntoHierarchy(cats));

		const frontpage = await FrontpageService.getFrontpageWithContent();
		setFrontpageContent(frontpage);
	}

	/*useEffect(()=> {
		// check if user is touch device by detecting touch events on the window
		const checkTouchDevice = () => {
			setIsTouchDevice(true);
		}
		window.addEventListener('touchstart', checkTouchDevice);
		return () => {
			window.removeEventListener('touchstart', checkTouchDevice);
		}
	}, [])*/

	const organizeCategoriesIntoHierarchy = (categoriesParam)=>{
		if (!categoriesParam) return [];
		console.log('Organizing categories')
		// add a category named "Alle artikler" to the categories array
		//categories.unshift({name: 'Alle artikler', _id: null, parentCategory: null, children: []});
		let categories = categoriesParam;
		// extract all categories without a parentCategory property into mainCategories
		let childCategories = categories.filter(cat => cat.parentCategory);
		// extract all categories with a parentCategory property into subCategories
		let parentCategories = categories.filter(cat => !cat.parentCategory);

		// organize categories into a tree structure, where the children with _id as parent are part of it's object
		let newOrganizedCats = {};
		parentCategories.forEach(cat => {
			newOrganizedCats[cat._id] = cat;
			if (cat.name.toLowerCase() === 'båter og utstyr')
				newOrganizedCats[cat._id].order = 0;
			else if (cat.name.toLowerCase() === 'på tur')
				newOrganizedCats[cat._id].order = 1;
			else if (cat.name.toLowerCase() === 'kunnskap')
				newOrganizedCats[cat._id].order = 2;
			else if (cat.name.toLowerCase() === 'båtfolk og events')
				newOrganizedCats[cat._id].order = 3;
			else if (cat.name.toLowerCase() === 'nyheter')
				newOrganizedCats[cat._id].order = 4;
		});
		childCategories.forEach(cat => {
			if (!newOrganizedCats[cat.parentCategory].children)
				newOrganizedCats[cat.parentCategory].children = [];
			newOrganizedCats[cat.parentCategory].children.push(cat);
		});

		return newOrganizedCats;
	}

	const getAndAssignTags = ()=>{
		tagService.getAllFreq((tags)=>{
			// order tags by alphabetical name
			tags.sort((a, b) => a.name.localeCompare(b.name));
			setTags(tags);
		});
	}

	const fetchBatsokCategories = async ()=>{
		let res = await fetch(`${SiteService.apiPath}/batsok/categories`);
		let cats = await res.json();
		setBatsokCategories(cats);
	}
	const fetchBatsokTags = async ()=>{
		let res = await fetch(`${SiteService.apiPath}/batsok/tags`);
		let tags = await res.json();
		setBatsokTags(tags);
	}

	const context = {
		//isTouchDevice: isTouchDevice,
		categories: categories,
		setCategories: setCategories,
		organizedCategories: organizedCategories,
		setOrganizedCategories: setOrganizedCategories,

		fetchBatsokCategories: fetchBatsokCategories,
		batsokCategories: batsokCategories,
		fetchBatsokTags: fetchBatsokTags,
		batsokTags: batsokTags,

		selectedMainCategory: selectedMainCategory,
		setSelectedMainCategory: setSelectedMainCategory,

		tags: tags,
		setTags: setTags,
		getAndAssignTags: getAndAssignTags,

		frontpageContent: frontpageContent,
		setFrontpageContent: setFrontpageContent
	};

	return <SiteContext.Provider value={context}>
		{props.children}
	</SiteContext.Provider>
}

export default SiteContext;