import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import SiteService from "../services/SiteService";
import Animations from "../scss/animations.scss"
import UserContext from "../store/userContext";

const BatpermContext = React.createContext({
	harbors: [],

	getHarborNames: ()=>{},
	isMenuOpen: false,
	setIsMenuOpen: (isOpen)=>{},

	additionalNavElements: [],
	setAdditionalNavElements: (jsxEl)=>{},

	notifications: [],
	deleteNotification: (id)=>{},
	getNotifications: ()=>{},
});


export function BatpermContextProvider(props){
	const [harbors, setHarbors] = useState([])
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const [additionalNavElements, setAdditionalNavElements] = useState([])
	const [notifications, setNotifications] = useState([])

	useEffect(()=>{
		getHarborNames();
		getNotifications();
	},[]);

	async function getHarborNames(){try {
		let res = await fetch(SiteService.apiPath + '/batperm/harbors/names');

		if (!res.ok) throw new Error(res.statusText);

		let harbors = await res.json();

		setHarbors(harbors);
	}catch (e) {
		console.error(e);
	}}

	const getNotifications = async () => {
		let res = await fetch(`${SiteService.apiPath}/batperm/notifications`)
		if (!res.ok) return alert('Feil under henting av varsler');

		let data = await res.json();
		setNotifications(data);
		return data;
	}
	const deleteNotification = async (id) => {
		let req = await fetch(`${SiteService.apiPath}/batperm/notifications/${id}`, {method: 'DELETE'});
		if (!req.ok) return alert('Feil under sletting av varsel');

		setNotifications(notifications.filter(notification => notification._id!== id))
		return true;
	}

	const context = {
		harbors: harbors,
		getHarborNames: getHarborNames,
		isMenuOpen: isMenuOpen,
		setIsMenuOpen: setIsMenuOpen,
		additionalNavElements: additionalNavElements,
		setAdditionalNavElements: setAdditionalNavElements,

		notifications: notifications,
		deleteNotification: deleteNotification,
		getNotifications: getNotifications,
	};

	return <BatpermContext.Provider value={context}>
		{props.children}
	</BatpermContext.Provider>
}

export default BatpermContext;