import React, { useEffect } from 'react';

export default function ArticleEmbed({ key, part }) {
	useEffect(() => {
		const scriptContent = part.value.match(/<script.*?>([\s\S]*?)<\/script>/);
		if (scriptContent && scriptContent[1]) {
			eval(scriptContent[1]);
		}
	}, []);

	return (
		<div
			key={key}
			className={'embedContainer'}
			dangerouslySetInnerHTML={{ __html: part.value }}
		/>
	);
};
