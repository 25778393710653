import React, { useState } from 'react';

const ClickToOpen = ({ buttonStyle, buttonClassName, buttonText, children }) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleClick = () => {
		setIsOpen(true);
	};

	return (
		<>
			{isOpen ? (
				<div>{children}</div>
			) : (
				<button
					style={buttonStyle}
					className={buttonClassName}
					onClick={handleClick}
				>
					{buttonText}
				</button>
			)}
		</>
	);
};

export default ClickToOpen;
