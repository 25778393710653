import classes from "../batperm.module.scss";
import {Link} from "react-router-dom";
import UserContext from "../../store/userContext";
import BatpermContext from "../BatpermContext";
import {useContext, useEffect, useState} from "react";
import BatpermLogin from "../pages/BatpermLogin";
import BatpermNav from "./BatpermNav";


function BatpermLayout(props){
const userContext = useContext(UserContext);
const batpermContext = useContext(BatpermContext);


return (<>


	{!userContext.isLoadingAuth &&
	<div className={[classes.batpermContainer].join(' ')}>
		{userContext.isLoggedIn ? <>
				<BatpermNav/>
				<div className={[classes.mainContainer, batpermContext.isMenuOpen && classes.hidden].join(' ')}>
					{props.children}
				</div>
			</>
			:<>
				<BatpermLogin></BatpermLogin>
			</>
		}

	</div>}


</>)}


export default BatpermLayout;