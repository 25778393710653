import {useContext, useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import SiteService from "../services/SiteService";
import UserContext from "../store/userContext";
import userContext from "../store/userContext";
import {set} from "mongoose";

const usePageTracking = () => {
	const location = useLocation();
	const [isFirstLoad, setIsFirstLoad] = useState(true);

	const user = useContext(userContext);

	useEffect(() => {
		const trackPageView = async () => {
			try {
				// Track page view only if user is not admin
				if (user.role > 0 || user.isLoadingAuth) {
					return;
				}

				const response = await fetch(SiteService.apiPath+'/visit', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						pageUrl: window.location.pathname + window.location.search,
						referer: window.document.referrer,
						isSessionStart: isFirstLoad,
					}),
				});

				if (!response.ok) {
					throw new Error(`HTTP error! status: ${response.status}`);
				}
			} catch (error) {
				console.error('Error tracking page view:', error);
			}

			if (isFirstLoad) {
				setIsFirstLoad(false);
			}
		};

		trackPageView();
	}, [location, user.isLoadingAuth]);
};

export default usePageTracking;
