import {useEffect, useRef, useState} from "react";
import AdminEdit from "../../../components/adminEdit/AdminEdit";
import AdminDefaultPricesService from "../../../services/AdminDefaultPricesService";
import classes from "../../../components/adminEdit/AdminEdit.module.scss";
import Helpers from "../../../helpers/Helpers";

function AdminDefaultPrices(){

	const [isLoading, setIsLoading] = useState(false);
	const [defaultPrices, setDefaultPrices] = useState([]);

	useEffect(()=>{

	}, [])


	function TableHeaders(){return(
		<div className={classes.th}>
			<div className={classes.td}></div>
			<div className={classes.td}>Navn</div>
			<div className={[classes.td, classes.centerCell].join(' ')}>Pris(NOK)</div>
			<div className={[classes.td, classes.centerCell].join(' ')}>Rabatt %</div>
		</div>
	)}


	function UnEditableSectionChildren({obj, refObj}){
		refObj.price = useRef(obj.price);
		refObj.discountPercent = useRef(obj.discountPercent);
		refObj.discountExpirationDate = useRef(new Date(obj.discountExpirationDate));
		let date = refObj.discountExpirationDate.current;

		//date / refobj.discountExpirationDate.current becomes empty after
		//saving, so we try first, and if failed we set it to the new updated date.
		let dateAsInputValue = undefined;
		try{	dateAsInputValue = Helpers.convertDateToInputValue(date);
		}catch{	dateAsInputValue = Helpers.convertDateToInputValue(new Date(obj.discountExpirationDate));}

		let datePlusOneDayAsValue = Helpers.convertDateToInputValue(new Date(new Date().setDate(new Date().getDate()+1)));

		return(<>
			<div className={[classes.td, classes.centerCell].join(' ')}>{obj.isEditable ?
				<input ref={refObj.price} type={"number"} defaultValue={obj.price} required={true}/>
				:
				<>{obj.price}</>}
			</div>

			<div className={[classes.td, classes.centerCell].join(' ')}>
				{obj.isEditable ? <>
				<div>For å fjerne, sett rabatt til 0 og lagre.</div>
				<input type={"number"} defaultValue={obj.discountPercent} ref={refObj.discountPercent} required={true}/>
				<input required={true} type={"date"} defaultValue={dateAsInputValue} ref={refObj.discountExpirationDate} min={datePlusOneDayAsValue}/>
					</>:
				<>{obj.discountPercent && <>{obj.discountPercent}{obj.discountExpirationDate && <>
				{` (${dateAsInputValue})`}</>}</>}</>}
			</div>
		</>)
	}


	function EditableSectionChildren({obj, index, handleChange}) {return(<>

	</>)}




	return(
		<AdminEdit
			PaginationBool={true}
			SortObject={{price:'Pris', discountPercent: 'Rabatt'}}
			SearchObject={{}}
			isLoading={isLoading}
			disableNameInputBool={true}
			pageName={"Standard-priser"}
			mainService={AdminDefaultPricesService}
			mainArray={defaultPrices}
			setMainArray={setDefaultPrices}
			TableHeaders={TableHeaders}
			EditableSectionChildren={EditableSectionChildren}
			UnEditableSectionChildren={UnEditableSectionChildren}
		></AdminEdit>
	)
}

export default AdminDefaultPrices;