import {useEffect, useRef, useState} from "react";
import classes from "./AdminEdit.module.scss";
import animationClasses from "../../scss/animations.scss";
import editableIcon from "../../images/icons/editable.svg"

function AdminEdit({
	pageName,
	mainArray,
	setMainArray,
	mainService,
	EditableSectionChildren,
	UnEditableSectionChildren,
	TableHeaders,
	PaginationBool,
	NewSectionBool,
	disableNameInputBool,
	BeforeTableChildren,

	SortObject,
	SearchObject,
	DefaultSearch,
	DefaultSortOrder,

	isLoading
}){

	const [thisIsLoading, setThisIsLoading] = useState(true);

	const [paginationLoading, setPaginationLoading] = useState(false);
	const [currPage, setCurrPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [totalResults, setTotalResults] = useState(undefined);
	const [sortBy, setSortBy] = useState(DefaultSearch?DefaultSearch:'name');

	const [sortOrder, setSortOrder] = useState(DefaultSortOrder?DefaultSortOrder:"1");
	const [searchString, setSearchString] = useState("");
	const [searchBy, setSearchBy] = useState("name");
	const [searchActive, setSearchActive] = useState(null);


	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await mainService.getAll();
				console.log(data)
				if (data.mainObj) {
					data.mainObj.forEach((obj) => {
						obj.isEditable = false;
					});

					if (data.pagination) {
						setTotalPages(data.pagination.totalPages);
						setTotalResults(data.pagination.totalResults);
					}
					setMainArray(data.mainObj);
				} else {
					data.forEach((obj) => {
						obj.isEditable = false;
					});
					setMainArray(data);
				}

				setThisIsLoading(false);
			} catch (error) {
				// Handle error here
				console.error(error);
			}
		};

		if (SearchObject)
			initializeIfSearchEnabled();
		else
			fetchData();
	}, []);

	const initializeIfSearchEnabled = async () => {
		await changePagination(null, null, sortBy, sortOrder, '')
		setThisIsLoading(false);
	}

	const createNewMainObj = (newObjName) =>{
		let failed = false;
		if (mainArray&&mainArray.length>0)
			mainArray.forEach((obj)=>{
				if(obj.name === newObjName) {
					failed = true;
					return alert('Navn eksisterer allerede');
				}
			})

		if(!failed)
			mainService.create({name: newObjName}, (obj)=>{
				obj.isEditable = true;
				if (mainArray&&mainArray.length>0)
					setMainArray(mainArray.concat(obj));
				else
					setMainArray([obj]);
			})
	}

	const handleChange = (propertyName, newValue, index) => {
		setMainArray((oldList) => {
			oldList[index][propertyName] = newValue;
			return [...oldList];
		});
	};

	const toggleIsEditable = (sectionObj) =>{
		let newArray = mainArray;
		newArray.forEach((newObj)=>{
			if (newObj._id === sectionObj._id) {
				newObj.isEditable = !newObj.isEditable;
			}
		});
		setMainArray(newArray.concat());
	}

	const removeAndDeleteObject = (sectionObj)=>{
		mainService.delete(sectionObj._id, (success)=>{
			if(success) {
				let newArray = mainArray;
				newArray.forEach((newObj, i) => {
					if (newObj._id === sectionObj._id) {
						newArray.splice(i, 1);
					}
				});
				setMainArray(newArray.concat());
			}else{
				alert('Feil under sletting.')
			}
		})
	}


	function SearchBlock(){
		let searchRef = useRef(searchString);
		let searchByRef = useRef(searchBy);

		return(<form onSubmit={()=>{
		setSearchString(searchRef.current.value);
		changePagination(null, currPage, sortBy, sortOrder, searchRef.current.value)
	}} className={classes.searchBlock}>
		<input placeholder={searchBy} defaultValue={searchString} ref={searchRef}  type={"text"}></input>
		<select defaultValue={searchBy} onChange={()=> {
			setSearchBy(searchByRef.current.value);
		}} ref={searchByRef}>
			<option value={'name'}>Navn</option>
			{Object.keys(SearchObject).map((propertyName)=>{return(
				<option key={propertyName} value={propertyName}>{SortObject[propertyName]}</option>
			)})}
		</select>
		<button type={"submit"}>🔍</button>
	</form>)}


	function SortBlock() {
		let sortRef = useRef(sortBy);
		let orderRef = useRef(sortOrder);

	return(<div className={classes.sortBlock}>
		<label>Sorter: </label>
		<select defaultValue={sortBy} onChange={()=> {
			changePagination(null, currPage, sortRef.current.value, orderRef.current.value, searchString);
			setSortBy(sortRef.current.value);
		}} ref={sortRef}>
			<option value={'name'}>Navn</option>
			{Object.keys(SortObject).map((propertyName)=>{return(
				<option key={propertyName} value={propertyName}>{SortObject[propertyName]}</option>
			)})}
		</select>

		<label style={{marginLeft:'7px'}}>Rekkefølge: </label>
		<select defaultValue={sortOrder} onChange={()=> {
			changePagination(null, currPage, sortRef.current.value, orderRef.current.value, searchString);
			setSortOrder(orderRef.current.value);
		}} ref={orderRef}>
			<option value={'1'}>Stigende</option>
			<option value={'-1'}>Synkende</option>
		</select>
	</div>)}

	function StatsBlock(){
	return(<div className={classes.statsBlock}>
		<table><tbody>
			{totalResults && <tr>
				<th>Totalt:</th>
				<th>{totalResults}</th>
			</tr>}
		</tbody></table>
	</div>)}

	useEffect(()=>{
		if (PaginationBool)
			changePagination(null, currPage, sortBy, sortOrder, searchString);
	}, [currPage, sortBy, sortOrder, searchString, searchActive, searchBy])

	const changePagination = (pageOffset, exactPage, sortBy, orderBy, searchString)=>{
		let destinationPage = currPage+pageOffset;
		if (exactPage) destinationPage = exactPage
		setPaginationLoading(true);

		let searchObj = {
			[searchBy]: searchString
		}
		if (searchActive !== null)
			searchObj["active"] = searchActive;

		mainService.getPage(destinationPage-1, {[sortBy]: Number(orderBy)}, searchObj, (data)=>{
			data.mainObj.forEach((obj)=>{
				obj.isEditable = false;
			})
			if(data.pagination){
				setTotalPages(data.pagination.totalPages);
				setTotalResults(data.pagination.totalResults);
			}
			setMainArray(data.mainObj);
			setCurrPage(destinationPage);
			setPaginationLoading(false);
		})
	}

	function ShowActiveInactiveBlock(){
		const selectedStyle={backgroundColor:'white', color:'black'};

		return<div style={{float:'right'}}>
		<button className={'buttonDull2'} style={searchActive===null?selectedStyle:{}} type={"button"} onClick={()=>setSearchActive(null)}>Alle</button>
		<button className={'buttonDull2'} style={searchActive===true?selectedStyle:{}} type={"button"} onClick={()=>setSearchActive(true)}>Aktiv</button>
		<button className={'buttonDull2'} style={searchActive===false?selectedStyle:{}} type={"button"} onClick={()=>setSearchActive(false)}>Inaktiv</button>
	</div>}

	function PaginationBlock(){
		if(paginationLoading)
			return(<div className={classes.paginationBlock}>Laster...</div>)
		return (<div className={classes.paginationBlock}>
		<SearchBlock></SearchBlock>
		<div>
			{currPage > 1 && <>
				<button onClick={()=>changePagination(null,1, sortBy, sortOrder, searchString)}>{'|<'}</button>
			</>}

			{currPage > 1 && <>
				<button onClick={()=>changePagination(-1, null, sortBy, sortOrder, searchString)}>{'<'}</button>
			</>}
			{currPage > 2 && <span>{currPage-2}</span>}
			{currPage > 1 && <span>{currPage-1}</span>}

			<span style={{borderBottom: '1px white solid'}}>{currPage}</span>

			{currPage < totalPages-1 && <span>{currPage+1}</span>}

			{currPage < totalPages && <>
				<span>{totalPages}</span>
				<button onClick={()=>changePagination(1, null, sortBy, sortOrder, searchString)}>{'>'}</button>
			</>}

			{currPage < totalPages && <>
				<button onClick={()=>changePagination(null,totalPages, sortBy, sortOrder, searchString)}>{'>|'}</button>
			</>}
		</div>
		{SortObject && <SortBlock></SortBlock>}
	</div>)}


	function SectionRow({sectionObj, index}){
		let refObj = {};
		let nameRef = useRef();
		const [isSectionLoading, setIsSectionLoading] = useState(false);

		// ReferenceObj(refObj) should be an object of React useRefs coming from the UnEditableSectionChildren
		// we will iterate over it and assign it all to the sectionObj
		// example: {name: nameRef, active: nameRef}
		function saveSectionObj(sectionObj){
			setIsSectionLoading(true);
			sectionObj.name = nameRef.current.value;

			if(refObj) {
				Object.keys(refObj).forEach((propertyName) => {
					if (refObj[propertyName].current.type === "checkbox") {
						sectionObj[propertyName] = refObj[propertyName].current.checked;
					}
					else if (refObj[propertyName].current.name === "SELECT") {
						if (refObj[propertyName].current.value === "") {
							sectionObj[propertyName] = null;
						} else if (!isNaN(refObj[propertyName].current.value)) {
							sectionObj[propertyName] = Number(refObj[propertyName].current.value);
						}
					}else {
						sectionObj[propertyName] = refObj[propertyName].current.value;
					}
				})
			}


			mainService.save(sectionObj, (success)=>{
				if(success) {
					setMainArray(mainArray.concat());
					toggleIsEditable(sectionObj);
				}
				setIsSectionLoading(false);
			})
		}

		let displayType = 'none';
		if(sectionObj.isEditable) {
			displayType = 'block';
		}

		return(<>
			<form id={'form'+index}
				  className={[
					  classes.tr,
					  classes.section,
					  classes[`${sectionObj.isEditable ? 'sectionIsEditable' : 'sectionNotEditable'}`],
					  isSectionLoading ? animationClasses.animationShine : ''
				  ].join(' ')}
				  style={{borderBottom: "2px solid gray", paddingBottom: "10px"}}
				  onSubmit={(ev)=>{
					  ev.preventDefault();
					  saveSectionObj(sectionObj);
				  }}
			>
				<div className={[classes.td ,classes.alignTop].join(' ')}>
					<img alt={'rediger'} className={classes.editToggleButton} src={editableIcon}
						 onClick={()=>{toggleIsEditable(sectionObj)}}
					/>
					{sectionObj.isEditable && <>
						<button form={'form'+index} className={classes.editToggleButton}
								style={{backgroundColor: '#dffbc3', border: '1px solid green'}}
								type={"submit"}
						>
							<span>💾</span>
						</button>
						<button className={classes.editToggleButton}
								onClick={(ev)=>{ev.preventDefault();removeAndDeleteObject(sectionObj)}}
								style={{backgroundColor: '#efa8a8', border: '1px solid red'}}>
							🗑️
						</button>
					</>}
				</div>

				{sectionObj.isEditable ?
				<div className={[classes.td, classes.alignTop].join(' ')}>
					<input type={"text"} ref={nameRef} id={sectionObj._id + "Input"} defaultValue={sectionObj.name} disabled={!sectionObj.isEditable || disableNameInputBool} className={classes['isEditable'+sectionObj.isEditable.toString()]} style={{display:'inline'}}/>
				</div>
					:
					<div className={[classes.td, classes.alignTop].join(' ')}>
						{sectionObj.name}
					</div>
				}

				<UnEditableSectionChildren obj={sectionObj} index={index} refObj={refObj} handleChange={handleChange}></UnEditableSectionChildren>


				{/* EDITABLE SECTION (not really used I think, might be redundant) */}
				{sectionObj.isEditable && <div>
					<EditableSectionChildren obj={sectionObj} handleChange={handleChange} index={index}/>
				</div>}

				{/*<div style={{marginTop:'-2px',float:'right',fontSize: '0.7rem'}}>{'Id: '+sectionObj._id}</div>*/}
			</form>
		</>)
	}



	if(thisIsLoading || isLoading){
		return(
			<div style={{maxWidth:'1000px', marginLeft:'auto', marginRight:'auto'}}>
				<h1>{pageName}</h1>
				{BeforeTableChildren&&<div style={{margin: '1em'}}><BeforeTableChildren/></div>}
				<div>
					{PaginationBool && <PaginationBlock></PaginationBlock>}
					<h3>Laster...</h3>
				</div>
			</div>
		)
	}

	else return(<div className={[classes.adminEditContainer].join(' ')}>
		<h1>{pageName}</h1>
		{BeforeTableChildren&&<BeforeTableChildren/>}
		<div>
			{PaginationBool && <ShowActiveInactiveBlock/>}
			{PaginationBool && <PaginationBlock></PaginationBlock>}
			<div className={classes.table}>
				{TableHeaders && <TableHeaders></TableHeaders>}
				{(mainArray&&mainArray.length>0)&&mainArray.map((obj, index)=>{return (
					<SectionRow key={index} sectionObj={obj} index={index}></SectionRow>
				)})}
			</div>
			{PaginationBool && <PaginationBlock></PaginationBlock>}

			{NewSectionBool &&
				<form className={classes.createNewCatContainer} onSubmit={(ev)=>{
					ev.preventDefault(); createNewMainObj(document.getElementById('createNewInput').value);
				}}>
					<button>Ny</button>
					<input id={'createNewInput'} placeholder={'navn'} minLength={1} required={true}/>
			</form>}

			<StatsBlock></StatsBlock>
		</div>
	</div>)
}

export default AdminEdit;