import {Link} from "react-router-dom";
import classes from "./BatpermBoatCardFull.module.scss";
import SiteService from "../../services/SiteService";
import harborIcon from "../../images/icons/harbor.png";

export default function BatpermHarborCardFull({harbor}){
	return(

		<Link className={classes.batpermBoatCard} to={`/batperm/havn/${harbor._id}`}>
			<div className={classes.boatImageContainer}>
				{harbor.imageName ? <img src={`${SiteService.apiPath}/image/batperm-boat/${harbor._id}${harbor.imageName}`}/>
					:<img style={{objectFit:'contain'}} src={harborIcon}/>
				}
			</div>
			<div className={classes.boatSecondContainer}>
				<div className={classes.textContainer}>
					<div className={classes.boatName}>
						{harbor.name && harbor.name}
					</div>
				</div>
			</div>
			<div className={classes.cardOverlayText}>Åpne</div>
		</Link>

	)}