import {Link} from "react-router-dom";
import React from "react";
import classes from "../../pages/min_side/MinSide.module.scss";
import CaptainHatIcon from "../../svg/CaptainHatIcon";
import HeartIcon from "../../svg/HeartIcon";
import BellIcon from "../../svg/BellIcon";
import PlusIcon from "../../svg/PlussIcon";

export default function MinSideTop(props) {
return (
	<div className={classes.minSide}>
		<nav className={classes.minSideTop}>
			<Link to="/min-side/profil">
				<CaptainHatIcon fillColor1={'black'} fillColor2={"none"}/>
				Profil
			</Link>
			<Link to="/min-side/favoritter">
				<HeartIcon />
				Favoritter
			</Link>
			<Link to="/min-side/varsler">
				<BellIcon strokeWidth={'1'}/>
				Varsler
			</Link>
			<Link to="/min-side/abonnement">
				<PlusIcon/>
				Abonnement
			</Link>
		</nav>
		{props.children}
	</div>
);
}