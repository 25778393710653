import {Link} from "react-router-dom";
import classes from "../batperm.module.scss";
import BatpermContext from "../BatpermContext";
import react, {useContext} from "react";

export default function MainMenuLink({to, label, onClick}){
	const batpermContext = useContext(BatpermContext);

	if (!to && onClick) return(
		<button className={classes.navLink} onClick={(ev)=>{
			ev.preventDefault();
			onClick();
			batpermContext.setIsMenuOpen(false);
		}}>{label}</button>
	)

	return(<Link to={to} className={classes.navLink} onClick={()=>{
		batpermContext.setIsMenuOpen(false);
	}}>{label}</Link>)
}