import {useEffect, useRef, useState} from "react";
import NextIcon from "../../svg/NextIcon";
import useEventListener from "../../hooks/useEventListener";

export default function ImagePopout({imageRef, changeSlideShowFunction, currentSlideShowSrc, bottomText, maxImages, imageIndex}) {
	const [isShowingImage, setIsShowingImage] = useState(false);
	const [isZoomed, setIsZoomed] = useState(false);

	const popoutRef = useRef(null);

	const onClick = ()=>{
		setIsShowingImage(!isShowingImage)
		if (isShowingImage)
			document.body.style.overflow = "hidden";
		else
			document.body.style.overflow = "";
	}

	useEffect(() => {
		let imageEl = imageRef?.current;
		if (!imageEl)
			return;
		imageEl.style.cursor = 'zoom-in';
		imageEl.addEventListener('click', onClick);
		// if unmounting, remove event listeners
		return () => {
			try {
				imageEl.removeEventListener('click', onClick);
			}catch (e) {
				console.log(e);
			}
		};
	}, []);

	useEventListener(popoutRef, 'key:escape', () => {
		setIsShowingImage(false)
		if (changeSlideShowFunction)
			changeSlideShowFunction(null)
	});
	if (changeSlideShowFunction){
		// imageCarousel already has event listeners for this
		//useEventListener(popoutRef, 'key:ArrowLeft', () => changeSlideShowFunction(-1));
		//useEventListener(popoutRef, 'key:ArrowRight', () => changeSlideShowFunction(1));
	}

	function Popout(){
		return <div ref={popoutRef} className={'imagePopoutContainer'}><div className={'imagePopout'}>
			<div className={'imagePopoutInner'}>
				<img src={currentSlideShowSrc?currentSlideShowSrc:imageRef.current.src} style={{scale:isZoomed?'2':'1'}}/>
				{maxImages&&<div className={'imagePopoutIndex'}>{imageIndex + 1} / {maxImages}</div>}
				<button className={'button1 close'} onClick={()=>setIsShowingImage(false)}>𐌗</button>
				{/*<button className={'button1 zoom'}
						 onClick={() => setIsZoomed(!isZoomed)}>{isZoomed ? '-' : '+'}</button>*/}
				{changeSlideShowFunction&&<><button className={'carouselArrow carouselArrowLeft'} onClick={()=>changeSlideShowFunction(1)}>
					<NextIcon></NextIcon>
				</button>
					<button className={'carouselArrow carouselArrowRight'} onClick={()=>changeSlideShowFunction(-1)}>
				<NextIcon></NextIcon>
				</button>
				{bottomText&& <div className={'carouselDesc'}>{bottomText}</div>}
				</>}
			</div>
		</div>
		</div>
	}

	return isShowingImage ? <Popout/> : null;
}