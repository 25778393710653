import {useEffect, useRef, useState} from "react";
import VideoPlayer from "../../ui/VideoPlayer";
import ContentRibbon from "../ContentRibbon";
import ContentBlockWrapper from "../ContentBlockWrapper";
import SiteService from "../../../services/SiteService";
import PlayIcon from "../../../svg/PlayIcon";
import NextIcon from "../../../svg/NextIcon";
import useSwipe from "../../../hooks/useSwipe";
import {useNavigate} from "react-router-dom";

export default function VideoBlock1({parts, sectionTitle, customStyle, link, slidesPerView}){
	const navigate = useNavigate();
	const videosContainerRef = useRef();

	const [slidesPerViewState, setSlidesPerViewState] = useState(slidesPerView ? slidesPerView : '3.2');
	const swiperRef = useRef(null);

	const playingVideoRef = useRef(null);

	const [videoPlaying, setVideoPlaying] = useState(null);
	const [videoPlayingIndex, setVideoPlayingIndex] = useState(null);

	const [videoDetails, setVideoDetails] = useState(null);

	useEffect(() => {
		return () => {
			document.body.style.overflow = "auto";
		}
	}, []);

	useEffect(() => {
		const handleResize = () => {try {
			if (window.innerWidth < 810 && slidesPerViewState !== "2" && (slidesPerView !== '2.1')) {
				swiperRef.current.setAttribute('slides-per-view', '2.3');
				//swiperRef.current.setAttribute('centered-slides', 'true');
				//swiperRef.current.setAttribute('centered-slides-bounds', 'true');
				//swiperRef.current.setAttribute('navigation', 'false');
				swiperRef.current.setAttribute('free-mode', 'true');
				swiperRef.current.setAttribute('momentum', 'false');
				setSlidesPerViewState('2.3');
			} else if (window.innerWidth >= 810) {
				swiperRef.current.setAttribute('momentum', 'false');
				swiperRef.current.setAttribute('free-mode', 'false');
				swiperRef.current.setAttribute('slides-per-view', slidesPerViewState);
				setSlidesPerViewState(slidesPerView);
			}
		}catch (e) {}};

		// Initial call to set correct slides-per-view on mount
		handleResize();

		// Event listener for window resize
		window.addEventListener('resize', handleResize);

		// Cleanup function to remove event listener
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [slidesPerViewState]);

	useSwipe(playingVideoRef,
		()=>onNextPreviousClick(1),
		()=>onNextPreviousClick(-1),
		()=>onNextPreviousClick(1),
		()=>onNextPreviousClick(-1)
	);

	const getVideoDetails = async (videoId) => {
		let res = await fetch(`${SiteService.apiPath}/video/${videoId}`);
		if (!res.ok) {
			return null;
		}
		return res.json();
	}
	const [firstRender, setFirstRender] = useState(true);
	useEffect(() => {
		if (!firstRender) return;
		setFirstRender(false);
		console.log('getting video details')
		getAndSetVideoDetails();
	}, []);

	const getAndSetVideoDetails = async () => {
		let newParts = [];
		for (let i = 0; i < parts.length; i++) {
			let newPartProperties = await getVideoDetails(parts[i].id);
			newParts.push({...newPartProperties, id: parts[i].id});
		}
		setVideoDetails(newParts);
	}

	const closeVideo = () => {
		setVideoPlayingIndex(null);
		setVideoPlaying(null);
		document.body.style.overflow = "auto";
		//navigate(-1);
	}
	useEffect(() => {
		const handlePopState = () => {
			setVideoPlayingIndex(null);
			setVideoPlaying(null);
			document.body.style.overflow = "auto";
		};

		window.addEventListener('popstate', handlePopState);

		return () => {
			window.removeEventListener('popstate', handlePopState);
		};
	}, []);

	const onVideoContainerClick = (index) => {
		setVideoPlaying(parts[index].id?parts[index].id:parts[index]._id);
		document.body.style.overflow = "hidden";
		setVideoPlayingIndex(index);
		navigate('/?v='+parts[index].id, { replace: false })
	}

	const onNextPreviousClick = (direction) => {
		if (!direction) direction = 1;

		//find index of current playing video by comparing playingVideo.id to parts.id
		let index = parts.findIndex((part) => part.id === videoPlaying)+(direction);
		if (index >= parts.length) index = 0;
		if (index < 0) index = parts.length - 1;
		swiperRef.current.swiper.slideTo(index);
		setVideoPlaying(parts[index].id?parts[index].id:parts[index]._id);
		setVideoPlayingIndex(index);
	}

	return <ContentBlockWrapper customStyle={customStyle?customStyle:null}><div className={'videoBlock1 contentBlock'}>
		{sectionTitle&&<ContentRibbon ribbonText={sectionTitle ? sectionTitle : 'Kategoriboks 1'} link={link}></ContentRibbon>}
		<div className={'videosContainer'}>
			<swiper-container ref={swiperRef} style={{width:'100%'}} class="swiper-1" slides-per-view={slidesPerView}
							  navigation="true" space-between="20" grab-cursor="true">
			{parts&&parts.map((part, i) => {
				return <swiper-slide key={i}>
					<div className={['videoContainer', i===0?'first':''].join(' ')} ref={videosContainerRef} onClick={() => onVideoContainerClick(i)}>
						{(videoDetails && videoDetails[i] && videoDetails[i].sponsored) && <div className={'videoSponsored'}>Sponset</div>}
						<button className={'playButton'} type={"button"}>
							<PlayIcon>⏵</PlayIcon>
						</button>
						<img loading="lazy" src={`${SiteService.apiPath}/image/video-thumbnails/${part.id?part.id:part._id}`}/>
						{(videoDetails && videoDetails[i] && videoDetails[i].durationSeconds) && <div className={'videoDuration'}>{videoDetails[i].durationSeconds+'s'}</div>}
						<div className={'videoOverlay'}>
							<div className={'videoOverlayText'}>
								<div
									className={'videoTitle'}>{(videoDetails && videoDetails[i]) && videoDetails[i].name}</div>
							</div>
						</div>
					</div>
				</swiper-slide>
			})}
			</swiper-container>
		</div>
		{videoPlaying&&<div className={'videoPlayerContainer videoContainer animationFade-in'} ref={playingVideoRef}>
			<div className={'videoPlayerVoid'} onClick={closeVideo}></div>
			<VideoPlayer videoId={videoPlaying} autoPlay={true} slowDown={false} disableControls={false} index={videoPlayingIndex} maxIndex={parts.length}></VideoPlayer>
			<button className={'closeButton'}  onClick={closeVideo}>×</button>
			<button className={'prevButton'} onClick={()=> onNextPreviousClick(-1)}>
				<NextIcon/>
			</button>
			<button className={'nextButton'} onClick={()=> onNextPreviousClick(1)}>
				<NextIcon/>
			</button>
		</div>}
	</div>
	</ContentBlockWrapper>
}