import siteService from "../../../services/SiteService";
import ImagePopout2 from "../../ui/ImagePopout2";
import {useEffect, useState} from "react";

export default function ArticleImageGallery({part, article, images}){
	if (!images && part && part.images)
		images = part.images;

	let imageUrlBase = null;
	if (article && article._id)
		imageUrlBase = `${siteService.apiPath}/image/articles/${article._id}`

	const [selectedIndex, setSelectedIndex] = useState(null);

	const changeSlideShowFunction = (changeNumber) => {
		if (changeNumber === null) return setSelectedIndex(null);

		let newIndex = selectedIndex + changeNumber;
		if (newIndex < 0)
			newIndex = images.length - 1;
		else if (newIndex >= images.length)
			newIndex = 0;

		setSelectedIndex(newIndex);
	}

	if (!images) return  null;
	return <div className={'articleImageGallery'}>
		{images.map((image,i) => <figure key={i}>
			<img src={image.value.startsWith('data:image') ? image.value:`${imageUrlBase}/${image.value}`}
				 alt={image.alt}
				 onClick={() => setSelectedIndex(i)}
			/>
		</figure>)}

		<ImagePopout2 src={(selectedIndex !== null)?`${imageUrlBase}/${images[selectedIndex].value}`:null}
					   bottomText={(selectedIndex !== null)? <span>{images[selectedIndex].desc && images[selectedIndex].desc}</span> : null}
					   changeSlideShowFunction={changeSlideShowFunction}
					  imageIndex={selectedIndex}
					  maxImages={images.length}
		/>
	</div>
}