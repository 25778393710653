import {useEffect, useState} from "react";
import SiteService from "../../services/SiteService";
import classes from "../batperm.module.scss";
import tableClasses from "../components/BatPermBoatList.module.scss";
import {Link} from "react-router-dom";

export default function BatpermHarbors() {
	const [harbors, setHarbors] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		fetchHarbors();
	}, []);

	const fetchHarbors = async () => {
		const response = await fetch(SiteService.apiPath + '/batperm/harbors');
		const data = await response.json();
		setHarbors(data);
		setIsLoading(false);
	};

	const createNewHarbor = async () => {
		const name = window.prompt('Hva er navnet til Havnen?');
		if (name === null) return;

		const response = await fetch(SiteService.apiPath + '/batperm/harbor', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				name: name,
			})
		})
		const data = await response.json();
		setHarbors([...harbors, data]);
	};

	const removeAdmin = async (harborId, adminId, name) => {
		if(!confirm('Er du sikker på at du vil fjerne '+ name +' fra Havnen?')) return;

		const response = await fetch(SiteService.apiPath + `/batperm/harbor/${harborId}/remove-admin`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				userId: adminId,
			})
		})

		if (response.status === 401)
			return confirm('Du har ikke tilgang til funksjonen');
		if (response.status === 404)
			return confirm('Brukeren finnes ikke');
		if (response.status === 200)
			return confirm('Fullført. Brukeren er fjernet fra Havnen');
	};

	const assignAdminToHarbor = async (harborId) => {
		const userEmail = window.prompt('Hva er brukerens e-postadresse?');
		if (userEmail === null) return;

		const response = await fetch(SiteService.apiPath + `/batperm/harbor/${harborId}/assign-admin`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				userEmail: userEmail,
			})
		})

		if (response.status === 401)
			return confirm('Du har ikke tilgang til funksjonen');
		if (response.status === 404)
			return confirm('Brukeren finnes ikke');
		if (response.status === 200)
			return confirm('Fullført. Brukeren er tildelt til Havnen');
	};


if (isLoading) {return(<><h1>Mine Havner</h1> <div>Laster...</div></>)}
return(

	<div className={[tableClasses.tableContainer].join(' ')}>
	<h1>Havner</h1>
	<div>
		<button onClick={(ev)=>{ev.preventDefault(); createNewHarbor();}}>Ny Havn</button>
	</div>
	<br/>

	<table>
		<tbody>
			<tr>
				<th>Navn</th>
				<th>Administrator</th>
				<th></th>
			</tr>
			{harbors.length>0 && harbors.map((harbor, index) => {return(
				<tr key={index}>
					<td >{harbor.name}</td>
					<td>
						{harbor.admins.map((admin) => {return(
						<div className={classes.roundBlock} onClick={()=>{removeAdmin(harbor._id, admin._id, admin.name)}}>{admin.email}
							<span className={classes.crossButton}>+</span>
						</div>
						)})}
					</td>
					<td className={tableClasses.actions}>
						<button onClick={()=> {assignAdminToHarbor(harbor._id)}}>Tildel admin</button>
						<Link to={`/batperm/havn/${harbor._id}`}>Vis</Link>
					</td>
				</tr>
			)})}
		</tbody>
	</table>
</div>

)}