import { useState, useEffect } from 'react';

const useFileDrop = (ref, callback, disabled = false) => {
	const [isDragging, setIsDragging] = useState(false);

	useEffect(() => {
		const node = ref.current;

		const removeListenersAndClass = (node) => {try{
			node.removeEventListener('dragover', handleDragOver);
			node.removeEventListener('dragenter', handleDragEnter);
			node.removeEventListener('dragleave', handleDragLeave);
			node.removeEventListener('drop', handleDrop);
			node.classList.remove('fileDroppable');
		}catch(e){}}

		if (disabled) return removeListenersAndClass(node);
		if (!node || disabled) return;

		const handleDragOver = (event) => {
			event.preventDefault();
			if (!event.dataTransfer.types.includes('Files')) return;
			setIsDragging(true);
			node.classList.add('draggingOver');
		};

		const handleDragEnter = (event) => {
			event.preventDefault();
			if (!event.dataTransfer.types.includes('Files')) return;
			setIsDragging(true);
			node.classList.add('draggingOver');
		};

		const handleDragLeave = () => {
			setIsDragging(false);
			node.classList.remove('draggingOver');
		};

		const handleDrop = (event) => {
			event.preventDefault();
			setIsDragging(false);
			node.classList.remove('draggingOver');
			if (callback) {
				const files = event.dataTransfer.files;
				if (files.length > 0) {
					const readers = Array.from(files).map((file) => {
						const reader = new FileReader();
						return new Promise((resolve, reject) => {
							reader.onload = () => {
								resolve(reader.result);
							};
							reader.onerror = reject;
							reader.readAsDataURL(file);
						});
					});

					Promise.all(readers)
						.then((results) => {
							callback(results);
						})
						.catch((error) => {
							console.error('Error reading files:', error);
						});
				}
			}
		};


		node.addEventListener('dragover', handleDragOver);
		node.addEventListener('dragenter', handleDragEnter);
		node.addEventListener('dragleave', handleDragLeave);
		node.addEventListener('drop', handleDrop);
		node.classList.add('fileDroppable')

		return () => {
			removeListenersAndClass(node);
		};
	}, [ref, callback, disabled]);

	return isDragging;
};

export default useFileDrop;
