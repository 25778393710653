import classes from "./Layout.module.scss";
import MainNavigationTop from "./MainNavigationTop";
import {useRef, useState} from "react";
import Footer from "./Footer";
import useFileDrop from "../../hooks/useFileDrop";

function Layout(props){
	const [isBannerVisible, setIsBannerVisible] = useState(false);
	const mainRef = useRef();

	useFileDrop(mainRef);

	return (<>
		<div lang={"no"} className={classes.grid_container}>
			<MainNavigationTop isBannerVisible={isBannerVisible} setIsBannerVisible={setIsBannerVisible}></MainNavigationTop>
			<main ref={mainRef} className={classes.grid_main} style={isBannerVisible?{paddingTop:"0"}:null}>
				{props.children}
			</main>
			<Footer/>
		</div>
	</>)
}


export default Layout;