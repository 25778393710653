import ContentRibbon from "../ContentRibbon";
import ContentPart1 from "../ContentPart1";
import ContentBlockWrapper from "../ContentBlockWrapper";

export default function ContentBlock8({parts, sectionTitle, customStyle, link}) {
	if (!parts)
		parts = [{},{}]


return(<ContentBlockWrapper customStyle={customStyle?customStyle:null}><div className={'contentBlock8 contentBlock'}>
	{sectionTitle&& <ContentRibbon ribbonText={sectionTitle ? sectionTitle : 'Seksjon-tittel'} link={link}/>}

	<div className={'contentBlock8Inner'}>
		{parts.map((part, i) => {
			return <ContentPart1 part={part} key={i}/>
		})}
	</div>
</div></ContentBlockWrapper>)};