import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import SiteService from "../services/SiteService";
import Animations from "../scss/animations.scss"

const UserContext = React.createContext({
	name: '',
	email: '',
	ownedArticles: [],
	followedTopics: [],
	notifications: [],
	isSubscribed: false,
	role: 0,
	isLoggedIn: false,
	isVerified: false,
	awaitVerification: false,

	adminHarborIds: [],

	isLoadingAuth: true,

	login: (username, pass, cb) => {},
	auth: () => {},
	getSubscriptionInfo: ()=>{},
	changeName: (name)=>{},
	followTopic: (topicType, topicId) => {},
	unfollowTopic: (topicType, topicId) => {},
	setFollowedTopics: (topics)=>{},
	deleteNotification: (notificationId) => {},
	readNotification: (notificationId) => {},
	updateNotificationsLastRead: () => {},
	notificationsLastRead: Date,
});


export function UserContextProvider(props){
	const navigate = useNavigate();

	const [isLoadingAuth, setIsLoadingAuth] = useState(true)

	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [ownedArticles, setOwnedArticles] = useState([])
	const [isSubscribed, setIsSubscribed] = useState(false)
	const [role, setRole] = useState(0)
	const [isLoggedIn, setIsLoggedIn] = useState(false)
	const [isVerified, setIsVerified] = useState(false)
	const [awaitVerification, setAwaitVerification] = useState(false)

	const [followedTopics, setFollowedTopics] = useState([])
	const [notifications, setNotifications] = useState([])
	const [notificationsLastRead, setNotificationsLastRead] = useState(null);

	const [adminHarborIds, setAdminHarborIds] = useState([])

	const notificationsTimer = useRef(null);

	useEffect(()=>{
		authHandler();
		if (isLoggedIn && !notificationsTimer.current)
			notificationsTimer.current = setInterval(getNotifications, 180000);
	},[]);

	async function loginHandler(email, pass, cb) {try {
		const response = await fetch(SiteService.apiPath + `/user/login`, {
			method: "POST",
			body: JSON.stringify({
				email: email,
				password: pass
			}),
			headers: {
				'Content-Type': 'application/json'
			}
		});
		const data = await response.json();
		if (data.verified === false) {
			setAwaitVerification(true);
			return navigate('/verifiser');
		}
		if (data.success) {
			setName(data.name);
			setRole(data.role);
			setEmail(data.email);
			setIsLoggedIn(true);
			setIsVerified(data.isEmailVerified);
			setIsSubscribed(data.isSubscribed);
			setOwnedArticles(data.ownedArticles);
			setAdminHarborIds(data.adminHarborIds);
			setFollowedTopics(data.followedTopics);
			setNotifications(data.notifications? data.notifications.reverse():null);
			setNotificationsLastRead(data.notificationsLastRead);

			if (cb)
				cb(true)

			setTimeout(()=>{
				if (data.destination)
					return navigate(data.destination);

				if (window.location.pathname.indexOf("/login") > -1
					|| window.location.pathname.indexOf("/verifiser") > -1)
					return navigate('/');
			}, 1100);

		} else {
			if (cb)
				return cb(false,data);
		}
	} catch (error) {
		console.error(error);
		return cb(false);
	}}


	async function getSubscriptionInfoHandler() {
		let res = await fetch(SiteService.apiPath + '/user/subscription');
		if (!res.ok){
			return {}
		}
		res = await res.json();
		return res;
	}

	async function getOwnedArticlesHandler() {
		let res = await fetch(SiteService.apiPath + '/user/ownedarticles');
		if (!res.ok){
			return {}
		}
		res = await res.json();
		return res;
	}


	function authHandler(){
		console.log('authing..')
		fetch(SiteService.apiPath+'/user/auth', {method: 'POST'}).then((res) => {
			return res.json();
		})
		.then((data) => {
			if(data.verified === false){
				setAwaitVerification(true);
				navigate('/verifiser');
			}
			else if (data.success) {
				setName(data.name);
				setRole(data.role);
				setEmail(data.email);
				setIsLoggedIn(true);
				setIsVerified(data.isEmailVerified);
				setIsSubscribed(data.isSubscribed);
				setOwnedArticles(data.ownedArticles);
				setAdminHarborIds(data.adminHarborIds);
				setFollowedTopics(data.followedTopics);
				if (data.notifications)
					setNotifications(data.notifications.reverse());
				setNotificationsLastRead(data.notificationsLastRead);
			}else{
				//navigate('/');
			}
			setIsLoadingAuth(false);
		})
	}

	async function changeName(name){try {
		let res = await fetch(SiteService.apiPath + '/user/setname',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					updateObj: {name: name}
				}),
			});
		if (!res.ok) {
			return {}
			//todo handle error
		}

		setName(name);
	}catch (e) {
		console.error(e);
	}}

	async function followTopic(topicType, topicId) {
		let res = await fetch(SiteService.apiPath + '/user/followtopic', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				topicType: topicType,
				topicId: topicId
			}),
		});
		if (!res.ok) {
			alert('Feil ved følging av emnet');
			return false;
		}

		//get data from json
		let data = await res.json();

		//add to followed topics
		setFollowedTopics([...followedTopics, data]);
		return true;
	}

	async function unfollowTopic(topicType, topicId) {
		let res = await fetch(SiteService.apiPath + '/user/unfollowtopic', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				topicType: topicType,
				topicId: topicId
			}),
		});
		if (!res.ok) {
			alert('Feil ved av-følging av emnet');
			return false;
		}

		//remove from followed topic
		setFollowedTopics(prevFollowedTopics =>
			prevFollowedTopics.filter(
				topic => !(topic.topicType === topicType && topic.topicId === topicId)
			)
		);
		return true;
	}

	async function getNotifications(){
		let res = await fetch(SiteService.apiPath + '/user/notifications');
		if (!res.ok){
			return console.error('Feil under henting av varsler')
		}
		let data = await res.json();
		setNotifications(data.reverse());
	}

	async function updateNotificationsLastRead(){
		let res = await fetch(SiteService.apiPath + '/user/notifications/lastread', {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json'
			}
		});
		if (!res.ok) {
			console.error('Failed to update notifications last read:', res.statusText);
		}
		setNotificationsLastRead(new Date());
	}

	async function readNotification(notificationId) {try {
		// Send a PATCH request to update the notification's isRead status in the backend
		let res = await fetch(SiteService.apiPath + '/user/notifications/' + notificationId, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ isRead: true })
		});

		if (res.ok) {
			// Update the React state without needing the server response
			setNotifications(prevNotifications =>
				prevNotifications.map(notification =>
					notification._id === notificationId
						? { ...notification, isRead: true }
						: notification
				)
			);
		} else {
			console.error('Failed to update notification:', res.statusText);
		}
	} catch (error) {
		console.error('Error:', error);
	}}


	async function deleteNotification(notificationId) {
		let res = await fetch(SiteService.apiPath + '/user/notifications/'+notificationId, {
			method: 'DELETE',
		});
		if (!res.ok) {
			alert('Feil ved sletting av notifikasjon');
			return false;
		}

		//remove from notifications
		setNotifications(prevNotifications =>
			prevNotifications.filter(
				notification =>!(notification._id === notificationId)
			)
		);
		return true;
	}

	const context = {
		name: name,
		role: role,
		email: email,
		ownedArticles: ownedArticles,
		adminHarborIds: adminHarborIds,
		isSubscribed: isSubscribed,
		isLoggedIn: isLoggedIn,
		isVerified: isVerified,
		awaitVerification: awaitVerification,
		isLoadingAuth: isLoadingAuth,

		login: loginHandler,
		auth: authHandler,
		getSubscriptionInfo: getSubscriptionInfoHandler,
		changeName: changeName,

		followedTopics: followedTopics,
		followTopic: followTopic,
		unfollowTopic: unfollowTopic,
		setFollowedTopics: setFollowedTopics,

		notifications: notifications,
		getNotifications: getNotifications,
		deleteNotification: deleteNotification,
		readNotification: readNotification,
		updateNotificationsLastRead: updateNotificationsLastRead,
		notificationsLastRead: notificationsLastRead
	};

	return <UserContext.Provider value={context}>
		{props.children}
	</UserContext.Provider>
}

export default UserContext;