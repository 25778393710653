import advertService from "../../../services/AdvertService";
import SiteService from "../../../services/SiteService";
import {useContext, useRef, useState} from "react";
import adPlacementService from "../../../services/AdPlacementService";
import ContentBlockWrapper from "../ContentBlockWrapper";
import useInView from "../../../hooks/useInView";
import advertImage from "../../../images/adv-5.webp";
import advertMobileImage from "../../../images/adv-mob.webp";
import userContext from "../../../store/userContext";

export default function AdvertBlock5({parts, adPlacementId, customStyle}){ // brandboard
	if (!parts)
		parts = [{}];

	const id = adPlacementId?adPlacementId:parts[0].id;

	const [ad, setAd] = useState(null);

	const adRef = useRef();
	const role = useContext(userContext).role;
	const handleInView = () => {
		if(!id || ad) return;
		adPlacementService.getById(id, (ad)=>{
			setAd(ad);
		}, role > 0)
	};
	useInView(adRef, {rootMargin:'100px'}, handleInView, true);


	//todo remove this temporary video
	const [isTempVideo, setIsTempVideo] = useState(Math.random()>0.7);
	if(window.location.toString().indexOf('/artikkel') > -1 && isTempVideo) return(
		<ContentBlockWrapper customStyle={customStyle?customStyle:null}
		><a ref={adRef} className={'advertBlock5 advBlock contentBlock'} href={'https://baterisjoen.no/'} target={"_blank"}
			onClick={() => advertService.advertOnclick(ad._id)} style={{aspectRatio: 'auto',	width: '630px'}}>
			<video src={"https://b-v.no:443/api/video/stream/66b0bf6299ee93bb59d5b8b1"} autoPlay={true} muted={true} controls={false}
			unselectable={'true'} disablePictureInPicture={true} loop={true} disableRemotePlayback={true}
		   playsInline={true}/>
		</a></ContentBlockWrapper>
	)

	return <ContentBlockWrapper customStyle={customStyle?customStyle:null}
	><a ref={adRef} className={'advertBlock5 advBlock contentBlock'} href={ad&& ad.link ? ad.link : ''} target={"_blank"}
			  onClick={() => advertService.advertOnclick(ad._id)}>
		<picture>
			<source media="(max-width: 810px)"
					srcSet={(ad&&ad.wideImage) ? `${SiteService.apiPath}/image/adv/${ad.mobileImage}` : advertMobileImage}/>
			<img className="advertBlock5Image"
				 src={(ad&&ad.wideImage) ? `${SiteService.apiPath}/image/adv/${ad.wideImage}` : advertImage}
				 sizes="(max-width: 810px) 100vw, 810px"
				 alt="Annonse"/>
		</picture>
	</a></ContentBlockWrapper>
}