import React, {useRef, useState} from "react";
import "./ToggleSwitch.scss";

function ToggleSwitch({toggleRef, isToggled, setIsToggled, onToggle, toggleIsLoading}) {
	//const [isToggled, setIsToggled] = useState(false);
	const [isLoading, setIsLoading] = useState(false);


	if(!onToggle && setIsToggled)
		onToggle = () => setIsToggled(!isToggled);

	function stopLoading(){
		setIsLoading(false);
	}



	return (
		<label className={`toggle-switch ${isLoading ? 'toggle-switch-loading' : ''}`}>
			<input ref={toggleRef} type="checkbox" checked={isToggled&&isToggled} onChange={()=>{
				if(setIsToggled) {
					onToggle(stopLoading);
					setIsLoading(true)
				}
			}} />
			<span className="switch" />
		</label>
	);
}
export default ToggleSwitch;