import {useEffect, useState} from "react";
import SiteService from "../../services/SiteService";
import classes from "../batperm.module.scss";
import tableClasses from "../components/BatPermBoatList.module.scss";
import {Link} from "react-router-dom";

export default function BatpermQrCodes(){
	const [QRCodes, setQRCodes] = useState([]);

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		loadPage();
	}, []);

	const loadPage = async () => {
		await fetchQRCodes();
		await import('../../redis/qrcode.min.js');
		setIsLoading(false);
	}

	const fetchQRCodes = async () => {
		const response = await fetch(SiteService.apiPath + '/batperm/qrcodes');
		const data = await response.json();
		data.forEach((QRCode, i) => {
			if(QRCode.batpermBoatId) {
				data[i].boatName = QRCode.batpermBoatId.name;
				data[i].modelName = QRCode.batpermBoatId.modelName;
			}
		});
		console.log(data)
		setQRCodes(data);
	};


	const saveQRCode = async (QRCode) => {
		const response = await fetch(`${SiteService.apiPath}/batperm/qrcode/${QRCode._id}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(QRCode),
		});
		const data = await response.json();

		//set old QRCode in QRCodes to the one in data
		const index = QRCodes.findIndex((QRCode) => QRCode._id === data._id);
		QRCodes[index] = data;
		setQRCodes([...QRCodes]);
	};

	const createNewQRCode = async () => {
		const response = await fetch(SiteService.apiPath + '/batperm/qrcode', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
			})
		})
		const data = await response.json();
		setQRCodes([...QRCodes, data]);
	};

	const summonQRCode = (elId, url) => {
		const el = document.getElementById(elId);
		el.innerHTML = '';
		new window.QRCode(document.getElementById(elId), url);
		window.scrollTo(0, 0);
	}

	function QRCodeSingle({QRCode}){
		return(<tr>
			<td>{QRCode._id}</td>
			<td>{QRCode.modelName && QRCode.modelName}</td>
			<td>{QRCode.boatName && QRCode.boatName}</td>
			<td className={tableClasses.actions}>
				<Link to={`/batperm/qr/${QRCode._id}`}>Besøk</Link>
				<button onClick={(ev)=>{
					ev.preventDefault();
					summonQRCode('qrcode', `${SiteService.path}/batperm/qr/${QRCode._id}`);}
				}>
					Vis QR-kode
				</button>
			</td>
		</tr>);
	}




	if (isLoading) {return <div>
		<h1>QR-Koder</h1>
		<p>Laster...</p>
	</div>
	}


	return(

		<div>
			<h1>QR-Koder</h1>
			<div id={"qrcode"}></div>

			<div>
				<button onClick={(ev)=>{ev.preventDefault(); createNewQRCode();}}>Ny QR-kode</button>
			</div>

			<br/>

			<div className={tableClasses.tableContainer}>
				<table><tbody>
				<tr>
					<th>ID</th>
					<th>Båtmodell</th>
					<th>Båtnavn</th>
					<th></th>
				</tr>
				{QRCodes.map((QRCode, index) => {
					return <QRCodeSingle key={index} QRCode={QRCode}/>
				})}
				</tbody></table>
			</div>

		</div>

	)}