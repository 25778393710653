import {useRef} from "react";
import ContentRibbon from "../ContentRibbon";
import VideoPlayer from "../../ui/VideoPlayer";
import ContentBlockWrapper from "../ContentBlockWrapper";

export default function VideoBlockBig({parts, sectionTitle, customStyle, link}) {
	const videosContainerRef = useRef();

	return <ContentBlockWrapper customStyle={customStyle?customStyle:null}>
		<div className={'videoBlockBig contentBlock'}>
		{sectionTitle&&<ContentRibbon ribbonText={sectionTitle ? sectionTitle : 'Kategoriboks 1'} link={link}></ContentRibbon>}

		<div className={'videoContainer'} ref={videosContainerRef}>
				<VideoPlayer videoId={parts[0].id} />
		</div>
	</div>
	</ContentBlockWrapper>
}