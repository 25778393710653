import {useState, useEffect, useRef} from 'react';
import { createRoot } from 'react-dom/client';

function useDismissibleBanner(message, button, name, delay = 0) {
	const [isVisible, setIsVisible] = useState(false);
	const bannerElRef = useRef();

	useEffect(() => {
		const isBannerClosed = localStorage.getItem(name);
		if (!isBannerClosed) {
			const timer = setTimeout(() => {
				setIsVisible(true);
			}, delay);
			return () => clearTimeout(timer);
		}
	}, []);

	const closeBanner = () => {
		bannerElRef.current.style.transform = 'translateY(-100%)';
		setTimeout(() => {
			setIsVisible(false);
			localStorage.setItem(name, 'true');
		}, 500); // Duration matches CSS transition duration
	};

	useEffect(() => {
		if (!isVisible) return;

		const bannerElement = document.createElement('div');
		document.body.appendChild(bannerElement);
		setTimeout(()=>{
			bannerElRef.current.style.transform = 'translateY(0)'}, 100);

		const root = createRoot(bannerElement);

		const banner = (
			<div ref={bannerElRef}
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					right: 0,
					transform: 'translateY(-100%)',
					transition: 'transform 0.5s ease-out',
					backgroundColor: 'var(--theme-color2)',
					color: 'var(--theme-color1)',
					padding: '10px',
					textAlign: 'center',
					fontFamily: 'var(--font1)',
					display: 'flex',
					justifyContent: 'center',
					zIndex: 1000,
				}}>
				<div style={{maxWidth:'95vw', width:'800px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<div style={{width:'100%'}}>{message}</div>
					<button
						onClick={closeBanner}
						style={{
							marginLeft: '10px',
							float: 'right',
							background: 'none',
							border: 'none',
							color: 'black',
							cursor: 'pointer',
							fontSize: '20px'
						}}>
						×
					</button>
				</div>
			</div>
		);

		root.render(banner);

		return () => {
			root.unmount();
		};
	}, [isVisible, message, button]);

	return null; // No need to return anything since the banner is rendered automatically
}

export default useDismissibleBanner;
